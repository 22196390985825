<template>
  <modal ref="modalDetail"
         id="modal-connection-detail"
         modal-class="primary_modal"
         @hide="close"
         :hide-footer="true">
    <template #header>
    </template>
    <template #body>
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="container-form">
              <div class="avatar-container">
                <b-avatar size="156px" :src="form.avatar"/>
              </div>
              <b-form-group label-cols="4"
                            label="Librarian:"
                            label-for="input-sm">
                <div class="text-container">
                  <p class="text text--link" @click="onGoTeacherDetail(form.librarianId)">{{ form.fullName }}</p>
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="About Me:"
                            label-for="input-sm">
                <div class="text-container">
                  <p class="text">{{ form.aboutMe }}</p>
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Request Description:"
                            label-for="input-sm">
                <div class="text-container">
                  <p class="text">{{ form.requestDescription }}</p>
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Number of Students:"
                            label-for="input-sm">
                <div class="text-container">
                  <p class="text">{{ form.studentsNumber }}</p>
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Student Age Range:"
                            label-for="input-sm">
                <div class="text-container">
                  <p class="text">{{ form.ageRange }}</p>
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Course:"
                            label-for="input-sm">
                <div class="text-container">
                  <p class="text">{{ form.course }}</p>
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Topics of interest:"
                            label-for="input-sm">
                <b-list-group class="topics-list">
                  <b-list-group-item v-for="(topic, idx) in form.topics" :key="idx" class="d-flex justify-content-between align-items-center">
                    <span class="topic">
                      {{topic}}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Accepted"
                            label-for="input-sm"
                            v-if="form.accepted">
                <div class="text-container">
                  <p class="text">{{ setDate(form.accepted) }}</p>
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Partner"
                            label-for="input-sm"
                            v-if="form.accepted">
                <div class="text-container">
                  <p class="text text--link" @click="onGoTeacherDetail(form.partnerId)">{{ form.partner }}</p>
                </div>
              </b-form-group>

              <p class="text text--link text--link-connection" v-if="form.accepted" @click="goPartnerships(form.classroomCode)">View Connection</p>

              <b-form-group label-cols="4"
                            label="Inactive"
                            label-for="input-sm"
                            v-if="form.isConnectionInactive">
                <div class="text-container">
                  <p class="text text--inactive">{{ form.inactive }}</p>
                </div>
              </b-form-group>
            </div>
          </b-col>
          <b-overlay :show="loading"
                     no-wrap
                     spinner-medium
                     rounded="sm"/>
        </b-row>
      </b-container>
    </template>
  </modal>
</template>

<script>
import moment from "moment"
import { Modal } from '@/core/components'
import { LibrarianConnectionsService } from '@/core/services'
import { PartnershipsMixins } from '../Mixins'

export default {
  name: 'ModalConnectionDetail',
  mixins: [PartnershipsMixins],
  components: {
    Modal
  },
  props: {
    connectionId: {
      default: null
    }
  },
  data() {
    return {
      form: {
        avatar: '',
        fullName: '',
        aboutMe: '',
        requestDescription: '',
        studentsNumber: '',
        ageRange: '',
        course: '',
        topics: '',
        partner: '',
        accepted: '',
        inactive: '',
        classroomCode: ''
      },
      options: [],
      loading: false
    }
  },
  async mounted() {
  },
  methods: {
    onGoTeacherDetail(teacherId) {
      if (teacherId) {
        this.$router.push({
          path: `Teacher/${teacherId}`,
        })
      }
    },
    goPartnerships(id) {
      this.fetchPartnership(id)
      this.$router.push({name: 'Partnership'})
    },
    setDate (date) {
      return date ? moment(date).format('M/D/YYYY') : ''
    },
    open () {
      this.$refs.modalDetail.showModal()
      if (this.connectionId) {
        this.getAdminById()
      }
    },
    close() {
      this.form = {
        avatar: '',
        fullName: '',
        aboutMe: '',
        requestDescription: '',
        studentsNumber: '',
        ageRange: '',
        course: '',
        topics: '',
        partner: '',
        accepted: '',
        inactive: '',
        classroomCode: ''
      }
      this.$refs.modalDetail.hideModal()
    },
    getAdminById() {
      this.loading = true
      LibrarianConnectionsService.getConnectionsRequestById(this.connectionId).then((reponse) => {
        const data = reponse.data
        if (reponse.success) {
          this.form = {
            avatar: data.avatar,
            fullName: data.name,
            aboutMe: data.about_me,
            requestDescription: data.request_description,
            studentsNumber: data.number_of_students,
            ageRange: data.age_range?.name,
            course: data.course,
            topics: data.topics_of_interest.length ? data.topics_of_interest : ['Any'],
            partner: data.partner?.full_name,
            accepted: data.accepted,
            inactive: data.inactive,
            classroomCode: data.classroom_code,
            librarianId: data.librarian_id,
            partnerId: data.librarian_partner_id,
            isConnectionInactive: data.is_connection_inactive
          }
        }
      }).finally(() => this.loading = false)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.avatar-container
  width 100%
  display flex
  justify-content center
  padding-bottom 40px

.subtitle
  text-transform uppercase
  font-size 14px
  font-weight bold
  margin-top 1rem

.text-container
  border solid 2px color-blue-100
  background-color color-40
  border-radius 5px
  padding 12px 16px
  margin-bottom 12px

.text
  margin 0
  font-size 14px
  color color-blue-200
  display inline-block

.text--link
  cursor pointer
  text-decoration underline
  color color-blue-400

.text--inactive
  text-transform capitalize

.text--link-connection
  margin-bottom 20px !important

.topics-list
  max-height 300px
  overflow-y auto
  padding-bottom 12px

  .topic
    text-overflow ellipsis
    width 200px
    white-space nowrap
    overflow hidden

.container-form
  margin 2rem 0

.input-error, .input-error:focus
  border-color red

.error
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin 5px 0

.custom-checkbox
  margin 20px 0
  ::v-deep
    .custom-control-label
      padding-left 10px
      font font-opensans-semibold !important

      &::after
        font-weight 400
        font-size 18px !important

.flex-column
  margin-top 30px

</style>

<style lang="stylus">
#modal-connection-detail
  .modal-dialog
    min-width unset

</style>
