import {
  FETCH_COURSE_LIST_START,
  FETCH_COURSE_LIST_DATA,
  FETCH_COURSE_DETAIL_DATA,
  FETCH_COURSE_TASK_DETAIL,
  FETCH_TASK_LIST_DATA
} from './mutation-types'
import { CourseService, UploadService } from '../../services/'

export const actions = {
  fetchCoursesList ({ commit }, { filters, pagination, sortBy }) {
    commit(FETCH_COURSE_LIST_START)
    return CourseService.getCourseList(filters, pagination, sortBy).then((res) => {
      const course = res.data.map((elem) => ({
        name: {
          course_name: elem.name.length >= 15 ? `${elem.name.slice(0, 12)}...`: elem.name,
          course_full_name: elem.name,
          id: elem.id
        },
        subject: elem.subject.name,
        free_paid: elem.premium ? 'Paid' : 'Free',
        status: elem.published ? 'Published' : 'Not Published',
        published: elem.published,
        id: elem.id,
        weeks: elem.weeks ? elem.weeks : 0,
      }))
      const pag = {
        currentPage: res.current_page,
        perPage: res.per_page,
        totalRow: res.next_page_url ? ((Number(res.current_page) + 1) * res.per_page) : (res.current_page * res.per_page)
      }
      commit(FETCH_COURSE_LIST_DATA, { course, pag })
      return course
    })
  },
  fetchCoursesDetail ({ commit }, id) {
    if (id) {
      return CourseService.getCourseById(id).then((res) => {
        const data = res.data
        const course = {
          courseName: data.name,
          min_age: data.min_age,
          max_age: data.max_age,
          level: data.level_id || null,
          languages: data.languages.map(e => e.id),
          subject: data.subject_id || null,
          duration: data.weeks,
          publish: !!data.published,
          sumary: data.pdf_url,
          courseStudentGroupTypeId: data.course_student_group_type_id,
          courseGuideLink: data.course_guide_link,
          topic: data.topic_id ? data.topic_id : 'none'
        }
        commit(FETCH_COURSE_DETAIL_DATA, course)
      })
    } else {
      commit('resetCourseDetail')
    }
  },
  fetchTaskList ({ commit }, courseId) {
    let studentTasks = []
    let teacherTasks = []
    if (courseId) {
      return CourseService.getListTaskCourse(courseId).then((res) => {
        teacherTasks = res.data.teacher.map((elem) => ({
          task_name: { name: elem.task_name, id: elem.id },
          topic: elem.topic,
          order: elem.order,
          visible: !!elem.published
        }))
        studentTasks = res.data.student.map((elem) => ({
          task_name: { name: elem.task_name, id: elem.id },
          topic: elem.topic,
          order: elem.order,
          visible: !!elem.published
        }))
        commit(FETCH_TASK_LIST_DATA, { studentTasks, teacherTasks })
      })
    }
    commit(FETCH_TASK_LIST_DATA, { studentTasks, teacherTasks })
    return Promise.resolve({ studentTasks, teacherTasks })
  },
  fetchTaskDetails ({ commit }, { courseId, taskId }) {
    return Promise.resolve(
      CourseService.getTaskById(courseId, taskId).then(async (res) => {
        let task = res.data
        const taskMedia = task.media
        if (taskMedia.length) {
          const mediaSignedUrl = JSON.parse(JSON.stringify(taskMedia))
            for (let i = 0; i < taskMedia.length; i++) {
              const attachment = mediaSignedUrl[i]
              if (attachment.type === 'transcoder') {
                const url = attachment.url.split('/')
                const key = url[url.length - 1]
                attachment.signedUrl = await UploadService.getVideo(key)
              }
            }
          task.media = mediaSignedUrl
        }
        commit(FETCH_COURSE_TASK_DETAIL, task)
      }))
  },
  resetTaskDetail ({ commit }) {
    commit('resetTaskDetail')
  },
  resetCourseDetail ({ commit }) {
    commit('resetCourseDetail')
  },
  updateVideoTimeLimit ({ commit }, videoTimeLimit) {
    commit('updateVideoTimeLimit', videoTimeLimit)
  }
}
