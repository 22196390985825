<template>
  <div>
    <b-overlay :show="loading"
               spinner-medium
               rounded="sm">
      <b-col class="container-actions">
        <select-dropdown :options="options"
                         v-model="roleSelected"
                         @input="handleDropdown"
                         class="--secundary"
                         value-field="slug"/>
        <search-input class="--secundary"
                      v-model="search"
                      placeholder="ADMIN NAME SEARCH"
                      @on-search="handlerSearch"/>
        <b-button @click="openAdminDetail(null)" class="button button--resize">Add Admin</b-button>
      </b-col>
      <b-table :responsive="true"
               hover
               sort-icon-left
               :fields="fields"
               :items="adminList"
               table-variant="ligth"
               head-variant="ligth"
               no-local-sorting
               id="admin-table"
               ref="table">
        <template #cell(name)="data">
          <a class="a-title a-title--name"
             @click="openAdminDetail(data.item.id)">
             {{ data.item.first_name }} {{ data.item.last_name }}</a>
        </template>
        <template #cell(email_address)="data">
          <span class="a-title a-title--email">{{ data.item.user && data.item.user.email }}</span>
        </template>
        <template #cell(status)="data">
          {{ setStatus(data.item.active) }}
        </template>
        <template #cell(role)="data">
          {{ data.item.user && setRoles(data.item.user.roles) }}
        </template>
      </b-table>
    </b-overlay>
    <modal-admin-detail ref="modalAdminDetail"
                        :admin-id="adminSelectedId"
                        @admin-saved="updateAdminList"/>
  </div>
</template>

<script>
import { AlertMixins } from '../Mixins'
import { SearchInput, SelectDropdown } from '@/core/components'
import ModalAdminDetail from './ModalAdminDetail.vue'
import { AdministratorsService } from '@/core/services'
export default {
  name: 'AdministerAdmins',
  mixins: [AlertMixins],
  components: {
    SearchInput,
    ModalAdminDetail,
    SelectDropdown
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'email_address',
          label: 'EMAIL ADDRESS'
        },
        {
          key: 'status',
          label: 'STATUS'
        },
        {
          key: 'role',
          label: 'ROLE'
        }
      ],
      options: [{ name: 'All', slug: 'all'}],
      adminList: [],
      roleSelected: 'all',
      search: '',
      loading: false,
      adminSelectedId: null
    }
  },
  async mounted() {
    this.loading = true
    await AdministratorsService.getAdminRoles().then((response) => {
      if (response.success) {
        this.options = [{ name: 'All', slug: 'all'}, ...response.data]
      }
    })
    this.getAdmins()
  },
  methods: {
    getAdmins() {
      this.loading = true
      let data = {
        filter_by: this.roleSelected
      }
      if (this.search) {
        data.search_string = this.search
      }
      AdministratorsService.getAdministrators(data).then((response) => {
        if (response.success) {
          this.adminList = response.data
          if (!this.adminList.length) {
            this.$bvModal.msgBoxOk('No matches found.').then(() => {
              this.search = ''
              this.roleSelected = 'all'
              this.getAdmins()
            })
          }
        } else {
          const errorMessage = response.message ? response.message : 'Error.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }).finally(() => this.loading = false)
    },
    updateAdminList() {
      this.getAdmins()
    },
    handlerSearch() {
      this.getAdmins()
    },
    handleDropdown() {
      this.search = ''
      this.getAdmins()
    },
    addAdmin() {
      this.$refs.modalAdminDetail.open()
    },
    openAdminDetail(adminId) {
      if (adminId) {
        this.adminSelectedId = adminId
      } else {
        this.adminSelectedId = null
      }
      this.$nextTick(() => this.$refs.modalAdminDetail.open())
    },
    setStatus(status) {
      return status ? 'Enabled' : 'Disabled'
    },
    setRoles(roles) {
      const adminRoles = roles.map((rol) => rol.name).join(',')
      return adminRoles
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.container-actions
  display flex
  justify-content space-between
  margin-bottom 10px

  @media(max-width 800px)
    display: block

  .--secundary
    max-width 400px
    margin-right 10px
    margin-top 10px

.button--resize
  padding 5px 40px
  margin-top 10px
  height auto !important

.a-title--name, .a-title--email
  text-overflow ellipsis
  overflow hidden
  max-width 250px
  white-space nowrap
  display block
  cursor pointer
  @media(max-width 1000px)
    max-width 200px
  @media(max-width 800px)
    max-width 100px

.a-title--email
 cursor auto
</style>
