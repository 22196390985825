import {http} from './http'
import {exportFiles} from '../utils'

const resource = 'api/admin'

export default {
  getTeacherList(filters, sort) {
    const params = {
      sort_by: sort
    }
    if (filters.filterBy) {
      params.filter_by = filters.filterBy
    }
    if (filters.text) {
      params.search_string = filters.text
    }
    if (filters.startDate) {
      params.start_sing_up_date = filters.startDate
    }
    if (filters.endDate) {
      params.end_sing_up_date = filters.endDate
    }
    return http.get(`${resource}/teachers`, {params}).then(response => {
      return response
    })
  },
  exportTeacherList({filters}) {
    const params = {}
    if (filters.filterBy) {
      params.filter_by = filters.filterBy
    }
    if (filters.text) {
      params.search_string = filters.text
    }
    if (filters.startDate) {
      params.start_sing_up_date = filters.startDate
    }
    if (filters.endDate) {
      params.end_sing_up_date = filters.endDate
    }
    return http.get(`${resource}/teachers/export`, {
      params,
      responseType: 'blob',
      headers: {
        'content-type': 'application/vnd.ms-excel;charset=UTF-8'
      }
    }).then(response => exportFiles(response, 'teachers.xlsx'))
  },
  exportTeachersClassroom({filters}) {
    const params = {}
    if (filters.filterBy) {
      params.filter_by = filters.filterBy
    }
    if (filters.text) {
      params.search_string = filters.text
    }
    if (filters.startDate) {
      params.start_sing_up_date = filters.startDate
    }
    if (filters.endDate) {
      params.end_sing_up_date = filters.endDate
    }
    return http.get(`${resource}/teachers/export-classrooms`, {
      params,
      responseType: 'blob',
      headers: {
        'content-type': 'application/vnd.ms-excel;charset=UTF-8'
      }
    }).then(response => exportFiles(response, 'teachers-classrooms.xlsx'))
  },
  getTeacherById(id) {
    return http.get(`${resource}/teachers/${id}`).then(response => {
      return response
    })
  },
  updateTeacher(id, data) {
    return http.put(`${resource}/teachers/${id}`, data).then(response => {
      return response
    })
  },
  getClassroomsTeacher(id) {
    return http.get(`${resource}/teachers/${id}/classrooms`).then(response => {
      return response
    })
  },
  updateTeacherClassroom(idTeacher, idClassrooms, data) {
    return http.put(`${resource}/teachers/${idTeacher}/classrooms/${idClassrooms}`, data).then(response => {
      return response
    })
  },
  getTeacherClassroomById(idTeacher, idClassrooms) {
    return http.get(`${resource}/teachers/${idTeacher}/classrooms/${idClassrooms}`).then(response => {
      return response
    })
  },
  deleteClassroom(idTeacher, idClassroom) {
    return http.delete(`${resource}/teachers/${idTeacher}/classrooms/${idClassroom}`).then(response => {
      return response
    })
  },
  masquerade(idTeacher) {
    return http.post(`${resource}/teachers/${idTeacher}/token`)
  },
  getVerificationEmailUrl(idTeacher) {
    return http.get(`${resource}/teachers/${idTeacher}/verification-email/url`).then(response => {
      return response
    })
  },
  verificationEmail(idTeacher) {
    return http.post(`${resource}/teachers/${idTeacher}/verification-email/resend`)
  },
  schools(country_id, state_id) {
    return http.get(`${resource}/countries/${country_id}/states/${state_id}/schools`).then(response => {
      return response
    })
  },
  states(country_id) {
    return http.get(`${resource}/countries/${country_id}/states`).then(response => {
      return response
    })
  },
  createTeacher(data) {
    return http.post(`${resource}/teachers`, data)
  },
  updateTeacherUserType(teacherId, data = {}) {
    return http.put(`${resource}/teachers/${teacherId}`, data).then(response => {
      return response
    })
  },
  listTeacherReports(teacherId) {
    return http.get(`${resource}/teachers/${teacherId}/reports`).then(response => {
      return response
    })
  },
  deleteTeacher(idTeacher) {
    return http.delete(`${resource}/teachers/${idTeacher}`).then(response => {
      return response
    })
  },
  teacherTypes() {
    return http.get(`${resource}/teachers/types`).then(response => {
      return response
    })
  },
}
