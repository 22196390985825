<template>
  <div>
    <b-overlay
        :show="isLoadingCourses"
        spinner-medium
        rounded="sm">
    <b-row class="mx-3 my-1">
      <b-col lg="3" class="my-1">
        <select-dropdown
          :options="options"
          v-model="selected"
          placeholder="FILTER BY"
          value-field="value"
          class-select="--secundary">
        </select-dropdown>
      </b-col>
      <b-col lg="8" class="my-1">

        <select-dropdown v-if="selected === 'subject'" @input="getCoursesForClassroom"
                          placeholder="SUBJECTS" v-model="search"
                          :options="getSubjects"
                          class-select="custom-dropdown--md --secundary"/>
        <select-dropdown v-else-if="selected === 'language'" @input="getCoursesForClassroom"
                          placeholder="LANGUAGES" v-model="search"
                          :options="getLanguages"
                          class-select="custom-dropdown--md --secundary"/>
        <select-dropdown v-else-if="selected === 'level'" @input="getCoursesForClassroom"
                          placeholder="LEVELS" v-model="search"
                          :options="getLevels"
                          class-select="custom-dropdown--md --secundary"/>
        <select-dropdown v-else-if="selected === 'age_range'" @input="getCoursesForClassroom"
                          placeholder="AGE RANGES"
                          v-model="search" :options="getAgeRanges"
                          class-select="custom-dropdown--md --secundary"/>

        <search-input v-else v-model="search" @on-search="handlerSearch"></search-input>

      </b-col>
    </b-row>
      <b-table
        v-show="getCourses.length"
        responsive
        hover
        sort-icon-left
        :fields="fields"
        :items="getCourses"
        table-variant="ligth"
        head-variant="ligth"
        no-local-sorting
        @sort-changed="sortingChanged"
        :current-page="getPagination.currentPage"
        :per-page="0"
        id="table"
        ref="table">
          <template  #cell(name)="data">
            <a class="a-title mx-2" @click="goTo(data.value.id)" > {{data.value.course_name}}</a>
          </template>
          <template #cell(status)="status">
            <b-badge  :class="status.value === 'Published'? 'published' : 'not-published' " v-html="status.value"></b-badge>
          </template>
          <template  #cell(free_paid)="data" >
            <span class="mx-2" >{{data.item.free_paid}}</span>
          </template>
          <template  #cell(subject)="data">
            <span>{{data.item.subject}}</span>
          </template>
      </b-table>

    <b-row class=" justify-content-md-center">
      <b-col  class="col-2 mx-auto">
        <b-pagination
          v-show="showPagination"
          v-model="getPagination.currentPage"
          :total-rows="getPagination.totalRow"
          :per-page="getPagination.perPage"
          @change="changePage"
          first-number
          last-number
          align="fill"
          class="my-1">
      <template #prev-text><i class="icon-chevron-left"></i></template>
      <template #next-text><i class="icon-chevron-right"></i></template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row >
      <br>
      <b-col md="8" lg="4" class="pb-2">
        <b-button @click="navigate" class="button primary button--resize">create new course</b-button>
      </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { SelectDropdown, SearchInput } from '@/core/components/'
import { CourseMixins, UtilsMixins } from '../Mixins'

export default {
  name: 'CourseContent',
  components: {
    SelectDropdown,
    SearchInput
  },
  mixins: [CourseMixins, UtilsMixins],
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: 'COURSE NAME',
          sortable: true,
        },
        {
          key: 'subject',
          label: '  SUBJECT ',
          sortable: true
        },
        {
          key: 'free_paid',
          label: 'FREE / PAID',
          sortable: true,
          class: 'free'
        },
        {
          key: 'status',
          label: 'STATUS',
          sortable: false,
          class: 'status'
        }
      ],
      loadingCourse: false,
      options: [
        { name: 'Subject', value: 'subject' },
        { name: 'Level', value: 'level' },
        { name: 'Age', value: 'age_range' },
        { name: 'Language', value: 'language' },
        { name: 'All', value: 'all' }
      ],
      selected: null,
      search: '',
      sortBy: ''
    }
  },
  beforeMount () {
    this.loadingCourse = true
  },
  mounted () {
    this.fetchCoursesList({ filters: { text: '', filterBy: '' }, pagination: 1, sortBy: '' })
    this.fetchAgeRange()
    this.fetchLanguages()
    this.fetchLevels()
    this.fetchSubjects()
  },
  methods: {
    handlerSearch (arg) {
      this.fetchCoursesList({ filters: { filterBy: this.selected, text: this.search }, pagination: 1, sortBy: this.sortBy })
      return arg
    },
    navigate () {
      this.$router.push({ name: 'DetailCourse' })
    },
    goTo (id) {
      this.$router.push({ path: `/PanelAdmin/Course/${id}` })
    },
    changePage (arg) {
      this.fetchCoursesList({ filters: { filterBy: this.selected, text: this.search }, pagination: arg, sortBy: this.sortBy })
    },
    sortingChanged (arg) {
      if (arg.sortBy === 'name') {
        this.sortBy = arg.sortDesc ? 'course_name_desc' : 'course_name'
      } else if (arg.sortBy === 'free_paid') {
        this.sortBy = arg.sortDesc ? 'premium_desc' : 'premium'
      } else {
        this.sortBy = arg.sortDesc ? 'subject_desc' : 'subject'
      }
      this.fetchCoursesList({ filters: { filterBy: this.selected, text: this.search }, pagination: 1, sortBy: this.sortBy })
    },
    getCoursesForClassroom(search) {
      this.fetchCoursesList({ filters: { filterBy: this.selected, text: search }, pagination: 1, sortBy: this.sortBy })
    }
  },
  computed: {
    showPagination () {
      return this.getPagination.totalRow > 10
    }
  },
  watch: {
    getCourses(newValue) {
      if(!newValue.length && this.search){
        this.fetchCoursesList({ filters: { text: '', filterBy: '' }, pagination: 1, sortBy: '' })
        this.selected= null,
        this.search= '',
        this.sortBy= ''
        this.$bvModal.msgBoxOk(`No Course were found matching the entered criteria..`)
      }
    },
    selected(newValue) {
      this.search= ''
      this.fetchCoursesList({ filters: { filterBy: newValue }, pagination: 1, sortBy: this.sortBy })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.button--resize
  width auto !important
  padding 0 58.5px

@media (max-width 600px)
  .button--resize
    padding 0 20px
</style>
