<template>
  <div>
    <b-overlay
      :show="isLoadingAlerts || isLoadingAlertDetail"
      spinner-medium
      rounded="sm">
      <b-col class="container-actions">
        <select-dropdown class-select="--secundary"
                         :options="userDropdwn"
                         v-model="selectedFilterBy"
                         placeholder="FILTER BY"
                         value-field="value"/>
        <b-button @click="navigate" class="button button--resize">create new alert</b-button>
      </b-col>
      <b-table
        :responsive="true"
        hover
        sort-icon-left
        :fields="fields"
        :items="getAlerts"
        table-variant="ligth"
        head-variant="ligth"
        no-local-sorting
        class="alert-table"
        id="alert-table"
        ref="table">
        <template #cell(message)="data" class="description-alert">
          <a class="a-title description-alert" @click="goTo(data.item.id)">{{removeTags(data.item.message)}}</a>
        </template>
        <template #cell(starts_at)="data">
          {{ setDate(data.item.starts_at) }}
        </template>
        <template #cell(ends_at)="data">
          {{ setDate(data.item.ends_at) }}
        </template>
        <template #cell(user)="data">
          {{ data.item.user_type_id === 3 ? 'Teacher' : 'Student' }}
        </template>
        <template #cell(delete)="data">
          <b-button @click="onDelete(data.item.id)" class="button">Delete</b-button>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import { AlertMixins } from '../Mixins'
import { SelectDropdown } from '@/core/components'
import { AlertsService } from '@/core/services'
import moment from 'moment'

export default {
  name: 'AlertsList',
  mixins: [AlertMixins],
  components: {
    SelectDropdown
  },
  data() {
    return {
      fields: [
        {
          key: 'message',
          label: 'DESCRIPTION'
        },
        {
          key: 'starts_at',
          label: 'STARTS AT'
        },
        {
          key: 'ends_at',
          label: 'ENDS AT'
        },
        {
          key: 'user',
          label: 'USER'
        },
        {
          key: 'delete',
          label: ''
        }
      ],
      userDropdwn: [
        {
          name: 'Teachers',
          value: 'teachers',
        },
        {
          name: 'Students',
          value: 'students',
        },
        {
          name: 'All',
          value: 'all',
        }
      ],
      selectedFilterBy: 'all',
      isLoadingAlertDetail: false
    }
  },
  async mounted() {
    await this.fetchAlertsList().catch((e) => {
      const errorMessage = e.message ? e.message : 'Error.'
      this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
    })
  },
  methods: {
    async goTo(id) {
      this.$router.push({path: `/PanelAdmin/alerts/${id}`})
    },
    async navigate() {
      await this.resetAlertDetail()
      this.$router.push({name: 'AlertDetail'})
    },
    setDate(date) {
      return moment(date).format('MM/DD/YYYY, h:mm a')
    },
    removeTags(string) {
      const regex = /(<([^>]+)>)/ig
      return string.replace(regex, " ").trim()
    },
    onDelete(alertId) {
      this.isLoadingAlertDetail = true
      AlertsService.deleteAlert(alertId).then(() => {
        this.$bvModal.msgBoxOk('Successful Deleted')
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error delete alert.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      }).finally(() => {
        this.fetchAlertsList()
        this.isLoadingAlertDetail = false
      })
    },
  },
  watch: {
    async selectedFilterBy (newFilterValue) {
      await this.fetchAlertsList(newFilterValue).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.container-actions
  display flex
  justify-content space-between
  margin-bottom 10px

  @media(max-width 800px)
    display: block

  .--secundary
    max-width 500px
    margin-top 10px

.button--resize
  padding 5px 40px
  margin-top 10px
  height auto !important

.alert-table
  overflow-x auto

  ::v-deep
    #alert-table
      min-width 600px

.description-alert
  text-overflow ellipsis
  overflow hidden
  max-width 400px
  white-space nowrap
  display block
  cursor pointer
  @media(max-width 1000px)
    max-width 200px
  @media(max-width 800px)
    max-width 100px

</style>
