<template>
  <div>
    <b-overlay :show="loading"
               spinner-medium
               rounded="sm">
      <b-col class="container-actions">
        <select-dropdown :options="options"
                         v-model="filterSelected"
                         @input="handleDropdown"
                         class="role-dropdown --secundary"
                         value-field="slug"/>
        <search-input class="search-input --secundary"
                      v-model="search"
                      v-if="false"
                      placeholder="LIBRARY NAME SEARCH"
                      @on-search="handlerSearch"/>
      </b-col>
      <b-table :responsive="true"
               hover
               sort-icon-left
               :fields="fields"
               :items="connectionList"
               @sort-changed="sortingChanged"
               table-variant="ligth"
               head-variant="ligth"
               no-local-sorting
               id="library-table"
               ref="table"
               @row-clicked="showConnectionDetail">
        <template #cell(date)="data">
          <span class="a-title">{{ setDate(data.item.date) }}</span>
        </template>
        <template #cell(user)="data">
          <span class="a-title a-title--name">{{ data.item.user }}</span>
        </template>
        <template #cell(accepted)="data">
          <span class="a-title">{{ setDate(data.item.accepted) }}</span>
        </template>
        <template #cell(partner)="data">
          <span class="a-title a-title--name">{{ data.item.partner }}</span>
        </template>
      </b-table>
      <b-row class="justify-content-md-center">
        <b-col md="3" class="mx-auto">
          <b-pagination v-show="showPagination && !loading"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        @change="changePagination"
                        :per-page="perPage"
                        aria-controls="teachers-table"
                        first-number
                        last-number
                        align="fill"
                        size="sm"
                        class="my-2">
            <template #prev-text><i class="icon-chevron-left"></i></template>
            <template #next-text><i class="icon-chevron-right"></i></template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-overlay>
    <modal-connection-detail ref="modalConnectionDetail"
                             :connectionId="connectionSelected.id" />
  </div>
</template>

<script>
import moment from "moment"
import { AlertMixins } from '../Mixins'
import { SearchInput, SelectDropdown } from '@/core/components'
import ModalConnectionDetail from './ModalConnectionDetail'
import { LibrarianConnectionsService } from '@/core/services'

export default {
  name: 'LibraryConnectionList',
  mixins: [AlertMixins],
  components: {
    SearchInput,
    SelectDropdown,
    ModalConnectionDetail
  },
  data() {
    return {
      fields: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'user',
          label: 'User',
          sortable: true
        },
        {
          key: 'accepted',
          label: 'ACCEPTED',
          sortable: true
        },
        {
          key: 'partner',
          label: 'PARTNER'
        }
      ],
      options: [
        { name: 'All', slug: 'all'},
        { name: 'Connected', slug: 'connected'},
        { name: 'Active', slug: 'active'},
        { name: 'Inactive', slug: 'inactive'},
      ],
      connectionList: [],
      filterSelected: 'all',
      search: '',
      loading: false,
      connectionSelectedId: null,
      currentPage: 1,
      perPage: 10,
      totalRows: '',
      connectionSelected: {},
      sortBy: {}
    }
  },
  async mounted() {
    this.getLibraryList()
  },
  computed: {
    showPagination() {
      return this.totalRows > this.perPage
    },
  },
  methods: {
    changePagination(page) {
      this.getLibraryList(page)
    },
    sortingChanged(arg) {
      this.sortBy = {
        field: arg.sortBy,
        order: arg.sortDesc ? 'desc' : 'asc'
      }
      this.getLibraryList()
    },
    showConnectionDetail (rowSelected) {
      this.connectionSelected = rowSelected
      this.$nextTick(() => {
        this.$refs.modalConnectionDetail.open()
      })
    },
    setDate (date) {
      return date ? moment(date).format('M/D/YYYY') : ''
    },
    getLibraryList(page = 1) {
      this.loading = true
      let data = {
        type: this.filterSelected
      }
      if (this.search) {
        data.name = this.search
      }
      LibrarianConnectionsService.getConnectionsRequest(data, this.sortBy, page).then((response) => {
        if (response.success) {
          this.connectionList = response.data
          this.perPage = response.per_page
          this.currentPage = response.current_page
          this.totalRows = response.next_page_url ? ((Number(response.current_page) + 1) * response.per_page) : (response.current_page * response.per_page)

          if (!this.connectionList.length && this.search) {
            this.$bvModal.msgBoxOk('No matches found.').then(() => {
              this.search = ''
              this.filterSelected = 'all'
              this.getLibraryList()
            })
          }
        } else {
          const errorMessage = response.message ? response.message : 'Error.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }).finally(() => this.loading = false)
    },
    updateConnectionList() {
      this.getLibraryList()
    },
    handlerSearch() {
      this.getLibraryList()
    },
    handleDropdown() {
      this.search = ''
      this.getLibraryList()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.container-actions
  display flex
  justify-content space-between
  margin-bottom 10px

  .--secundary
    max-width 400px
    margin-right 10px
    margin-top 10px

  .role-dropdown
    max-width 350px

  @media(max-width 900px)
    display block

    .role-dropdown
      max-width 400px

  .search-input
    max-width unset

.button--resize
  padding 5px 40px
  margin-top 10px
  height auto !important
  min-width 250px
  @media(max-width 550px)
    min-width auto

.a-title--name
  text-overflow ellipsis
  overflow hidden
  max-width 250px
  white-space nowrap
  display block
  @media(max-width 1000px)
    max-width 200px
  @media(max-width 800px)
    max-width 100px
</style>
