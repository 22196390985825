<template>
  <modal ref="modal-courses-list"
         modal-class="primary_modal"
         :hideFooter="true">
    <template #body>
      <div class="wrapper-classroom-content__inner">
        <h2 class="title">Courses</h2>
        <div class="container-course-content">
          <div class="container-header-filters">
            <div class="container-item-filter container-filter-by">
              <select-dropdown placeholder="FILTER BY"
                               value-field="value"
                               v-model="filter.filter_by"
                               :options="filters"
                               @input="getCoursesFilterBy"
                               class-select="custom-dropdown--md custom-dropdown--secondary"/>
            </div>
            <div class="container-item-filter">
              <select-dropdown v-if="filterBy === 'subject'"
                               @input="getCoursesForClassroom"
                               placeholder="SUBJECTS"
                               v-model="filter.search_string"
                               :options="getSubjects"
                               class-select="custom-dropdown--md custom-dropdown--secondary"/>
              <select-dropdown v-else-if="filterBy === 'language'"
                               @input="getCoursesForClassroom"
                               placeholder="LANGUAGES"
                               v-model="filter.search_string"
                               :options="getLanguages"
                               class-select="custom-dropdown--md custom-dropdown--secondary"/>
              <select-dropdown v-else-if="filterBy === 'level'"
                               @input="getCoursesForClassroom"
                               placeholder="LEVELS"
                               v-model="filter.search_string"
                               :options="getLevels"
                               class-select="custom-dropdown--md custom-dropdown--secondary"/>
              <select-dropdown v-else-if="filterBy === 'age_range'"
                               @input="getCoursesForClassroom"
                               placeholder="AGE RANGES"
                               v-model="filter.search_string"
                               :options="getAgeRanges"
                               class-select="custom-dropdown--md custom-dropdown--secondary"/>
              <div v-else class="common-search-bar-container">
                <input v-on:keyup.enter="getCoursesForClassroom"
                       type="text"
                       placeholder="SEARCH"
                       class="common-search-bar"
                       v-model="filter.search_string">
              </div>
            </div>
          </div>
        </div>
        <div class="separator-filters"/>
        <div class="container-course-content">
          <div class="container-courses">
            <CourseCard v-for="(course) in getCourses"
                        :course="course"
                        :key="`course-${course.id}`"
                        @on-add-course-content="addCourse"/>
            <b-overlay no-wrap :show="isLoadingCourses"/>
          </div>
        </div>
      </div>
      <b-row class=" justify-content-md-center">
        <b-col  class="col-2 mx-auto">
          <b-pagination v-show="showPagination"
                        v-model="getPagination.currentPage"
                        :total-rows="getPagination.totalRow"
                        :per-page="getPagination.perPage"
                        @change="changePage"
                        first-number
                        last-number
                        align="fill"
                        class="my-1 mt-4">
              <template #prev-text><i class="icon-chevron-left"></i></template>
              <template #next-text><i class="icon-chevron-right"></i></template>
          </b-pagination>
        </b-col>
      </b-row>
      <div class="container-button">
        <b-button @click="closeModal"
                  class="button button--cancel">
                  Cancel
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from '@/core/components/'
import { UtilsMixins, CourseMixins } from '@/modules/panel/Mixins'
import SelectDropdown from '../SelectDropdown/SelectDropdown.vue';
import CourseCard from './CourseCard.vue';

export default {
  name: "CoursesList",
  mixins: [UtilsMixins, CourseMixins],
  components: {
    Modal,
    'select-dropdown': SelectDropdown,
    CourseCard
  },
  data() {
    return {
      filter: {
        filter_by: 'published',
        search_string: null
      }
    }
  },
  props: {
    classroom_id: {
      type: Number,
    },
    userType: {
      type: String
    }
  },
  async mounted() {
    this.fetchAgeRange()
    this.fetchLanguages()
    this.fetchLevels()
    this.fetchSubjects()
  },
  methods: {
    getCoursesFilterBy() {
      this.filter.search_string = ''
      this.getCoursesForClassroom()
    },
    getCoursesForClassroom() {
      if (typeof this.filter.search_string === 'string' && this.filter.filter_by === 'published' && this.filter.search_string.length > 1) {
        this.fetchCoursesList({ filters: { filterBy: 'all', text: this.filter.search_string, userType: this.userType }, pagination: 1 })
      }
      if (typeof this.filter.search_string === 'string' && this.filter.filter_by === 'published' && this.filter.search_string.length < 1) {
        this.fetchCoursesList({ filters: { filterBy: this.filter.filter_by, text: this.filter.search_string, userType: this.userType }, pagination: 1 })
      }
      if (this.filter.search_string && this.filter.filter_by !== 'published') {
        this.fetchCoursesList({ filters: { filterBy: this.filter.filter_by, text: this.filter.search_string, userType: this.userType }, pagination: 1 })
      }
    },
    filterChanged(value) {
      this.filterSelected = value
    },
    changePage (arg) {
      this.fetchCoursesList({ filters: { filterBy: this.filter.filter_by, text: this.filter.search_string, userType: this.userType }, pagination: arg })
    },
    addCourse(args) {
      this.$bvModal.msgBoxConfirm('Changing the course will remove all existing students, student videos, and all teacher created/modified tasks from both classrooms. Do you wish to continue?', {
        size: 'sm',
        dialogClass: "modal-message-box confirm-modal-box",
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'GO BACK'
      }).then((res) => {
        if (res) {
          this.$emit('add-course', args)
          return
        }
      })
    },
    openModal() {
      this.fetchCoursesList({ filters: { text: '', filterBy: 'published', userType: this.userType } })
      this.$refs["modal-courses-list"].showModal()
    },
    closeModal() {
      this.$refs["modal-courses-list"].hideModal()
    }
  },
  computed: {
    filterBy() {
      return this.filter.filter_by
    },
    filters() {
      return [
        {
          id: 1,
          name: 'All',
          value: 'published'
        },
        {
          id: 2,
          name: 'Subject',
          value: 'subject'
        },
        {
          id: 3,
          name: 'Language',
          value: 'language'
        },
        {
          id: 5,
          name: 'Level',
          value: 'level'
        },
        {
          id: 5,
          name: 'Age',
          value: 'age_range'
        },
      ]
    },
    showPagination () {
      return this.getPagination.totalRow > 10
    }
  },
  watch: {
    getCourses(newValue) {
      if(!newValue.length && this.filter.search_string){
        this.fetchCoursesList({ filters: { text: '', filterBy: 'published', userType: this.userType } })
        this.filter.filter_by = null,
        this.filter.search_string = ''
        this.$bvModal.msgBoxOk(`No Course were found matching the entered criteria.`)
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.container-courses
  position relative

.common-search-bar-container
  position relative

  &:before
    content: ''
    position absolute
    width 30px
    height 30px
    left 6px
    top 5px
    pointer-events none
    background-repeat no-repeat
    background-position center
    background-size contain
    background-image url("~@/assets/images/search-input-icon.svg")
    z-index 1

  .common-search-bar
    background-color color-gray-100
    border 2px solid color-gray-400
    height 40px
    border-radius(4px)
    padding 4px 6px
    width 100%
    position relative
    font-weight 700
    color color-black-200
    font-size 16px
    outline none
    padding-left 45px
    placeholder-styles(@block{
      font-size 16px
      color color-black-200
    })

.wrapper-classroom-page
    padding 60px 0px 75px 0px

.container-header-filters
  flexbox(flex)
  .container-item-filter
    margin-right 11px
    width 100%
    &:last-child
      margin-right 0px
    &.container-filter-by
      min-width 177px
      width 177px

    .custom-dropdown--secondary
      ::v-deep
        .custom-dropdown-button-icon
          font-size 1.65rem

.wrapper-classroom-content
  border-top 2px solid color-purple-100
  background-color rgba(#F5F6FF, 0.8)
  padding 36px 15px 70px 15px
  margin-bottom 25px

  &__inner
    margin 0px auto
    max-width 1345px
    width 100%
    margin-top 2rem

    .title
      text-align center
      font font-opensans-bold
      font-size 28px
      color color-black-200
      margin-bottom 20px

    .container-course-content
      max-width 1190px
      width 100%
      margin 0px auto

    .separator-filters
      width 100%
      height 3px
      background-color color-gray-100
      margin 23px 0px 35px 0px

    .container-courses
      display grid
      grid-template-columns repeat(3, 1fr)
      gap 40px 48px
      grid-gap 40px 48px

        &__header
          background-color #F5F6FF
          border-radius(4px 4px 0px 0px)
          padding 15px 17px

        &__body
          padding 15px 19px 30px 19px
          flexbox(flex)
          flex-direction(column)
          justify-content(space-between)

        &__info
          &-item
            font-size 13px
            color color-black-200
            margin-bottom 7px
            &:last-child
              margin-bottom 0px

.container-button
  display flex
  justify-content center

  .button--cancel
    margin 2rem 0

@media (max-width: 1150px)
  .wrapper-classroom-content
    &__inner
      .container-courses
        grid-template-columns repeat(2, 1fr)
        gap 30px 30px
        grid-gap 30px 30px

@media (max-width: 750px)
  .wrapper-classroom-content
    &__inner
      .container-courses
        grid-template-columns repeat(1, 1fr)

</style>
<style lang="stylus">
.confirm-modal-box
  .modal-content
    .modal-header
      justify-content center

    .modal-body
      text-align center
      margin 0

    .modal-footer
      justify-content center

      .btn-primary
        margin 0

      .btn-secondary
        font-weight 600
        font-size 14px
</style>
