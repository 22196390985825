import {
  FETCH_EXCHANGE_LIST_START,
  FETCH_EXCHANGE_LIST_DATA,
  FETCH_EXCHANGE_DETAIL_START,
  FETCH_EXCHANGE_DETAIL_DATA,
  UPDATE_EXCHANGE_DETAIL_START,
  UPDATE_EXCHANGE_DETAIL_DATA
} from './mutation-types'
import { ExchangeService } from '../../services/'

export const actions = {
  fetchExchangeList ({ commit }, { filters, sort_by }) {
    commit(FETCH_EXCHANGE_LIST_START)
    return ExchangeService.getExchanges(filters, sort_by).then((res) => {
      const exchanges = res.data.map((elem) => ({
        ...elem
      }))
      commit(FETCH_EXCHANGE_LIST_DATA, { exchanges })
      return exchanges
    })
  },
  fetchExchangeDetail ({ commit }, id) {
    if (id) {
      commit(FETCH_EXCHANGE_DETAIL_START)

      return ExchangeService.getExchangeById(id).then((res) => {
        const data = res.data
        const exchange = {
          ...data,
        }
        commit(FETCH_EXCHANGE_DETAIL_DATA, exchange)
      })
    } else {
      commit(FETCH_EXCHANGE_DETAIL_DATA, {})
    }
  },
  updateExchangeDetail ({ commit }, {id,data}) {
    commit(UPDATE_EXCHANGE_DETAIL_START)

    if (id) {
      return ExchangeService.updateExchange(id, data).then((res) => {
        const data = res.data
        const exchange = {
          ...data,
        }
        commit(UPDATE_EXCHANGE_DETAIL_DATA, exchange)
      })
    } else {
      commit(UPDATE_EXCHANGE_DETAIL_DATA, {})
    }
  },
  resetExchangeDetail ({ commit }) {
    commit('resetExchangeDetail')
  }
}
