import { http } from './http'

const resource = 'api/admin'

export default {
  getSubmissionById (classroom_id, submission_id) {
    return http.get(`${resource}/classrooms/${classroom_id}/submissions/${submission_id}`).then(response => {
      return response
    })
  },
  updateSubmissionStatus (classroom_id, submission_id, data) {
    return http.put(`${resource}/classrooms/${classroom_id}/submissions/${submission_id}`, data).then(response => {
      return response
    })
  }
}
