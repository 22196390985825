import { mapActions, mapGetters } from 'vuex'
export const AlertMixins = {
  computed: {
    ...mapGetters({
      getAlerts: 'AlertStore/getAlerts',
      isLoadingAlerts: 'AlertStore/isLoadingAlerts',
      loadingAlertsData: 'AlertStore/loadingAlertsData',
      updatingAlertsData: 'AlertStore/updatingAlertsData',
      getAlertDetail: 'AlertStore/getAlertDetail'
    })
  },
  methods: {
    ...mapActions({
      fetchAlertsList: 'AlertStore/fetchAlertsList',
      fetchAlertsDetail: 'AlertStore/fetchAlertsDetail',
      updateAlertsDetail: 'AlertStore/updateAlertsDetail',
      resetAlertDetail: 'AlertStore/resetAlertDetail',
    })
  }
}
