const FETCH_ALERT_LIST_START = 'FETCH_ALERT_LIST_START'
const FETCH_ALERT_LIST_DATA = 'FETCH_ALERT_LIST_DATA'
const FETCH_ALERT_DETAIL_START = 'FETCH_ALERT_DETAIL_START'
const FETCH_ALERT_DETAIL_DATA = 'FETCH_ALERT_DETAIL_DATA'
const UPDATE_ALERT_DETAIL_START = 'UPDATE_ALERT_DETAIL_START'
const UPDATE_ALERT_DETAIL_DATA = 'UPDATE_ALERT_DETAIL_DATA'

export {
  FETCH_ALERT_LIST_START,
  FETCH_ALERT_LIST_DATA,
  FETCH_ALERT_DETAIL_START,
  FETCH_ALERT_DETAIL_DATA,
  UPDATE_ALERT_DETAIL_START,
  UPDATE_ALERT_DETAIL_DATA,
}
