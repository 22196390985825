export const getters = {
  getClassroomPartnership: (state) => {
    return state.classroomPartnership
  },
  isLoadingPartnership: (state) => {
    return state.loadingPartnership
  },
  updatingPartnership: (state) => {
    return state.updatingPartnership
  },
  getError: (state) => {
    return state.error
  }
}
