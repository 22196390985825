const FETCH_AGE_RANGE_LIST = 'FETCH_AGE_RANGE_LIST'
const FETCH_SUBJECTS_LIST = 'FETCH_SUBJECTS_LIST'
const FETCH_LANGUAGE_LIST = 'FETCH_TASK_LIST_DATA'
const FETCH_STANDARDS_LIST = 'FETCH_STANDARDS_LIST'
const FETCH_LEVELS_LIST = 'FETCH_LEVELS_LIST'
const FETCH_STUDENT_GROUP_TYPE = 'FETCH_STUDENT_GROUP_TYPE'
const FETCH_TEACHER_TYPES = 'FETCH_TEACHER_TYPES'

export {
  FETCH_AGE_RANGE_LIST,
  FETCH_SUBJECTS_LIST,
  FETCH_LANGUAGE_LIST,
  FETCH_STANDARDS_LIST,
  FETCH_LEVELS_LIST,
  FETCH_STUDENT_GROUP_TYPE,
  FETCH_TEACHER_TYPES
}
