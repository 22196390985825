import {
  FETCH_COURSE_LIST_START,
  FETCH_COURSE_LIST_DATA,
  FETCH_COURSE_DETAIL_DATA,
  FETCH_COURSE_TASK_DETAIL,
  FETCH_TASK_LIST_DATA
} from './mutation-types'
export const
  mutations = {
    [FETCH_COURSE_LIST_START] (state) {
      state.loadingCourses = true
    },
    [FETCH_COURSE_LIST_DATA] (state, { course, pag }) {
      state.courses = course
      state.pagination = pag
      state.loadingCourses = false
    },
    [FETCH_TASK_LIST_DATA] (state, { studentTasks, teacherTasks }) {
      state.taskList = studentTasks
      state.teacherTaskList = teacherTasks
    },
    [FETCH_COURSE_DETAIL_DATA] (state, data) {
      state.courseDetail = data
    },
    [FETCH_COURSE_TASK_DETAIL] (state, detail) {
      state.taskDetail = detail
      state.taskDetail.tags = detail.tags.map(elem => elem.name)
    },
    resetTaskDetail(state) {
      state.taskDetail = Object.assign({}, state.defaultTaskDetail)
    },
    resetCourseDetail(state) {
      state.courseDetail = Object.assign({}, state.defaultCourseDetail)
      state.taskList = []
      state.loadingCourses = false
    },
    updateVideoTimeLimit(state, videoTimeLimit) {
      state.taskDetail.video_time_limit = videoTimeLimit
    }
  }
