const FETCH_COURSE_LIST_START = 'FETCH_COURSE_LIST_START'
const FETCH_COURSE_LIST_DATA = 'FETCH_COURSE_LIST_DATA'
const FETCH_TASK_LIST_DATA = 'FETCH_TASK_LIST_DATA'
const FETCH_COURSE_DETAIL_DATA = 'FETCH_COURSE_DETAIL_DATA'
const FETCH_COURSE_TASK_DETAIL = 'FETCH_COURSE_TASK_DETAIL'

export {
  FETCH_COURSE_LIST_START,
  FETCH_COURSE_LIST_DATA,
  FETCH_COURSE_DETAIL_DATA,
  FETCH_COURSE_TASK_DETAIL,
  FETCH_TASK_LIST_DATA
}
