import {
  FETCH_AGE_RANGE_LIST,
  FETCH_SUBJECTS_LIST,
  FETCH_LANGUAGE_LIST,
  FETCH_STANDARDS_LIST,
  FETCH_LEVELS_LIST,
  FETCH_STUDENT_GROUP_TYPE,
  FETCH_TEACHER_TYPES
} from './mutation-type'
import {
  SubjectService,
  StandardService,
  LevelService,
  LanguageService,
  AgeRangeService,
  CourseService,
  TeacherService
} from '../../services/'

export const actions = {
  fetchAgeRange ({ commit }) {
    return AgeRangeService.getAgeRanges().then((res) => {
      commit(FETCH_AGE_RANGE_LIST, res.data)
      return res.data
    })
  },
  fetchSubjects ({ commit }) {
    return SubjectService.getSubjects().then((res) => {
      commit(FETCH_SUBJECTS_LIST, res.data)
      return res.data
    })
  },
  fetchLanguages ({ commit }) {
    return LanguageService.getLanguages().then((res) => {
      commit(FETCH_LANGUAGE_LIST, res.data)
      return res.data
    })
  },
  fetchStandards ({ commit }) {
    return StandardService.getStandards().then((res) => {
      commit(FETCH_STANDARDS_LIST, res.data)
      return res.data
    })
  },
  fetchLevels ({ commit }) {
    return LevelService.getLevels().then((res) => {
      commit(FETCH_LEVELS_LIST, res.data)
      return res.data
    })
  },
  fetchStudentGroupType ({ commit }) {
    return CourseService.getCourseStudentGroupTypes().then((res) => {
      commit(FETCH_STUDENT_GROUP_TYPE, res.data)
      return res.data
    })
  },
  fetchTeacherTypes ({ commit }) {
    return TeacherService.teacherTypes().then((res) => {
      commit(FETCH_TEACHER_TYPES, res.data)
      return res.data
    })
  }
}
