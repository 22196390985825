<template>
  <modal ref="modalDetail" modal-class="primary_modal" @hide=" $emit('modal-teacher-detail-hide')" :hideFooter=true>
    <template #header>
    </template>
    <template #body>
      <div class="container-top container-top-back">
        <a @click="closeModalDetail"><i class="icon-caret-left"></i> Back</a>
      </div>
      <b-container>
        <b-row>
          <b-col md="5" xl="5" class="container-left">
            <b-row>
              <b-col cols="12">
                <h2 class="mx-5 title text-center">{{ getTeacherDetail.name }}</h2>
                <b-row>
                  <b-col xl="5" class="text-center">
                    <b-avatar size="156px" :src="getTeacherDetail.avatar"></b-avatar>
                  </b-col>
                  <b-col xl="7">
                    <section class="list-container mx-1">
                      <div class="list-item">
                        <p>School name:</p>
                        <p><strong>{{ getTeacherDetail.schoolName }}</strong></p>
                      </div>
                      <div class="list-item">
                        <p>School website:</p>
                        <p><strong>{{ getTeacherDetail.schoolWebsite }}</strong></p>
                      </div>
                      <div class="list-item">
                        <p>School address:</p>
                        <p><strong>{{ getTeacherDetail.schoolAddress }}</strong></p>
                      </div>
                      <div class="list-item">
                        <p>Country:</p>
                        <p><strong>{{ getTeacherDetail.country }}</strong></p>
                      </div>
                      <div class="list-item">
                        <p>Email:</p>
                        <p><strong>{{ getTeacherDetail.email }}</strong></p>
                      </div>
                      <div class="list-item">
                        <p>Phone Number:</p>
                        <p><strong>{{ getTeacherDetail.phoneNumber }}</strong></p>
                      </div>
                      <div class="list-item">
                        <p>Login id:</p>
                        <p><strong>{{ getTeacherDetail.loginId }}</strong></p>
                      </div>
                      <div class="list-item">
                        <p>Sing up date:</p>
                        <p><strong>{{ getTeacherDetail.singUpDate }}</strong></p>
                      </div>
                      <div class="list-item" v-for="(exchange) in getTeacherDetail.exchangeTokensBalance" :key="exchange.id">
                        <p v-show="exchange.balance_tokens >= 1">{{ exchange.name }} Exchange(s):</p>
                        <p v-show="exchange.balance_tokens >= 1"><strong>{{ exchange.balance_tokens }}</strong></p>
                      </div>
                      <div class="list-item" v-if="getTeacherDetail.email_verified_at">
                        <b-button class="button mx-3" @click="$emit('on-show-add-tokens-modal')">Add Exchanges</b-button>
                      </div>
                    </section>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <h2 class="subtitle">About User</h2>
                <p class="teacher-about">{{ getTeacherDetail.about }}</p>
                <div class="teacher-report-container">
                  <h2 class="subtitle">Reports</h2>
                  <p class="reported" v-if="!teacherReports.length">No reports yet</p>
                  <div v-for="teacherReport in teacherReports" :key="teacherReport.id">
                    <div class="report-header">
                      <p class="reported-by">Reported by: {{fullName(teacherReport)}}</p>
                      <p class="reported">
                        Date reported: {{setDueDate(teacherReport.created_at)}}
                      </p>
                    </div>
                    <p class="report-content">{{teacherReport.report}}</p>
                  </div>
                </div>
                <div class="container-form">
                  <h2 class="subtitle subtitle--email">Email</h2>
                  <b-form-group>
                    <b-form-input v-model="$v.newEmail.$model"
                                  autocomplete="off"
                                  class="input-email"
                                  type="text"
                                  placeholder="Account email"/>
                    <div class="error" v-if="!$v.newEmail.validEmail && $v.newEmail.$dirty">
                      Invalid email
                    </div>
                    <b-button class="button button--email my-1"
                              :disabled="getTeacherDetail.email_verified_at"
                              @click="onVerificationEmail">Send Verification Email
                    </b-button>
                    <b-button class="button button--email my-1"
                              v-if="!getTeacherDetail.email_verified_at"
                              @click="onCopyEmailUrl">Copy verification email url
                    </b-button>
                  </b-form-group>

                  <h2 class="subtitle">Teacher Type</h2>
                  <select-dropdown :options="getTeacherTypes"
                                   v-model="teacherTypeSelected"
                                   class="--secundary"
                                   value-field="slug"/>

                  <div v-if="false">
                    <h2 class="subtitle">Preferred communication method *</h2>
                    <b-form-radio v-model="preferredCommunication"
                                  :options="communicationMethods"
                                  name="comunication-methods"/>
                    <div class="container-mobile-number">
                      <b-form-imput v-model="$v.phoneCountryCode.$model"
                                    :validator="$v.phoneCountryCode"
                                    label="Country Code *"
                                    :disabled="!isMobileNumber"
                                    custom-class="common-input common-input--primary common-input--country-Code"/>

                      <b-form-imput v-model="$v.phoneNumber.$model"
                                    :validator="$v.phoneNumber"
                                    label="Mobile Number *"
                                    :disabled="!isMobileNumber"
                                    custom-class="common-input common-input--primary"/>
                    </div>
                  </div>
                  <span>
                    <b-form-checkbox v-model="getTeacherDetail.on_hold" class="custom-checkbox checkbox-lg">
                      On Hold
                    </b-form-checkbox>
                    <b-form-checkbox v-model="getTeacherDetail.teacherEnable" class="custom-checkbox checkbox-lg">
                      Teacher Enable
                    </b-form-checkbox>
                    <b-form-checkbox v-model="getTeacherDetail.teacherVerified" class="custom-checkbox checkbox-lg">
                      Teacher Verified
                    </b-form-checkbox>
                    <b-form-checkbox v-model="getTeacherDetail.emailVerified" class="custom-checkbox checkbox-lg">
                      School Email Verified
                    </b-form-checkbox>
                    <b-form-checkbox v-model="emailVerifiedAt" class="custom-checkbox checkbox-lg">
                      Email Verified
                    </b-form-checkbox>
                    <b-form-checkbox v-model="getTeacherDetail.teacher_agreement_completed_at" class="custom-checkbox checkbox-lg">
                      Teacher Agreement Completed
                    </b-form-checkbox>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="12" align-self="center"
                     v-if="showMasqueradeButton"
                     class="text-center container-button container-button-masquerade">
                <b-button class="button my-1" @click="masqueradeTeacher">Masquerade</b-button>
              </b-col>
              <b-col cols="12" align-self="center" class="text-center container-button container-button-bottom">
                <b-button class="button b-ligth mx-3" @click="closeModalDetail">Cancel</b-button>
                <b-button class="button mx-3" @click="updateTeacher">Save</b-button>
              </b-col>
              <b-button class="button mx-3 container-button-bottom" @click="deleteTeacher">Teacher Delete</b-button>
            </b-row>

            <b-overlay :show="isLoadingTeachers || isOverlay" no-wrap spinner-medium rounded="sm"/>
          </b-col>

          <b-col md="7" xl="7" class="">
            <b-row>
              <b-col cols="12">
                <h2 class="my-2 mx-5 title">Classrooms</h2>
                <b-table hover id="classroomTable" :fields="fields" :items="getTeacherClassrooms" responsive
                         sort-icon-left small
                         table-variant="ligth" head-variant="ligth" :current-page="currentPage" :per-page="perPage">
                  <template #cell(name)="data">
                    <a class="a-title" @click="goPartnerships(data.value.id)">
                      {{
                        data.value.classroom_name.length > 12 ? `${data.value.classroom_name.slice(0, 10)}...` : data.value.classroom_name
                      }}</a>
                  </template>
                  <template #cell(date)="data">
                    <p class="text-date"> {{ data.value.start }} </p>
                    <p class="text-date"> {{ data.value.end }}</p>
                  </template>
                  <template #cell(paired)="paired">
                    <b-form-checkbox
                      v-model="paired.value"
                      disabled
                      class="custom-checkbox checkbox-lg">
                    </b-form-checkbox>
                  </template>
                </b-table>
              </b-col>
              <b-col cols="12" class=" my-3 mx-5">
                <b-pagination ref="pagination" v-show="showPagination" v-model="currentPage"
                              :total-rows="getTeacherClassrooms.length" :per-page="perPage"
                              aria-controls="teachers-table"
                              first-number last-number align="fill" size="sm" class="my-3 mx-5">
                  <template #prev-text><i class="icon-chevron-left"></i></template>
                  <template #next-text><i class="icon-chevron-right"></i></template>
                </b-pagination>
              </b-col>
            </b-row>
            <b-overlay :show="isLoadingTeachers" no-wrap spinner-medium rounded="sm"/>
          </b-col>
        </b-row>
      </b-container>
      <modal id="modal-masquerade-teacher"
             hideFooter>
        <template #body>
          <a :href="urlMasqueradeTeacher"
             target="_blank"
             class="masquerade-link"
             @click="$bvModal.hide('modal-masquerade-teacher')">
             {{urlMasqueradeTeacher}}</a>
        </template>
      </modal>
    </template>
  </modal>
</template>

<script>
import {Modal, SelectDropdown, CustomRadio} from '@/core/components/'
import {TeacherMixins, UtilsMixins, PartnershipsMixins} from '../Mixins'
import {TeacherService} from '@/core/services'
import {AppStorage} from '@/core/utils'
import {required, email, requiredIf} from 'vuelidate/lib/validators'
import {Input} from '@/core/components/Form'
import moment from 'moment'

export default {
  name: 'ModalTeacherDetail',
  mixins: [TeacherMixins, UtilsMixins, PartnershipsMixins],
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    const iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
    return {
      fields: [
        {key: 'name', label: 'NAME', sortable: true},
        {key: 'subject', label: 'SUBJECT', sortable: true},
        {key: 'code', label: 'CODE', sortable: true},
        {key: 'date', label: 'DATES', sortable: true},
        {key: 'paired', label: 'PAIRED', sortable: true}
      ],
      min: minDate,
      perPage: 15,
      currentPage: 1,
      newEmail: null,
      isOverlay: false,
      urlMasqueradeTeacher: '',
      iOSDevice: iOSDevice,
      showMasqueradeButton: false,
      communicationMethods: [
        {name: 'Email', id: 'email', value: 'email'},
        {name: 'Text Message', id: 'text_message', value: 'text_message'},
        {name: 'WhatsApp', id: 'whatsapp', value: 'whatsapp'}
      ],
      teacherTypeSelected: null,
      teacherReports: [],
      preferredCommunication: null,
      phoneCountryCode: null,
      phoneNumber: null,
      emailVerifiedAt: null
    }
  },
  components: {
    Modal,
    SelectDropdown,
    'b-form-radio': CustomRadio,
    'b-form-imput': Input,
  },
  validations: {
    getTeacherDetail: {
      email: {
        required
      }
    },
    newEmail: {
      required,
      validEmail: function() {
        //validate email without special characters
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(this.newEmail)
      }
    },
    preferredCommunication: {
      required,
      email
    },
    phoneCountryCode: {
      required: requiredIf(function() {
        return this.isMobileNumber
      })
    },
    phoneNumber: {
      required: requiredIf(function() {
        return this.isMobileNumber
      })
    }
  },
  methods: {
    deleteTeacher() {
      this.$bvModal.msgBoxConfirm(`${this.getTeacherDetail.name}, as well as any associated classrooms and students will be deleted from LUV, would you like to continue?`, {
        title: 'Delete Teacher',
        size: 'sm',
        dialogClass: "modal-message-box confirm-modal-box",
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'CANCEL'
      }).then((res) => {
        if (res) {
          TeacherService.deleteTeacher(this.getTeacherDetail.id).then(() => {
            this.$bvModal.msgBoxOk('Successful Delete!').then(() => {
              this.closeModalDetail()
              this.$emit('on-delete-teacher')
            })
          }).catch((e) => {
            const errorMessage = e.message ? e.message : 'Error while loading course.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          })
          return
        }
      })
    },
    openModalDetail() {
      return new Promise((resolve) => {
        this.$refs.modalDetail.showModal()
        resolve(this)
      })
    },
    goPartnerships(id) {
      this.fetchPartnership(id)
      this.$router.push({name: 'Partnership'})
    },
    closeModalDetail() {
      return new Promise((resolve) => {
        this.$refs.modalDetail.hideModal()
        resolve(this)
      })
    },
    async updateTeacher() {
      this.$v.getTeacherDetail.$touch()
      this.$v.newEmail.$touch()
      if (this.$v.getTeacherDetail.$anyError || this.$v.newEmail.$anyError) {
        return
      }
      const data = {
        on_hold: this.getTeacherDetail.on_hold ?? 0,
        is_teacher_enabled: this.getTeacherDetail.teacherEnable ?? 0,
        is_teacher_validated: this.getTeacherDetail.teacherVerified ?? 0,
        is_email_validated: this.getTeacherDetail.emailVerified ?? 0,
        email_verified_at: this.emailVerifiedAt ?? 0,
        teacher_agreement_completed_at: this.getTeacherDetail.teacher_agreement_completed_at ?? 0,
        user_type: {slug: this.teacherTypeSelected}
      }

      this.newEmail !== this.getTeacherDetail.email && (data['email'] = this.$v.newEmail.$model)
      this.isOverlay = true
      TeacherService.updateTeacher(this.getTeacherDetail.id, data).then(async (resp) => {
        if (resp.success) {
          await this.$bvModal.msgBoxOk('Successful Upgrade!')
          if (this.newEmail !== this.getTeacherDetail.email) {
            await this.$bvModal.msgBoxConfirm('The email address has changed. Would you like to send a verification email now?', {
              size: 'sm',
              dialogClass: "modal-message-box confirm-modal-box",
              centered: true,
              okTitle: 'SEND',
              cancelTitle: 'NOT YET'
            }).then((res) => {
              if (res) {
                this.onVerificationEmail()
              }
            })
          }
          this.fetchDetail(this.getTeacherDetail.id).finally(()=>{
            this.setShowMasqueradeButton()
          })
        } else {
          const errorMessage = resp.message ? resp.message : 'Error updating data.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            centered: true,
            dialogClass: 'text-center'
          })
        }
      }).finally(() => {
        this.isOverlay = false
      })
    },
    masqueradeTeacher() {
      TeacherService.masquerade(this.getTeacherDetail.id).then((res) => {
        AppStorage.setItem('teacher-token', res.token)

        this.urlMasqueradeTeacher = `${process.env.VUE_APP_BASE_URL_TEACHER_PORTAL}/security/login-as-teacher?`
        this.urlMasqueradeTeacher += `admin_token=${AppStorage.getUser().token}`
        this.urlMasqueradeTeacher += `&teacher_token=${res.token}`
        this.urlMasqueradeTeacher += `&user_type=${res.user_type.slug}`

        if (this.iOSDevice) {
          this.$bvModal.show('modal-masquerade-teacher')
          return
        }

        const link = document.createElement('a')

        link.href = this.urlMasqueradeTeacher
        link.target = '_blank'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    onVerificationEmail() {
      this.isOverlay = true
      TeacherService.verificationEmail(this.$v.getTeacherDetail.$model.id).then((res) => {
        if (res.success) {
          this.$bvModal.msgBoxOk('Verification Email Sent')
        } else {
          const errorMessage = res.message ? res.message : 'Error sending verification email.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        }
      }).finally(() => {
        this.isOverlay = false
      })
    },
    onCopyEmailUrl() {
      this.isOverlay = true
      TeacherService.getVerificationEmailUrl(this.$v.getTeacherDetail.$model.id).then((res) => {
        this.isOverlay = false
        navigator.clipboard.writeText(res.data.url)
        this.$bvModal.msgBoxOk('The url is copied into the clipboard ')
      }).catch((e) => {
        console.log(e)
        this.isOverlay = false
      })
    },
    setShowMasqueradeButton() {
      this.showMasqueradeButton = this.getTeacherDetail.emailVerified
    },
    fullName(report) {
      return report?.reporting_teacher?.first_name + ' ' + report?.reporting_teacher?.last_name
    },
    setDueDate (date) {
      return moment(date).format('M/D/YYYY')
    },
  },
  async mounted() {
    this.setShowMasqueradeButton()
  },
  computed: {
    showPagination() {
      return this.getTeacherClassrooms.length > 15
    },
    isMobileNumber() {
      return this.preferredCommunication !== 'email'
    }
  },
  watch: {
    getTeacherDetail(newValue) {
      this.newEmail = newValue.email
      this.teacherTypeSelected = newValue?.user_type?.slug
      this.preferredCommunication = newValue.preferredCommunication,
      this.phoneCountryCode = newValue.phoneCountryCode,
      this.phoneNumber = newValue.teacherPhoneNumber
      this.emailVerifiedAt = newValue.email_verified_at
      if (newValue.id) {
        this.isOverlay = true
        TeacherService.listTeacherReports(newValue.id).then(({data}) => {
          this.teacherReports = data
        }).finally(() => this.isOverlay = false)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

#date-input
  margin-bottom 0 !important

.btn-picker
  .b-form-btn-label-control
    ::v-deep
      .btn
        margin 0

.teacher-about
  margin-bottom 40px !important

.container-top {
  margin: 0rem -1.2rem 1rem -1.2rem
  padding-bottom: .5rem
  padding-left: 1rem
  border-bottom: 2px solid red
  border-color: color-gray-400
  color: color-blue-300

  &-back {
    width: calc(100% + 1rem);
    margin: 0;
    margin-left: -0.5rem;
  }

  a {
    font-size: 1rem
    font-weight: bolder
    margin-top: 0
    margin-bottom: 1rem

    &.icon-caret-left:before {
      font-size: 1rem
    }
  }
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin 10px 0
}

.container-button {
  padding: 0.5rem;
  color: #2f3e98;

  display: flex
  flex-direction: row
  width 100%
  max-width 420px
  justify-content: center

  &-masquerade {
    border-bottom: 2px solid color-gray-400;
    margin-bottom 4rem
  }

  &-bottom {
    margin-bottom 2rem
  }
}

.container-text {
  display: flex

  .b-avatar {
    margin-top: 2rem
    width 120px
    height 120px
  }
}

.list-item {
  display: flex
  margin: .4rem 0
  justify-content: left

  p {
    text-align: left
    min-width 112px
    max-width: 230px
    margin .1rem .3rem
    overflow-wrap: break-word
  }
}

.title {
  text-transform: uppercase
  font-size: 24px
  font-weight: bold
  overflow-wrap: break-word
}

.teacher-report-container
  display block
  position relative

.subtitle {
  text-transform: uppercase
  font-size: 14px
  font-weight: bold
  margin-top 1rem

  &.reported {
    font: font-opensans-regular
    font-size: 14px
    text-transform: capitalize
    text-align right
  }
  &.reported-by {
    text-align right
    span {
      font: font-opensans-regular
      font-size: 14px
      text-align right
    }
  }
}

.report-header
  display flex
  justify-content: space-between

  p
    margin 0px 0 8px 0
    max-width 48%
    overflow hidden
    text-overflow ellipsis
    overflow-wrap unset !important

.report-content
  margin-bottom 20px !important

.subtitle--email {
  margin-bottom 0
}

p {
  font-size: 13px
  overflow-wrap: break-word
}

.container-form {
  margin 2rem 0

  .custom-checkbox {
    margin-top 1.5rem
  }
}

.text-date {
  font-size 14px !important
}

.input-email
  margin-top 8px

.button--email
  height auto
  padding-top 7.5px
  padding-bottom 7.5px
  display block

.container-mobile-number
  display flex
  ::v-deep
    .form-group:first-child
      max-width 70px
      margin-right 10px

      .error-content
        padding-left 20px

    .form-group:nth-child(2)
      width 100%
      margin-top 22px

.container-left {

  @media (min-width: 1200px) {
    .col-xl-7,
    .col-xl-5 {
      flex(0 0 100%);
      max-width: 100%;
      width: 100%;
    }
  }
  @media (min-width: 1360px) {
    .col-xl-5 {
      flex(0 0 41.666667%);
      max-width: 41.666667%;
    }

    .col-xl-7 {
      flex(0 0 58.333333%);
      max-width: 58.333333%;
    }
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1500px;
  }
}

@media (min-width: 768px) {
  .container-left {
    border-right: 1px solid #e8ecee;
  }
}

</style>

<style lang="stylus">
#modal-masquerade-teacher
  .modal-body
    width 100%
    margin 0

    .body
      padding-bottom 20px

      .masquerade-link
        word-wrap break-word
        text-decoration underline
</style>
