import { http } from './http'

const resource = 'api/admin'

export default {
  getParentOrganizations(filters) {
    return http.get(`${resource}/parent-organizations`, {params: {filters}}).then(response => {
      return response
    })
  },
  createParentOrganization(form) {
    return http.post(`${resource}/parent-organizations`, form).then(response => {
      return response
    })
  },
  getParentOrganizationById(organizationId) {
    return http.get(`${resource}/parent-organizations/${organizationId}`).then(response => {
      return response
    })
  },
  updateParentOrganization(organizationId, form) {
    return http.put(`${resource}/parent-organizations/${organizationId}`, form).then(response => {
      return response
    })
  },
  getParentOrganizationsAssociated(organizationId) {
    return http.get(`${resource}/parent-organizations/${organizationId}`).then(response => {
      return response
    })
  },
  addSchools(organizationId, form) {
    return http.put(`${resource}/parent-organizations/${organizationId}/schools`, form).then(response => {
      return response
    })
  },
  deleteSchools(organizationId, form) {
    return http.delete(`${resource}/parent-organizations/${organizationId}/schools`, {data: form}).then(response => {
      return response
    })
  },
  deleteParentOrganization(organizationId) {
    return http.delete(`${resource}/parent-organizations/${organizationId}`).then(response => {
      return response
    })
  },
  getSchools(filters) {
    return http.get(`${resource}/schools`, {params: {filters}}).then(response => {
      return response
    })
  },
  getTokensByParentOrganization(organizationId) {
    return http.get(`${resource}/parent-organizations/${organizationId}/exchange-tokens-purchases`).then(response => {
      return response
    })
  },
  getTeachersByParentOrganization(organizationId) {
    return http.get(`${resource}/parent-organizations/${organizationId}/teachers`).then(response => {
      return response
    })
  },
  addTokensToParentOrganization({organization_id, amount_tokens, expire_at, subject_id}) {
    return http.post(`${resource}/parent-organizations/${organization_id}/exchange-tokens-purchases`, { amount_tokens, expire_at, subject_id}).then(response => {
      return response
    })
  },
  assignationExchanges({organization_id, amount_tokens, teacher_id, subject_id}) {
    return http.post(`${resource}/parent-organizations/${organization_id}/exchange-tokens-purchases/assignation`, { amount_tokens, teacher_id, subject_id}).then(response => {
      return response
    })
  }
}
