import { mapActions, mapGetters } from 'vuex'
export const ExchangesMixins = {
  computed: {
    ...mapGetters({
      getExchanges: 'BundleStore/getExchanges',
      isLoadingExchanges: 'BundleStore/isLoadingExchanges',
      loadingExchangesData: 'BundleStore/loadingExchangesData',
      updatingExchangesData: 'BundleStore/updatingExchangesData',
      getExchangesDetail: 'BundleStore/getExchangesDetail',
      getPagination: 'BundleStore/getPagination',
    })
  },
  methods: {
    ...mapActions({
      fetchExchangeList: 'BundleStore/fetchExchangeList',
      fetchExchangeDetail: 'BundleStore/fetchExchangeDetail',
      updateExchangeDetail: 'BundleStore/updateExchangeDetail',
      resetExchangeDetail: 'BundleStore/resetExchangeDetail',
    })
  }
}
