<template>
  <div class="wrapper-security-page">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col>
          <div class="wrapper-security-form">
            <h2 class="security-form-subtitle mb-1">FORGOT PASSWORD</h2>
            <div class="security-form-page-info mb-4">
              <div class="required-fields">all fields required *</div>
            </div>
            <form @submit.prevent="" class="mt-2">
              <b-form-input @keyup.enter.native="requestLink"
                            v-model="$v.form.email.$model"
                            placeholder="Your Email *"
                            :class="{ 'form-group--error': $v.form.email.$error }"
                            type="email"/>
              <div class="error" v-if="!$v.form.email.required && $v.form.email.$dirty">Email field is required</div>
              <div class="security-form-container-btns c-btns-secondary">
                <b-button @click="$router.push({name: 'LoginPages'})" class="button">Cancel</b-button>
                <b-button @click="requestLink" class="button">APPLY</b-button>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal ref="account_forgot_password_success">
      <template #body>
        <h2 class="modal-title"></h2>
        <p>A recovery link as been sent to your email. Please check your email to complete the password recovery
          process. If you do not see an email, check your Junk/Spam folder.</p>
      </template>
      <template #footer>
        <b-button @click="closeModal" class="button">OK</b-button>
      </template>
    </modal>
    <modal ref="account_forgot_password_error">
      <template #body>
        <h2 class="modal-title">The email is not in the system.</h2>
      </template>
      <template #footer>
        <b-button @click="closeErrorModal">OK</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/core/components/Modal/Modal.vue';
import {required} from 'vuelidate/lib/validators';
import { AuthService } from '@/core/services'

export default {
  name: "ForgotPasswordPage",
  components: {
    Modal,
  },
  data() {
    return {
      form: {
        email: '',
      }
    }
  },
  validations: {
    form:{
      email: {
        required
      }
    }
  },
  methods: {
    requestLink: function () {
      const {email} = this.form

      if(!this.$v.form.email.$anyError) {
        AuthService.passwordRecoveryEmail(email).then((response) => {
          if (response.success === true) {
            this.showSuccessModal()
          } else {
            this.showErrorModal()
          }
        }).catch(() => {
          this.form.email = ''
        })
      }
    },
    showSuccessModal: function () {
      return this.$refs["account_forgot_password_success"].showModal()
    },
    closeModal: function () {
      return this.$refs["account_forgot_password_success"].hideModal().then(() => {
        this.$router.push({name: 'LoginPages'})
      })
    },
    showErrorModal: function () {
      return this.$refs["account_forgot_password_error"].showModal()
    },
    closeErrorModal: function () {
      return this.$refs["account_forgot_password_error"].hideModal().then(() => {
      })
    },
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.wrapper-security-page
  .wrapper-security-form
    width 100%
    padding-top 60px
    flexbox(flex)
    align-items(center)
    flex-direction(column)
    margin-bottom 100px
    @media (max-width 520px)
      padding-top 10px
      margin-bottom 0

  .security-form
    &-subtitle
      font font-opensans-bold
      font-size 24px
      color color-black-200
      text-transform uppercase
      text-align center
      margin-bottom 26px

    &-page-info
      margin-bottom 21px
      text-align center

      .required-fields
        font font-opensans-regular
        font-size 14px
        color color-black-200
        opacity 0.6
        font-style italic

    &-container-btns
      margin-top 50px
      flexbox(flex)
      align-items(center)
      justify-content(space-between)
      flex-direction(column)

      .button
        margin 5px

      &.c-btns-secondary
        margin-top 40px
        flex-direction(row)
        justify-content(center)

  .form-group--error
    border-color red

  .error
    width 100%
    text-align center
    font font-opensans-bold
    font-size 11px
    color red

</style>
