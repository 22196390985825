<template>
  <div>
    <social />
  </div>
</template>
<script>
import Social from './Social.vue'
export default {
  name: 'footerLayaout',
  components: {
    Social
  }
}
</script>
