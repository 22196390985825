import Vue from 'vue'
import VueRouter from 'vue-router'
import SecurityRoutes from '@/modules/security/routes'
import PanelRoutes from '@/modules/panel/routes'
import { AppStorage, adminTypes } from '@/core/utils'

Vue.use(VueRouter)

let routes = []

routes = routes.concat(SecurityRoutes, PanelRoutes)

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const loggedIn = !!AppStorage.getUser().token

  let isAdminCourseEditor
  let isCustomerServiceAgent
  let isTestAdmin

  if (loggedIn && AppStorage.getUser().roles) {
    isAdminCourseEditor = !!AppStorage.getUser().roles.find(rol => rol.slug === adminTypes.adminCourseEditor)
    isCustomerServiceAgent = !!AppStorage.getUser().roles.find(rol => rol.slug === adminTypes.customerServiceAgent)
    isTestAdmin = !!AppStorage.getUser().roles.find(rol => rol.slug === adminTypes.adminTest)
  } else {
    AppStorage.clear()
  }

  //prevents going to login again
  const path = to.path.slice(0, 26)

  if (!loggedIn && to.path !== '/' && to.path !== '/forgot-password' && path !== '/security/recover-password') {
    return next({name: 'LoginPages', query: {redirect: to.fullPath}})
  }

  // redirect to login page if not logged in and trying to access a restricted page
  if (to.meta.isAuthenticate && !loggedIn) {
    return next({name: 'LoginPages', query: {redirect: to.fullPath}})
  }

  if (to.name === 'LoginPages' && loggedIn) {
    return next({ name: 'CoursesList' })
  }

  if ((!to.meta.adminCourseEditor && isAdminCourseEditor) || (!to.meta.isTestAdmin && isTestAdmin)) {
    return next({ name: 'CoursesList' })
  }

  if (!to.meta.customerServiceAgent && isCustomerServiceAgent) {
    return next({ name: 'TeacherContent' })
  }
  next()
})

export default router
