// Common const to handle http requests and responses
// src/http.js
import axios from 'axios'
import qs from 'qs'
import { AuthService } from '@/core/services'
import { AppStorage } from '@/core/utils'

import router from '@/router'

// parse error response
function parseError (messages) {
  // error
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject(new Error(messages[0]))
    }
    if (messages instanceof Object && messages.detail) {
      // JWT DRF returns an object error response
      return Promise.reject(messages.detail)
    } else {
      return Promise.reject(messages)
    }
  } else {
    return Promise.reject(new Error('Error'))
  }
}

// parse response
function parseBody (response) {
  if (response.status === 200 || response.status === 201) {
    return response.data
  } else {
    return parseError(response.data.messages)
  }
}

// axios instance
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  paramsSerializer: function (params) {
    return qs.stringify(params, { indices: false })
  }
})

// request header
instance.interceptors.request.use((config) => {
  // Do something before request is sent
  // api token
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && user.token) {
    config.headers.Authorization = 'Bearer ' + user.token
  }
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return parseBody(response)
}, function (error) {
  const originalRequest = error.config
  const user = AppStorage.getUser()
  if ((error.response.status === 403 || error.response.status === 401) && user !== {} && !originalRequest._retry) {
    // originalRequest._retry = true
    //
    // return AuthService.loginWithToken(user.refreshToken).then(() => {
    //   const user = AppStorage.getUser()
    //   originalRequest.headers.Authorization = 'Bearer ' + user.token;
    //   return instance(originalRequest)
    // }).catch(() => {
    AuthService.logout()
    router.push({ name: 'LoginPages' })
    // })
  }
  let responseData = error.response.data
  if (error.response.status === 401 && user.token) {
    responseData = {
      ...responseData,
      message: 'You have been logged out of your account, log back in.'
    }
  }

  return parseError(responseData)
})

export const http = instance
