<template>
  <b-col md="12">
    <b-row class="my-2 justify-content-end">
      <b-button @click="exportPurchaseReport" :disabled="false" class="button b-ligth --sm mx-1 my-1">
        Export Purchase Report
      </b-button>
    </b-row>
    <b-row class="my-2 container-filter">
      <select-dropdown class-select="--secundary dropdown-purchase"
                       :options="filterOptions"
                       v-model="selectedFilter"
                       placeholder="FILTER BY"
                       value-field="value"/>

      <div class="container-date">
        <search-input class="--secundary mx-2" v-if="!isDate" v-model="search"
                      :placeholder="searchLabel(selectedFilter)"
                      @on-search="handlerSearch"></search-input>
        <div v-if="isDate" class="container-date">
          <b-form-datepicker v-model="startDate"
                             class="--secundary mx-2 datepicker-custom"
                             id="date-input-t"
                             placeholder="Start sign up-date"
                             locale="en"/>
          <b-form-datepicker v-model="endDate"
                             class="--secundary mx-2 datepicker-custom"
                             id="date-input-t2"
                             placeholder="End sign up-date"
                             locale="en"/>
          <button @click="filterByDate"
                  :disabled="!startDate && !endDate"
                  class="button --search">Search
          </button>
        </div>
      </div>
    </b-row>
    <b-row class="my-2">
      <b-table responsive
               v-show="selectedTeachers && !isLoadingTokens"
               id="teacher-report-table"
               hover
               :fields="fields"
               sort-icon-left
               :items="reportList"
               table-variant="ligth"
               head-variant="ligth"
               no-local-sorting
               :per-page="perPage"
               :current-page="currentPage"
               @sort-changed="sortingChanged"
               @row-clicked="editExpirationDate">
        <template #cell(teacher)="data"><span
          class="full-name-teacher">{{ data.item.teacher && data.item.teacher.full_name }}</span></template>
        <template #cell(school)="data"><span class="full-school">{{
            data.item.teacher && data.item.teacher.school && data.item.teacher.school.name
          }}</span></template>
        <template #cell(parent_organization)="data"><span
          class="parent-organization-name">{{ parentOrganizationName(data) }}</span></template>
        <template #cell(purchase_date)="data">{{ setDate(data.item.created_at) }}</template>
        <template #cell(subject)="data">{{ data.item.subject && data.item.subject.name }}</template>
        <template #cell(country)="data">
          {{ data.item.teacher && data.item.teacher.country && data.item.teacher.country.name }}
        </template>
        <template #cell(amount_tokens)="data">{{ data.item.total_price }}</template>
        <template #cell(payment_method)="data">
          {{ data.item.payment_history && data.item.payment_history.payment_method.name }}
        </template>
      </b-table>

      <b-table responsive
               v-show="selectedParentOrganization && !isLoadingTokens"
               id="report-table"
               :fields="fieldsParentOrg"
               sort-icon-left
               :items="reportList"
               table-variant="ligth"
               head-variant="ligth"
               :per-page="perPage"
               :current-page="currentPage">
        <template #cell(icon)="data">
          <i :class="`fa ${data.detailsShowing ? 'fa-minus-circle' : 'fa-plus-circle'} parent-org-icon`"
             @click.self="data.toggleDetails" aria-hidden="true"></i>
        </template>
        <template #cell(parent_organization)="data"><span class="full-name-teacher">{{ data.item.name }}</span>
        </template>
        <template #row-details="data">
          <b-table responsive
                   hover
                   id="report-table-details-parent-org"
                   :fields="fieldsDetailsParentOrg"
                   :items="data.item.teachers_exchange_tokens_purchase"
                   head-variant="ligth"
                   @row-clicked="editExpirationDate">
            <template #cell(icon)="data">
              <i :class="`fa ${data.detailsShowing ? 'fa-minus' : 'fa-plus'} parent-org-icon parent-org-icon--details`"
                 @click.self="data.toggleDetails" aria-hidden="true"></i>
            </template>
            <template #cell(teacher)="data"><span class="full-name-teacher">{{ data.item.teacher.full_name }}</span>
            </template>
            <template #cell(created_at)="data">{{ setDate(data.item.created_at) }}</template>
            <template #cell(subject)="data">{{ data.item.subject && data.item.subject.name }}</template>
            <template #cell(amount_tokens)="data">{{ data.item.amount_tokens }}</template>
            <template #row-details="data">
              <b-table responsive
                       id="report-table-transaction"
                       :fields="fieldsTransaction"
                       :items="data.item.history"
                       head-variant="ligth">
                <template #cell(icon)=""></template>
                <template #cell(created_at)="data">{{ setDate(data.item.created_at) }}</template>
                <template #cell(amount_tokens)="data">{{ data.item.amount_tokens }}</template>
              </b-table>
            </template>
          </b-table>
        </template>
      </b-table>

      <student-report-table v-show="selectedStudents && !isLoadingTokens"
                            :students-list="reportList"
                            :per-page="perPage"
                            :current-page="currentPage"
                            @sort-changed="sortingChanged"/>
    </b-row>

    <b-row class="justify-content-md-center">
      <b-col md="3" class="mx-auto">
        <b-pagination ref="pagination" v-show="showPagination && !isLoadingTokens" v-model="currentPage"
                      :total-rows="reportList.length" :per-page="perPage" aria-controls="teachers-table"
                      first-number last-number align="fill" size="sm" class="my-2">
          <template #prev-text><i class="icon-chevron-left"></i></template>
          <template #next-text><i class="icon-chevron-right"></i></template>
        </b-pagination>
      </b-col>
    </b-row>

    <b-overlay :show="isLoadingTokens" spinner-medium rounded="sm" no-wrap/>

    <modal-token-expiration ref="modal-token-expiration"
                            :is-parent-organization="selectedParentOrganization"
                            :quantity="tokenData.amount_tokens"
                            :amoundPaid="tokenData.total_price"
                            :purchaseDate="tokenData.created_at"
                            :expirationDate="tokenData.expire_at"
                            :subject="tokenData.subject && tokenData.subject.name"
                            :teacher="tokenData.teacher && tokenData.teacher.full_name"
                            @on-token-updated="onTokenUpdated"/>
  </b-col>
</template>

<script>
import { SelectDropdown, SearchInput } from '@/core/components'
import { ModalTokenExpiration } from './'
import { AccountTokensService } from '@/core/services'
import { UtilsMixins } from '@/modules/panel/Mixins'
import StudentReportTable from './StudentReportTable.vue'
import moment from "moment"

export default {
  name: 'PurchaseReport',
  mixins: [UtilsMixins],
  components: {
    SelectDropdown,
    ModalTokenExpiration,
    SearchInput,
    StudentReportTable
  },
  data () {
    return {
      selected_token: {},
      selectedFilter: 'teacher',
      search: '',
      startDate: null,
      endDate: null,
      reportList: [],
      isLoadingTokens: false,
      sort_by: null,
      tokenData: {},
      currentPage: 1,
      perPage: 15,
      fields: [
        { key: 'teacher', label: 'TEACHER', sortable: true, class: 'classroom' },
        { key: 'school', label: 'SCHOOL', sortable: true },
        { key: 'parent_organization', label: 'PARENT ORGANIZATION', sortable: true, class: 'classroom' },
        { key: 'purchase_date', label: 'PURCHASE DATE', sortable: true },
        { key: 'subject', label: 'SUBJECT', sortable: true },
        { key: 'country', label: 'COUNTRY', sortable: true },
        { key: 'amount_tokens', label: 'AMOUNT PAID', sortable: true },
        { key: 'payment_method', label: 'PAYMENT METHOD', sortable: true },
      ],
      fieldsParentOrg: [
        { key: 'icon', label: '', class: 'icon-details-collapse' },
        { key: 'parent_organization', label: 'PARENT ORGANIZATION' },
      ],
      fieldsDetailsParentOrg: [
        { key: 'icon', label: '', class: 'icon-details-collapse' },
        { key: 'teacher', label: 'TEACHER', class: 'classroom' },
        { key: 'created_at', label: 'PURCHASE DATE' },
        { key: 'subject', label: 'SUBJECT' },
        { key: 'amount_tokens', label: 'QTY' }
      ],
      fieldsTransaction: [
        { key: 'icon', label: '', class: 'icon-details-collapse' },
        { key: 'created_at', label: 'DATE' },
        { key: 'amount_tokens', label: 'AMOUNT' }
      ],
      detailsParentOrg: [
        { teacher: 'Jose R', amount_tokens: 10 },
        { teacher: 'Diego H', amount_tokens: 8 }
      ],
      tokenOptions: [
        { name: 'ALL', value: '' },
        { name: 'PURCHASE EXCHANGES', value: 'purchase' },
        { name: 'FREE EXCHANGES', value: 'free' },
      ],
      filterOptions: [
        { name: 'BY TEACHER', value: 'teacher' },
        { name: 'TEACHERS BY DATE', value: 'date' },
        { name: 'BY PARENT ORG', value: 'parent_organization' },
        { name: 'BY STUDENT', value: 'student' },
        // { name: 'BY SCHOOL', value: 'school' }
      ],
    }
  },
  computed: {
    isDate () {
      return this.selectedFilter === 'date'
    },
    selectedParentOrganization () {
      return this.selectedFilter === 'parent_organization'
    },
    isLibrary () {
      return this.selectedFilter === 'school'
    },
    selectedTeachers () {
      return this.selectedFilter === 'teacher' || this.selectedFilter === 'date'
    },
    selectedStudents () {
      return this.selectedFilter === 'student'
    },
    showPagination() {
      return this.reportList.length > this.perPage
    },
  },
  methods: {
    searchLabel (selectedFilter) {
      switch (selectedFilter) {
        case 'parent_organization':
          return 'PARENT ORG NAME SEARCH'
        case 'student':
          return 'STUDENT NAME SEARCH'
        case 'school':
          return 'SCHOOL NAME SEARCH'
        default:
          return 'TEACHER NAME SEARCH'
      }
    },
    parentOrganizationName (data) {
      return data.item.teacher?.school?.parent_organization?.name
    },
    async exportPurchaseReport () {
      let exportData = {}
      switch (this.selectedFilter) {
        case 'date':
          exportData = {
            'filter_by_dates': {
              'start_date': this.startDate,
              'end_date': this.endDate,
            },
            'type': 'teacher'
          }
          break
        case 'student':
          exportData = {
            'student_name': this.search,
            'type': 'student'
          }
          break
        default:
          exportData = {
            'teacher_name': this.search,
            'type': 'teacher'
          }
      }

      let reponseData
      if (this.selectedStudents) {
        reponseData = await AccountTokensService.exportStudentReports(exportData)
      } else {
        reponseData = await AccountTokensService.exportTokensList(exportData)
      }
      const url = window.URL.createObjectURL(reponseData)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Purchase Report.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    async filterByDate () {
      this.isLoadingTokens = true
      await AccountTokensService.getTokensList({
        'start_date': this.startDate,
        'end_date': this.endDate,
        'type': this.selectedParentOrganization ? 'parent_organization' : 'teacher'
      }).then(({ data }) => {
        this.reportList = Object.values(data)
        this.isLoadingTokens = false
      })
    },
    async handlerSearch (search) {
      this.isLoadingTokens = true
      let data = {}
      switch (this.selectedFilter) {
        case 'parent_organization':
          data = {
            'parent_organization_name': search.searchForm,
            'type': this.selectedFilter
          }
          break
        case 'student':
          data = {
            'student_name': search.searchForm,
            'type': this.selectedFilter
          }
          break
        default:
          data = {
            'teacher_name': search.searchForm,
            'type': 'teacher'
          }
      }
      if (this.selectedStudents) {
        this.getStudentReports(data)
        return
      }
      await AccountTokensService.getTokensList(data).then(({ data }) => {
        this.reportList = Object.values(data)
      }).finally(() => {
        this.isLoadingTokens = false
      })
    },
    setDate (date) {
      return moment(date).format('M/D/YYYY')
    },
    async getStudentReports (data) {
      await AccountTokensService.getStudentReports({ ...data }, this.sort_by).then(({ data }) => {
        this.reportList = data.map(({ ...item }) => {
          return {
            username: item.user?.student?.first_name + ' ' + item.user?.student?.last_initial,
            classroom: item.classroom?.name,
            purchaseDate: item.created_at,
            subject: item.classroom?.subject?.name,
            country: item.classroom?.teacher?.country?.name,
            amount: item.amount,
            classroomCode: item.classroom?.code
          }
        })
      }).finally(() => {
        this.isLoadingTokens = false
      })
    },
    async filterByTokenType () {
      this.isLoadingTokens = true
      const data = {
        'type': this.selectedFilter === 'date' ? 'teacher' : this.selectedFilter
      }
      if (this.selectedStudents) {
        this.getStudentReports({ ...data })
        return
      }
      await AccountTokensService.getTokensList({ ...data }, this.sort_by).then(({ data }) => {
        this.reportList = Object.values(data)
      }).finally(() => {
        this.isLoadingTokens = false
      })
    },
    editExpirationDate (selected_token, _, event) {
      if (event.target.classList.contains('parent-org-icon')) {
        return
      }
      this.tokenData = selected_token
      this.$refs['modal-token-expiration'].token_data = selected_token
      this.$refs['modal-token-expiration'].open()
    },
    onTokenUpdated () {
      this.$refs['modal-token-expiration'].close()
      this.tokenData = {}
      this.filterByTokenType()
    },
    sortingChanged (arg) {
      this.sort_by = {
        field: arg.sortBy,
        order: arg.sortDesc ? 'desc' : 'asc'
      }

      this.filterByTokenType()
    },
  },
  watch: {
    async selectedFilter () {
      this.sort_by = {}
      this.currentPage = 1
      await this.filterByTokenType()
    }
  },
  async mounted () {
    await this.filterByTokenType()
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.dropdown-purchase
  max-width 400px

.container-date
  display flex
  justify-content space-evenly
  width 100%

.container-filter
  flex-wrap nowrap

  .datepicker-custom
    margin-bottom 0

    ::v-deep

      #date-input-t,
      #date-input-t2
        padding 0 10px !important

      .form-control
        padding 0 4px !important
        margin-top 3px !important
        position relative
        top 6px

.full-name-teacher,
.full-school,
.parent-organization-name,
.classroom-name
  text-overflow ellipsis
  overflow hidden
  max-width 180px
  white-space nowrap
  display block
  @media (max-width: 1400px)
    max-width 120px

.parent-org-icon
  font-size 20px
  cursor pointer
  position realtive
  z-index 10
  color color-blue-100

.classroom-name
  cursor pointer
  font font-opensans-bold
  width fit-content

#report-table-details-parent-org
  background-color red

::v-deep
  .b-table-details
    td
      padding-right 0 !important
      padding 0 !important

      #report-table-details-parent-org
        td
          border-right-style none !important
          border-left-style none !important
          padding 0.4rem 1.5rem !important

        .icon-details-collapse
          padding 0 !important
          padding-left 20px !important
          width 20px

        .b-table-details
          pointer-events none

          td
            border-right-style none !important
            border-left-style none !important
            padding-right 0 !important
            padding 0 !important

            #report-table-transaction
              td
                border-right-style none !important
                border-left-style none !important
                padding 0.4rem 1.5rem !important

              .icon-details-collapse
                padding 0 !important
                padding-left 20px !important
                width 38px

      .table-responsive
        margin-bottom 0 !important

::v-deep
  .icon-details-collapse
    padding 0 !important
    padding-left 20px !important
    width 20px

@media (max-width: 1000px)
  .container-filter
    flex-wrap wrap

@media (max-width: 650px)
  .my-2
    .button
      height auto !important

  .container-filter
    flex-wrap wrap

    .container-date
      margin-top 20px
      flex-direction column
</style>
