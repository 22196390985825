import {mapActions, mapGetters} from 'vuex'

export const TeacherMixins = {
  computed: {
    ...mapGetters({
      getTeachers: 'TeacherStore/getTeachers',
      isLoadingTeachers: 'TeacherStore/isLoadingTeachers',
      isLoadingClassroom: 'TeacherStore/isLoadingClassroom',
      getTeacherDetail: 'TeacherStore/getTeacherDetail',
      getTeacherClassrooms: 'TeacherStore/getTeacherClassrooms',
      addingTokens: 'TeacherStore/addingTokens',
    })
  },
  methods: {
    ...mapActions({
      fetchTeacherList: 'TeacherStore/fetchTeacherList',
      fetchDetail: 'TeacherStore/fetchDetail',
      resetDetail: 'TeacherStore/resetDetail',
      fetchClassrooms: 'TeacherStore/fetchClassrooms',
      addTokens: 'TeacherStore/addTokens',
      updateExpirationDate: 'TeacherStore/updateExpirationDate',
    })
  }
}
