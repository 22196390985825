export const getters = {
  getAgeRanges: (state) => {
    return state.ageRanges
  },
  getSubjects: (state) => {
    return state.subjects
  },
  getLanguages: (state) => {
    return state.languages
  },
  getLevels: (state) => {
    return state.levels
  },
  getStandards: (state) => {
    return state.standards
  },
  getCourseStudentGroupTypes: (state) => {
    return state.courseStudentGroupTypes
  },
  getTeacherTypes: (state) => {
    return state.teacherTypes
  }
}
