const FETCH_SUBJECT_LIST_START = 'FETCH_SUBJECT_LIST_START'
const FETCH_SUBJECT_LIST_DATA = 'FETCH_SUBJECT_LIST_DATA'
const FETCH_TASK_LIST_DATA = 'FETCH_TASK_LIST_DATA'
const FETCH_SUBJECT_DETAIL_START = 'FETCH_SUBJECT_DETAIL_START'
const FETCH_SUBJECT_DETAIL_DATA = 'FETCH_SUBJECT_DETAIL_DATA'

export {
  FETCH_SUBJECT_LIST_START,
  FETCH_SUBJECT_LIST_DATA,
  FETCH_SUBJECT_DETAIL_DATA,
  FETCH_SUBJECT_DETAIL_START,
  FETCH_TASK_LIST_DATA
}
