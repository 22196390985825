<template>
  <div>
    <b-overlay
      :show="isLoadingSubjects"
      spinner-medium
      rounded="sm">
<!--      <b-row class="mx-3 my-1">-->
<!--        <b-col lg="12" class="my-1">-->
<!--          <search-input v-model="search_string" @on-search="handlerSearch"></search-input>-->
<!--        </b-col>-->
<!--      </b-row>-->
      <b-table
        :responsive="true"
        hover
        sort-icon-left
        :fields="fields"
        :items="getSubjects"
        table-variant="ligth"
        head-variant="ligth"
        no-local-sorting
        @sort-changed="sortingChanged"
        id="table"
        ref="table">
        <template #cell(name)="data">
          <a class="a-title mx-2" @click="goTo(data.item.id)"> {{ data.value }}</a>
        </template>
        <template #cell(type)="status">
          {{ status.value }}
        </template>
        <template #cell(number_of_tokens)="number_of_tokens">
          <span class="mx-2">{{ number_of_tokens.value }}</span>
        </template>
      </b-table>
<!--      <b-row>-->
<!--        <br>-->
<!--        <b-col md="8" lg="4" class="pb-2">-->
<!--          <b-button @click="navigate" class="button primary">create new subject</b-button>-->
<!--        </b-col>-->
<!--      </b-row>-->
    </b-overlay>
  </div>
</template>

<script>
// import {SearchInput} from '@/core/components/'
import {SubjectMixins} from '../Mixins'

export default {
  name: 'SubjectsList',
  components: {
    // SearchInput
  },
  mixins: [SubjectMixins],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'SUBJECT NAME',
          sortable: true,
        },
        {
          key: 'type',
          label: 'TYPE',
          sortable: true,
        },
        {
          key: 'number_of_tokens',
          label: 'NUMBER OF TOKENS',
          sortable: true,
        },
      ],
      search_string: '',
      sortBy: ''
    }
  },
  async mounted() {
    await this.fetchSubjects()
  },
  methods: {
    async fetchSubjects() {
      await this.fetchSubjectsList({
        filters: {search_string: this.search_string},
        sortBy: this.sortBy
      })

      console.log(
        this.getSubjects
      )
      this.$refs.table.refresh()
    },
    handlerSearch(arg) {
      this.fetchSubjects()

      return arg
    },
    navigate() {
      this.$router.push({name: 'SubjectsDetail'})
    },
    goTo(id) {
      this.$router.push({path: `/PanelAdmin/subjects/${id}`})
    },
    sortingChanged(arg) {
      if (arg.sortBy === 'name') {
        this.sortBy = arg.sortDesc ? 'course_name_desc' : 'course_name'
      } else if (arg.sortBy === 'free_paid') {
        this.sortBy = arg.sortDesc ? 'premium_desc' : 'premium'
      } else {
        this.sortBy = arg.sortDesc ? 'subject_desc' : 'subject'
      }
      this.fetchSubjects()
    },
  },
  computed: {
    showPagination() {
      return this.getPagination.totalRow > 10
    }
  },
  watch: {
    getSubjects(newValue) {
      if (!newValue.length) {
        this.$bvModal.msgBoxOk(`No Subject were found matching the entered criteria..`)
        this.search_string = ''
        this.sortBy = ''

        this.fetchSubjects()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

</style>
