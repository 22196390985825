export const state = {
  classroomPartnership: {
    show_end_date: false,
    end_date: null,
    classrooms: {
      name: '',
      code: '',
      teacher: '',
      loginId: '',
      country: '',
      age: '',
      subject: '',
      nativeLanguage: '',
      task: '',
      dates: '',
      level: '',
      course: {},
    },
    partnership: {
      name: '',
      code: '',
      teacher: '',
      loginId: '',
      country: '',
      age: '',
      subject: '',
      nativeLanguage: '',
      task: '',
      dates: '',
      teacherId: '',
      level: '',
      course: {},
    }
  },
  loadingPartnership: false,
  updatingPartnership: false,
  error: ''
}
