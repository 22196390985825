import {http} from './http'

const resource = 'api/admin/librarian-connection'

export default {
  getConnectionsRequest(filters, sortBy, page) {
    return http.get(`${resource}/page`, {params: {filters, sort_by: sortBy, page}}).then(response => {
      return response
    })
  },
  getConnectionsRequestById(connectionId) {
    return http.get(`${resource}/${connectionId}`).then(response => {
      return response
    })
  },
}
