<template>
  <div class="form-group">
    <label v-if="label" :for="`checkbox-group-${_uid}`">{{ label }}</label>

    <b-form-checkbox-group v-model="inputValue"
                           :id="`checkbox-group-${_uid}`"
                           :options="options"
                           :name="name"
                           :class="customClassInput"
                           style="column-count: 3;"
                           :value-field="valueField"
                           :text-field="textField"
                           :state="state"/>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`checkbox-group-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`checkbox-group-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import {InputMixins} from "@/modules/panel/Mixins"

export default {
  name: "CheckboxGroup",
  mixins: [InputMixins],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    name: {
      type: String,
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    customClass: {
      type: String,
    },
    valueField: {
      type: String,
      default: 'value'
    },
    textField: {
      type: String,
      default: 'text'
    },
    isObjectArray: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customClassInput() {
      const className = {
        'is-invalid': this.state === false,
        'check-box-admin': true
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    },
    inputValue: {
      get: function () {
        let values = []
        for (let i = 0; i < this.value.length; i++) {
          if (this.isObjectArray) {
            values.push(this.value[i][this.valueField])
          } else {
            values.push(this.value[i])
          }
        }
        return values ?? []
      },
      set: function (values) {
        let options = [];

        for (let i = 0; i < values.length; i++) {
          let element

          if (this.isObjectArray) {
            element = this.options.find((el) => el[this.valueField] === values[i])
          } else {
            element = this.options.find((el) => {
              return el.value === values[i]
            }).value

          }

          if (element) {
            options.push(element)
          }
        }
        this.$emit('input', options)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.check-box-admin
  ::v-deep
    .custom-checkbox {
      .custom-control-label {
        font font-opensans-regular
        font-size 16px
        padding-top 2px

        &::before {
          border-radius 0
        }
      }

      .custom-control-label {
        &::before {
          color white !important
          border-color color-blue-100 !important
          background-color white !important
          height: 1.2rem
          border-radius: 0px !important
          width: 1.2rem
        }

        &::after {
          color color-blue-100
        }
      }

      .custom-control-input {
        &:focus {
          & ~ .custom-control-label::before {
            box-shadow 0 0 0 0.2rem rgba(color-blue-100, 0.25)
            height: 1.2rem
            border-radius: 0px
            width: 1.2rem
          }

          &:not(:checked) ~ .custom-control-label::before {
            border-color color-blue-100
            border-radius: none
            height: 1.2rem
            width: 1.2rem
          }
        }

        & ~ .custom-control-label::after {
          border-radius: 0px
          height: 1.2rem
          width: 1.2rem
        }

        &:checked ~ .custom-control-label::after {
          font-family 'icomoon' !important
          background-image none
          content "\e92a"
          background-color: white
          border 1px solid color-blue-100
          box-shadow 0 0 0 0.1rem rgba(color-blue-100, 0.25)
          font-size 1rem
          height: 1.2rem
          width: 1.2rem
        }
      }
    }
</style>
