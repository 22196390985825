import LoginPages from '../pages/loginPages.vue'
import ViewLoginPage from '../views/login.vue'
import ForgotPasswordPage from '../pages/ForgotPasswordPage.vue'
import RecoverPasswordPage from '../pages/RecoverPasswordPage.vue'

const routes = [
  {
    path: '/',
    component: ViewLoginPage,
    children: [
      {
        path: '',
        name: 'LoginPages',
        component: LoginPages
      },
      {
        path: 'forgot-password',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage,
      },
      {
        path: 'security/recover-password/:token',
        name: 'RecoverPasswordPage',
        component: RecoverPasswordPage,
      },
    ]
  }
]
export default routes
