import {http} from './http'

const resource = 'api/admin'

export default {
  getAdministrators(filters) {
    return http.get(`${resource}/administrators`, {params: filters}).then(response => {
      return response
    })
  },
  getAdminById(adminId) {
    return http.get(`${resource}/administrators/${adminId}`).then(response => {
      return response
    })
  },
  addAdmin(form) {
    return http.post(`${resource}/administrators`, form).then(response => {
      return response
    })
  },
  updateAdmin(adminId, form) {
    return http.put(`${resource}/administrators/${adminId}`, form).then(response => {
      return response
    })
  },
  getAdminRoles() {
    return http.get(`${resource}/admin-roles`,).then(response => {
      return response
    })
  },
}
