<template>
  <modal ref="modalDetail"
         id="modal-teacher-token-add"
         @hide="close"
         :hideFooter="true">
    <template #header>
    </template>
    <template #body>
      <b-container>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="12">
                <h2 class="subtitle">Add Exchanges</h2>
                <div class="container-form">
                  <b-form-group>
                    <b-form-group>
                      <select-dropdown :options="getSubjects"
                                       v-model="$v.subjectSelected.$model"
                                       placeholder="Select Subject *"
                                       value-field="id">
                      </select-dropdown>
                      <div class="error" v-if="!$v.subjectSelected.required && $v.subjectSelected.$dirty">
                        Subject field is required
                      </div>
                    </b-form-group>
                    <b-form-input v-model="$v.token_data.number_of_tokens.$model"
                                  autocomplete="off"
                                  maxlength="2"
                                  class="input-email"
                                  type="text"
                                  placeholder="Number of tokens"/>
                    <div class="error"
                           v-if="!$v.token_data.number_of_tokens.required && $v.token_data.number_of_tokens.$dirty"> Password field is required</div>
                    <div class="error"
                           v-if="!$v.token_data.number_of_tokens.maxValue && $v.token_data.number_of_tokens.$dirty"> Max value 10</div>
                    <b-input-group>
                      <b-form-input id="date-input" v-model="expire_at_formatted" type="text" placeholder="Due Date"
                                    autocomplete="off" readonly></b-form-input>
                      <b-input-group-append class="btn-picker">
                        <b-form-datepicker v-model="$v.token_data.expire_at.$model" button-only right
                                           :min="min_expire_at"
                                           :max="max_expire_at"
                                           locale="en-US"></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>

                    <b-button class="button button--email my-1"
                              @click="close">Cancel
                    </b-button>
                    <b-button class="button button--email my-1 mx-2"
                              @click="saveTokens">Save
                    </b-button>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>

            <b-overlay :show="addingTokens || loading" no-wrap spinner-medium rounded="sm">
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </modal>
</template>

<script>
import {SelectDropdown, Modal} from '@/core/components'
import {TeacherMixins, UtilsMixins} from '../Mixins'
import {required, maxValue} from "vuelidate/lib/validators"
import {ParentOrganizationService} from '@/core/services'
import moment from 'moment'

export default {
  name: 'ModalTeacherTokenAdd',
  mixins: [TeacherMixins, UtilsMixins],
  props: {
    isParentOrganizationTab: {
      type: Boolean,
      default: false
    },
    organizationId: {
      type: [Number, String],
    }
  },
  data() {
    const expire_at = moment().add(6, 'M').format('YYYY-MM-DD')
    const max_expire_at = moment().add(2, 'y').add(6, 'M').format('YYYY-MM-DD')

    return {
      min_expire_at: expire_at,
      max_expire_at: max_expire_at,
      subjectSelected: null,
      token_data: {
        number_of_tokens: 1,
        expire_at: expire_at,
      },
      tokenDataInit: {
        number_of_tokens: 1,
        expire_at: expire_at,
      },
      loading: false
    }
  },
  validations: {
    token_data: {
      number_of_tokens: {
        required: required,
        maxValue: maxValue(10)
      },
      expire_at: {
        required: required
      },
    },
    subjectSelected: {
      required: required,
    }
  },
  components: {
    Modal,
    SelectDropdown
  },
  methods: {
    open() {
      return new Promise((resolve) => {
        this.$refs.modalDetail.showModal()
        resolve(this)
      })
    },
    close() {
      this.$v.$reset()
      this.subjectSelected = null
      this.token_data = {...this.tokenDataInit}
      return new Promise((resolve) => {
        this.$refs.modalDetail.hideModal()
        resolve(this)
      })
    },
    async saveTokens() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }

      if (!this.addingTokens) {
        if (!this.isParentOrganizationTab) {
          try {
            const teacher_id = this.getTeacherDetail.id
            const amount_tokens = this.token_data.number_of_tokens
            const expire_at = this.token_data.expire_at
            const subject_id = this.subjectSelected

            await this.addTokens({teacher_id, amount_tokens, expire_at, subject_id})

            this.$emit('on-success-store-account-tokens')
          } catch (e) {
            const errorMessage = e.message ? e.message : 'Error fetching teachers list.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          }
        } else {
          this.loading = true
          try {
            const organization_id = this.organizationId
            const amount_tokens = this.token_data.number_of_tokens
            const expire_at = this.token_data.expire_at
            const subject_id = this.subjectSelected

            await ParentOrganizationService.addTokensToParentOrganization({organization_id, amount_tokens, expire_at, subject_id})

            this.$emit('on-success-store-account-tokens')
          } catch (e) {
            const errorMessage = e.message ? e.message : 'Error.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          } finally {
            this.loading = false
          }
        }

      }
    },
  },
  mounted() {
    this.fetchSubjects()
  },
  computed: {
    expire_at_formatted: function () {
      return moment(this.token_data.expire_at).format('MM/DD/YYYY')
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

#date-input
  margin-bottom 0 !important

.btn-picker
  .b-form-btn-label-control
    ::v-deep
      .btn
        margin 0

.teacher-about
  margin-bottom 40px !important

.container-top {
  margin: 0rem -1.2rem 1rem -1.2rem
  padding-bottom: .5rem
  padding-left: 1rem
  border-bottom: 2px solid red
  border-color: color-gray-400
  color: color-blue-300

  &-back {
    width: calc(100% + 1rem);
    margin: 0;
    margin-left: -0.5rem;
  }

  a {
    font-size: 1rem
    font-weight: bolder
    margin-top: 0
    margin-bottom: 1rem

    &.icon-caret-left:before {
      font-size: 1rem
    }
  }
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin 10px 0
}

.container-button {
  padding: 0.5rem;
  color: #2f3e98;

  display: flex
  flex-direction: row
  width 100%
  max-width 420px
  justify-content: center

  &-masquerade {
    border-bottom: 2px solid color-gray-400;
    margin-bottom 4rem
  }

  &-bottom {
    margin-bottom 2rem
  }
}

.container-text {
  display: flex

  .b-avatar {
    margin-top: 2rem
    width 120px
    height 120px
  }
}

.list-item {
  display: flex
  margin: .4rem 0
  justify-content: left

  p {
    text-align: left
    min-width 112px
    max-width: 230px
    margin .1rem .3rem
    overflow-wrap: break-word
  }
}

.title {
  text-transform: uppercase
  font-size: 24px
  font-weight: bold
  overflow-wrap: break-word
}

.teacher-report-container
  display block
  position relative

.subtitle {
  text-transform: uppercase
  font-size: 14px
  font-weight: bold
  margin-top 1rem

  &.reported {
    font: font-opensans-regular
    font-size: 14px
    text-transform: capitalize
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

p {
  font-size: 13px
  overflow-wrap: break-word
}

.container-form {
  margin 2rem 0

  .custom-checkbox {
    margin-top 1.5rem
  }
}

.text-date {
  font-size 14px !important
}

.input-email
  margin-top 8px

.button--email
  height auto
  padding-top 7.5px
  padding-bottom 7.5px

</style>
<style lang="stylus">
#modal-teacher-token-add
  .modal-body
    margin 0 !important
    padding 0 !important

    .close
      position absolute
      right 0
      z-index 1
</style>
