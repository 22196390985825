<template>
  <div>
    <b-overlay :show="loading"
               spinner-medium
               rounded="sm">
      <b-col class="container-actions">
        <div class="container-actions-left">
          <select-dropdown :options="options"
                           v-model="filterSelected"
                           @input="handleDropdown"
                           class="role-dropdown --secundary"
                           value-field="slug"/>
          <select-dropdown :options="countries"
                           v-model="countrySelected"
                           @input="handleCountryDropdown"
                           class="role-dropdown --secundary"
                           value-field="id"/>
        </div>
        <div class="container-actions-right">
          <search-input class="search-input --secundary"
                        v-model="search"
                        placeholder="SCHOOL NAME SEARCH"
                        @on-search="handlerSearch"/>
          <select-dropdown :options="states"
                           v-if="isUsa"
                           v-model="stateSelected"
                           @input="handleStateDropdown"
                           class="role-dropdown --secundary"
                           value-field="id"/>
        </div>
      </b-col>
      <b-table :responsive="true"
               hover
               sort-icon-left
               :fields="fields"
               :items="schoolList"
               table-variant="ligth"
               head-variant="ligth"
               no-local-sorting
               @sort-changed="sortingChanged"
               id="admin-table"
               ref="table">
        <template #cell(name)="data">
          <a class="a-title a-title--name"
             @click="openSchoolDetail(data.item)">
             {{ data.item.name }}</a>
        </template>
        <template #cell(country)="data">
          <span class="a-title a-title--schools">{{ data.item.country && data.item.country.name }}</span>
        </template>
        <template #cell(dpa_signed_date)="data">
          <span class="a-title a-title--schools">{{ dpaSignedDate(data.item.dpa_agreement_completed_at) }}</span>
        </template>
        <template #cell(teachers)="data">
          <span class="a-title a-title--schools">{{ data.item.teachers_count }}</span>
        </template>
        <template #cell(parent_organization)="data">
          <span class="a-title a-title--schools">{{ data.item.parent_organization && data.item.parent_organization.name }}</span>
        </template>
      </b-table>
      <b-row class="justify-content-md-center">
        <b-col md="3" class="mx-auto">
          <b-pagination v-show="showPagination && !loading"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        @change="changePagination"
                        :per-page="perPage"
                        aria-controls="teachers-table"
                        first-number
                        last-number
                        align="fill"
                        size="sm"
                        class="my-2">
            <template #prev-text><i class="icon-chevron-left"></i></template>
            <template #next-text><i class="icon-chevron-right"></i></template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-overlay>
    <modal-school-detail ref="modalSchoolDetail"
                         @click-checkbox="getSchools(currentPage)"
                         :school-id="schoolSelected.id"
                         :school-name="schoolSelected.name"/>
  </div>
</template>

<script>
import { AlertMixins } from '../Mixins'
import { SearchInput, SelectDropdown } from '@/core/components'
import { SchoolsService, CountriesService, TeacherService } from '@/core/services'
import ModalSchoolDetail from './ModalSchoolDetail'
import moment from 'moment'

export default {
  name: 'ParentOrgList',
  mixins: [AlertMixins],
  components: {
    SearchInput,
    SelectDropdown,
    ModalSchoolDetail
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'NAME',
          sortable: true
        },
        {
          key: 'country',
          label: 'COUNTRY',
          sortable: true
        },
        {
          key: 'dpa_signed_date',
          label: 'DPA SIGNED DATE',
          sortable: true
        },
        {
          key: 'teachers',
          label: '# TEACHERS AT SCHOOL',
          sortable: true
        },
        {
          key: 'parent_organization',
          label: 'PARENT ORGANIZATION',
          sortable: true
        }
      ],
      options: [
        { name: 'All Schools', slug: 'all'},
        { name: 'DPA Completed', slug: 'completed'}
      ],
      schoolList: [],
      filterSelected: 'all',
      search: '',
      loading: false,
      schoolSelected: {
        id: null,
        name: ''
      },
      currentPage: 1,
      perPage: 20,
      countries: [],
      countrySelected: '',
      states: [],
      stateSelected: null,
      sort_by: {},
      totalRows: ''
    }
  },
  async mounted() {
    this.loading = true
    await CountriesService.getCountries().then(({data}) => {
      this.countries = [
        {name: 'All Countries', id: ''},
        ...data
      ]
    })
    this.getSchools()
  },
  computed: {
    showPagination() {
      return this.totalRows > this.perPage
    },
    isUsa () {
      return this.countrySelected === 237
    }
  },
  methods: {
    dpaSignedDate(date) {
      return date ? moment(date).format('MM/DD/YYYY') : null
    },
    sortingChanged (arg) {
      this.sort_by = {
        field: arg.sortBy,
        order: arg.sortDesc ? 'desc' : 'asc'
      }
      this.getSchools()
    },
    changePagination(page) {
      this.getSchools(page)
    },
    getSchools(page = 1) {
      this.loading = true
      let data = {
        dpa_agreement: this.filterSelected,
        country_id: this.countrySelected,
        state_id: this.stateSelected,
      }
      if (this.search) {
        data.search = this.search
      }
      SchoolsService.getSchools(data, this.sort_by, page).then((response) => {
        if (response.success) {
          this.schoolList = response.data
          this.perPage = response.per_page
          this.currentPage = response.current_page
          this.totalRows = response.next_page_url ? ((Number(response.current_page) + 1) * response.per_page) : (response.current_page * response.per_page)
          if (!this.schoolList.length && this.search) {
            this.$bvModal.msgBoxOk('No matches found.').then(() => {
              this.search = ''
              this.filterSelected = 'all'
              this.countrySelected = ''
              this.getSchools()
            })
          }
        } else {
          const errorMessage = response.message ? response.message : 'Error.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }).finally(() => this.loading = false)
    },
    handlerSearch() {
      this.getSchools()
    },
    handleDropdown() {
      this.search = ''
      this.getSchools()
    },
    async handleCountryDropdown(countryId) {
      this.search = ''
      if (countryId === 237) {
        await TeacherService.states(countryId).then((response) => {
          this.states = response.data
        })
        this.stateSelected = 1
      } else {
        this.stateSelected = null
      }
      this.getSchools()
    },
    handleStateDropdown() {
      this.getSchools()
    },
    openSchoolDetail(school) {
      this.schoolSelected = school
      this.$nextTick(() => this.$refs.modalSchoolDetail.open())
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.container-actions
  display flex
  justify-content space-between
  margin-bottom 10px

  .--secundary
    margin-top 10px

  .role-dropdown, .state-dropdown
    max-width 350px

  @media(max-width 900px)
    display block

    .role-dropdown
      max-width 400px

.a-title--name, .a-title--schools
  text-overflow ellipsis
  overflow hidden
  max-width 200px
  white-space nowrap
  display block
  cursor pointer
  @media(max-width 1000px)
    max-width 200px
  @media(max-width 800px)
    max-width 100px

.a-title--schools
  cursor auto

.a-title--exchanges
  span
    cursor pointer
    text-decoration underline
    font font-opensans-semibold

.container-actions-left
  min-width 350px
  @media (max-width 900px)
    min-width 250px
  @media (max-width 600px)
    min-width unset
    width 100%

.container-actions-right
  width 100%
  padding-left 10px
  @media (max-width 900px)
    padding-left 0
    display flex
    flex-direction column

    .search-input
      order 2

    .state-dropdown
      order 1
</style>
