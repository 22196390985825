<template>
  <div>
    <div class="top">
      <a class="mx-2" href="#/PanelAdmin/subjects"><i class="icon-caret-left"></i> Back</a>
    </div>
    <b-row class="my-1">
      <b-col cols="12">
        <b-overlay :show="loadingSubjectsData" spinner-medium rounded="sm">
          <b-form @submit="saveSubject" class="container-form">
            <h2 class="title">Subjects</h2>
            <h6 class="subtitle"><i>Require fields *</i></h6>

            <b-form-input
              v-model="$v.getSubjectDetail.name.$model"
              type="text"
              placeholder="Subject Name *"
              :class="{ 'form-group--error': $v.getSubjectDetail.name.$error, 'format-input': 'format-input' }"/>
            <div class="error" v-if="!$v.getSubjectDetail.name.required && $v.getSubjectDetail.name.$dirty">
              Subject Name field is required
            </div>

            <b-form-group>
              <select-dropdown
                :options="subject_types"
                v-model="$v.getSubjectDetail.type.$model"
                :class="{ 'form-group--error': $v.getSubjectDetail.type.$error, 'format-input': 'format-input' }"
                placeholder="Subject type *"
                value-field="id">
              </select-dropdown>
              <div class="error" v-if="!$v.getSubjectDetail.type.required && $v.getSubjectDetail.type.$dirty">
                Subject type field is required
              </div>
            </b-form-group>

            <b-form-input
              id="input-6"
              v-model="$v.getSubjectDetail.number_of_tokens.$model"
              type="number"
              placeholder="Number of tokens (0 = free)"
              sr-only>
            </b-form-input>
            <div class="error"
                 v-if="!$v.getSubjectDetail.number_of_tokens.required && $v.getSubjectDetail.number_of_tokens.$dirty">
              Number of tokens is required
            </div>
            <div class="error"
                 v-if="!$v.getSubjectDetail.number_of_tokens.between && $v.getSubjectDetail.number_of_tokens.$dirty">
              Number of tokens must be between 0 and 10
            </div>
            <br><br>
            <section class="container-button">
<!--              <b-button v-if="getSubjectDetail.id" class="button b-ligth" @click="OpenModal()">Delete</b-button>-->
              <b-button @click="goBack()" class="button b-ligth">Cancel</b-button>
              <b-button type="submit" class="button primary --sm">Save</b-button>
            </section>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <modal ref="deleteSubject" modal-class="delete">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title text-center">DELETE {{ getSubjectDetail.name }} ?</h2>
        <p class="text-center">
          Are you sure?
        </p>
      </template>
      <template #footer>
        <b-button class="button b-ligth" @click="deleteSubject"> Delete</b-button>
        <b-button class="button" @click="CloseModal"> cancel</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {SelectDropdown, Modal} from '@/core/components'
import {SubjectMixins, UtilsMixins} from '../Mixins'
import {SubjectService} from '@/core/services'
import {between, required} from 'vuelidate/lib/validators'

export default {
  name: 'SubjectDetail',
  components: {
    SelectDropdown,
    Modal,
  },
  mixins: [SubjectMixins, UtilsMixins],
  data() {
    return {
      loadingSubject: false,
      loadingTask: false,
      errorOrder: '',
      subject_types: [{id: 'world_languages', name: 'world_languages'}, {
        id: 'non_world_languages',
        name: 'non_world_languages'
      }],
    }
  },
  validations: {
    getSubjectDetail: {
      name: {
        required,
      },
      type: {
        required,
      },
      number_of_tokens: {
        required,
        between: between(0, 10)
      },
    }
  },
  beforeMount() {
    this.loadingSubject = true
    this.loadingTask = true
  },
  async mounted() {
    await this.fetchSubjectsDetail(this.$route.params.id)
  },
  methods: {
    saveSubject(e) {
      e.preventDefault()
      this.$v.getSubjectDetail.$touch()
      if (this.$v.getSubjectDetail.$anyError) {
        return
      }

      this.save()
    },
    OpenModal() {
      this.$refs.deleteSubject.showModal()
    },
    CloseModal() {
      this.$refs.deleteSubject.hideModal()
    },
    goBack() {
      this.$router.push({name: 'Subjects'})
    },
    async deleteSubject() {
      try {
        await SubjectService.deleteSubject(this.$route.params.id)

        await this.$router.push({name: 'Subjects'})
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while deleting subject.'

        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      }
    },
    async save() {
      const data = {
        name: this.getSubjectDetail.name,
        type: this.getSubjectDetail.type,
        number_of_tokens: Number(this.getSubjectDetail.number_of_tokens),
      }
      if (this.$route.params.id) {
        try {
          await SubjectService.updateSubject(this.$route.params.id, data)

          await this.$bvModal.msgBoxOk('Successful Updated!')
        } catch (e) {
          const errors = Object.values(e.errors).flat().toString().replace(/,/gs, ' ')
          const errorMessage = e.message ? e.message : 'Error while loading subject.'

          await this.$bvModal.msgBoxOk(`${errorMessage} ${errors} Please try again.`)
        }
      } else {
        try {
          let res = await SubjectService.createSubject(data)

          await this.$bvModal.msgBoxOk('Successful Created!')
          await this.$router.push({path: `/PanelAdmin/subjects/${res.data.id}`})
        } catch (e) {
          const errors = Object.values(e.errors).flat().toString().replace(/,/gs, ' ')
          const errorMessage = e.message ? e.message : 'Error while loading subject.'

          await this.$bvModal.msgBoxOk(`${errorMessage} ${errors} Please try again.`)
        }
      }
    },
    showErrors(err) {
      this.$bvModal.msgBoxOk(`${err.message}, Please try again`)
    }
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

a {
  color: color-blue-100
  font font-opensans-bold
  font-size: 17px
  text-decoration none
  margin-top: 0
  margin-bottom: 1rem

  .icon-caret-left {
    color: color-blue-100
  }
}

.container-form {
  width: 80%
  margin: 1px auto
}

.container-task {
  width: 100%
  margin: 1px auto
}

.container-button {
  padding: 1rem 0
  padding-top: 0
  width 90%
  margin 0

  .button {
    margin 0 2% 0 3%
    margin-top: 1rem
  }
}

.title {
  font font-opensans-bold
  font-size: 24px
  color: color-black-200
}

.subtitle {
  font-size 14px
  color color-black-300
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin-bottom: 10px
}

.order-input {
  height 3rem
  width 2.6rem
  text-decoration: none
  text-align: center
  cursor: pointer
  padding-left: 11px
  border: 2px solid color-gray-200
  border-radius: 4px
}

.top {
  width: 105%
  margin: 0rem 0rem 1rem -1.5rem
  padding-bottom: .5rem
  border-bottom: 2px solid red
  border-color: color-gray-400
}

.create-task-button {
  width: 100% !important;
  max-width: 300px !important;
}

</style>
