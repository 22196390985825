import {http} from './http'

const resource = 'api/admin'

export default {
  getActionsItem(filters) {
    return http.get(`${resource}/actions`, {params: {filters: filters}}).then(response => {
      return response
    })
  },
  getActionById(actionId) {
    return http.get(`${resource}/actions/${actionId}`).then(response => {
      return response
    })
  },
  addAction(form) {
    return http.post(`${resource}/actions`, form).then(response => {
      return response
    })
  },
  updateAction(actionId, form) {
    return http.put(`${resource}/actions/${actionId}`, form).then(response => {
      return response
    })
  },
  deleteAction(actionId) {
    return http.delete(`${resource}/actions/${actionId}`).then(response => {
      return response
    })
  },
  updateActionStatus(actionId, status) {
    return http.put(`${resource}/actions-active/${actionId}`, status).then(response => {
      return response
    })
  },
  updateActionOrder(actions) {
    return http.put(`${resource}/actions-order`, actions).then(response => {
      return response
    })
  },
}
