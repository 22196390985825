import axios from 'axios'
import { AppStorage, Uploader } from '@/core/utils'
import moment from 'moment'

const UPLOAD_CHUNK_SIZE_MB = process.env.VUE_APP_S3_UPLOAD_CHUNK_SIZE_MB
const UPLOAD_THREADS = process.env.VUE_APP_S3_UPLOAD_THREADS
const VUE_APP_S3_TIMEOUT = 1200 * 1000

const s3Upload = (file, key, bucket, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    const uploaderOptions = {
      file: file,
      fileKey: key,
      bucket: bucket,
      chunkSize: UPLOAD_CHUNK_SIZE_MB,
      threadsQuantity: UPLOAD_THREADS,
      timeout: VUE_APP_S3_TIMEOUT,
      useTransferAcceleration: false
    }
    const uploader = new Uploader(uploaderOptions)
    uploader
      .onProgress(({ percentage }) => {
        onUploadProgress(percentage)
      })
      .onError((error) => {
        reject(error)
      })
      .onSuccess(() => {
        resolve({ success: true })
      })

    uploader.start()
  })
}

const getProfileMetadata = function () {
  const user = AppStorage.getUser()

  return {
    country: !user.country_name ? '' : user.country_name.replace(/[^a-zA-Z0-9]/g, ''),
    school: !user.school_name ? '' : user.school_name.replace(/[^a-zA-Z0-9]/g, ''),
    teacher: !user.full_name ? '' : user.full_name.replace(/[^a-zA-Z0-9]/g, '')
  }
}

class UploadManager {
  constructor (uploadData) {
    this.step = ''

    this.progressFile = 0
    this.bucket = process.env.VUE_APP_S3_STUDENT_VIDEO_INPUT_BUCKET

    this.fileName = uploadData.fileName
    this.fileUrl = uploadData.fileUrl
    this.fileUrlInput = uploadData.fileUrlInput
    this.originalFile = uploadData.originalFile
    this.groupId = uploadData.fileName
    this.key = uploadData.fileName
    this.keyMeta = uploadData.fileName + '.json'

    this.onProgressFn = () => {
    }
  }

  async triggerTranscoder (url, size) {
    const rTriggerTranscoder = await axios.post(process.env.VUE_APP_S3_TRANSCODER_TRIGGER_URL, {
      FileUrl: url,
      size: size
    })

    if (rTriggerTranscoder.status !== 200) {
      throw new Error(`Oops, something went wrong. LUV had a problem when trying to render the video: ${this.fileName}.`)
    }
  }

  async uploadVideoFile (file) {
    // upload the video file
    await s3Upload(
      file,
      this.key,
      this.bucket,
      (progress) => {
        this.progressFile = Math.round((progress * 90) / 100)
        this.onProgressFn(this.progressFile)
      }
    )
  }

  onProgress (onProgress) {
    this.onProgressFn = onProgress
    return this
  }

  async uploadMetaFile () {
    const metaContent = { key: this.key, metadata: getProfileMetadata() }
    const metaFile = new File([JSON.stringify(metaContent)], this.keyMeta, {
      type: 'application/json'
    })

    await s3Upload(metaFile, this.keyMeta, this.bucket, (progress) => {
      this.onProgressFn(this.progressFile + Math.round((progress * 10) / 100))
    })
  }
}

const generateFileName = function () {
  return (moment().utc().format('YYYYMMDDHHmmssSSS') + Math.round(Math.random() * (999 - 100) + 100))
}
function getExtension (filename) {
  return filename.substring((filename.lastIndexOf('.')) + 1).toLowerCase()
}
function removeExtension (filename) {
  return filename?.substring(0, filename.lastIndexOf('.'))
}
const createFileNameData = async function (file) {
  const baseNamePrefix = 'task-media'
  const baseName = `${baseNamePrefix}-${generateFileName(file.name)}`
  const extension = getExtension(file.name)
  const fileName = baseName + '.' + extension
  const label = removeExtension(file.name) + '.mp4'
  const fileUrl = process.env.VUE_APP_S3_STUDENT_STORAGE + '/' + baseName + '.mp4'
  const fileUrlInput = process.env.VUE_APP_S3_IMPUT_STORAGE + '/' + fileName
  const originalFile = file.name

  return {
    baseNamePrefix,
    baseName,
    extension,
    fileName,
    fileUrl,
    fileUrlInput,
    originalFile,
    label
  }
}

const uploadFile = async function (file, customData, onUploadProgress) {
  const uManager = new UploadManager(customData)
    .onProgress((percentage) => {
      onUploadProgress(percentage)
    })

  const fileUrlInput = customData.fileUrlInput

  try {
    // call the transcoder check
    await uManager.triggerTranscoder(fileUrlInput, file.size)

    // upload the video file
    await uManager.uploadVideoFile(file)

    // upload the meta file
    await uManager.uploadMetaFile()

    return {
      name: uManager.fileUrl,
      originalFile: uManager.originalFile
    }
  } catch (error) {
    console.error(error)
  }
}

export default {
  videoUploadFile: uploadFile,
  createFileNameData
}
