<template>
  <b-col md="12">
    <b-row class="my-2 justify-content-between">
      <b-button @click="showTeacherCreateModal" class="button my-1">
        Create Teacher
      </b-button>
      <div>
        <b-button @click="exportTeachers" :disabled="exporting" class="button b-ligth --sm mx-1 my-1">
          Export Teachers
        </b-button>
        <b-button @click="exportTeachersClassroom" :disabled="exporting" class="button b-ligth --sm mx-1 my-1">
          Export Classrooms
        </b-button>
      </div>
    </b-row>
    <b-row class="my-2">
      <b-col lg="4" class="my-1">
        <select-dropdown class-select="--secundary" :options="teacherOptions" v-model="selectedFilterBy"
                         placeholder="FILTER BY" value-field="value">
        </select-dropdown>
      </b-col>
      <b-col lg="8" class="my-1">
        <search-input class="search-input" v-if="!isDate" v-model="search" :placeholder="selectedFilterBy === 'by_parent_org' ? 'PARENT ORG NAME SEARCH' : 'TEACHER NAME SEARCH'"
                      @on-search="handlerSearch"></search-input>
        <div class="container-date" v-if="isDate">
          <b-form-datepicker v-model="startSingUp"
                             class="--secundary mx-2 datepicker-custom"
                             id="date-input-t"
                             placeholder="Start sign up-date"
                             locale="en"/>
          <b-form-datepicker v-model="endSingUp"
                             class="--secundary mx-2 datepicker-custom"
                             id="date-input-t2"
                             placeholder="End sign up-date"
                             locale="en"/>
          <button @click="filterTeacherList" class="button --search">Search</button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-table v-show="getTeachers.length" responsive id="teachers-table" hover :fields="fields" sort-icon-left
               :items="getTeachers" table-variant="ligth" head-variant="ligth" ref="teacherTable"
               :current-page="currentPage" :per-page="perPage">
        <template #cell(teacher_name)="data">
          <a class="a-title" @click="OpenModalTeacherDetail(data.item.teacher_name.id)">
            {{ showNameOrEmail(data.item) }}
          </a>
        </template>
        <template #cell(parent_org)="data">
          <span
            @click="(data.item.email_verified_at && data.item.name) || OpenModalTeacherDetail(data.item.teacher_name.id)">
            {{ truncateString(data.item.parentOrganization && data.item.parentOrganization.name, 20) }}
          </span>
        </template>
        <template #cell(school)="data">{{ truncateString(data.item.school, 12) }}</template>
        <template #cell(country)="data">{{ truncateString(data.item.country, 14) }}</template>
      </b-table>
    </b-row>
    <b-row class=" justify-content-md-center">
      <b-col md="3" class="mx-auto">
        <b-pagination ref="pagination" v-show="showPagination" v-model="currentPage"
                      :total-rows="getTeachers.length" :per-page="perPage" aria-controls="teachers-table"
                      first-number last-number align="fill" size="sm" class="my-2">
          <template #prev-text><i class="icon-chevron-left"></i></template>
          <template #next-text><i class="icon-chevron-right"></i></template>
        </b-pagination>
      </b-col>
    </b-row>

    <modal-teacher-detail ref="modalTeacherDetail" :teacherDetail="teacherDetail" :classroom="classroom"
                          @modal-teacher-detail-hide="resetDetail"
                          @on-delete-teacher="deleteTeacher"
                          @on-show-add-tokens-modal="onShowAddTokensModal"/>

    <modal-teacher-token-add ref="addTokensModal" :teacherDetail="teacherDetail" :classroom="classroom"
    @on-success-store-account-tokens="onSuccessStoreAccountTokens"/>

    <b-overlay :show="isLoadingTeachers || exporting" spinner-medium rounded="sm" no-wrap/>

    <modal-teacher-create ref="modalTeacherCreate"/>
  </b-col>
</template>

<script>
import {SelectDropdown, SearchInput} from '@/core/components'
import {ModalTeacherDetail, ModalTeacherTokenAdd} from './index'
import {TeacherMixins, UtilsMixins} from '../Mixins'
import {TeacherService} from '@/core/services'
import ModalTeacherCreate from './ModalTeacherCreate.vue'

export default {
  name: 'TeacherContent',
  mixins: [TeacherMixins, UtilsMixins],
  components: {
    SelectDropdown,
    SearchInput,
    ModalTeacherDetail,
    ModalTeacherTokenAdd,
    ModalTeacherCreate
  },
  data() {
    return {
      fields: [
        {key: 'teacher_name', label: 'TEACHER NAME', sortable: true},
        {key: 'parent_org', label: 'PARENT ORG', sortable: true},
        {key: 'school', label: 'SCHOOL', sortable: true},
        {key: 'country', label: 'COUNTRY', sortable: true}
      ],
      classroom: [],
      teacherDetail: {},
      perPage: 10,
      currentPage: 1,
      search: '',
      selectedFilterBy: 'verified_teachers',
      sortBy: '',
      startSingUp: '',
      endSingUp: '',
      teacherOptions: [
        {name: 'Reported Teachers', value: 'reported_teachers'},
        {name: 'Verified Teachers', value: 'verified_teachers'},
        {name: 'Unverified Teachers', value: 'unverified_teachers'},
        // {name: 'Po pending Teachers', value: 'po_pending_teachers'},
        {name: 'Email not yet verified', value: 'unverified_email'},
        {name: 'Not yet matched with school', value: 'not_yet_matched_school'},
        {name: 'By sign up date ', value: 'signup_date'},
        {name: 'By Parent Org', value: 'by_parent_org'},
        {name: 'Librarians', value: 'librarian'},
        {name: 'all', value: 'all'}
      ],
      exporting: false
    }
  },
  async mounted() {
    await this.getTeachersList({
      filterBy: this.selectedFilterBy
    })

    if (this.getTeacherDetail.id) {
      this.OpenModalTeacherDetail(this.getTeacherDetail.id)
    }

    if (this.$route.params.id) {
      this.OpenModalTeacherDetail(this.$route.params.id)
    }
    await this.fetchTeacherTypes()
  },
  methods: {
    deleteTeacher() {
      this.filterTeacherList()
    },
    showTeacherCreateModal() {
      this.$refs.modalTeacherCreate.openModalTeacherCreate()
    },
    OpenModalTeacherDetail(id) {
      this.$refs.modalTeacherDetail.openModalDetail()
      this.fetchDetail(id).finally(()=>{
        this.$refs.modalTeacherDetail.setShowMasqueradeButton()
      })
      this.fetchClassrooms(id)
      this.teacherDetail = this.getTeacherDetail
      this.classroom = this.getTeacherClassrooms
    },
    onSuccessStoreAccountTokens() {
      this.$refs.addTokensModal.close()
      this.fetchDetail(this.getTeacherDetail.id).finally(()=>{
        this.$refs.modalTeacherDetail.setShowMasqueradeButton()
      })
    },
    onShowAddTokensModal() {
      this.$refs.addTokensModal.open()
    },
    async handlerSearch(arg) {
      this.search = arg.searchForm

      await this.getTeachersList({
        filterBy: this.selectedFilterBy,
        text: this.search,
        startDate: this.startSingUp,
        endDate: this.endSingUp
      }, this.sortBy)

      return (arg)
    },
    exportTeachers() {
      this.exporting = true
      TeacherService.exportTeacherList({
        filters: {
          filterBy: this.selectedFilterBy,
          text: this.search,
          startDate: this.startSingUp,
          endDate: this.endSingUp
        }
      })
        .catch((e) => {
          const errorMessage = e.message ? e.message : 'Error while loading course.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        })
        .finally(() => {
          this.exporting = false
        })
    },
    exportTeachersClassroom() {
      this.exporting = true
      TeacherService.exportTeachersClassroom({
        filters: {
          filterBy: this.selectedFilterBy,
          text: this.search,
          startDate: this.startSingUp,
          endDate: this.endSingUp
        }, sort: this.sortBy
      })
        .catch((e) => {
          const errorMessage = e.message ? e.message : 'Error while loading course.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        })
        .finally(() => {
          this.exporting = false
        })
    },
    truncateString(str, num) {
      if (str?.length > num) {
        return str?.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    filterTeacherList() {
      this.getTeachersList({
        filterBy: this.filterBy,
        text: this.search,
        startDate: this.startSingUp,
        endDate: this.endSingUp
      }, this.sortBy)
    },
    async getTeachersList(filters = {filterBy: '', text: '', startDate: '', endDate: ''}, sort = '') {
      try {
        await this.fetchTeacherList({
          filters: filters,
          sort: sort
        })

        this.$refs.teacherTable.refresh()
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error fetching teachers list.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }
    },
    showNameOrEmail(str) {
      return this.truncateString(str?.teacher_name.name?.trim() ? str.teacher_name.name : str.email, 15)
    }
  },
  computed: {
    showPagination() {
      return this.getTeachers.length > 10
    },
    isDate() {
      return this.selectedFilterBy === 'signup_date'
    }
  },
  watch: {
    selectedFilterBy(newValue) {
      this.filterBy = newValue
      if (this.filterBy !== 'signup_date') {
        this.getTeachersList({
          filterBy: this.filterBy,
          text: this.search,
          startDate: this.startSingUp,
          endDate: this.endSingUp
        }, this.sortBy)
      }
    },
    getTeachers(newValue) {
      if ((!newValue.length && this.search)) {
        if (this.selectedFilterBy === 'by_parent_org') {
          this.$bvModal.msgBoxOk(`No parent organizations were found matching the entered criteria.`)
          if (this.search) {
            this.selectedFilterBy = 'by_parent_org'
          } else {
            this.selectedFilterBy = 'all'
          }
        } else {
          this.$bvModal.msgBoxOk(`No Teachers were found matching the entered criteria..`)
          this.selectedFilterBy = 'all'
        }
        this.getTeachersList({
          filterBy: this.selectedFilterBy
        })
        this.search = ''
        this.sortBy = ''
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.container-date {
  display: flex
  justify-content: space-evenly
  width: 100%
}

.datepicker-custom
  ::v-deep

    #date-input-t
    #date-input-t2
      padding 0 10px !important

    .form-control
      padding 0 4px !important
      margin-top 7px !important

</style>
