<template>
  <b-input-group>
    <b-input-group-prepend class="input-group-prepend-custom" is-text>
      <i class="icon-search-dark" @click="onSearch"></i>
    </b-input-group-prepend>
    <b-form-input @keydown.enter="onSearch" id="filter-input" type="search" v-model="searchForm"
                  :placeholder="placeholder">
    </b-form-input>
  </b-input-group>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {}
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'SEARCH'
    }
  },
  methods: {
    onSearch() {
      this.$emit('on-search', {
        searchForm: this.searchForm
      })
    }
  },
  computed: {
    searchForm: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  beforeMount() {
    this.searchForm = this.value
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

#filter-input {
  font: font-opensans-bolder
  font-size: 14px
  placeholder-styles(@block{
    color color-black-200
    font: font-opensans-bolder
    font-size: 14px
    })
  padding-left 3.188rem
}

.input-group-prepend-custom
  .input-group-text
    margin: 3px 4px

.icon-search-dark:before
  color color-blue-100
  font-size 2rem

</style>
