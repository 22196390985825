export const getters = {
  getCourses: (state) => {
    return state.courses
  },
  getPagination: (state) => {
    return state.pagination
  },
  isLoadingCourses: (state) => {
    return state.loadingCourses
  },
  getCourseDetail: (state) => {
    return state.courseDetail
  },
  getCourseTask: (state) => {
    return state.taskList
  },
  getCourseTeacherTasks: (state) => {
    return state.teacherTaskList
  },
  getDetailTask: (state) => {
    return state.taskDetail
  }
}
