<template>
  <modal ref="modalSchoolDetail"
         id="modal-school-detail"
         modal-class="primary_modal"
         @hide="close"
         :hide-footer="true">
    <template #header>
    </template>
    <template #body>
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 class="modal-title text-center">School Detail</h2>
            <div class="container-form">
              <b-form-group label-cols="4"
                            label="Name:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.name"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="DPA Signed Date:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.dpaSignedDate"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Parent Organization Name:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.parentOrganizationName"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Address:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.address"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            v-if="isUsa"
                            label="City:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.city"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            v-if="isUsa"
                            label="State:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.state"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            v-if="!isUsa"
                            label="Country:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.country"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Website:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.website"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Phone Number:"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.phoneNumber"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Library Expiration:"
                            class="subtitle library-expiration"
                            label-for="input-sm">
                <b-form-input v-model="form.libraryExpiration"
                              autocomplete="off"
                              @click="showLibraryDetail"
                              :class="{'library-expiration__date': form.libraryExpiration}"
                              readonly
                              :disabled="!form.libraryExpiration"
                              type="text"/>
                <b-button class="button" @click="showAddSubscription">Add Subscription</b-button>
              </b-form-group>
              <b-form-checkbox v-model="form.dpaSignedCheckbox"
                               @change="clickCheckbox"
                               :disabled="form.dpaParentOrganization"
                               class="custom-checkbox checkbox-lg">
                DPA Signed
              </b-form-checkbox>
              <b-form-group label-cols="4"
                            label="List of teachers associated:"
                            class="subtitle mt-4"
                            label-for="input-sm">
                <b-list-group class="teachers-list">
                  <b-list-group-item v-for="(teacher, idx) in form.teacherList" :key="idx" class="d-flex justify-content-between align-items-center">
                    <span class="teacher-item">
                      {{teacher.full_name}}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </b-form-group>
            </div>
          </b-col>
          <b-overlay :show="loading"
                     no-wrap
                     spinner-medium
                     rounded="sm"/>
        </b-row>
      </b-container>
      <add-library-subscription :schoolId="schoolId" @save-subscription="getSchoolById()" ref="modal-library-subscription"/>
      <modal-token-expiration ref="modal-subscription-detail"
                              :is-library="true"
                              :subscription-id="form.librarianSubscription && form.librarianSubscription.id"
                              :amound-paid="form.librarianSubscription && form.librarianSubscription.total_price"
                              :purchase-date="form.librarianSubscription && form.librarianSubscription.start_date"
                              :expiration-date="form.librarianSubscription && form.librarianSubscription.end_date"
                              @expire-date-update="getSchoolById()"
                              subject="Library"/>
    </template>
  </modal>
</template>

<script>
import {Modal} from '@/core/components'
import { SchoolsService } from '@/core/services'
import moment from 'moment'
import AddLibrarySubscription from './AddLibrarySubscription'
import ModalTokenExpiration from './ModalTokenExpiration'

export default {
  name: 'ModalSchoolDetail',
  components: {
    Modal,
    AddLibrarySubscription,
    ModalTokenExpiration
  },
  props: {
    schoolId: {
      default: null
    },
    schoolName: {
      type: String
    }
  },
  data() {
    return {
      form: {
        name: '',
        dpaSignedCheckbox: '',
        dpaSignedDate: '',
        parentOrganizationName: '',
        address: '',
        city: '',
        state: '',
        stateId: null,
        country: '',
        countryId: null,
        website: '',
        phoneNumber: '',
        teacherList: [],
        dpaParentOrganization: false,
        libraryExpiration: '',
        librarianSubscription: {}
      },
      loading: false
    }
  },
  async mounted() {
  },
  computed: {
    isUsa() {
     return this.form.countryId === 237
    }
  },
  methods: {
    showLibraryDetail() {
      this.$refs['modal-subscription-detail'].open()
    },
    showAddSubscription() {
      this.$refs['modal-library-subscription'].open()
    },
    open() {
      this.$refs.modalSchoolDetail.showModal()
      this.getSchoolById()
    },
    close() {
      this.form = {
        name: '',
        dpaSignedCheckbox: '',
      }
      this.$refs.modalSchoolDetail.hideModal()
    },
    getSchoolById() {
      this.loading = true
      SchoolsService.getSchoolById(this.schoolId).then((response) => {
        if (response.success) {
          const school = response.data
          this.form = {
            name: school.name,
            dpaSignedCheckbox: !!school.dpa_agreement_completed_at || !!school.parent_organization?.is_dpa_signed,
            dpaSignedDate: school.dpa_agreement_completed_at ? moment(school.dpa_agreement_completed_at).format('MM/DD/YYYY') : null,
            parentOrganizationName: school.parent_organization?.name,
            address: school.address,
            city: school.city?.name,
            state: school.state?.name,
            stateId: school.state?.id,
            country: school.country?.name,
            countryId: school.country?.id,
            website: school.website,
            phoneNumber: school.phone_number,
            teacherList: school.teachers,
            dpaParentOrganization: !!school.parent_organization?.is_dpa_signed,
            libraryExpiration: school.librarian_subscription?.end_date ? moment(school.librarian_subscription.end_date).format('MM/DD/YYYY') : '',
            librarianSubscription: school.librarian_subscription ?? {}
          }
        } else {
          const errorMessage = response.message ? response.message : 'Error.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }).finally(() => this.loading = false)
    },
    clickCheckbox(check) {
      this.loading = true
      const isCheck = check ? moment().format('YYYY-MM-DD') : null
      const data = {
        id: this.schoolId,
        dpa_agreement_completed_at: isCheck,
        country_id: this.form.countryId
      }
      SchoolsService.checkDpaSchool(this.schoolId, data).then((response) => {
        if (!response.success) {
          const errorMessage = response.message ? response.message : 'Error when signing the school.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }).finally(() => {
        this.loading = false
        this.getSchoolById()
        this.$emit('click-checkbox')
      })
    }
  },
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.modal-title
  margin-bottom 62px
  word-break break-word

.subtitle
  font-size 14px
  ::v-deep
    .col-form-label
      padding-top 0 !important

.library-expiration
  ::v-deep
    .col
      display flex
      @media(max-width 510px)
        flex-direction column

      .form-control
        width 40%
        @media(max-width 510px)
          width 100%

  .library-expiration__date
    cursor pointer

  .button
    font-size 13px
    text-transform capitalize
    margin-top .25rem
    margin-left .5rem
    @media(max-width 510px)
      margin-left 0
      margin-top 0

.container-form
  margin 2rem 0

.flex-column
  margin-top 30px
  ::v-deep
    div
      display flex
      justify-content center
      flex-wrap wrap
      gap 10px

.custom-checkbox
    margin-bottom 20px

.teachers-list
  max-height 300px
  overflow-y auto

  .teacher-item
    text-overflow ellipsis
    width 200px
    white-space nowrap
    overflow hidden
</style>

<style lang="stylus">
#modal-school-detail
  .modal-dialog
    min-width unset

    .modal-body
      .close
        position absolute
        right 0
        z-index 1
</style>
