const FETCH_PARTNERSHIP_START = 'FETCH_PARTNERSHIP_START'
const FETCH_PARTNERSHIP_DATA = 'FETCH_PARTNERSHIP_DATA'
const FETCH_ERROR_DATA = 'FETCH_ERROR_DATA'
const FETCH_ERROR_START = 'FETCH_ERROR_START'
const UPDATE_PARTNERSHIP_START = 'UPDATE_PARTNERSHIP_START'
const UPDATE_PARTNERSHIP_DATA = 'UPDATE_PARTNERSHIP_DATA'

export {
  FETCH_PARTNERSHIP_START,
  FETCH_PARTNERSHIP_DATA,
  FETCH_ERROR_DATA,
  FETCH_ERROR_START,
  UPDATE_PARTNERSHIP_START,
  UPDATE_PARTNERSHIP_DATA
}
