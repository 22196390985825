import {
  FETCH_AGE_RANGE_LIST,
  FETCH_SUBJECTS_LIST,
  FETCH_LANGUAGE_LIST,
  FETCH_STANDARDS_LIST,
  FETCH_LEVELS_LIST,
  FETCH_STUDENT_GROUP_TYPE,
  FETCH_TEACHER_TYPES
} from './mutation-type'

export const
  mutations = {
    [FETCH_AGE_RANGE_LIST] (state, data) {
      state.ageRanges = data
    },
    [FETCH_SUBJECTS_LIST] (state, data) {
      state.subjects = data
    },
    [FETCH_LANGUAGE_LIST] (state, data) {
      state.languages = data
    },
    [FETCH_STANDARDS_LIST] (state, data) {
      state.standards = data
    },
    [FETCH_LEVELS_LIST] (state, data) {
      state.levels = data
    },
    [FETCH_STUDENT_GROUP_TYPE] (state, data) {
      state.courseStudentGroupTypes = data
    },
    [FETCH_TEACHER_TYPES] (state, data) {
      state.teacherTypes = data
    }
  }
