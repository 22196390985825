import { http } from './http'

const resource = 'api/admin'

export default {
  getCourseList (filters, pagination, sort) {
    const page = pagination || 1
    const params = {
      page,
      sort_by: sort
    }
    if (filters.text) {
      params.filter_by = filters.filterBy
      params.search_string = filters.text
    }
    if (filters.filterBy === 'published') {
      params.filter_by = filters.filterBy
    }

    params.user_type = filters.userType

    return http.get(`${resource}/courses`, { params }).then(response => {
      return response
    })
  },
  createCourse (data) {
    return http.post(`${resource}/courses`, data).then(response => {
      return response
    })
  },
  getCourseById (id) {
    return http.get(`${resource}/courses/${id}`).then(response => {
      return response
    })
  },
  getCourseStudentGroupTypes () {
    return http.get(`${resource}/course-student-group-types`).then(response => {
      return response
    })
  },
  updateCourse (id, data) {
    return http.put(`${resource}/courses/${id}`, data).then(response => {
      return response
    })
  },
  deleteCourse (id) {
    return http.delete(`${resource}/courses/${id}`).then(response => {
      return response
    })
  },
  updatePublishedCourse (id, data) {
    return http.put(`${resource}/courses/${id}/published`, data).then(response => {
      return response
    })
  },
  getListTaskCourse (id) {
    return http.get(`${resource}/courses/${id}/tasks`).then(response => {
      return response
    })
  },
  createTaskCourse (id, data) {
    return http.post(`${resource}/courses/${id}/tasks`, data).then(response => {
      return response
    })
  },
  getTaskById (idCourse, idTask) {
    return http.get(`${resource}/courses/${idCourse}/tasks/${idTask}`).then(response => {
      return response
    })
  },
  updateTask (idCourse, idTask, data) {
    return http.put(`${resource}/courses/${idCourse}/tasks/${idTask}`, data).then(response => {
      return response
    })
  },
  deleteTask (idCourse, idTask) {
    return http.delete(`${resource}/courses/${idCourse}/tasks/${idTask}`).then(response => {
      return response
    })
  },
  orderTask (idCourse, data) {
    return http.put(`${resource}/courses/${idCourse}/tasks-order`, data).then(response => {
      return response
    })
  },
  visibleTask (idCourse, idTask, data) {
    const published = {published: data}
    return http.put(`${resource}/courses/${idCourse}/tasks/${idTask}/publish`, published).then(response => {
      return response
    })
  },
  topics() {
    return http.get(`${resource}/topics`).then(response => {
      return response
    })
  }
}
