<template>
  <div>
    <b-overlay
      :show="isLoadingExchanges"
      spinner-medium
      rounded="sm">
<!--      <b-row class="mx-3 my-1">-->
<!--        <b-col lg="12" class="my-1">-->
<!--          <search-input v-model="search_string" @on-search="handlerSearch"></search-input>-->
<!--        </b-col>-->
<!--      </b-row>-->
      <b-table
        :responsive="true"
        hover
        sort-icon-left
        :fields="fields"
        :items="getExchanges"
        table-variant="ligth"
        head-variant="ligth"
        no-local-sorting
        @sort-changed="sortingChanged"
        id="table"
        ref="table">
        <template #cell(subject)="data">
          <a class="a-title mx-2 exchange-name" @click="goTo(data.item.id)"> {{ data.item.subject.name }}</a>
        </template>
        <template #cell(country)="country">
          {{ country.value.name }}
        </template>
        <template #cell(price)="price">
          ${{ price.value }}
        </template>
      </b-table>
      <b-row>
        <br>
        <b-col md="8" lg="4" class="pb-2">
          <b-button @click="navigate" class="button primary button--resize">create new price</b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>

import {ExchangesMixins} from '../Mixins'

export default {
  name: 'ExchangesList',
  mixins: [ExchangesMixins],
  data() {
    return {
      fields: [
        {
          key: 'subject',
          label: 'SUBJECT',
          sortable: true,
        },
        {
          key: 'country',
          label: 'COUNTRY',
          sortable: true,
        },
        {
          key: 'price',
          label: 'PRICE',
          sortable: true,
        },
      ],
      search_string: '',
      sort_by: {
        field: 'country',
        order: 'asc'
      }
    }
  },
  async mounted() {
    await this.fetchExchanges()
  },
  methods: {
    async fetchExchanges() {
      await this.fetchExchangeList({
        filters: {search_string: this.search_string},
        sort_by: this.sort_by
      })

      this.$refs.table.refresh()
    },
    handlerSearch(arg) {
      this.fetchExchanges()

      return arg
    },
    navigate() {
      this.$router.push({name: 'PricingDetail'})
    },
    goTo(id) {
      this.$router.push({path: `/PanelAdmin/pricing/${id}`})
    },
    sortingChanged(arg) {
      this.sort_by = {
        field: arg.sortBy,
        order: arg.sortDesc ? 'desc' : 'asc'
      }

      this.fetchExchanges()
    },
  },
  computed: {
    showPagination() {
      return this.getPagination.totalRow > 10
    }
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.exchange-name
  cursor pointer

.button--resize
  width auto !important
  padding 0 58.5px

@media (max-width 600px)
  .button--resize
    padding 0 20px
</style>
