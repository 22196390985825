import { mapActions, mapGetters } from 'vuex'

export const UserMixins = {
  computed: {
    ...mapGetters({
      isLoadingProfile: 'UserStore/isLoadingProfile',
      getUser: 'UserStore/getUser'
    })
  },
  methods: {
    ...mapActions({
      setUser: 'UserStore/setUser'
    })
  }
}
