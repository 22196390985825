import { http } from './http'

const resource = 'api/admin'

export default {
  getExchanges (filters, sort_by) {
    const params = {}

    if(sort_by){
      params.sort_by = sort_by
    }

    if (filters.search_string) {
      params.filter_by = filters.filterBy
      params.search_string = filters.text
    }

    return http.get(`${resource}/exchange-tokens`, { params }).then(response => {
      return response
    })
  },
  createExchangePrice (data) {
    return http.post(`${resource}/exchange-tokens`, data).then(response => {
      return response
    })
  },
  getExchangeById (id) {
    return http.get(`${resource}/exchange-tokens/${id}`).then(response => {
      return response
    })
  },
  updateExchange (id, data) {
    return http.put(`${resource}/exchange-tokens/${id}`, data).then(response => {
      return response
    })
  },
  deleteExchange (id) {
    return http.delete(`${resource}/exchange-tokens/${id}`).then(response => {
      return response
    })
  },
}
