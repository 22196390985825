<template>
  <div>
    <div class="form-group-attach">
      <div v-if="attachments.length === 0" class="form-group-attach-list">
        <span v-if="placeholder" class="placeholder">{{ placeholder }}</span>
      </div>

      <div v-else class="form-group-attach-links" :class="{'only-links': onlyAttach}">
        <ul class="container-attachment" :class="{'container-attachment--media': isMedia}">
          <li :key="`attach-file-${_uid}_${index}`"
              class="link-container"
              v-for="(attachment, index) in attachments">
            <a target="_blank" class="text-normal" :href="setUrl(attachment)">
              <i class="fa fa-file-o"/>
              <p class="link-container__label">{{ attachment.label }}</p>
            </a>
            <button class="button-delete" type="button" @click="onDeleteAttachment(attachment)">
              <span class="icon-delete"/>
            </button>
            <select-dropdown :options="mediaOptions"
                             v-model="attachment.video_environment"
                             v-if="isMedia"
                             :disabled="attachment.video_processed === 2"
                             class="options-dropdown --secundary"/>
          </li>
        </ul>
      </div>

      <div :class="classGroupActions">
        <upload-file v-if="!onlyAttach"
                     :errors="errors"
                     :show-errors="showErrors"
                     @error="onFileError"
                     @file-select="onFileChanged"
                     :accept="accept"/>
        <button @click="onCreateAttachLink" class="button attach mx-1" small>
          Attach Link
          <span class="icon-attach"/>
        </button>
      </div>

      <modal modal-class="modal-attach-link" ref="modalAttachLink">
        <template #body>
          <div class="modal-attach-link-body">
            <h3 class="title">Attach Link</h3>
            <attach-link v-model="links"></attach-link>
          </div>
        </template>
        <template #footer>
          <button @click="onSaveLinks" :disabled="!links.length" class="button button-control button-save">Save</button>
          <button @click="onCloseLinksModal" class="button button-control">Cancel</button>
        </template>
      </modal>
    </div>
    <div class="invalid-feedback" v-if="hasErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`file-group-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UploadFile from './UploadFile'
import AttachLink from './AttachLink.vue'
import { Modal, SelectDropdown } from '@/core/components'
import { VideoUploadService } from '@/core/services'

export default {
  name: 'UploadFileAttachGroup',
  components: {
    AttachLink,
    UploadFile,
    Modal,
    SelectDropdown
  },
  data() {
    return {
      links: [],
      errors: [],
      mediaOptions: [
        { name: 'Create', id: 'create'},
        { name: 'Respond', id: 'respond'},
        { name: 'Both', id: 'both'},
        { name: 'Media', id: 'media'}
      ]
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    positionButtons: {
      type: String,
      default: 'end'
    },
    placeholder: {
      type: String,
      default: null
    },
    onlyAttach: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    isMedia: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    setUrl(attachment) {
      return attachment?.type === 'transcoder' ? attachment.signedUrl : attachment.url
    },
    onCreateAttachLink() {
      this.$refs.modalAttachLink.showModal()
    },
    onCloseLinksModal() {
      this.$refs.modalAttachLink.hideModal()
    },
    onDeleteAttachment(attachment) {
      this.$emit('Attachment:delete', attachment)
    },
    onSaveLinks() {
      if (this.links.length > 0) {
        this.links = this.links.map((link) => {
          link.type = 'file',
          link.original_file = link.label
          return link
        })
        this.attachments = [...this.value, ...this.links]
        this.onCloseLinksModal()
        this.links = []
      }
    },
    onFileError(err) {
      this.$emit('error', err)
      this.errors.push(err.message)
    },
    async onFileChanged(files) {
      let file
      const isVideo = files[0].type.startsWith('video/')
      if (isVideo) {
        const customData = await VideoUploadService.createFileNameData(files[0])

        file = {
          url: URL.createObjectURL(files[0]),
          objectURL: URL.createObjectURL(new Blob([files[0]])),
          file: files[0],
          index_file: 1,
          type_file: 'media',
          filename: files[0].name,
          customData: customData,
          label: customData.label,
          isVideo: true,
          video_environment: 'create'
        }
      } else {
        file = {
          url: URL.createObjectURL(files[0]),
          label: files[0].name,
          file: files[0]
        }
      }
      this.attachments.push(file)
    }
  },
  computed: {
    classGroupActions() {
      const clazz = {
        'form-group-attach-actions': true
      }

      if (this.positionButtons) {
        clazz[`align-items-${this.positionButtons}`] = !!this.positionButtons
      }

      return {...clazz}
    },
    attachments: {
      get() {
        return this.value ?? []
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    hasErrors() {
      return this.errors.length > 0
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/mixins.styl"
@import '~@/core/stylus/common.styl'

.modal-attach-link-body

  h3
    font font-opensans-bold
    color color-black-200
    font-size 24px
    text-align center

.form-group-attach
  margin-bottom 1rem
  display block
  border 2px solid color-gray-400
  min-height 90px
  width: 100%
  max-width: 100%
  padding 10px 10px 10px 15px
  border-radius 4px

  &-list
    flex 1

    .placeholder
      font font-opensans-regular
      font-size 14px
      color color-black-200

  &-links
    width auto !important
    margin-bottom 12px
    overflow-x auto

    .container-attachment
      padding 0
      margin 0
      list-style-type none
      display flex
      overflow-x auto

      & > li
        border 0
        padding 0
        color color-3
        display flex
        flex-direction column
        align-items center
        border 2px solid color-blue-100
        margin-right 10px
        position relative

        a
          font font-opensans-bold
          font-size 14px
          color color-34
          text-transform uppercase
          display flex
          flex-direction column
          align-items center
          padding 18px 10px 10px 10px
          width 100%

          .link-container__label
            max-width 140px
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
            text-align center
            color black
            font-size 12px

          i
            font-size 26px
            margin-bottom 8px
            color color-blue-100

        .button-delete
          position absolute
          right 0
          color black

        button
          border 0
          background-color transparent

          span
            font-size 0.7rem

            &:before
              color color-black-100

    .container-attachment--media
      height 152px

      & > li
        height 130px
        min-width 130px

        a
          padding-top 24px

          .link-container__label
            max-width 120px

        .options-dropdown
          max-width 130px
          margin 0 auto

  .only-links
    ul
      & > li
        border none

        a
          padding 0
          margin-right 20px

          i
            display none

          .link-container__label
            color color-blue-400
            font-size 16px

        .button-delete
          top -4px

  &-actions
    justify-content end
    display flex

    ::v-deep
      .form-upload
        margin-bottom 0

        .form-upload-file
          border 0
          padding 0
          margin 0
          min-height unset
          margin-right 5px

    .button
      display: inline-block;

      .attach
        span.icon-link
          transform rotate(45deg)

.button
  &-control
    border-radius: 4px;

  &-save
    &:disabled
      background-color #b8b8b8 !important
      border-color #b8b8b8 !important
      border-width 3px !important
</style>
