import {
  FETCH_PARTNERSHIP_START,
  FETCH_PARTNERSHIP_DATA,
  FETCH_ERROR_START,
  FETCH_ERROR_DATA,
  UPDATE_PARTNERSHIP_START,
  UPDATE_PARTNERSHIP_DATA
} from './mutation-types'

export const
  mutations = {
    [FETCH_PARTNERSHIP_START] (state) {
      state.loadingPartnership = true
    },
    [FETCH_ERROR_START] (state) {
      state.error = ''
    },
    [FETCH_PARTNERSHIP_DATA] (state, data) {
      state.classroomPartnership = data
      state.loadingPartnership = false
    },
    [FETCH_ERROR_DATA] (state, data) {
      state.error = data
      state.loadingPartnership = false
    },
    [UPDATE_PARTNERSHIP_START] (state) {
      state.updatingPartnership = true
    },
    [UPDATE_PARTNERSHIP_DATA] (state) {
      state.updatingPartnership = false
    },
  }
