<template>
  <modal ref="modalParentOrgExchangeDetail"
         id="modal-parent-org-exchange-detail"
         modal-class="primary_modal"
         @hide="close"
         :hide-footer="true">
    <template #header>
    </template>
    <template #body>
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 class="modal-title text-center">{{parentOrganizationName}} Exchange Detail</h2>
            <div class="container-form">
              <div class="title-container">
                <p class="title">Used</p>
                <p class="title">Available</p>
              </div>
              <b-form-group label-cols="4"
                            label="Languages & Cultures Exchange(s):"
                            class="subtitle container-group"
                            label-for="input-sm">
                <b-form-input v-model="form.languagesAndCulturesExchangeUsed"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
                <b-form-input v-model="form.languagesAndCulturesExchange"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Arts & Sciences Exchange(s):"
                            class="subtitle container-group"
                            label-for="input-sm">
                <b-form-input v-model="form.artsAndSciencesExchangeUsed"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
                <b-form-input v-model="form.artsAndSciencesExchange"
                              autocomplete="off"
                              readonly
                              disabled
                              type="text"/>
              </b-form-group>
              <b-form-group class="flex-column text-center">
                <b-button class="button button--email my-1" @click="openAddExchange">Add Exchanges </b-button>
                <b-button class="button button--email my-1" @click="openDistributeExchanges">Distribute Exchanges</b-button>
              </b-form-group>
            </div>
          </b-col>
          <b-overlay :show="loading"
                     no-wrap
                     spinner-medium
                     rounded="sm"/>
        </b-row>
      </b-container>
      <modal-teacher-token-add ref="addTokensModal"
                               @on-success-store-account-tokens="onSuccessStoreAccountTokens"
                               :organization-id="parentOrganizationId"
                               :is-parent-organization-tab="true"/>
      <modal-distribute-exchanges ref="modalDistributeExchanges"
                                  :tokens-available="form"
                                  @success-assignation-exchanges="successAssignationExchanges"
                                  :organization-id="parentOrganizationId"/>
    </template>
  </modal>
</template>

<script>
import {Modal} from '@/core/components'
import ModalTeacherTokenAdd from './ModalTeacherTokenAdd'
import ModalDistributeExchanges from './ModalDistributeExchanges'
import { ParentOrganizationService } from '@/core/services'

export default {
  name: 'ModalParentOrgExchangeDetail',
  components: {
    Modal,
    ModalTeacherTokenAdd,
    ModalDistributeExchanges
  },
  props: {
    parentOrganizationId: {
      default: null
    },
    parentOrganizationName: {
      type: String
    }
  },
  data() {
    return {
      form: {
        languagesAndCulturesExchange: '',
        artsAndSciencesExchange: '',
      },
      loading: false
    }
  },
  async mounted() {
  },
  methods: {
    open() {
      this.$refs.modalParentOrgExchangeDetail.showModal()
      this.getParentOrById()
    },
    close() {
      this.form = {
        languagesAndCulturesExchange: '',
        artsAndSciencesExchange: '',
      }
      this.$refs.modalParentOrgExchangeDetail.hideModal()
    },
    getParentOrById() {
      this.loading = true
      ParentOrganizationService.getTokensByParentOrganization(this.parentOrganizationId).then((response) => {
        const data = response.data
        if (response.success) {
          const languagesAndCultures = data.find((subject) => subject.type === 'world_languages')
          const artsAndSciences = data.find((subject) => subject.type === 'non_world_languages')
          this.form = {
            languagesAndCulturesExchange: languagesAndCultures?.available_tokens_count ?? 0,
            artsAndSciencesExchange: artsAndSciences?.available_tokens_count ?? 0,
            languagesAndCulturesExchangeUsed: languagesAndCultures?.used_tokens_count ?? 0,
            artsAndSciencesExchangeUsed: artsAndSciences?.used_tokens_count ?? 0,
          }
        }
      }).finally(() => this.loading = false)
    },
    openAddExchange() {
      this.$refs.addTokensModal.open()
    },
    openDistributeExchanges() {
      this.$refs.modalDistributeExchanges.openModalDistributeExchanges()
    },
    async onSuccessStoreAccountTokens() {
      await this.getParentOrById()
      this.$refs.addTokensModal.close()
      this.$emit('on-success-account-tokens')
    },
    successAssignationExchanges() {
      this.getParentOrById()
      this.$emit('success-assignation-exchanges')
    }
  },
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.modal-title
  margin-bottom 62px
  word-break break-word

.subtitle
  font-size 14px
  ::v-deep
    .col-form-label
      padding-top 0 !important

.container-group
  ::v-deep
    .col
      justify-content space-between
      display flex

      .form-control
        width 48%
        text-align center

.container-form
  margin 2rem 0

  .title-container
    display flex
    justify-content end

    .title
      margin 0 !important
      width 32%
      text-align center
      margin-left 3% !important
      margin-bottom 10px !important

.flex-column
  margin-top 30px
  ::v-deep
    div
      display flex
      justify-content center
      flex-wrap wrap
      gap 10px

</style>

<style lang="stylus">
#modal-parent-org-exchange-detail
  .modal-dialog
    min-width unset

    .modal-body
      .close
        position absolute
        right 0
        z-index 1
</style>
