import { render, staticRenderFns } from "./ModalDistributeExchanges.vue?vue&type=template&id=6da763ed&scoped=true&"
import script from "./ModalDistributeExchanges.vue?vue&type=script&lang=js&"
export * from "./ModalDistributeExchanges.vue?vue&type=script&lang=js&"
import style0 from "./ModalDistributeExchanges.vue?vue&type=style&index=0&id=6da763ed&lang=stylus&scoped=true&"
import style1 from "./ModalDistributeExchanges.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da763ed",
  null
  
)

export default component.exports