import {
  FETCH_TEACHER_LIST_START,
  FETCH_TEACHER_LIST_DATA,
  FETCH_TEACHER_DETAIL_DATA,
  FETCH_TEACHER_CLASSROOMS_DATA,
  FETCH_TEACHER_CLASSROOMS_START,
  ADD_TEACHER_TOKENS_START,
  ADD_TEACHER_TOKENS_DATA
} from './mutation-types'

import {TeacherService, AccountTokensService} from '../../services/'

const fixWebsites = function (url) {
  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    url = 'http://' + url
  }

  return url
}

export const actions = {
  fetchTeacherList({commit}, {filters, sort}) {
    commit(FETCH_TEACHER_LIST_START)
    return TeacherService.getTeacherList(filters, sort).then((res) => {
      const teachers = res.data.map((elem) => ({
        teacher_name: {name: elem.full_name, id: elem.id},
        email: elem.user?.email,
        school: elem.school && elem.school.name ? elem.school.name : '',
        website: elem.school && elem.school.website ? fixWebsites(elem.school.website) : '',
        country: elem.country && elem.country.name ? elem.country.name : '',
        classrooms: elem.classrooms_count,
        active_classrooms: elem.active_classrooms_count,
        is_email_verified: !!elem.is_email_verified,
        email_verified_at: elem.user?.email_verified_at,
        teacher_agreement_completed_at: !!elem.teacher_agreement_completed_at,
        available_tokens_count: elem.user?.available_tokens_count,
        parentOrganization: elem.parent_organization
      }))
      commit(FETCH_TEACHER_LIST_DATA, teachers)
      return teachers
    })
  },
  fetchDetail({commit}, teacherId) {
    commit(FETCH_TEACHER_LIST_START)
    if (teacherId) {
      return TeacherService.getTeacherById(teacherId).then((res) => {
        const {data} = res

        const teacher = {
          name: data.full_name,
          schoolName: data.school?.name,
          schoolWebsite: data.school?.website,
          schoolAddress: data.school?.address,
          country: data.country_name,
          email: data.email,
          avatar: data.avatar,
          phoneNumber: data.school?.phone_number,
          loginId: data.email,
          singUpDate: data.created_at.slice(0, 10),
          about: data.about_info,
          report: data.last_active_report ? data.last_active_report.report : ' No reports yet ',
          dateReported: data.last_active_report ? data.last_active_report.created_at.slice(0, 10) : '',
          on_hold: !!data.on_hold,
          teacherEnable: !!data.is_teacher_enabled,
          emailVerified: !!data.is_email_validated,
          teacherVerified: !!data.is_teacher_validated,
          email_verified_at: !!data.email_verified_at,
          teacher_agreement_completed_at: !!data.teacher_agreement_completed_at,
          available_tokens_count: data.available_tokens_count,
          exchangeTokensBalance: data.exchange_tokens_balance,
          id: data.id,
          user_type: data.user_type,
          preferredCommunication: data.preferred_communication,
          teacherPhoneNumber: data.phone_number,
          phoneCountryCode: data.phone_country_code,
        }
        commit(FETCH_TEACHER_DETAIL_DATA, teacher)

        return res
      })
    }
  },
  resetDetail({commit}) {
    const data = {
      name: '',
      schoolName: '',
      schoolWebsite: '',
      schoolAddress: '',
      country: '',
      email: '',
      phoneNumber: '',
      loginId: '',
      singUpDate: '',
      about: '',
      report: '',
      dateReported: '',
      teacherEnable: false,
      emailVerified: false,
      teacherVerified: false,
      teacher_agreement_completed_at: false,
      id: ''
    }
    commit(FETCH_TEACHER_DETAIL_DATA, data)
  },
  fetchClassrooms({commit}, teacherId) {
    commit(FETCH_TEACHER_CLASSROOMS_START)
    if (teacherId) {
      return TeacherService.getClassroomsTeacher(teacherId).then((res) => {
        const classrooms = res.data.map((elem) => ({
          name: {classroom_name: elem.name, id: elem.code},
          code: elem.code, subject: elem.subject.name,
          date: {start: elem.start_date.slice(0, 10), end: elem.end_date.slice(0, 10)}, paired: !!elem.partner_id
        }))
        commit(FETCH_TEACHER_CLASSROOMS_DATA, classrooms)
      })
    }
  },
  addTokens({commit}, {teacher_id, amount_tokens, expire_at, subject_id}) {
    commit(ADD_TEACHER_TOKENS_START)
    return AccountTokensService.addTokens({teacher_id, amount_tokens, expire_at, subject_id}).then((res) => {
      commit(ADD_TEACHER_TOKENS_DATA)

      return res
    })
  },
  updateExpirationDate({commit}, {exchangePurchasesReportId, new_expire_at}) {
    commit(ADD_TEACHER_TOKENS_START)
    return AccountTokensService.updateExpirationDate(exchangePurchasesReportId, new_expire_at).then((res) => {
      commit(ADD_TEACHER_TOKENS_DATA)

      return res
    })
  },
}
