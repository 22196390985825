import { render, staticRenderFns } from "./AdministerAdmins.vue?vue&type=template&id=5f0e1940&scoped=true&"
import script from "./AdministerAdmins.vue?vue&type=script&lang=js&"
export * from "./AdministerAdmins.vue?vue&type=script&lang=js&"
import style0 from "./AdministerAdmins.vue?vue&type=style&index=0&id=5f0e1940&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f0e1940",
  null
  
)

export default component.exports