import {http} from './http'

const resource = 'api/admin'

export default {
  saveSubscription({school_id, total_price, end_date, start_date}) {
    return http.post(`${resource}/librarian-subscription`, {school_id, total_price, end_date, start_date}).then(response => {
      return response
    })
  },
  updateSubscriptionExpiration(subscriptionId, newExpireDate) {
    return http.put(`${resource}/librarian-subscription/${subscriptionId}`, {end_date: newExpireDate}).then(response => {
      return response
    })
  },
  getSubscriptionById(subscriptionId) {
    return http.get(`${resource}/librarian-subscription/${subscriptionId}`).then(response => {
      return response
    })
  }
}
