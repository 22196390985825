import {http} from './http'

const resource = 'api/admin'

export default {
  getTokensList(filters, sortBy = {}) {
    return http.get(`${resource}/exchange-tokens-purchases`, {params: {filters: filters, sort_by: sortBy}}).then(response => {
      return response
    })
  },
  exportTokensList(filters) {
    return http.get(`${resource}/exchange-tokens-purchases-export`, {
      params: {filters: filters},
      responseType: "blob"
    }).then(response => {
      return response
    })
  },
  addTokens({teacher_id, amount_tokens, expire_at, subject_id}) {
    return http.post(`${resource}/exchange-tokens-purchases`, {teacher_id, amount_tokens, expire_at, subject_id}).then(response => {
      return response
    })
  },
  updateExpirationDate(exchangePurchasesReportId, new_expire_at) {
    return http.put(`${resource}/exchange-tokens-purchases/${exchangePurchasesReportId}`, {expire_at: new_expire_at}).then(response => {
      return response
    })
  },
  getStudentReports(filters, sortBy = {}) {
    return http.get(`${resource}/payment-histories`, {params: {filters: filters, sort_by: sortBy}}).then(response => {
      return response
    })
  },
  exportStudentReports(filters) {
    return http.get(`${resource}/payment-histories-export`, {
      params: {filters: filters},
      responseType: "blob"
    }).then(response => {
      return response
    })
  },
}
