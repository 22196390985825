export const state = {
  teachers: [],
  loadingTeachers: false,
  loadingClassrooms: true,
  addingTokens: false,
  teacherDetail: {
    name: '',
    schoolName: '',
    schoolWebsite: '',
    schoolAddress: '',
    country: '',
    email: '',
    phoneNumber: '',
    loginId: '',
    singUpDate: '',
    about: '',
    report: '',
    dateReported: '',
    on_hold: false,
    teacherEnable: false,
    emailVerified: false,
    email_verified_at: false,
    teacherVerified: false,
    teacher_agreement_completed_at: false,
    id: '',
    user_type: {slug:''}
  },
  teacherClassrooms: []
}
