export const getters = {
  getSubjects: (state) => {
    return state.subjects
  },
  getPagination: (state) => {
    return state.pagination
  },
  isLoadingSubjects: (state) => {
    return state.loadingSubjects
  },
  loadingSubjectsData: (state) => {
    return state.loadingSubjectsData
  },
  getSubjectDetail: (state) => {
    return state.subjectDetail
  },
}
