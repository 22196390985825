<template>
  <div class="student-payment d-inline-flex" @click="paymentNotAllowedModal">
    <b-form-checkbox
      v-model="checkedStudentPayment"
      class="custom-checkbox"
      @change="handleCheckedPayment"
      :disabled="disabled || paymentNotAllowed"
    >
      Payment&nbsp;
    </b-form-checkbox>
    <a
      v-if="studentPaymentEnable"
      class="student-payment__amount"
      href="#"
      @click.prevent="handleRegisterStudentPayment"
      >${{ studentPaymentPrice }}</a
    >

    <modal ref="registerStudentPayment" modal-class="student-payment__modal" @hide="handleCancel">
      <template #body>
        <h2 class="modal-title text-center">Student Payment Amount</h2>
        <p class="text-center">
          Please enter the amount, in U.S. dollars, that each student in this
          classroom will need to pay to participate
        </p>
        <br />
        <number
          v-if="loadingClassroom"
          ref="vue-number-format"
          v-model="$v.price.$model"
          v-bind="number"
          placeholder="Price of tokens *"
          class="form-control"
        ></number>
      </template>
      <template #footer>
        <b-button class="button b-ligth" @click="handleCancel">
          Cancel</b-button
        >
        <b-button :disabled="hasError" @click="handlePayment" class="button">
          Accept
        </b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Modal } from "@/core/components";
import { between, decimal, required } from "vuelidate/lib/validators";
import { PartnershipService } from "@/core/services";
import { number } from "@coders-tm/vue-number-format";

export default {
  name: "StudentPayment",
  components: { number, Modal },
  props: {
    classroomCode: {
      type: String,
      required: true,
    },
    studentPaymentEnable: {
      type: Boolean,
      default: false,
    },
    studentPaymentPrice: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    studentAge: {
      type: [Number, String]
    }
  },
  data() {
    return {
      loadingClassroom: false,
      classroom: null,
      price: "",
      checkedStudentPayment: false,
      number: {
        decimal: ".",
        separator: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  validations: {
    price: {
      required,
      decimal,
      between: between(0, 1000),
    },
  },
  computed: {
    hasError() {
      return !!this.$v.price.$error;
    },
    paymentNotAllowed() {
      return this.studentAge < 13
    }
  },
  methods: {
    paymentNotAllowedModal() {
      if (this.paymentNotAllowed) {
        this.$bvModal.msgBoxOk('Student Pay is not allowed for classrooms with younger students');
      }
    },
    async handleRegisterStudentPayment() {
      if (this.paymentNotAllowed) {
        return
      }
      await this.$refs.registerStudentPayment.showModal();

      this.loadingClassroom = false;

      this.classroom = await PartnershipService.getClassroom(
        this.classroomCode
      );

      this.price = this.classroom?.student_payment_required
        ? this.classroom.student_payment_price
        : "";

      await this.$nextTick();
      this.loadingClassroom = true;
    },
    handleCancel() {
      this.$refs.registerStudentPayment.hideModal();

      if (this.classroom?.student_payment_required) {
        this.price = this.classroom.student_payment_price;
      } else {
        this.checkedStudentPayment = false;
        this.price = "";
      }
    },
    async handleCheckedPayment(checked) {
      if (checked) {
        await this.handleRegisterStudentPayment();
        return false;
      }

      try {
        await PartnershipService.saveStudentPayment(this.classroomCode, {
          paymentRequired: false,
        });

        this.$emit("on-cancel-payment", { classroomCode: this.classroomCode });
      } catch (e) {
        await this.$bvModal.msgBoxOk(
          `An error occurred while canceling the payment. Please try again.`
        );
      }
    },
    async handlePayment() {
      this.$v.price.$touch();
      if (this.$v.price.$invalid) {
        return;
      }

      try {
        const response = await PartnershipService.saveStudentPayment(this.classroom.code, {
          paymentRequired: true,
          paymentPrice: this.price,
        });

        if (!response.success) {
          throw Error(response.message)
        }

        const studentPaymentRequired = response.data.student_payment_required
        const studentPaymentPrice = response.data.student_payment_price

        await this.$refs.registerStudentPayment.hideModal();

        this.$emit("on-payment", {
          classroomCode: this.classroom.code,
          paymentRequired: studentPaymentRequired,
          paymentPrice: studentPaymentPrice,
        });
      } catch (e) {
        const errorMessage = e.message ? e.message : 'An error occurred while saving the payment.'
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again. `);
      }
    },
    onInput() {
      this.$v.price.$touch();
    },
  },
  watch: {
    studentPaymentEnable: {
      deep: true,
      handler(currentClassroom) {
        this.checkedStudentPayment = !!currentClassroom;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="stylus">
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.student-payment
  &__amount
      color: color-blue-100
      font font-opensans-bold
      font-size: 17px
      text-decoration none
      padding-top 2px

  &__modal
    .modal-footer
      button
        width auto
</style>
