<template>
  <div class="form-group">
    <label v-if="label" >{{ label }}</label>

    <select-dropdown
      v-model="inputValue"
      :id="`select-${_uid}`"
      :class-select="customClass"
      :state="state === false ? 'is-invalid' : ''"
      :placeholder="placeholder"
      :options="options"
      :disabled="disabled"
      @input="onSelectOption"/>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`select-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`select-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import {InputMixins} from "@/modules/panel/Mixins"
import SelectDropdown from '../SelectDropdown/SelectDropdown'

export default {
  name: "Select",
  mixins: [InputMixins],
  components: {
    'select-dropdown': SelectDropdown,
  },
  data: function () {
    return {}
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    customClass: {
      type: String,
    },
  },
  methods: {
    onSelectOption (value) {
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'
::v-deep
  .custom-dropdown
    ::v-deep
      .dropdown-toggle
        span
          color #414D94
</style>

