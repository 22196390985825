import axios from 'axios'
import { AppStorage, adminTypes } from '@/core/utils'
import VideoUploadService from './video-upload-service'

import moment from 'moment'

// const isVideo = function (ext) {
//   switch (ext.toLowerCase()) {
//     case 'mp4':
//     case 'webm':
//     case 'ogg':
//       return true
//   }
//   return false
// }

function getExtension (filename) {
  return filename.substr((filename.lastIndexOf('.')))
}

const getSignature = function (key, bucket = process.env.VUE_APP_S3_BUCKET, client_method = 'put_object') {
  return axios.post(process.env.VUE_APP_S3_SIGNED_URL, {
    bucket: bucket,
    key: key, //, "prefix",
    client_method: client_method
  }).then(function (result) {
    const signedUrl = result.data.signed_url

    const signature = {}

    signature.url = signedUrl
    signature.inputs = {}

    const pairs = signedUrl.substring(signedUrl.indexOf('?') + 1).split('&')
    for (let i = 0; i < pairs.length; i++) {
      if (!pairs[i]) {
        continue
      }
      const pair = pairs[i].split('=')
      signature.inputs[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }

    return signature
  }).catch(function (error) {
    console.error(error)
  })
}

const getS3FormPolicy = function (signature) {
  const s3Form = new FormData()

  s3Form.append('X-Amz-Algorithm', signature.inputs['X-Amz-Algorithm'])
  s3Form.append('X-Amz-Credential', signature.inputs['X-Amz-Credential'])
  s3Form.append('X-Amz-Date', signature.inputs['X-Amz-Date'])
  s3Form.append('X-Amz-Expires', signature.inputs['X-Amz-Expires'])
  s3Form.append('X-Amz-Signature', signature.inputs['X-Amz-Signature'])
  s3Form.append('X-Amz-SignedHeaders', signature.inputs['X-Amz-SignedHeaders'])

  return s3Form
}

const getProfileMetadata = function () {
  const user = AppStorage.getUser()

  return {
    country: user.country_name,
    school: user.school_name,
    teacher: user.full_name
  }
}

const upload = function (url, file, config) {
  return axios.put(url, file, config)
    .catch(function (error) {
      console.error(error)
    })
}

const generateFileName = function () {
  return (moment().utc().format('YYYYMMDDHHmmssSSS') + Math.round(Math.random() * (999 - 100) + 100))
}

const uploadFile = async function (file, index_file = null, type_file = null) {
  const baseName = generateFileName(file.name)
  const extension = getExtension(file.name)
  const fileName = baseName + '.' + extension
  const key = 'resources/' + fileName
  const fileUrl = process.env.VUE_APP_S3_STORAGE + '/' + fileName

  return getSignature(key).then((signature) => {
    return upload(signature['url'], file, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      return {name: fileUrl, index_file: index_file, type_file: type_file, type: 'file', originalFile: file.name}
    })
  })
}

const uploadMultipleFiles = async function (files = []) {
  // admins allowed to upload transcoder videos
  const allowedAdmins = !!AppStorage.getUser().roles.find(rol => rol.slug === adminTypes.adminCourseEditor || rol.slug === adminTypes.superAdmin)

  const promises = []

  for (let i = 0; i < files.length; i++) {
    const isVideo = files[i].isVideo
    if (isVideo && allowedAdmins) {
      const resp = await VideoUploadService.videoUploadFile(files[i].file, files[i].customData, (progress) => {
        console.log(progress, 'progress');
      })
      promises.push({...resp, index_file: files[i].index_file, type_file: files[i].type_file, type: 'transcoder'})
    } else {
      promises.push(uploadFile(files[i].file, files[i].index_file, files[i].type_file))
    }
  }

  return Promise.all(promises).then(function (results) {
    const data = []

    results.forEach(function (file) {
      data.push(file)
    })

    return data
  })
}

const getVideo = async function (key) {
  const bucket = process.env.VUE_APP_S3_STUDENT_VIDEO_OUTPUT_BUCKET
  const signature = await getSignature('resources/' + key, bucket, 'get_object')

  return signature.url
}

const getVideoThumbnail = async function (key) {
  const bucket = process.env.VUE_APP_S3_STUDENT_VIDEO_OUTPUT_BUCKET
  const signatureThumbnail = await getSignature('resources/MedResVideo/thumbnails/' + key + '-00001.png', bucket, 'get_object')

  return signatureThumbnail.url
}

export default {
  getSignature: getSignature,
  getS3FormPolicy: getS3FormPolicy,
  getProfileMetadata: getProfileMetadata,
  upload: upload,
  uploadFile: uploadFile,
  uploadMultipleFiles: uploadMultipleFiles,
  getVideo,
  getVideoThumbnail
}
