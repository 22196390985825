<template>
  <modal ref="modalDetail"
         id="modal-admin-detail"
         modal-class="primary_modal"
         @hide="close"
         :hide-footer="true">
    <template #header>
    </template>
    <template #body>
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="container-form">
              <b-form-group label-cols="4"
                            label="First Name"
                            label-for="input-sm">
                <b-form-input v-model="$v.form.firstName.$model"
                              :class="{'input-error': firstNameValid}"
                              autocomplete="off"
                              maxlength="40"
                              type="text"/>
                <div class="error" v-if="firstNameValid">
                  Field is required
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Last Name"
                            label-for="input-sm">
                <b-form-input v-model="$v.form.lastName.$model"
                              :class="{'input-error': lastNameValid}"
                              maxlength="40"
                              autocomplete="off"
                              type="text"/>
                <div class="error" v-if="lastNameValid">
                  Field is required
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Email Address"
                            label-for="input-sm">
                <b-form-input v-model="$v.form.email.$model"
                              :class="{'input-error': emailRequiredValid || emailAddressValid}"
                              maxlength="40"
                              autocomplete="off"
                              type="text"/>
                <div class="error" v-if="emailRequiredValid">
                  Field is required
                </div>
                <div class="error" v-if="emailAddressValid">
                  Invalid Email
                </div>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Role"
                            label-for="input-sm">
                <select-dropdown :options="options"
                                 v-model="form.role"
                                 class="--secundary color-dropdown"
                                 value-field="slug"/>
              </b-form-group>
              <b-form-checkbox v-model="form.active"
                               class="custom-checkbox checkbox-lg">
                Enabled
              </b-form-checkbox>
              <b-form-group class="flex-column text-center">
                <b-button class="button button--email my-1  mr-3" @click="close">Cancel</b-button>
                <b-button class="button button--email my-1" @click="onSave">Save</b-button>
              </b-form-group>
            </div>
          </b-col>
          <b-overlay :show="loading"
                     no-wrap
                     spinner-medium
                     rounded="sm"/>
        </b-row>
      </b-container>
    </template>
  </modal>
</template>

<script>
import {Modal, SelectDropdown} from '@/core/components'
import {required, email} from "vuelidate/lib/validators"
import { AdministratorsService } from '@/core/services'

export default {
  name: 'ModalAdminDetail',
  components: {
    Modal,
    SelectDropdown
  },
  props: {
    adminId: {
      default: null
    }
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'super-admin',
        active: true
      },
      options: [],
      loading: false
    }
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  async mounted() {
    await AdministratorsService.getAdminRoles().then((response) => {
      if (response.success) {
        this.options = response.data
      }
    })
  },
  methods: {
    open() {
      this.$refs.modalDetail.showModal()
      if (this.adminId) {
        this.getAdminById()
      }
    },
    close() {
      this.$v.$reset()
      this.form = {
        firstName: '',
        lastName: '',
        email: '',
        role: 'super-admin',
        active: true
      }
      this.$refs.modalDetail.hideModal()
    },
    getAdminById() {
      this.loading = true
      AdministratorsService.getAdminById(this.adminId).then((reponse) => {
        const data = reponse.data
        if (reponse.success) {
          this.form = {
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.user && data.user.email,
            role: data.user && data.user.roles[0].slug,
            active: !!data.active
          }
        }
      }).finally(() => this.loading = false)
    },
    async onSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      const adminData = {
        email: this.form.email,
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        role: this.form.role,
        active: this.form.active,
      }
      this.loading = true
      if (this.adminId) {
        AdministratorsService.updateAdmin(this.adminId, adminData).then((reponse) => {
          if (reponse.success) {
            this.getAdminById()
            this.$emit('admin-saved')
            this.$bvModal.msgBoxOk('Update successful!.', {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          } else {
            const errorMessage = reponse.message ? reponse.message : 'Failed to update.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          }
        }).finally(() => this.loading = false)
      } else {
        AdministratorsService.addAdmin(adminData).then((reponse) => {
          if (reponse.success) {
            this.$emit('admin-saved')
            this.$bvModal.msgBoxOk('Admin added successful!.', {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            }).then(() => {
              this.close()
            })
          } else {
            const errorMessage = reponse.message ? reponse.message : 'Failed to update.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          }
        }).finally(() => this.loading = false)
      }
    }
  },
  computed: {
    firstNameValid () {
      return !this.$v.form.firstName.required && this.$v.form.firstName.$dirty
    },
    lastNameValid () {
      return !this.$v.form.lastName.required && this.$v.form.lastName.$dirty
    },
    emailRequiredValid () {
      return !this.$v.form.email.required && this.$v.form.email.$dirty
    },
    emailAddressValid () {
      return !this.$v.form.email.email && this.$v.form.email.$dirty
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.subtitle
  text-transform uppercase
  font-size 14px
  font-weight bold
  margin-top 1rem

.container-form
  margin 2rem 0

.input-error, .input-error:focus
  border-color red

.error
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin 5px 0

.custom-checkbox
  margin 20px 0
  ::v-deep
    .custom-control-label
      padding-left 10px
      font font-opensans-semibold !important

      &::after
        font-weight 400
        font-size 18px !important

.flex-column
  margin-top 30px

</style>

<style lang="stylus">
#modal-admin-detail
  .modal-dialog
    min-width unset

</style>
