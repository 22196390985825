import {
  FETCH_ALERT_LIST_START,
  FETCH_ALERT_LIST_DATA,
  FETCH_ALERT_DETAIL_START,
  FETCH_ALERT_DETAIL_DATA,
  UPDATE_ALERT_DETAIL_START,
  UPDATE_ALERT_DETAIL_DATA
} from './mutation-types'
export const
  mutations = {
    [FETCH_ALERT_LIST_START] (state) {
      state.loadingAlerts = true
    },
    [FETCH_ALERT_LIST_DATA] (state, { alerts }) {
      state.alerts = alerts
      state.loadingAlerts = false
    },
    [FETCH_ALERT_DETAIL_START] (state) {
      state.loadingAlertsData  = true
    },
    [FETCH_ALERT_DETAIL_DATA] (state, data) {
      state.alertDetail = Object.assign(state.alertDetailDefault, data)
      state.loadingAlertsData  = false
    },
    [UPDATE_ALERT_DETAIL_START] (state) {
      state.updatingAlertsData  = true
    },
    [UPDATE_ALERT_DETAIL_DATA] (state, data) {
      state.alertDetail = Object.assign(state.alertDetailDefault, data)
      state.updatingAlertsData  = false
    },
    resetAlertDetail(state) {
      state.alertDetail = {
        user_type_id: 3,
        message: '',
        color: 'warning',
        starts_at: null,
        ends_at: null
      },
      state.loadingAlertsData  = false
    }
  }
