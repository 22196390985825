import { mapActions, mapGetters } from 'vuex'

export const UtilsMixins = {
  computed: {
    ...mapGetters({
      getAgeRanges: 'UtilsStore/getAgeRanges',
      getSubjects: 'UtilsStore/getSubjects',
      getLanguages: 'UtilsStore/getLanguages',
      getLevels: 'UtilsStore/getLevels',
      getStandards: 'UtilsStore/getStandards',
      getCourseStudentGroupTypes: 'UtilsStore/getCourseStudentGroupTypes',
      getTeacherTypes: 'UtilsStore/getTeacherTypes'
    })
  },
  methods: {
    ...mapActions({
      fetchAgeRange: 'UtilsStore/fetchAgeRange',
      fetchSubjects: 'UtilsStore/fetchSubjects',
      fetchLanguages: 'UtilsStore/fetchLanguages',
      fetchStandards: 'UtilsStore/fetchStandards',
      fetchLevels: 'UtilsStore/fetchLevels',
      fetchStudentGroupType: 'UtilsStore/fetchStudentGroupType',
      fetchTeacherTypes: 'UtilsStore/fetchTeacherTypes'
    })
  }
}
