import { mapActions, mapGetters } from 'vuex'
export const SubjectMixins = {
  computed: {
    ...mapGetters({
      getSubjects: 'SubjectStore/getSubjects',
      isLoadingSubjects: 'SubjectStore/isLoadingSubjects',
      loadingSubjectsData: 'SubjectStore/loadingSubjectsData',
      getSubjectDetail: 'SubjectStore/getSubjectDetail',
      getPagination: 'SubjectStore/getPagination',
    })
  },
  methods: {
    ...mapActions({
      fetchSubjectsList: 'SubjectStore/fetchSubjectsList',
      fetchSubjectsDetail: 'SubjectStore/fetchSubjectsDetail',
      updateSubjectsDetail: 'SubjectStore/updateSubjectsDetail',
    })
  }
}
