<template>
  <div class="modal-container">
    <b-modal :id="id"  :modal-class="modalClass" ref="modal" :hide-header="hideHeader" :hide-footer="hideFooter" centered
             footer-class="justify-content-center" @hide="$emit('hide')">
      <template #default>
        <button @click="hideModal" class="close" v-if="!hideCloseButton">
          <span class="icon-delete"></span>
        </button>
        <div class="body">
          <slot name="body"></slot>
        </div>
      </template>
      <template #modal-footer>
        <slot name="footer"></slot>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      default: ''
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    modalClass: {
      type: [Object, String, Array]
    },
    hideHeader: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    showModal: function () {
      return new Promise((resolve) => {
        this.$refs.modal.show()

        resolve(this)
      })
    },
    hideModal: function () {
      return new Promise((resolve) => {
        this.$refs.modal.hide()

        resolve(this)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.close {
  float: right;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  background-color transparent
  border 0
  padding 1em
  opacity 1

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    opacity 1
  }

  span:before {
    color: #000
    font-weight bold
  }
}

.body {
  padding-left 10px
  padding-right 10px
}

.close + .body {
  padding-top 10px
}
</style>
