<template>
  <b-modal id="avatar-list-modal" centered hide-footer hide-header size="lg">
    <div class="avatar-list-container">
      <div class="avatar-list">
        <div class="avatar-img"
             :class="{'avatar-img-selected': avatarSelected === avatar.id}"
             v-for="avatar in $options.avatars"
             @click="avatarSelected = avatar.id"
             :key="avatar.id">
          <b-img-lazy v-bind="mainProps" :src="$options.teacherPortalUrl + getAvatarImageById(avatar.id)" :alt="avatar.id"/>
        </div>
      </div>
    </div>
    <div class="avatar-list-buttons">
      <div class="list-buttons-first">
        <b-button @click="$bvModal.hide('avatar-list-modal')" class="button avatar-button">Cancel</b-button>
        <b-button :disabled="!avatarSelected"
                  @click="form.avatar = $options.teacherPortalUrl + getAvatarImageById(avatarSelected)"
                  class="button avatar-button">SUBMIT</b-button>
      </div>
      <b-button @click="$emit('upload-avatar')" class="button avatar-button-upload">UPLOAD AVATAR</b-button>
    </div>
  </b-modal>
</template>

<script>
import { avatars } from '@/core/utils'

export default {
  name: 'AvatarList',
  teacherPortalUrl: process.env.VUE_APP_BASE_URL_TEACHER_PORTAL,
  avatars: avatars,

  data () {
    return {
      form: {
        avatar: ''
      },
      avatarFile: {},
      avatarSelected: null,
      mainProps: { blank: true, width: 100, height: 100, class: 'm-2' },
    }
  },
  methods: {
    getAvatarImageById (id) {
      const img = avatars.find((img) => img.id === id)
      return img ? img.src : ''
    },
    openUploadFile () {
      this.$refs["upload-image-avatar"].openUploadFile()
    }
  },
  watch: {
    'form.avatar'(newAvatar) {
      this.$emit('on-avatar-select', newAvatar)
      this.$bvModal.hide('avatar-list-modal')
    },
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.form-upload-avatar
  width 160px
  height 160px

  .form-upload-file
    padding 20px 0
    display flex
    flex-direction column
    height 100%
    justify-content end

    .form-file-text
      padding-bottom 25px

    .btn-input-file
      border-radius(4px)
      letter-spacing 0
      line-height 17px

#avatar-list-modal
  .avatar-list-container
    overflow-y scroll
    padding 5px
    padding-bottom 15px
    max-height 65vh

    &::-webkit-scrollbar
      width 8px
      height 10px
      background color-purple-200
      border-radius 4px

    &::-webkit-scrollbar-thumb
      background color-purple-600
      border-radius 4px

    .avatar-list
      display flex
      flex-wrap wrap
      justify-content center

      .avatar-img
        overflow visible
        cursor pointer

      .avatar-img-selected
        outline solid 2px color-purple-600
        transform scale(0.9)

    @media (max-width 750px)
      .avatar-list
        min-width 815px

  .avatar-list-buttons
    padding-top 40px
    display flex
    justify-content center

    .list-buttons-first
      display flex
      justify-content center

      .avatar-button
        margin-right 10px

  @media(max-width 600px)
    .avatar-list-buttons
      flex-direction column
      align-items center

      .avatar-button-upload
        margin-top 10px
</style>

<style lang="stylus">
#avatar-list-modal
  .modal-body
    width 100%
</style>
