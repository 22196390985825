<template>
  <b-row>
    <b-col md="12" class="my-2 mx-2">
      <b-overlay :show="isLoadingPartnership" spinner-medium rounded="sm">
        <b-col md="6" class="my-2 mx-5">
          <search-input
            v-model="searchValue"
            placeholder="CLASSROOM CODE SEARCH"
            @on-search="handlerSearch"
          >
          </search-input>
        </b-col>
        <b-col class="container-content">
          <section v-if="getClassroomPartnership.classrooms.name">
            <div class="container-table">
              <table>
                <tr>
                  <th class="th-none"></th>
                  <th class="th-top">CLASSROOM A</th>
                  <th class="th-top">PARTNER B</th>
                </tr>
                <tr>
                  <th class="th-left">Name:</th>
                  <td>{{ getClassroomPartnership.classrooms.name }}</td>
                  <td class="td-border">
                    {{ getClassroomPartnership.partnerships.name }}
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Code:</th>
                  <td>{{ getClassroomPartnership.classrooms.code }}</td>
                  <td class="td-border">
                    {{ getClassroomPartnership.partnerships.code }}
                  </td>
                </tr>
                <tr>
                  <th class="th-left">{{ isLibrarian ? 'Librarian:' : 'Teacher:' }}</th>
                  <td>
                    <a class="link link--teacher" @click="onGoTeacherDetail(getClassroomPartnership.classrooms.teacher_id)">{{ getClassroomPartnership.classrooms.teacher }}</a>
                  </td>
                  <td class="td-border">
                    <a class="link link--teacher" @click="onGoTeacherDetail(getClassroomPartnership.partnerships.teacherId)">{{ getClassroomPartnership.partnerships.teacher }}</a>
                  </td>
                </tr>
                <tr v-if="isLibrarian">
                  <th class="th-left">Teacher:</th>
                  <td>
                    <a class="link link--teacher" @click="onGoTeacherDetail(getClassroomPartnership.classrooms.teacherAssistantsId)">{{ getClassroomPartnership.classrooms.teacherAssistants }}</a>
                  </td>
                  <td class="td-border">
                    <a class="link link--teacher" @click="onGoTeacherDetail(getClassroomPartnership.partnerships.teacherAssistantsId)">{{ getClassroomPartnership.partnerships.teacherAssistants }}</a>
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Login:</th>
                  <td>
                    <p class="login-label">{{ getClassroomPartnership.classrooms.loginId }}</p>
                    <p class="login-label">{{ getClassroomPartnership.classrooms.teacherAssistantsEmail }}</p>
                  </td>
                  <td class="td-border">
                    <p class="login-label">{{ getClassroomPartnership.partnerships.loginId }}</p>
                    <p class="login-label">{{ getClassroomPartnership.partnerships.teacherAssistantsEmail }}</p>
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Country:</th>
                  <td>{{ getClassroomPartnership.classrooms.country }}</td>
                  <td class="td-border">
                    {{ getClassroomPartnership.partnerships.country }}
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Subject:</th>
                  <td>{{ classroomSubject }}</td>
                  <td class="td-border">{{ partnerSubject }}</td>
                </tr>
                <tr>
                  <th class="th-left">Age / Level:</th>
                  <td>
                    {{ ageRangesNameClassroom }}
                    <span v-show="showSlashTeacher"> / </span>
                    <span v-if="classroomSubject === 'Languages & Cultures'">
                      {{ getClassroomPartnership.classrooms.level }}
                    </span>
                  </td>
                  <td class="td-border">
                    {{ ageRangesNamePartnership }}
                    <span v-show="showSlashPartner"> / </span>
                    <span v-if="partnerSubject === 'Languages & Cultures'">
                      {{ getClassroomPartnership.partnerships.level }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Native Language:</th>
                  <td>
                    {{ getClassroomPartnership.classrooms.nativeLanguage }}
                  </td>
                  <td class="td-border">
                    {{ getClassroomPartnership.partnerships.nativeLanguage }}
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Task Language:</th>
                  <td>{{ getClassroomPartnership.classrooms.task }}</td>
                  <td class="td-border">
                    {{ getClassroomPartnership.partnerships.task }}
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Course:</th>
                  <td class="th-course-name">
                    {{ getClassroomPartnership.classrooms.course.name }}
                  </td>
                  <td class="td-border">
                    <b-button
                      @click="showCoursesList"
                      class="button primary mx-4"
                      :disabled="sendingData"
                    >
                      Replace Course
                    </b-button>
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Type:</th>
                  <td>
                    {{
                      getClassroomPartnership.classrooms.courseStudentGroupType
                        ? getClassroomPartnership.classrooms
                            .courseStudentGroupType.name
                        : ""
                    }}
                  </td>
                  <td class="td-border">
                    {{
                      getClassroomPartnership.partnerships
                        .courseStudentGroupType
                        ? getClassroomPartnership.partnerships
                            .courseStudentGroupType.name
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Dates:</th>
                  <td>{{ getClassroomPartnership.classrooms.dates }}</td>
                  <td class="td-border">
                    {{ getClassroomPartnership.partnerships.dates }}
                  </td>
                </tr>
                <tr v-if="getClassroomPartnership.show_end_date">
                  <th class="th-left">End date</th>
                  <td>
                    <b-form-input
                      v-model="getClassroomPartnership.end_date"
                      :disabled="sendingData"
                    />
                  </td>
                  <td class="td-border">
                    <b-button
                      @click="updateEndDate"
                      class="button primary mx-4"
                      :disabled="
                        sendingData || !getClassroomPartnership.end_date
                      "
                    >
                      Update end date
                    </b-button>
                  </td>
                </tr>
                <tr>
                  <th class="th-left">Students Pay</th>
                  <td>
                    <student-payment
                      :classroom-code="getClassroomPartnership.classrooms.code"
                      :student-age="minAgeClassroom"
                      :student-payment-price="
                        getClassroomPartnership.classrooms.student_payment_price
                      "
                      :student-payment-enable="
                        getClassroomPartnership.classrooms
                          .student_payment_required
                      "
                      :disabled="
                        !getClassroomPartnership.classrooms.enableStudentPayment
                      "
                      @on-payment="onPaymentStudent"
                      @on-cancel-payment="onCancelPayment"
                    ></student-payment>
                  </td>
                  <td v-if="hasPartner">
                    <student-payment
                      :classroom-code="
                        getClassroomPartnership.partnerships.code
                      "
                      :student-age="minAgePartnership"
                      :student-payment-price="
                        getClassroomPartnership.partnerships
                          .student_payment_price
                      "
                      :student-payment-enable="
                        getClassroomPartnership.partnerships
                          .student_payment_required
                      "
                      :disabled="
                        !getClassroomPartnership.partnerships
                          .enableStudentPayment
                      "
                      @on-payment="onPaymentStudent"
                      @on-cancel-payment="onCancelPayment"
                    ></student-payment>
                  </td>
                  <td class="td-border" v-else></td>
                </tr>
                <tr>
                  <th class="th-left"></th>
                  <td>
                    <b-button
                      @click="$refs.deleteClassroom.showModal()"
                      class="button primary mx-4"
                      :disabled="sendingData"
                    >
                      Delete Classroom
                    </b-button>
                  </td>
                  <td class="td-border"></td>
                </tr>
              </table>
            </div>
            <b-col md="6" class="my-3 mx-4">
              <b-button
                v-if="
                  !getClassroomPartnership.partnerships.name &&
                  getClassroomPartnership.classrooms.course.id
                "
                @click="OpenModalAdd"
                class="button button--action-partnerships mx-4"
                :disabled="sendingData"
                >Add Partnerships
              </b-button>
              <b-button
                v-if="getClassroomPartnership.partnerships.name"
                @click="OpenModalBreak"
                class="button button--action-partnerships mx-4"
                :disabled="sendingData"
                >Remove Partnerships
              </b-button>
            </b-col>
          </section>
        </b-col>
      </b-overlay>
      <modal
        ref="addPartnership"
        modal-class="delete modal-add-partnership"
        @hide="CloseModalAdd"
      >
        <template #body>
          <h2 class="modal-title text-center">Add Partnerships</h2>
          <br />
          <section class="col-11 mx-auto">
            <b-form-input
              v-model="addCode"
              type="text"
              placeholder="Partner Classroom Code"
              required
            >
            </b-form-input>
          </section>
          <br />
        </template>
        <template #footer>
          <b-button
            class="button b-ligth"
            @click="CloseModalAdd"
            :disabled="sendingData"
          >
            Delete</b-button
          >
          <b-button
            @click="AddPartnership"
            class="button"
            :disabled="sendingData || !addCode"
          >
            Add Partnerships
          </b-button>
        </template>
      </modal>
      <modal
        ref="removePartnership"
        modal-class="delete modal-remove-partnership"
      >
        <template #body>
          <h2 class="modal-title text-center">Break Partnerships</h2>
          <p class="text-center">
            Date related to this partnerships will be lost. Are you sure you
            want to break this partnerships?
          </p>
          <br />
          <b-form-checkbox
            v-model="keepStudentData"
            class="custom-checkbox text-center"
          >
            Do you want keep students videos?
          </b-form-checkbox>
        </template>
        <template #footer>
          <b-button class="button b-ligth" @click="BreakPartnership">
            Break</b-button
          >
          <b-button @click="CloseModalBreak" class="button"> Cancel</b-button>
        </template>
      </modal>
      <modal
        id="modal-delete-classroom"
        ref="deleteClassroom"
        modal-class="modal-delete-classroom"
      >
        <template #body>
          <h2 class="modal-title">Delete Classroom</h2>
          <p class="second-text text-center">
            Deleting a classroom will permanently remove all data and videos
            associated with this classroom. Are you sure?
          </p>
        </template>
        <template #footer>
          <b-button
            class="button b-ligth"
            @click="deleteClassroom"
            :disabled="sendingData"
            >Delete</b-button
          >
          <b-button
            class="button"
            @click="$refs.deleteClassroom.hideModal()"
            :disabled="sendingData"
          >
            Cancel
          </b-button>
        </template>
      </modal>
      <modal
        id="errorsModal"
        ref="errorsModal"
        :hide-close-button="true"
        @hide="hideErrorsModal"
      >
        <template #body>
          <ul>
            <li v-for="(error, idx) in errors" :key="error + idx">
              {{ error }}
            </li>
          </ul>
        </template>
        <template #footer>
          <b-button class="button" @click="hideErrorsModal">OK</b-button>
        </template>
      </modal>
      <courses-list ref="courses-list" @add-course="updatePartnershipCourse" :user-type="getClassroomPartnership.classrooms.teacherUserType" />
    </b-col>
  </b-row>
</template>

<script>
import { Modal, SearchInput } from "@/core/components/";
import { PartnershipsMixins, TeacherMixins } from "../Mixins";
import { PartnershipService, TeacherService } from "@/core/services";
import CoursesList from "@/core/components/CoursesList/CoursesList";
import StudentPayment from "@/modules/panel/components/Partnership/StudentPayment.vue";
import { PartnershipSmallGroupOrWholeClassroomError } from "@/core/services/partnerships";

export default {
  name: "Partnerships",
  components: {
    StudentPayment,
    SearchInput,
    Modal,
    CoursesList,
  },
  mixins: [TeacherMixins, PartnershipsMixins],
  data() {
    return {
      searchValue: "",
      addCode: "",
      keepStudentData: true,
      sendingData: false,
      errors: [],
    };
  },
  mounted() {
    this.resetDetail();
  },
  methods: {
    showCoursesList() {
      if (this.hasStudentPay) {
        this.$bvModal.msgBoxOk(
          "You must first disable Students Pay before changing to a small group or whole classroom course."
        );
        return;
      }

      this.$refs["courses-list"].openModal();
    },
    onGoTeacherDetail(teacherId) {
      if (teacherId) {
        this.$router.push({
          path: `Teacher/${teacherId}`,
        })
      }
    },
    async AddPartnership() {
      if (this.sendingData) {
        return;
      }

      this.sendingData = true;

      let partner;
      await PartnershipService.getPartnership(this.addCode)
        .then((response) => {
          if (
            this.getClassroomPartnership.classrooms.student_payment_required
          ) {
            if (
              response.data.classroom.course_student_group_type &&
              response.data.classroom.course_student_group_type.id !== 1
            ) {
              throw new PartnershipSmallGroupOrWholeClassroomError(
                "You must first disable Students Pay before changing to a small group or whole classroom course."
              );
            }
          }

          partner = response.data;
        })
        .catch((e) => {
          this.$bvModal.msgBoxOk(`${e.message} Please try again.`);
        });
      if (!partner) {
        this.sendingData = false;
        return;
      }

      if (
        this.getClassroomPartnership.classrooms.course &&
        partner.classroom.course &&
        Object.keys(this.getClassroomPartnership.classrooms.course).length !==
          0 &&
        Object.keys(partner.classroom.course).length !== 0
      ) {
        if (
          this.getClassroomPartnership.classrooms.course.id !==
          partner.classroom.course.id
        ) {
          const responseConfirm = await this.$bvModal
            .msgBoxConfirm(
              "If you continue, all videos from both classrooms will be removed",
              {
                size: "sm",
                dialogClass: "modal-message-box confirm-add-course",
                centered: true,
                okTitle: "OK",
                cancelTitle: "Cancel",
              }
            )
            .then((res) => {
              res === false && this.CloseModalAdd();
              return res;
            });
          if (!responseConfirm) {
            return;
          }
        }

        if (
          this.getClassroomPartnership.classrooms.course
            .course_student_group_type_id !==
          partner.classroom.course.course_student_group_type_id
        ) {
          const responseConfirm = await this.$bvModal
            .msgBoxConfirm(
              "If you continue, all students from the 2nd classroom and all videos from both classrooms will be removed",
              {
                size: "sm",
                dialogClass: "modal-message-box confirm-add-course",
                centered: true,
                okTitle: "OK",
                cancelTitle: "Cancel",
              }
            )
            .then((res) => {
              res === false && this.CloseModalAdd();
              return res;
            });
          if (!responseConfirm) {
            return;
          }
        }
      }

      const data = {
        partner_classroom_code: this.addCode,
      };
      PartnershipService.createPartnership(
        this.getClassroomPartnership.classrooms.code,
        data
      )
        .then((res) => {
          if (!res.success) {
            const errorMessage = res.message
              ? res.message
              : "Error while pais the classrooms.";
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`);
          } else {
            this.CloseModalAdd();
            this.fetchPartnership(this.getClassroomPartnership.classrooms.code);
            this.$bvModal.msgBoxOk("Add Partner Successfully");
          }
        })
        .catch((e) => {
          const errorMessage = e.message
            ? e.message
            : "Error while loading course.";
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`);
        })
        .finally(() => (this.sendingData = false));
    },
    BreakPartnership() {
      if (this.sendingData) {
        return;
      }
      this.sendingData = true;
      PartnershipService.deletePartnership(
        this.getClassroomPartnership.classrooms.code,
        { keep_student_data: this.keepStudentData }
      )
        .then(() => {
          this.CloseModalBreak();
          this.fetchPartnership(this.getClassroomPartnership.classrooms.code);
          this.$bvModal.msgBoxOk("Break Partnership Successfully");
        })
        .catch((e) => {
          const errorMessage = e.message
            ? e.message
            : "Error while loading course.";
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`);
        })
        .finally(() => (this.sendingData = false));
    },
    updatePartnershipCourse(args) {
      if (this.sendingData) {
        return;
      }
      this.$refs["courses-list"].closeModal();
      this.sendingData = true;
      PartnershipService.updatePartnershipCourse(
        this.getClassroomPartnership.classrooms.code,
        { course_id: args.id }
      )
        .then((response) => {
          if (response.success) {
            this.fetchPartnership(this.getClassroomPartnership.classrooms.code);
            this.$bvModal.msgBoxOk("Update partnership course successful!");
            return;
          }
          const errorMessage = response.message
            ? response.message
            : "Error update course.";
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`);
        })
        .finally(() => (this.sendingData = false));
    },
    deleteClassroom() {
      if (this.sendingData) {
        return;
      }
      this.sendingData = true;
      TeacherService.deleteClassroom(
        this.getClassroomPartnership.classrooms.teacher_id,
        this.getClassroomPartnership.classrooms.id
      )
        .then(() => {
          this.fetchDetail(
            this.getClassroomPartnership.classrooms.teacher_id
          ).then(() => {
            this.$router.push({ name: "TeacherContent" });
          });
        })
        .catch((e) => {
          this.sendingData = false;
          const errorMessage = e.message
            ? e.message
            : "Error while delete the classroom.";
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`);
        })
        .finally(() => (this.sendingData = false));
    },
    async updateEndDate() {
      if (this.sendingData) {
        return;
      }
      this.sendingData = true;

      try {
        const classroom_code = this.getClassroomPartnership.classrooms.code;
        const end_date = this.getClassroomPartnership.end_date;

        await this.updatePartnershipEndDate({ classroom_code, end_date });
        await this.handlerSearch({ searchForm: classroom_code });
      } catch (e) {
        const errorList = e?.errors ? Object.values(e?.errors) : [];
        if (errorList.length) {
          errorList.map((error) => this.errors.push(error[0]));
          await this.$refs.errorsModal.showModal();
          return;
        }
        const errorMessage = e.message
          ? e.message
          : "Error while delete the classroom.";
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`);
      } finally {
        this.sendingData = false;
      }
    },
    hideErrorsModal() {
      this.errors = [];
      this.$refs.errorsModal.hideModal();
    },
    OpenModalAdd() {
      this.$refs.addPartnership.showModal();
    },
    CloseModalAdd() {
      this.$refs.addPartnership.hideModal();
      this.addCode = "";
    },
    OpenModalBreak() {
      this.keepStudentData = true;
      this.$refs.removePartnership.showModal();
    },
    CloseModalBreak() {
      this.$refs.removePartnership.hideModal();
    },
    handlerSearch(arg) {
      this.fetchPartnership(arg.searchForm).catch((e) => {
        const errorMessage = e.message
          ? e.message
          : "Error while loading course.";
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`);
      });
    },
    onPaymentStudent() {
      this.handlerSearch({
        searchForm: this.getClassroomPartnership.classrooms.code,
      });
    },
    onCancelPayment() {
      this.handlerSearch({
        searchForm: this.getClassroomPartnership.classrooms.code,
      });
    },
  },
  computed: {
    isLibrarian () {
      return this.getClassroomPartnership.classrooms.teacherUserType === 'teacher-librarian'
    },
    ageRangesNameClassroom () {
      return this.getClassroomPartnership?.classrooms?.age?.name
    },
    minAgeClassroom () {
      return this.getClassroomPartnership.classrooms?.age?.min_age
    },
    ageRangesNamePartnership () {
      return this.getClassroomPartnership?.partnerships?.age?.name
    },
    minAgePartnership () {
      return this.getClassroomPartnership.partnerships?.age?.min_age
    },
    hasStudentPay() {
      return (
        this.getClassroomPartnership.classrooms.student_payment_required ||
        this.getClassroomPartnership.partnerships.student_payment_required
      );
    },
    showSlashTeacher() {
      return (
        this.getClassroomPartnership.classrooms.age &&
        this.getClassroomPartnership.classrooms.level
      );
    },
    showSlashPartner() {
      return (
        this.getClassroomPartnership.partnerships.age &&
        this.getClassroomPartnership.partnerships.level
      );
    },
    classroomSubject() {
      return this.getClassroomPartnership.classrooms.subject;
    },
    partnerSubject() {
      return this.getClassroomPartnership.partnerships.subject;
    },
    hasPartner() {
      return !!this.getClassroomPartnership.partnerships.name;
    },
  },
  watch: {
    getError(newValue) {
      if (newValue) {
        this.searchValue = "";
        this.$bvModal.msgBoxOk(`${newValue} Please try again.`);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.container-content {
  min-height 28rem
  padding 0
}

.container-table {
  padding: 1rem 2rem 1rem 4rem
  width: 100%
  overflow-x: auto
}

th {
  background-color color-yellow-100
  height 46px
  width 10rem
  text-align center
}

.th-none {
  background-color white
}

.th-top {
  width 20rem
  text-align left
  padding 0 1rem
  height 30px
}

td {
  padding 0 1rem
  border 1px solid color-gray-200
}

.th-left {
  text-align left
  padding-left: 1rem
}

.link--teacher, .login-label {
  display block
  margin 0
  padding 0
  padding 4px 0
  max-width 320px
  width fit-content
}

.link--teacher {
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
}

.th-course-name {
  text-overflow ellipsis
  overflow hidden
  max-width 200px
}

.link {
  cursor pointer
}

.td-border {
  border-left-color: white
  border-left-width: 2px
}

.error {
  width 100%
  padding-top: 4rem
  text-align center
  font font-opensans-bold
}

.button--action-partnerships {
  width auto
  height auto
  padding 8px 70px
  @media (max-width 600px) {
    padding 10px 10px
  }
  @media (max-width 400px) {
    padding 5px 5px
    font-size 12px
  }
}
</style>
