<template>
  <div class="table-container">
    <b-table-simple class="table-item"
                    table-variant="ligth"
                    head-variant="ligth"
                    no-local-sorting>
      <b-thead class="table-head">
        <slot name="tableHeader"/>
      </b-thead>
      <draggable v-model="rowsList"
                 tag="b-tbody"
                 group="rowsList"
                 :handle="handle"
                 class="table-body"
                 @change="$emit('update-list-order', rowsList)">
        <b-tr v-for="row in rows" :key="row.id" class="item-row">
          <slot name="tableBody" :row="row"/>
        </b-tr>
      </draggable>
    </b-table-simple>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "TeacherTableDraggable",
  components: {
    draggable
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    handle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      rowsList: []
    }
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.tableHeader
    }
  },
  watch: {
    rows(newValue) {
      this.rowsList = newValue
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.table-container
  width 100%
  overflow-x auto

  .table-head
    background-color color-yellow-100

</style>
