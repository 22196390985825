export const state = {
  alerts: [],
  loadingAlerts: false,
  loadingAlertsData: false,
  updatingAlertsData: false,
  alertDetail: {
    user_type_id: null,
    message: '',
    color: 'warning',
    starts_at: null,
    ends_at: null
  },
  alertDetailDefault: {
    user_type_id: null,
    message: '',
    color: 'warning',
    starts_at: null,
    ends_at: null
  },
}
