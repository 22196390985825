<template>
  <b-form-group :id="`${id}-${type}-group-${_uid}`" :label="label" :label-for="`${id}-${type}-${_uid}`"
                :valid-feedback="validFeedback" :invalid-feedback="invalidFeedback"
                :state="state">
    <editor v-if="type === 'rich-text'"
            v-model="valueEditor"
            api-key="no-api-key"
            :init="tinymce_init"
            :initial-value="value"/>

    <b-form-textarea v-else-if="type === 'textarea'"
                     :id="`${id}-${type}-${_uid}`"
                     :type="type"
                     v-model="valueInput"
                     class="--secundary area"
                     @change="$emit('change', $event)"
                     @keyup="$emit('keyup', $event)"
                     @keypress="$emit('keypress', $event)"
                     @blur="$emit('blur')"
                     trim/>

    <b-form-input v-else
                  :id="`${id}-${type}-group-${_uid}`"
                  :type="type"
                  v-model="valueInput"
                  class="--secundary"
                  :readonly="readonly"
                  :max-length="maxlength" :state="state"
                  @change="$emit('change', $event)" @keyup="$emit('keyup', $event)"
                  @keypress="$emit('keypress', $event)" @blur="$emit('blur')"
                  :disabled="disabled"
                  trim></b-form-input>

    <div class="invalid-feedback-container" v-if="invalidUrlList.length && validateLinks">
      <div class="error">Field must have a valid url format.</div>
      <ul class="error-content">
        <li class="error-text" v-for="(invalidUrl, index) in invalidUrlList" :key="`invalidUrl_${index}`">
          {{ invalidUrl }}
        </li>
      </ul>
    </div>

  </b-form-group>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'CustomInput',
  components: {
    'editor': Editor
  },
  data: function () {
    return {
      tinymce_init: {
        link_default_target: '_blank',
        link_context_toolbar: true,
        initialValue: this.value,
        height: 500,
        menubar: false,
        plugins: 'lists link image help wordcount',
        toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link | forecolor backcolor',
        toolbar_mode: 'wrap',
        branding: false,
        init_instance_callback: (editor) => {
          editor.on('input', () => {
            this.$emit('input', editor.getBody().innerHTML)
          });
          editor.on('ExecCommand', () => {
            this.$emit('input', editor.getBody().innerHTML)
          });
        },
      },
      valueEditor: null,
      invalidUrlList: []
    }
  },
  props: {
    id: {
      type: String,
      default: 'input'
    },
    value: {
      type: [Number, String]
    },
    error: {
      type: Boolean,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String
    },
    validFeedback: {
      type: String
    },
    invalidFeedback: {
      type: String
    },
    customClass: {
      type: String
    },
    maxlength: {
      type: Number,
      default: 150
    },
    disabled: {
      type: Boolean,
      default: false
    },
    linkList: {
      type: Boolean,
      default: false
    },
    validateLinks: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  created() {
    // Prevent Bootstrap dialog from blocking focusin when adding links in tinymce-vue
    document.addEventListener('focusin', (e) => {
      if (e.target.closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation()
      }
    })
    this.valueEditor = this.value
    this.addLinkList()
  },
  methods: {
    addLinkList() {
      const urlTeacherPortal = process.env.VUE_APP_BASE_URL_TEACHER_PORTAL
      if (this.linkList) {
        this.tinymce_init = {
          ...this.tinymce_init,
          link_list: [
            { title: 'My Tasks Tab', value: `${urlTeacherPortal}/classroom/classroom-id?tab=my-tasks` },
            { title: 'My Students Tab', value: `${urlTeacherPortal}/classroom/classroom-id?tab=my-students` },
            { title: 'Submissions Tab', value: `${urlTeacherPortal}/classroom/classroom-id?tab=submissions` },
            { title: 'Message Board Tab', value: `${urlTeacherPortal}/classroom/classroom-id?tab=message-board` },
            { title: 'Notifications  ', value: `${urlTeacherPortal}/notifications` },
            { title: 'Edit Profile', value: `${urlTeacherPortal}/account/profile` },
            { title: 'My Account', value: `${urlTeacherPortal}/account/my-account` },
            { title: 'Teacher Resources', value: 'https://levelupvillage.com/resources/' },
            { title: 'Student Resources', value: 'https://levelupvillage.com/student-resources/' },
          ]
        }
      }
    },
    validUrl(value) {
      const regex = /href="([^"]+)"/g
      const matches = [...value.matchAll(regex)].map(match => match[1])
      const urlList = []
      matches.map((url) => {
        const domainParts = url.split(".")
        if (!(domainParts?.length >= 2 && domainParts[domainParts?.length - 1].length >= 2)) {
          urlList.push(url)
        }
      })
      this.invalidUrlList = urlList
      this.$emit('on-validate-links', !!this.invalidUrlList.length)
    }
  },
  computed: {
    /**
     * false (denotes invalid state) is great for when there's a blocking or required field. A user must fill in this field properly to submit the form.
     * true (denotes valid state) is ideal for situations when you have per-field validation throughout a form and want to encourage a user through the rest of the fields.
     * null Displays no validation state (neither valid nor invalid)
     * @returns {boolean}
     */
    state() {
      return !this.error ? null : false
    },
    valueInput: {
      get: function () {
        return this.value ?? ''
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    linkList() {
      this.addLinkList()
    },
    valueEditor(value) {
      this.validUrl(value)
      value && this.$emit('input', value)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.invalid-feedback-container
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin 10px 0
  word-break break-word

  .error-text
    text-align left

::v-deep
  .invalid-feedback
    width 100%
    color red
    text-align center
    font font-opensans-bold
    font-size 11px
    margin 10px 0

</style>
