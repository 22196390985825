import {http} from './http'

const resource = 'api/admin'

export default {
  getAlerts(filters) {
    return http.get(`${resource}/alerts`, {params: {filter_by : filters}}).then((response) => {
      return response
    })
  },
  createAlert (data) {
    return http.post(`${resource}/alerts`, data).then(response => {
      return response
    })
  },
  getAlertById (id) {
    return http.get(`${resource}/alerts/${id}`).then(response => {
      return response
    })
  },
  updateAlert (id, data) {
    return http.put(`${resource}/alerts/${id}`, data).then(response => {
      return response
    })
  },
  deleteAlert (id) {
    return http.delete(`${resource}/alerts/${id}`).then(response => {
      return response
    })
  },
  getUserTypes() {
    return http.get(`${resource}/user-types`).then(response => {
      return response
    })
  },
}
