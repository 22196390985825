export const getters = {
  getExchanges: (state) => {
    return state.exchanges
  },
  getPagination: (state) => {
    return state.pagination
  },
  isLoadingExchanges: (state) => {
    return state.loadingExchanges
  },
  loadingExchangesData: (state) => {
    return state.loadingExchangesData
  },
  updatingExchangesData: (state) => {
    return state.updatingExchangesData
  },
  getExchangesDetail: (state) => {
    return state.exchangeDetail
  },
}
