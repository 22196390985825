<template>
  <b-overlay :show="!isPlaying" :opacity="0.25" rounded="sm" class="video-overlay" @click="onClickOverlay">
    <div class="video-player" ref="video-player-wrapper" @click="pauseVid">
      <canvas ref="canvas-video-element" :width="canvasWidth"
              :height="canvasHeight" class="video-canvas"/>
    </div>
    <b-form-input id="range-1"
                  type="range"
                  v-model="currentTime"
                  min="0"
                  :max="player.duration"
                  step="0.01"
                  @change="seek"
                  @input="seek"/>

    <template #overlay>
      <div class="text-center">
        <span v-if="videoLoaded" class="icon-play-video"/>
        <b-spinner v-else-if="!videoLoaded && !videoLoadError" label="Spinning"/>
        <b-button v-else pill variant="primary" class="video-reload-button">
          <b-icon icon="arrow-counterclockwise"/>
          reload video
        </b-button>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { UploadService } from '@/core/services'

export default {
  name: "VideoCustom",
  props: {
    video: {
      type: Object,
      default: function () {
        return {
          url: '',
          video_processed: false
        }
      }
    },
    controls: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      player: document.createElement("video"),
      canvasWidth: 0,
      canvasHeight: 0,
      canvas: null,
      ctx: null,
      canvasUpdater: null,
      isPlaying: false,
      videoLoaded: false,
      videoLoadError: false,
      attempts: 1,
      currentTime: 0,
      currentTimeUpdater: null,
      signedUrl: null,
      signedThumbnailUrl: null,
      videoThumbnail: new Image(),
    }
  },
  async mounted () {
    await this.getS3Video()

    this.player.src = this.signedUrl + '#t=0.2'

    this.canvas = this.$refs['canvas-video-element']
    this.ctx = this.canvas.getContext('2d')

    this.player.onplay = () => {
      this.isPlaying = true;
      this.canvasUpdater = setInterval(this.updateCanvas, 20);
      this.currentTimeUpdater = setInterval(this.updateCurrentTime, 30);
    }

    this.player.onpause = () => {
      this.isPlaying = false
      clearInterval(this.currentTimeUpdater)
      clearInterval(this.canvasUpdater)
    }

    this.player.onended = () => {
      this.isPlaying = false
      clearInterval(this.currentTimeUpdater)
      clearInterval(this.canvasUpdater)
      this.currentTime = this.player.currentTime
    }

    this.player.onloadeddata = () => {
      this.videoLoaded = true

      let videoWrapper = this.$refs['video-player-wrapper']

      let maxWidth = videoWrapper.clientWidth; // Max videoWrapper.clientWidth for the image
      let maxHeight = videoWrapper.clientHeight;    // Max videoWrapper.clientHeight for the image
      let ratio = 0;  // Used for aspect ratio

      let videoWidth = this.player.videoWidth;    // Current image width
      let videoHeight = this.player.videoHeight;  // Current image height

      // Check if the current width is larger than the max
      if (videoWidth > maxWidth) {
        ratio = maxWidth / videoWidth;   // get ratio for scaling image

        videoHeight = videoHeight * ratio;    // Reset this.canvasHeight to match scaled image
        videoWidth = maxWidth;    // Reset this.canvasWidth to match scaled image
      }

      // Check if current videoHeight is larger than maxHeight
      if (videoHeight > maxHeight) {
        ratio = maxHeight / videoHeight; // get ratio for scaling image

        videoHeight = maxHeight;    // Reset this.canvasHeight to match scaled image
        videoWidth = videoWidth * ratio;    // Reset this.canvasWidth to match scaled image
      }

      this.canvasWidth = videoWidth;
      this.canvasHeight = videoHeight;

      this.videoThumbnail.src = this.signedThumbnailUrl + '#t=0.2';

      this.videoThumbnail.onload = () => {
        this.ctx.drawImage(this.videoThumbnail, 0, 0, this.canvas.width, this.canvas.height)
      }
    }

    this.player.onerror = () => {
      this.videoLoaded = false
      this.videoLoadError = true
    }
  },
  methods: {
    async getS3Video () {
      try {
        const url = this.video.url ? this.video.url.split('/') : ''
        const key = url[url.length - 1]

        this.signedUrl = await UploadService.getVideo(key)
        this.signedThumbnailUrl = await UploadService.getVideoThumbnail(key)

        this.videoLoadError = false
        this.videoLoaded = true
      } catch (error) {
        console.error('Signed Url video error: ' + error.message)
        this.signedUrl = null
        this.signedThumbnailUrl = null

        this.videoLoaded = false
        this.videoLoadError = true
      }
    },
    playVid () {
      this.player.play()
    },
    pauseVid () {
      this.player.pause()
    },
    async loadVid () {
      this.videoLoadError = false
      this.videoLoaded = false

      await this.getS3Video()
      this.player.src = this.signedUrl + '#t=0.2'

      this.player.load()
    },
    onClickOverlay () {
      if (this.videoLoaded) {
        this.playVid()
      } else if (this.videoLoadError) {
        this.loadVid()
      }
    },
    seek (value) {
      this.player.currentTime = value
    },
    updateCurrentTime () {
      this.currentTime = this.player.currentTime
    },
    updateCanvas () {
      this.ctx.drawImage(this.player, 0, 0, this.canvas.width, this.canvas.height);
    },
  },
  destroyed () {
    if (this.isPlaying) {
      this.pauseVid()
    }
  },
  watch: {
    video(newValue, onlValue) {
      if (newValue !== onlValue) {
        this.player.pause()
          this.getS3Video().then(() => {
            this.player.src = this.signedUrl + '#t=0.2'
            this.loadVid()
            this.currentTime = 0
        })
      }
    }
  }
}

</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.video-overlay
  border-radius 4px
  overflow hidden
  margin 0 auto
  width 334px
  background-color color-black-100

  .video-player
    width 334px
    height 395px
    background-color color-black-200
    position relative
    display block

    .video
      position absolute
      top 0
      left 0
      width 334px
      height 395px

    .video-canvas
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)

    &-control
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      cursor pointer
      width auto
      border 0
      background-color transparent

      .icon-play-video
        color white

        &:before
          font-size 3rem

  .icon-play-video
    font-size 4em

    &::before
      color color-gray-100

  .spinner-border
    width 4em
    height 4em
    border-color color-gray-100
    border-right-color transparent

  .video-reload-button
    padding 10px 0
    font-size 1em
    width 180px

::v-deep
  .custom-range
    position absolute
    width 90%
    left 50%
    bottom 15px
    margin-left -45%
    z-index 11
</style>
