<template>
  <section class="student-table-container">
    <b-table id="student-report-table"
             responsive
             hover
             :fields="studentFilds"
             sort-icon-left
             :items="studentsList"
             table-variant="ligth"
             head-variant="ligth"
             :per-page="perPage"
             :current-page="currentPage"
             no-local-sorting
             @sort-changed="(arg) => $emit('sort-changed', arg)"
             @row-clicked="showStudentDetails">
      <template #cell(student)="data"><span class="full-name-teacher">{{ data.item.username }}</span></template>
      <template #cell(classroom)="data">
        <a class="classroom-name" @click="goPartnerships(data.item.classroomCode)">
          {{ data.item.classroom }}</a>
      </template>
      <template #cell(purchase_date)="data">{{ setDate(data.item.purchaseDate) }}</template>
      <template #cell(subject)="data">{{ data.item.subject }}</template>
      <template #cell(country)="data">{{ data.item.country }}</template>
      <template #cell(amount_tokens)="data">{{ data.item.amount }}</template>
    </b-table>
    <modal-token-expiration ref="student-details-modal"
                            :is-student-reports="true"
                            :amound-paid="tokenData.amount"
                            :purchase-date="tokenData.purchaseDate"
                            :subject="tokenData.subject"
                            :student="tokenData.username"/>
  </section>
</template>

<script>
import {CourseMixins, UtilsMixins, PartnershipsMixins} from '../Mixins'
import ModalTokenExpiration from './ModalTokenExpiration.vue'
import moment from "moment"

export default {
  name: 'StudentReportTable',
  mixins: [CourseMixins, UtilsMixins, PartnershipsMixins],
  components: {
    ModalTokenExpiration
  },
  props: {
    studentsList: {
      type: Array,
      default: function() {
        return []
      }
    },
    perPage: {
      type: [Number, String]
    },
    currentPage: {
      type: [Number, String]
    }
  },
  data() {
    return {
      studentFilds: [
        {key: 'student', label: 'STUDENT', sortable: true},
        {key: 'classroom', label: 'CLASS', sortable: true},
        {key: 'purchase_date', label: 'PURCHASE DATE', sortable: true},
        {key: 'subject', label: 'SUBJECT', sortable: true},
        {key: 'country', label: 'COUNTRY', sortable: true},
        {key: 'amount_tokens', label: 'AMOUNT PAID', sortable: true}
      ],
      tokenData: {}
    }
  },
  methods: {
    goPartnerships(id) {
      if (id) {
        this.fetchPartnership(id)
        this.$router.push({name: 'Partnership'})
      }
    },
    setDate(date) {
      return moment(date).format('M/D/YYYY')
    },
    showStudentDetails(selected_token) {
      this.tokenData = selected_token
      this.$refs['student-details-modal'].open()
    },
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.student-table-container
  width 100%

.full-name-teacher,
.classroom-name
  text-overflow ellipsis
  overflow hidden
  max-width 180px
  white-space nowrap
  display block
  @media (max-width: 1400px)
    max-width 120px

.classroom-name
  cursor pointer
  font font-opensans-bold
  width fit-content

</style>
