export const state = {
  subjects: [],
  loadingSubjects: true,
  loadingSubjectsData: true,
  subjectDetail: {
    id: null,
    name: '',
    type: '',
    number_of_tokens: 0,
    created_at: '',
    updated_at: ''
  },
  subjectDetailDefault: {
    id: null,
    name: '',
    type: '',
    number_of_tokens: 0,
    created_at: '',
    updated_at: ''
  },
}
