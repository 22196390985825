<template>
  <div class="form-group">
    <label v-if="label" :for="`textarea-${_uid}`">{{ label }}</label>

    <editor v-if="richText" api-key="no-api-key" :init="tinymce_init" :initial-value="value"/>

    <b-form-textarea v-else
                     :id="`textarea-${_uid}`" v-model="inputValue" :class="customClassInput"
                     :placeholder="placeholder" :disabled="disabled" :state="state" :rows="rows"
                     :aria-describedby="`textarea-live-feedback-${_uid}`" :maxlength="maxlength"
                     @change="$emit('change', $event)"
                     @keyup="$emit('keyup', $event)"
                     @keypress="$emit('keypress', $event)"
                     @blur="$emit('blur')"
                     @focus="$emit('focus')"
                     @input="onInput"/>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`textarea-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`textarea-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {InputMixins} from "@/modules/panel/Mixins"

export default {
  name: "TextArea",
  data: function () {
    return {
      tinymce_init: {
        initialValue: this.value,
        height: 500,
        menubar: false,
        plugins: 'lists link image help wordcount',
        toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
        toolbar_mode: 'wrap',
        branding: false,
        init_instance_callback: (editor) => {
          editor.on('input', () => {
            this.$emit('input', editor.getBody().innerHTML)
          });
          editor.on('ExecCommand', () => {
            this.$emit('input', editor.getBody().innerHTML)
          });
        },
      }
    }
  },
  mixins: [InputMixins],
  components: {
    'editor': Editor
  },
  props: {
    maxlength: {
      type: [Number, String],
      default: 150
    },
    customClass: {
      type: String,
    },
    rows: {
      type: Number,
      default: 5
    },
    richText: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
  },
  computed: {
    customClassInput() {
      const className = {
        '': true
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

</style>

