<template>
  <div class="wrapper-actions">
    <b-overlay :show="loading"
               spinner-medium
               rounded="sm">
      <b-col class="container-actions">
        <select-dropdown :options="options"
                         v-model="subjectSelected"
                         @inputObject="handleDropdown"
                         class="--secundary"
                         value-field="name"/>
        <search-input class="search-action"
                      v-model="search"
                      placeholder="SEARCH"
                      @on-search="handlerSearch"/>
      </b-col>
      <table-draggable @update-list-order="onUpdateOrder"
                       :rows="actionList"
                       handle=".handle"
                       draggable>
        <template #tableHeader>
          <th class="column" :class="'column-' + field.key" v-for="field in fields" :key="field.key">{{field.label}}</th>
        </template>
        <template v-slot:tableBody="{row}">
          <td class="table-row column-order order-container handle" :style="{'pointer-events': isGetListWithSearch ? 'none' : 'auto'}">
            <b-icon-grip-horizontal class="icon-grip handle"/>
          </td>
          <td class="table-row"><span class="a-title a-title--name">{{row.group && row.group.name}}</span></td>
          <td class="table-row"><span class="a-title a-title--name">{{row.subject && row.subject.name}}</span></td>
          <td class="table-row"><span class="a-title a-title--name">{{triggerName(row.trigger)}}</span></td>
          <td class="table-row"><span class="a-title a-title--name">{{row.task}}</span></td>
          <td class="table-row">
            <a class="a-title--name a-title--link"
               @click="openAdminDetail(row.id)">
               {{ row.to_do }}</a>
          </td>
          <td class="table-row column-show">
            <p @click="updateStatus(row)"
               class="eye-icon"
               :class="row.active ? 'fa fa-eye' : 'fa fa-eye-slash'"></p>
          </td>
        </template>
      </table-draggable>
      <b-button @click="openAdminDetail(null)" class="button button--resize">Add New Action</b-button>
    </b-overlay>
    <modal-action-detail ref="modalActionDetail"
                         :action-id="actionSelectedId"
                         :triggers="triggers"
                         @action-saved="updateActionsList"/>
  </div>
</template>

<script>
import { AlertMixins, UtilsMixins } from '../Mixins'
import { SearchInput, SelectDropdown } from '@/core/components'
import ModalActionDetail from './ModalActionDetail'
import { ActionsItemService } from '@/core/services'
import TableDraggable from '@/core/components/TableDraggable/TableDraggable'

export default {
  name: 'ActionItems',
  mixins: [AlertMixins, UtilsMixins],
  components: {
    SearchInput,
    ModalActionDetail,
    SelectDropdown,
    TableDraggable,
  },
  data() {
    return {
      fields: [
        {
          key: 'order',
          label: 'ORDER'
        },
        {
          key: 'group',
          label: 'GROUP'
        },
        {
          key: 'subject',
          label: 'SUBJECT'
        },
        {
          key: 'trigger',
          label: 'TRIGGER'
        },
        {
          key: 'task',
          label: 'TASK'
        },
        {
          key: 'to_do',
          label: 'TO-DO'
        },
        {
          key: 'show',
          label: 'SHOW'
        }
      ],
      options: [{ name: 'All', id: 'all'}],
      actionList: [],
      subjectSelected: 'All',
      filterSelectedObject: {},
      newActionList: '',
      isGetListWithSearch: false,
      search: '',
      loading: false,
      actionSelectedId: null,
      triggers: [
        {name: 'New Classroom', id: 'new_classroom'},
        {name: 'Classroom Paired', id: 'classroom_paired'},
        {name: 'Partner Create Video', id: 'partner_create_video'},
        {name: 'Pending Student Video', id: 'pending_student_video'},
        {name: 'Student Due Date', id: 'student_due_date'},
        {name: 'Partner Student Create', id: 'partner_student_create_trigger'},
        {name: 'Partner Student Respond', id: 'partner_student_respond_trigger'},
        {name: 'Group Names Approved', id: 'groups_name_approved'}
      ]
    }
  },
  async mounted() {
    this.loading = true
    await this.getActions()
    await this.fetchSubjects()
    await this.fetchStudentGroupType()
    this.options = [
      { name: 'All', id: 'all'},
      ...this.getCourseStudentGroupTypes,
      ...this.getSubjects
    ]
    this.newActionList = [...this.actionList]
  },
  methods: {
    getActions(optionSelected) {
      this.loading = true
      let data = {}

      if (optionSelected?.type) {
        data.subject_id = optionSelected.id
      }
      if (optionSelected?.slug) {
        data.group_id = optionSelected.id
      }
      if (this.search) {
        data.search = this.search
      }
      ActionsItemService.getActionsItem(data).then((res) => {
        this.actionList = res.data
        if (!this.actionList.length && optionSelected) {
          this.$bvModal.msgBoxOk('No matches found.').then(() => {
            this.search = ''
            this.subjectSelected = 'All'
            this.getActions()
          })
        }
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Failed to get actions.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      }).finally(() => {
        this.loading = false
        this.isGetListWithSearch = !!this.search
      })
    },
    updateActionsList() {
      this.getActions(this.filterSelectedObject)
    },
    onUpdateOrder(newList) {
      this.loading = true
      const newActionListOrder = newList.map((item, idx) => {
        return {
          id: item.id,
          order: idx + 1
        }
      })
      ActionsItemService.updateActionOrder({actions: newActionListOrder}).then(() => {
        this.updateActionsList()
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Failed updated order.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      })
    },
    handlerSearch(e) {
      this.filterSelectedObject = 'All'
      this.subjectSelected = 'All'
      this.getActions(e)
    },
    handleDropdown(optionSelected) {
      this.search = ''
      this.filterSelectedObject = optionSelected
      this.getActions(optionSelected)
    },
    updateStatus(action) {
      ActionsItemService.updateActionStatus(action.id, {active: !action.active}).then(() => {
        this.updateActionsList()
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Failed updated status.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      })
    },
    addAdmin() {
      this.$refs.modalActionDetail.openModalAction()
    },
    openAdminDetail(actionId) {
      if (actionId) {
        this.actionSelectedId = actionId
      } else {
        this.actionSelectedId = null
      }
      this.$nextTick(() => this.$refs.modalActionDetail.openModalAction())
    },
    setRoles(roles) {
      const adminRoles = roles.map((rol) => rol.name).join(',')
      return adminRoles
    },
    triggerName(triggerSlug) {
      const trigger = this.triggers.find(trigger => trigger.id === triggerSlug)
      return trigger ? trigger.name : ''
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.container-actions
  display flex
  justify-content space-between
  margin-bottom 10px

  @media(max-width 800px)
    display block

  .--secundary
    max-width 400px
    margin-right 10px
    margin-top 10px

  .search-action
    margin-top 10px

.button--resize
  padding 5px 40px
  margin-top 10px
  height auto !important

::v-deep
  #actions-table
    min-width 600px
    thead
      tr
        th:first-child
          max-width 40px
          padding 0 10px !important

    tbody
      tr
        td:first-child
          background-color color-yellow-100
          padding 0.2rem 1rem !important

.eye-icon
  cursor pointer

.order-container
  display flex
  justify-content center
  background-color color-yellow-100

  .icon-grip
    font-size 40px

.wrapper-actions
  .column-order
    padding-right 10px !important
    padding-left 10px !important

.handle
  cursor grab

.a-title--name, .a-title--link
  text-overflow ellipsis
  overflow hidden
  white-space nowrap
  display block
  font-weight bold
  max-width 300px

.a-title--link
 cursor pointer

</style>
