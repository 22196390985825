<template>
  <div>
    <div class="top">
      <a class="mx-2" href="#/PanelAdmin/pricing"><i class="icon-caret-left"></i> Back</a>
    </div>
    <b-row class="my-1">
      <b-col cols="12">
        <b-overlay :show="loadingExchangesData || loadingExchange" spinner-medium rounded="sm">
          <div class="container-form">
            <h2 class="title">Country Exchange Price</h2>
            <h6 class="subtitle"><i>Require fields *</i></h6>

<!--            <b-form-input-->
<!--              v-model="$v.getExchangesDetail.name.$model"-->
<!--              type="text"-->
<!--              placeholder="Bundle Name *"-->
<!--              :class="{ 'form-group&#45;&#45;error': $v.getExchangesDetail.name.$error, 'format-input': 'format-input' }"/>-->
<!--            <div class="error" v-if="!$v.getExchangesDetail.name.required && $v.getExchangesDetail.name.$dirty">-->
<!--              Bundle Name field is required-->
<!--            </div>-->
            <b-form-group>
              <select-dropdown
                :options="getSubjects"
                v-model="$v.getExchangesDetail.subject.id.$model"
                :class="{ 'form-group--error': $v.getExchangesDetail.subject.id.$error, 'format-input': 'format-input' }"
                placeholder="Subject *"
                value-field="id">
              </select-dropdown>
              <div class="error" v-if="!$v.getExchangesDetail.subject.id.required && $v.getExchangesDetail.subject.id.$dirty">
                Subject field is required
              </div>
            </b-form-group>

            <b-form-group>
              <select-dropdown
                :options="countries"
                v-model="$v.getExchangesDetail.country_id.$model"
                :class="{ 'form-group--error': $v.getExchangesDetail.country_id.$error, 'format-input': 'format-input' }"
                placeholder="Exchange country *"
                value-field="id">
              </select-dropdown>
              <div class="error" v-if="!$v.getExchangesDetail.country_id.required && $v.getExchangesDetail.country_id.$dirty">
                Exchange country field is required
              </div>
            </b-form-group>

            <number
              v-if="!loadingExchangesData"
              id="input-6"
              ref="vue-number-format"
              v-model="$v.getExchangesDetail.price.$model"
              v-bind="number"
              placeholder="Price of tokens *"
              class="form-control"></number>

            <div class="error"
                 v-if="!$v.getExchangesDetail.price.required && $v.getExchangesDetail.price.$dirty">
              Price of tokens is required
            </div>
            <div class="error"
                 v-if="!$v.getExchangesDetail.price.decimal && $v.getExchangesDetail.price.$dirty">
              Price of tokens must be a numeric value
            </div>

            <br><br>

            <section class="container-button">
              <b-button type="button" @click="goBack()" class="button b-ligth">Cancel</b-button>
              <b-button type="button" @click="OpenDeleteModal" class="button primary --sm">Delete</b-button>
              <b-button type="button" @click="saveExchange" class="button primary --sm">Save</b-button>
            </section>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
    <modal ref="deleteExchange" modal-class="delete" id="delete-exchange">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title text-center">DELETE {{ getExchangesDetail.name }} ?</h2>
        <p class="text-center">
          Are you sure?
        </p>
      </template>
      <template #footer>
        <b-button class="button b-ligth" @click="deleteExchange"> Delete</b-button>
        <b-button class="button" @click="ClosDeleteMModal"> cancel</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {SelectDropdown, Modal} from '@/core/components'
import {ExchangesMixins, UtilsMixins} from '../Mixins'
import {ExchangeService, CountriesService} from '@/core/services'
import {required, decimal} from 'vuelidate/lib/validators'
import { number } from "@coders-tm/vue-number-format";

export default {
  name: 'ExchangeDetail',
  components: {
    SelectDropdown,
    Modal,
    number,
  },
  mixins: [ExchangesMixins, UtilsMixins],
  data() {
    return {
      number: {
        decimal: ".",
        separator: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
        nullValue: 0,
      },
      loadingExchange: false,
      subjectSelected: '',
      countries: []
    }
  },
  validations: {
    getExchangesDetail: {
      country_id: {
        required,
      },
      price: {
        required,
        decimal
      },
      subject: {
        id: {
          required
        }
      }
    }
  },
  async mounted() {
    try {
      this.loadingExchange = true
      await this.resetExchangeDetail()
      if (this.$route.params.id) {
        await this.fetchExchangeDetail(this.$route.params.id)
      }
      await CountriesService.getCountries().then(({data}) => {
        this.countries = data
      })
      this.fetchSubjects()
    } catch (e) {
      const errorMessage = e.message ? e.message : 'Error loading exchange.'

      await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
    } finally {
      this.loadingExchange = false
    }
  },
  methods: {
    OpenDeleteModal() {
      this.$refs.deleteExchange.showModal()
    },
    ClosDeleteMModal() {
      this.$refs.deleteExchange.hideModal()
    },
    goBack() {
      this.$router.push({name: 'Pricing'})
    },
    async deleteExchange() {
      try {
        this.loadingExchange = true
        await ExchangeService.deleteExchange(this.$route.params.id)

        await this.$router.push({name: 'Pricing'})
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while deleting exchange.'

        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loadingExchange = false
      }
    },
    async saveExchange() {
      this.$v.getExchangesDetail.$touch()
      if (this.$v.getExchangesDetail.$anyError || this.updatingExchangesData) {
        return
      }
      this.loadingExchange = true

      const data = {
        country_id: this.getExchangesDetail.country_id,
        price: this.getExchangesDetail.price,
        subject_id: this.getExchangesDetail.subject.id,
        is_enabled: true,
      }

      if (this.$route.params.id) {
        try {
          await this.updateExchangeDetail({id:this.$route.params.id, data})

          await this.$bvModal.msgBoxOk('Successful Updated!')
          await this.goBack()
        } catch (e) {
          let errorMessage
          if (e.message.includes("already exists")) {
            errorMessage = `This subject and country already has an exchange price. Please select a different country, or edit the existing exchange price`
          } else {
            errorMessage = e.message ? e.message : 'Error while loading exchange'
            errorMessage += '. Please try again.'
          }

          await this.$bvModal.msgBoxOk(errorMessage)
        } finally {
          this.loadingExchange = false
        }
      } else {
        try {
          await ExchangeService.createExchangePrice(data)

          await this.$bvModal.msgBoxOk('Successful Created!')
          await this.goBack()
        } catch (e) {
          let errorMessage
          if (e.message.includes("already exists")) {
            errorMessage = `This subject and country already has an exchange price. Please select a different country, or edit the existing exchange price`
          } else {
            errorMessage = e.message ? e.message : 'Error while loading exchange'
            errorMessage += '. Please try again.'
          }

          await this.$bvModal.msgBoxOk(errorMessage)
        } finally {
          this.loadingExchange = false
        }
      }
    },
  },
  async destroyed() {
    await this.resetExchangeDetail()
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

a {
  color: color-blue-100
  font font-opensans-bold
  font-size: 17px
  text-decoration none
  margin-top: 0
  margin-bottom: 1rem

  .icon-caret-left {
    color: color-blue-100
  }
}

.container-form {
  width: 80%
  margin: 1px auto
}

.container-task {
  width: 100%
  margin: 1px auto
}

.container-button {
  padding: 1rem 0
  padding-top: 0
  width 90%
  margin 0

  .button {
    margin 0 2% 0 3%
    margin-top: 1rem
  }

  .primary.--sm {
    width auto
  }
}

.title {
  font font-opensans-bold
  font-size: 24px
  color: color-black-200
}

.subtitle {
  font-size 14px
  color color-black-300
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin-bottom: 10px
}

.order-input {
  height 3rem
  width 2.6rem
  text-decoration: none
  text-align: center
  cursor: pointer
  padding-left: 11px
  border: 2px solid color-gray-200
  border-radius: 4px
}

.top {
  width: 105%
  margin: 0rem 0rem 1rem -1.5rem
  padding-bottom: .5rem
  border-bottom: 2px solid red
  border-color: color-gray-400
}

.create-task-button {
  width: 100% !important;
  max-width: 300px !important;
}

.modal-title {
  display flex
  justify-content center
  width 100%
}
</style>

<style lang="stylus">
#delete-exchange
  .modal-content
    .modal-body
      width auto
</style>
