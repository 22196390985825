<template>
  <div class="course-card" v-if="course.published">
    <div class="course-card__header">
      <div class="course-card__title">{{ course.name.course_full_name }}</div>
    </div>
    <div class="course-card__body">
      <div class="course-card__info">
        <div class="course-card__info-item">
          Estimated Duration: {{ course.weeks }} <strong>weeks</strong>
        </div>
      </div>
      <div class="course-card__btns">
        <b-button @click="$emit('on-add-course-content', course)"
                  class="button b-ligth">
                  ADD COURSE
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CourseCard",
  data() {
    return {}
  },
  props: {
    course: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.course-card__title
  overflow hidden
  width 250px
  text-overflow ellipsis

.course-card
  min-height 200px
  background-color #fff
  color color-black-200
  border-radius(4px)
  border 1px solid color-purple-500
  box-shadow(course-card-shadow)
  flexbox(flex)
  align-items(center)
  justify-content(center)
  flex-direction(column)

  &__item
    font font-opensans-regular
    font-size 13px
    color color-black-200
    margin 0
    margin-bottom 6px

    span
      font font-opensans-bold
      font-size 13px

  &__title
    font font-opensans-bold
    font-size 15px
    padding 0
    margin 0

  &__subtitle
    font font-opensans-bold
    font-size 15px
    padding 0
    margin 0
    margin-bottom 16px


  &__status
    color #3D3D3D
    font-size 15px

  &__completion
    margin-top 16px
    margin-bottom 16px

  &__btns
    padding 18px 0
    flexbox(flex)
    align-items(center)
    justify-content(center)

    button
      letter-spacing 0

  &__header
    background-color #F5F6FF
    border-radius(4px 4px 0px 0px)
    padding 15px 17px
    width 100%

  &__body
    padding 15px 19px 30px 19px
    flexbox(flex)
    flex-direction(column)
    justify-content(space-between)
    width 100%

  &__info
    &-item
      font-size 13px
      color color-black-200
      margin-bottom 7px
      &:last-child
        margin-bottom 0px


@media (min-width: 1710px)
  ::v-deep
    .btn-common
      min-width 146px
      margin-bottom 11px
      padding 6px
      font font-opensans-bold
      letter-spacing 0
      font-size 14px
      line-height 18px

@media (min-width: 576px)
  ::v-deep
    .btn-common
      min-width 146px
      margin-bottom 11px
      padding 6px
      font font-opensans-bold
      letter-spacing 0
      font-size 11px
      line-height 18px

@media (max-width: 575px)
  .course-card__btns
    flex-direction column

    button
      margin-right 0 !important
      margin-bottom 10px
      width 100%
</style>
