<template>
  <div :class="customClassInput">
    <input :id="'avatar-input-file-' + _uid" ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged">
    <b-avatar v-if="value" variant="info" :src="value" square size="100%" class="upload-avatar-preview" button
              @click="$emit('click-on-avatar')"/>
    <label v-else :for="`avatar-input-file--${_uid}`" class="form-upload-file">
      <span v-if="placeholder" class="form-file-text">{{ placeholder }}</span>
      <button type="button" class="btn-common btn-input-file" @click="$emit('click-on-avatar')">
        {{ label }}<span class="icon-upload"></span>
      </button>
    </label>

    <div class="invalid-feedback" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`file-avatar-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {InputMixins} from "@/modules/panel/Mixins"

export default {
  name: "UploadImageAvatar",
  mixins: [InputMixins],
  props: {
    customClass: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Upload File'
    },
    preview: {
      type: String,
    },
  },
  methods: {
    openUploadFile () {
      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.$emit('File:loaded', files)
    }
  },
  computed: {
    customClassInput () {
      const className = {
        'form-upload': true,
        'is-invalid': this.state === false,
        'form-upload-error': this.state === false,
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/mixins.styl"

.btn-input-file
  background-color color-blue-100
  border 3px solid color-blue-200
  border-radius(4px)
  font font-opensans-bold
  font-size 12px
  letter-spacing 1.6px
  color color-gray-700
  text-align center
  flexbox(flex)
  align-items(center)
  justify-content(center)
  padding 0 10px
  min-height unset
  height 25px
  text-decoration none
  text-transform capitalize

  &:focus,
  &:hover
    box-shadow none

  .icon-upload
    padding-left 5px

.form-upload
  margin-bottom 10px

  &-file
    display flex
    justify-content space-between
    align-items center
    width 100%
    border 2px solid color-gray-400
    background-color color-gray-500
    padding 0 8px 0 20px
    min-height 40px
    border-radius 7px
    min-width 300px

    .form-file-text
      font font-opensans-regular
      font-size 14px
      color color-black-200

  &-error .form-upload-file
    border: 2px solid color-red-error

    &:focus
      box-shadow(transparent-shadow !important)

.upload-avatar-preview
  border-radius 7px !important
  border: 2px solid color-gray-400 !important
  background-color: color-gray-500 !important

  &:focus
    border-radius 7px !important
    border: 2px solid color-gray-400 !important
    background-color: color-gray-500 !important
    box-shadow(transparent-shadow-color-16 !important)

</style>
