<template>
  <div class="login-form">
    <img
      :src="require('@/assets/images/luv-logo-standard.png')"
      alt="LUV"
      class="login-form-img"
    />
    <h1 class="login-form-title">Admin Log In</h1>
    <form @submit.prevent=""  class="container-inputs">
      <div class="login-form-separator-bar"></div>
      <input
        v-model="$v.form.email.$model"
        name="email"
        type="email"
        class="form-control form-input"
        :class="{ 'form-group--error': $v.form.email.$error }"
        placeholder="Email"
      />
      <div class="error" v-if="!$v.form.email.required && $v.form.email.$dirty"> Email field is required</div>
      <b-input
        v-model="$v.form.password.$model"
        name="password"
        type="password"
        class="form-control form-input"
        :class="{ 'form-group--error': $v.form.password.$error }"
        placeholder="Password"
      />
    <div class="error" v-if="!$v.form.password.required && $v.form.password.$dirty"> Password field is required</div>
    <div class="login-form-extra-fields">
      <b-form-checkbox  v-model="form.keep" class="check">Keep me signed in</b-form-checkbox>
      <a href="/#/forgot-password" class="forgot-password">Forgot Password?</a>
    </div>
    <div class="login-form-actions text-center">
      <b-button @click="onLogin" :disabled="$v.form.$invalid" class="btn-primary">Log In</b-button>
    </div>
    </form>
  </div>
</template>

<script>
import { AuthService } from '@/core/services'
import { AppStorage } from '@/core/utils'
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'LoginPages',
  data () {
    return {
      form: {
        email: null,
        password: null,
        keep: false
      },
    }
  },
  validations: {
    form:{
      email: {
        required,
      },
      password: {
        required,
      }
    }
  },
  mounted() {
    this.checkBrowserCompatibility()
  },
  methods: {
    onLogin () {
      this.$v.form.$touch()
      if(this.$v.form.$AnyError){
        return
      }
      AuthService.login(this.form.email, this.form.password)
        .then((response) => {
          const isCustomerServiceAgent = response.data.roles.find(rol => rol.slug === 'customer-service-agent')
          AppStorage.setUser(response.data)
          if(this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            if (isCustomerServiceAgent) return this.$router.push({ name: 'TeacherContent' })
            this.$router.push({ name: 'CoursesList' })
          }
        }).catch((e) => {
          const errorMessage = e.message ? e.message : 'Error while loading course.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        })
    },
    checkBrowserCompatibility() {
      const supportedBrowsers = {
        windows: {
          id: 'win',
          browser: "Chrome"
        },
        macOs:{
          id: 'mac',
          browser: "Chrome or Safari"
        },
        android: {
          id: 'android',
          browser: "Chrome"
        },
        ios: {
          id: [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ],
          browser: "Safari"
        }
      }

      // Get the user's operating system
      const operatingSystem = navigator.platform.toLowerCase()

      const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
      const isEdge = isChrome && (navigator.userAgent.indexOf("Edg") != -1)
      const isBrave = navigator?.brave?.isBrave?.name === "isBrave"
      const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                       navigator.userAgent &&
                       navigator.userAgent.indexOf('CriOS') === -1 &&
                       navigator.userAgent.indexOf('FxiOS') === -1

      if (operatingSystem.includes(supportedBrowsers.windows.id) && !isChrome || isEdge || isBrave) {
        this.showPopupBrowserCompatibility(supportedBrowsers.windows.browser)
        return
      }
      if (operatingSystem.includes(supportedBrowsers.macOs.id) && !(isChrome || isSafari) || isEdge) {
        this.showPopupBrowserCompatibility(supportedBrowsers.macOs.browser)
        return
      }
      if (operatingSystem.includes(supportedBrowsers.android.id) && !isChrome || isEdge) {
        this.showPopupBrowserCompatibility(supportedBrowsers.android.browser)
        return
      }
      if (supportedBrowsers.ios.id.includes(navigator.platform) && !isSafari) {
        this.showPopupBrowserCompatibility(supportedBrowsers.ios.browser)
        return
      }
    },
    showPopupBrowserCompatibility(browser) {
      this.$bvModal.msgBoxOk(`In order to have the best experience on Level Up Village, please switch to ${browser}.`, {
        size: 'sm',
        dialogClass: 'modal-message-box',
        centered: true
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';

.login-form {
  width 100%
  height 90%
  display flex
  flex-direction column
  align-items center
  justify-content center

  &-img {
    width 240px
    height 130px
    object-fit cover
  }

  &-title {
    font-family 'Open Sans', sans-serif
    font-weight 900
    font-size 20px
    color color-blue-100
  }

  &-separator-bar {
    width 52%
    max-width 420px
    margin-bottom 12px
    position relative
    background-color color-purple-400
    height 7px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 113px;
      background-color: color-green-100
    }
  }
}
.container-inputs {
  display flex;
  flex-direction column
  justify-content center
  align-items center
  width 100%
}

.form-control {
  margin 0.5rem
  padding 0.5rem
  width 50%
  max-width 400px
  color color-blue-100;
  border 2px solid color-blue-100
  background color-gray-300 0% 0% no-repeat padding-box
  border-radius 4px
  opacity: 1
  &:focus {
    outline: color-blue-300
    }
  }

  .btn-primary {
    color color-gray-300
    width 200px
    height 40px
    font-weight bold
    text-transform uppercase
    background color-blue-100 0% 0% no-repeat padding-box
    border 3px solid color-blue-200
    border-radius 4px
    opacity 1
  }

  .check {
    text-align left
    color color-black-200
  }

.error {
  width 100%
  text-align center
  font font-opensans-bold
  font-size: 11px
  color red
}

.login-form-extra-fields {
  width 100%
  max-width 400px
  margin auto
  display flex
  justify-content space-between
  font font-opensans-bold
  margin 20px 0

  .forgot-password {
    color color-black-200
  }
}

@media (max-width 600px) {
  .login-form-extra-fields {
    flex-direction column
    align-items center

    .forgot-password {
      margin-top 20px
    }
  }
}
</style>
