<template>
  <div>
    <div class="top">
      <a class="mx-2" href="#/PanelAdmin/alerts"><i class="icon-caret-left"></i> Back</a>
    </div>
    <h2 class="title">Alerts</h2>
    <h6 class="subtitle"><i>Require fields *</i></h6>
    <h2 class="label">Show alert for</h2>
    <custom-radio v-model="getAlertDetail.user_type_id" class="radio-buttons-container" :options="userDropdwn"/>
    <div class="datepiker-container">
      <div class="start-date-container">
        <h2 class="label">Start date</h2>
        <b-form-datepicker v-model="$v.startDate.$model"
                           class="--secundary datepicker-custom"
                           id="date-input-t"
                           :class="{ 'datepicker-custom--error': $v.startDate.$error}"
                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                           placeholder="Select"
                           :min="minStartDate"
                           locale="en"/>
        <div class="error" v-if="!$v.startDate.required && $v.startDate.$dirty">
          Field is required
        </div>
      </div>

      <div class="end-date-container">
        <h2 class="label">End date</h2>
        <b-form-datepicker v-model="$v.endDate.$model"
                           class="--secundary datepicker-custom"
                           :class="{ 'datepicker-custom--error': $v.endDate.$error}"
                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                           id="date-input-t2"
                           placeholder="Select"
                           :min="startDate"
                           locale="en"/>
        <div class="error" v-if="!$v.endDate.required && $v.endDate.$dirty">
          Field is required
        </div>
      </div>
    </div>
    <div class="timepiker-container">
      <div class="start-time-container">
        <h2 class="label">Start time</h2>
        <b-form-timepicker v-model="$v.startTime.$model"
                           class="--secundary datepicker-custom"
                           :class="{ 'datepicker-custom--error': $v.startTime.$error}"
                           locale="en"
                           placeholder="Select"/>
        <div class="error" v-if="!$v.startTime.required && $v.startTime.$dirty">
          Field is required
        </div>
        <div class="error" v-if="$v.startTime.required && !$v.startTime.invalidTime && $v.startTime.$dirty">
          The starts date must be before the ends date.
        </div>
      </div>
      <div class="end-time-container">
        <h2 class="label">End time</h2>
        <b-form-timepicker v-model="$v.endTime.$model"
                           class="--secundary datepicker-custom"
                           :class="{ 'datepicker-custom--error': $v.endTime.$error}"
                           locale="en"
                           placeholder="Select"/>
        <div class="error" v-if="!$v.endTime.required && $v.endTime.$dirty">
          Field is required
        </div>
        <div class="error" v-if="$v.endTime.required && !$v.endTime.invalidTime && $v.endTime.$dirty">
          The ends date must be after the start date.
        </div>
      </div>
    </div>
    <h2 class="label">Background color</h2>
    <select-dropdown :options="colorDropdwn"
                     v-model="getAlertDetail.color"
                     class="--secundary color-dropdown"
                     placeholder="Select color"
                     value-field="value"/>
    <custom-input v-model="getAlertDetail.message"
                  v-if="showCustomInputEditor"
                  id="create-message"
                  type="rich-text"
                  label="Message"
                  :maxlength="20000"/>
    <h2 v-show="!!getAlertDetail.message" class="label label-centered">Preview</h2>
    <b-alert :show="!!getAlertDetail.message"
             :variant="getAlertDetail.color"
             class="alert-container"
             dismissible>
      <div class="alert-content" v-html="getAlertDetail.message"/>
    </b-alert>
    <b-row>
      <b-col class="pb-2">
        <div class="container-buttons">
          <b-button @click="goBack" class="button button-margin">Cancel</b-button>
          <b-button @click="onSave" class="button" :class="{'button-margin': !!alertId}">Save</b-button>
          <b-button @click="onDelete" class="button" v-if="!!alertId">Delete</b-button>
        </div>
      </b-col>
      <br>
    </b-row>
    <b-overlay :show="loadingAlertsData || isLoading" no-wrap spinner-medium rounded="sm"/>
  </div>
</template>

<script>
import { SelectDropdown, CustomInput, CustomRadio } from '@/core/components'
import { AlertsService } from '@/core/services'
import { AlertMixins } from '../Mixins'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'AlertDetail',
  mixins: [AlertMixins],
  components: {
    SelectDropdown,
    CustomInput,
    CustomRadio
  },
  data() {
    return {
      userDropdwn: [],
      userSelected: null,
      colorDropdwn: [
        { name: 'Blue', value: 'primary'},
        { name: 'Gray', value: 'secondary'},
        { name: 'Green', value: 'success'},
        { name: 'Red', value: 'danger'},
        { name: 'Yellow', value: 'warning'},
        { name: 'Light blue', value: 'info'},
        { name: 'Dark gray', value: 'dark'},
      ],
      message: null,
      minStartDate: new Date(),
      isLoading: false,
      alertId: this.$route.params.id,
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      showCustomInputEditor: false
    }
  },
  validations: {
    getAlertDetail: {
      user_type_id: {
        required,
      },
      message: {
        required,
      }
    },
    startDate: {
      required,
    },
    endDate: {
      required,
    },
    startTime: {
      required,
      invalidTime() {
        return this.endTime !== '' ? moment(this.ends_at).isAfter(this.starts_at) : true
      }
    },
    endTime: {
      required,
      invalidTime() {
        return this.endTime !== '' ? moment(this.ends_at).isAfter(this.starts_at) : true
      }
    },
  },
  async mounted() {
    this.isLoading = true
    await AlertsService.getUserTypes().then(({data}) => {
      this.userDropdwn = data.filter((user) => user.slug === 'teacher' || user.slug === 'student')
    }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error user types.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
    })

    if (this.alertId) {
      await this.fetchAlertsDetail(this.alertId).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error loading alert.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      })
      this.startDate = this.getAlertDetail.starts_at.split(' ')[0]
      this.endDate = this.getAlertDetail.ends_at.split(' ')[0]
      this.startTime = this.getAlertDetail.starts_at.split(' ')[1]
      this.endTime = this.getAlertDetail.ends_at.split(' ')[1]
    }
    this.showCustomInputEditor = true
    this.isLoading = false
  },
  methods: {
    goBack() {
      this.$router.push({path: `/PanelAdmin/alerts`})
    },
    onValidation() {
      this.$v.$touch()
      return this.$v.getAlertDetail.$anyError || this.$v.startDate.$anyError || this.$v.endDate.$anyError || this.$v.startTime.$anyError || this.$v.endTime.$anyError
    },
    onSave() {
      if (this.onValidation() || this.removeTags(this.getAlertDetail.message).length === 0) {
        return
      }
      this.isLoading = true

      const data = {
        user_type_id: this.getAlertDetail.user_type_id,
        message: this.getAlertDetail.message,
        color: this.getAlertDetail.color,
        starts_at: this.starts_at,
        ends_at: this.ends_at
      }

      if (this.alertId) {
        AlertsService.updateAlert(this.alertId, data).then(() => {
          this.$bvModal.msgBoxOk('Successful Updated!').then(() => this.goBack())
        }).catch((e) => {
          const errorMessage = e.message ? e.message : 'Error updating alert.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }).finally(() => this.isLoading = false)
      } else {
        AlertsService.createAlert(data).then(() => {
          this.$bvModal.msgBoxOk('Successful Created!').then(() => this.goBack())
        }).catch((e) => {
          const errorMessage = e.message ? e.message : 'Error creating alert.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }).finally(() => this.isLoading = false)
      }
    },
    onDelete() {
      this.isLoadingAlert = true
      AlertsService.deleteAlert(this.alertId).then(() => {
        this.$bvModal.msgBoxOk('Successful Deleted').then(() => this.goBack())
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error delete alert.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      }).finally(() => this.isLoadingAlert = false)
    },
    removeTags(string) {
      const regex = /(<([^>]+)>)/ig
      return string.replace(regex, " ").trim()
    }
  },
  computed: {
    starts_at() {
      return this.startDate + ' ' + this.startTime
    },
    ends_at() {
      return this.endDate + ' ' + this.endTime
    },
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';
.title
  font font-opensans-bold
  font-size: 24px
  color color-black-200

.subtitle
  font-size 14px
  color color-black-300

.top
  width 105%
  margin 0rem 0rem 1rem -1.5rem
  padding-bottom .5rem
  border-bottom 2px solid red
  border-color color-gray-400

  a
    color color-blue-100
    font font-opensans-bold
    font-size: 17px
    text-decoration none
    margin-top: 0
    margin-bottom: 1rem

.container-user-dropdown
  max-width 50%
  @media (max-width 700px)
    max-width 100%

  .error
    width 100%
    color red
    text-align center
    font font-opensans-bold
    font-size 11px
    margin 5px 0

.label
  text-transform uppercase
  font-size 14px
  font-weight bold
  margin-top 1rem

.label-centered
  text-align center

.radio-buttons-container
  ::v-deep
    .form-group
      margin 0

.color-dropdown
  max-width calc(50% - 15px)
  @media (max-width 700px)
    max-width 100%

.datepiker-container
  display: flex

  ::v-deep
    #date-input-t,
    #date-input-t2
      padding 0 10px !important

    .form-control
      padding 0 4px !important
      margin-top 3px !important
      position relative
      top 6px

.timepiker-container
  display flex
  margin-top 1rem

  ::v-deep
    .btn
      padding 0 10px !important

    .form-control
      padding 0 4px !important
      margin-top 3px !important
      position relative
      top 6px
      font font-opensans-bold
      font-size 14px
      color color-black-200 !important

    .bi-clock, :hover, :focus
      color color-blue-100

.datepiker-container, .timepiker-container
  .datepicker-custom--error
    border-color #f00

::v-deep
  .form-group
    margin-top 1rem

    .d-block
      font font-opensans-bold
      font-size 14px
      color color-black-200 !important
      text-transform uppercase

  .form-group-container
    .form-group
      div
        display flex

        .custom-radio
          margin-right 20px

.start-date-container,
.end-date-container,
.start-time-container,
.end-time-container
  width: 100%
  margin-right:  10px
  @media (max-width 550px)
    margin-right 0

  .error
    width 100%
    color red
    text-align center
    font font-opensans-bold
    font-size 11px
    margin 5px 0

.end-date-container, .end-time-container
  margin-left 10px
  @media (max-width 550px)
    margin-left 0

.alert-container
  max-width 800px
  max-height 800px
  margin-right auto
  margin-left auto
  word-wrap break-word
  white-space pre-wrap
  overflow-y auto
  padding 0.75rem 1.25rem

  .alert-content
    font font-opensans-regular

  ::v-deep
    .close
      pointer-events none
      padding 0
      padding-right 4px

.container-buttons
  display flex
  justify-content center

  .button
    padding 0 40px

  .button-margin
    margin-right 20px

@media (max-width 550px)
  .timepiker-container, .datepiker-container
    display block

    .datepicker-custom
      margin-bottom 20px

  .container-buttons
    display block

    .button
      width 100%
      margin-right 0
      margin-top 10px
</style>
