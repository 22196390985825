import {
  FETCH_EXCHANGE_LIST_START,
  FETCH_EXCHANGE_LIST_DATA,
  FETCH_EXCHANGE_DETAIL_START,
  FETCH_EXCHANGE_DETAIL_DATA,
  UPDATE_EXCHANGE_DETAIL_START,
  UPDATE_EXCHANGE_DETAIL_DATA
} from './mutation-types'
export const
  mutations = {
    [FETCH_EXCHANGE_LIST_START] (state) {
      state.loadingExchanges = true
    },
    [FETCH_EXCHANGE_LIST_DATA] (state, { exchanges }) {
      state.exchanges = exchanges
      state.loadingExchanges = false
    },
    [FETCH_EXCHANGE_DETAIL_START] (state) {
      state.loadingExchangesData  = true
    },
    [FETCH_EXCHANGE_DETAIL_DATA] (state, data) {
      state.exchangeDetail = Object.assign(state.exchangeDetailDefault, data)
      state.loadingExchangesData  = false
    },
    [UPDATE_EXCHANGE_DETAIL_START] (state) {
      state.updatingExchangesData  = true
    },
    [UPDATE_EXCHANGE_DETAIL_DATA] (state, data) {
      state.exchangeDetail = Object.assign(state.exchangeDetailDefault, data)
      state.updatingExchangesData  = false
    },
    resetExchangeDetail(state) {
      state.exchangeDetail = {
        id: null,
        country_id: null,
        name: '',
        number_of_tokens: 1,
        price: 0,
        subject: {
          id: null
        },
        country: {}
      }
      state.loadingExchangesData  = false
    }
  }
