<template>
  <div>
    <modal ref="modalDetail"
           modal-class="modal-task-detail"
           @hide="closeModalAction()"
           :hide-close-button="true">
      <template #body>
        <a class="back">
          <span @click="closeModalAction()"><i class="icon-caret-left"></i> Back</span>
        </a>
        <section class="container-body">
          <b-overlay :show="loadingAction"
                     spinner-medium
                     rounded="sm">

            <h2 class="modal-title text-center">{{ taskTitle }}</h2>

            <b-form>
              <custom-radio v-model="form.isTask" class="radio-buttons-container" :options="optionsTask"/>

              <select-dropdown :options="getCourseStudentGroupTypes"
                               v-model="$v.form.group.$model"
                               placeholder="Group*"
                               class="--secundary actions-item-dropdown"
                               :class="{'actions-item-dropdown--error': !$v.form.group.required && $v.form.group.$dirty}"
                               value-field="id"
                               @inputObject="handleGroupDropdown"/>
              <div v-if="!$v.form.group.required && $v.form.group.$dirty"
                   class="error error--dropdown">
                Group field is required
              </div>

              <select-dropdown :options="getSubjects"
                               v-model="$v.form.subject.$model"
                               placeholder="Subject*"
                               class="--secundary actions-item-dropdown"
                               :class="{'actions-item-dropdown--error': !$v.form.subject.required && $v.form.subject.$dirty}"
                               value-field="id"/>
              <div v-if="!$v.form.subject.required && $v.form.subject.$dirty"
                   class="error error--dropdown">
                Subject field is required
              </div>

              <select-dropdown :options="triggers"
                               v-model="$v.form.trigger.$model"
                               placeholder="Trigger*"
                               class="--secundary actions-item-dropdown"
                               :class="{'actions-item-dropdown--error': !$v.form.trigger.required && $v.form.trigger.$dirty}"
                               value-field="id"/>
              <div v-if="!$v.form.trigger.required && $v.form.trigger.$dirty"
                   class="error error--dropdown">
                Trigger field is required
              </div>

              <h3 class="labels">Task*</h3>
              <b-form-checkbox v-model="form.useStudentTaskName"
                               :disabled="disabledCheckBox"
                               class="custom-checkbox checkbox-lg">
                Use Student Task Name
              </b-form-checkbox>
              <custom-input v-model="$v.form.task.$model"
                            invalid-feedback="Task field is required"
                            :maxlength="254"
                            :disabled="form.useStudentTaskName"
                            :error="!$v.form.task.required && $v.form.task.$dirty"/>

              <custom-input v-model="$v.form.toDo.$model"
                            label="To do*"
                            invalid-feedback="To do field is required"
                            :maxlength="254"
                            :error="!$v.form.toDo.required && $v.form.toDo.$dirty"/>

              <custom-input v-model="$v.form.details.$model"
                            type="rich-text"
                            :class="{'rich-text': !$v.form.details.required && $v.form.details.$dirty}"
                            v-if="showDetails"
                            label="Details*"
                            :link-list="true"
                            invalid-feedback="Details field is required"
                            :validate-links="true"
                            @on-validate-links="(arg) => validateLinks = arg"
                            :maxlength="20000"
                            :error="!$v.form.details.required && $v.form.details.$dirty"/>

              <custom-input v-model="$v.form.detailsCreate.$model"
                            type="rich-text"
                            :class="{'rich-text': !$v.form.detailsCreate.required && $v.form.detailsCreate.$dirty}"
                            v-if="showDetails && form.isTask"
                            label="Create Prompt*"
                            :link-list="true"
                            invalid-feedback="Create Prompt field is required"
                            :maxlength="20000"
                            :validate-links="true"
                            @on-validate-links="(arg) => validateLinks = arg"
                            :error="!$v.form.detailsCreate.required && $v.form.detailsCreate.$dirty"/>

              <custom-input v-model="$v.form.detailsResponse.$model"
                            type="rich-text"
                            :class="{'rich-text': !$v.form.detailsResponse.required && $v.form.detailsResponse.$dirty}"
                            v-if="showDetails && form.isTask"
                            label="Response Prompt*"
                            :link-list="true"
                            invalid-feedback="Response Prompt field is required"
                            :maxlength="20000"
                            :validate-links="true"
                            @on-validate-links="(arg) => validateLinks = arg"
                            :error="!$v.form.detailsResponse.required && $v.form.detailsResponse.$dirty"/>

              <custom-input v-model="$v.form.infoLink.$model"
                            label="More Info Link*"
                            invalid-feedback="The URL is not valid"
                            :maxlength="254"
                            v-if="!form.isTask"
                            :error="$v.form.infoLink.$error && $v.form.infoLink.$dirty"/>
            </b-form>
          </b-overlay>
        </section>
      </template>
      <template #footer>
        <b-button @click="openModalDelete" :disabled="isCreateAction" class="button b-ligth"> Delete</b-button>
        <b-button @click="closeModalAction()" class="button  b-ligth"> Cancel</b-button>
        <b-button @click="SaveAction" class="button"> Save</b-button>
      </template>
    </modal>
    <modal ref="deleteTask" id="delete-task-modal" modal-class="delete">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title text-center">DELETE {{ form.task }} ?</h2>
        <p class="text-center">
          Are you sure?
        </p>
      </template>
      <template #footer>
        <b-button class="button b-ligth" @click="deleteTask()"> Delete</b-button>
        <b-button @click="closeModalDelete" class="button"> Cancel</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal, CustomInput, SelectDropdown, CustomRadio} from '@/core/components'
import {CourseMixins, UtilsMixins} from '../Mixins'
import {required, url, requiredIf} from 'vuelidate/lib/validators'
import { ActionsItemService } from '@/core/services'
import { groupTypes } from '@/core/utils'

export default {
  name: 'ModalDetail',
  components: {
    Modal,
    CustomInput,
    SelectDropdown,
    CustomRadio
  },
  mixins: [CourseMixins, UtilsMixins],
  props: {
    actionId: {
      default: null
    }
  },
  data () {
    return {
      form: {
        group: '',
        subject: '',
        trigger: '',
        task: '',
        toDo: '',
        details: '',
        infoLink: '',
        useStudentTaskName: '',
        isTask: 0,
        detailsResponse: '',
        detailsCreate: ''
      },
      subjects: [],
      loadingAction: true,
      showDetails: false,
      optionsTask: [
        {id: 0, name: 'Link'},
        {id: 1, name: 'Create Video'},
      ],
      triggers: [
        {name: 'New Classroom', id: 'new_classroom'},
        {name: 'Classroom Paired', id: 'classroom_paired'},
        {name: 'Student Due Date', id: 'student_due_date'},
        {name: 'Pending Student Video', id: 'pending_student_video'}
      ],
      validateLinks: false,
      groupSelected: {}
    }
  },
  validations: {
    form: {
      group: {
        required
      },
      subject: {
        required
      },
      trigger: {
        required
      },
      task: {
        required: requiredIf(function() {
          return !this.form.useStudentTaskName
        })
      },
      toDo: {
        required
      },
      details: {
        required
      },
      detailsCreate: {
        required: requiredIf(function() {
          return this.form.isTask
        })
      },
      detailsResponse: {
        required: requiredIf(function() {
          return this.form.isTask
        }),
      },
      infoLink: {
        url
      }
    }
  },
  methods: {
    closeModalAction () {
      this.$v.$reset()
      this.form = {
        group: '',
        subject: '',
        trigger: '',
        task: '',
        toDo: '',
        details: '',
        infoLink: '',
        isTask: 0,
        detailsResponse: '',
        detailsCreate: ''
      }
      this.showDetails = false
      this.loadingAction = true
      this.$refs.modalDetail.hideModal()
    },
    async openModalAction () {
      this.$refs.modalDetail.showModal()
      this.showDetails = true
      if (this.actionId) {
        await this.getAdminById()
        if (this.form.group === 2) {
          this.triggers.push({name: 'Group Names Approved', id: 'groups_name_approved'})
        }
        return
      }
      this.loadingAction = false
    },
    openModalDelete() {
      this.$refs.deleteTask.showModal()
    },
    closeModalDelete() {
      this.$refs.deleteTask.hideModal()
    },
    async getAdminById() {
      this.showDetails = false
      await ActionsItemService.getActionById(this.actionId).then((response) => {
        const data = response.data
        if (response.success) {
          this.form = {
            group: data.group_id,
            subject: data.subject_id,
            trigger: data.trigger,
            task: data.task,
            toDo: data.to_do,
            details: data.details,
            detailsResponse: data.details_response,
            detailsCreate: data.details_create,
            infoLink: data.more_info_link,
            useStudentTaskName: !!data.use_student_task_name,
            isTask: data.is_task ? 1 : 0
          }
        }
      }).finally(() => {
        this.groupSelected = this.getCourseStudentGroupTypes.find(group => group.id === this.form.group)
        this.handleGroupDropdown(this.groupSelected)
        this.loadingAction = false
        this.showDetails = true
      })
    },
    deleteTask() {
      this.loadingTask = true
      ActionsItemService.deleteAction(this.actionId)
        .catch((e) => {
          const errorMessage = e.message ? e.message : 'Error deleted.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        })
        .finally(() => {
          this.$emit('action-saved')
          this.closeModalDelete()
          this.closeModalAction()
          this.loadingTask = false
        })
    },
    SaveAction() {
      this.$v.$touch()
      if (this.$v.$anyError || this.validateLinks) {
        return
      }
      const data = {
        "group_id": this.form.group,
        "subject_id": this.form.subject,
        "trigger": this.form.trigger,
        "use_student_task_name": this.form.useStudentTaskName,
        "task": this.form.task,
        "to_do": this.form.toDo,
        "details": this.form.details,
        "is_task": !!this.form.isTask,
        "details_response": this.form.detailsResponse,
        "details_create": this.form.detailsCreate
      }
      if (!this.form.is_task) {
        data["more_info_link"] = this.form.infoLink
      }
      this.loadingAction = true
      if (this.actionId) {
        ActionsItemService.updateAction(this.actionId, data)
          .then(() => {
            this.$emit('action-saved')
            this.$bvModal.msgBoxOk('Action updated successful!.', {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          })
          .catch((e) => {
            const errorMessage = e.message ? e.message : 'Failed to create action.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          })
          .finally(() => {
            this.loadingAction = false
          })
      } else {
        ActionsItemService.addAction(data)
          .then(() => {
            this.$emit('action-saved')
            this.$bvModal.msgBoxOk('Action added successful!.', {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            }).then(() => this.closeModalAction())
          })
          .catch((e) => {
            const errorMessage = e.message ? e.message : 'Failed to create action.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          })
          .finally(() => {
            this.loadingAction = false
          })
      }
    },
    handleGroupDropdown(group) {
      this.groupSelected = group

      const [partnerStudentCreate, partnerStudentRespond, pendingStudentVideo, groupNamesApproved] = [
        {name: 'Partner Student Create', id: 'partner_student_create_trigger'},
        {name: 'Partner Student Respond', id: 'partner_student_respond_trigger'},
        {name: 'Pending Student Video', id: 'pending_student_video'},
        {name: 'Group Names Approved', id: 'groups_name_approved'}
      ]

      this.triggers = this.triggers.filter((trigger) => (trigger.id !== partnerStudentCreate.id) && (trigger.id !== partnerStudentRespond.id))

      if ((group.slug === groupTypes.wholeClass) && !this.form.isTask) {
        this.triggers.push(partnerStudentCreate, partnerStudentRespond)
        if (this.form.trigger === pendingStudentVideo.id) {
          this.form.trigger = ''
        }
      } else {
        if (this.form.trigger === partnerStudentCreate.id || this.form.trigger === partnerStudentRespond.id) {
          this.form.trigger = ''
        }
      }

      this.triggers = this.triggers.filter((item) => item.id !== pendingStudentVideo.id)

      if (!(group.slug === groupTypes.wholeClass)) {
        this.triggers.push(pendingStudentVideo)
      }

      this.triggers = this.triggers.filter(trigger => trigger.id !== groupNamesApproved.id)

      if (group.slug === groupTypes.smallGroup) {
        this.triggers.push(groupNamesApproved)
      } else {
        if (this.form.trigger === groupNamesApproved.id) {
          this.form.trigger = ''
        }
      }
    }
  },
  mounted () {
    this.fetchSubjects()
    this.fetchStudentGroupType()
  },
  computed: {
    taskTitle () {
      return this.actionId ? 'ACTION ITEM' : 'CREATE NEW ACTION ITEM'
    },
    isCreateAction() {
      return !this.actionId
    },
    disabledCheckBox() {
      const triggerDisabled = [
        'pending_student_video',
        'student_due_date',
        'partner_student_create_trigger',
        'partner_student_respond_trigger'
      ]
      return !triggerDisabled.includes(this.form.trigger)
    }
  },
  watch: {
    'form.useStudentTaskName'(newVal) {
      newVal && (this.form.task = '')
    },
    'form.trigger'() {
      (!this.loadingAction && this.disabledCheckBox) && (this.form.useStudentTaskName = false)
      !this.disabledCheckBox && (this.form.useStudentTaskName = true)
    },
    'form.isTask'(newVal) {
      if (newVal) {
        this.triggers = this.triggers.filter((item) => item.id !== 'new_classroom')
        if (this.form.trigger === 'new_classroom') this.form.trigger = ''
      } else {
        this.triggers.unshift({name: 'New Classroom', id: 'new_classroom'})
      }
      this.handleGroupDropdown(this.groupSelected)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

a.back
  position absolute
  left -5px
  top 1.8rem
  color color-blue-100
  font font-opensans-bold
  font-size 17px
  text-decoration none
  margin-top -2rem !important
  padding 5px
  margin-bottom 1rem
  width 100%
  border-bottom 2px solid color-gray-400

  .icon-caret-left
    color color-blue-100

  span
    cursor pointer

.container-body
  width 80%
  margin 5.25rem auto 0

.actions-item-dropdown
  margin-top 15px
  max-width 50%
  display block
  @media(max-width 600px)
    max-width 100%

.actions-item-dropdown--error
  ::v-deep
    .dropdown-toggle
      border-color color-red-error !important

.labels
  margin-top 10px
  font font-opensans-semibold
  color color-black-400

.custom-checkbox
  margin-bottom 12px
  margin-left 10px

.modal-title
  margin-bottom 62px

.modal-title.text-center
  overflow-wrap break-word

.rich-text
  ::v-deep
    .tox-tinymce
      border-color color-red-error !important

.error
  width 100%
  text-align center
  margin-bottom 20px
  margin-top 5px
  font font-opensans-bold
  font-size 11px
  color red

.error--dropdown
  max-width 50%
  @media(max-width 600px)
    max-width 100%

.radio-buttons-container
  ::v-deep
    .form-group
      margin 0

</style>
<style lang="stylus">
#delete-task-modal
  .modal-dialog
    min-width unset !important

    .modal-body
      width auto

      .close
        padding 0

</style>
