import {http} from './http'
import axios from "axios";

const resource = 'api/admin'

export default {
  getMonthlyStatements() {
    return http.get(`${resource}/monthly-statements`).then(response => {
      return response
    })
  },

  async getSignature (key, bucket = process.env.VUE_APP_S3_BUCKET, client_method = 'put_object') {
    return axios.post(process.env.VUE_APP_S3_SIGNED_URL, {
      bucket: bucket,
      key: key, //, "prefix",
      client_method: client_method
    }).then(function (result) {
      const signedUrl = result.data.signed_url

      const signature = {}

      signature['url'] = signedUrl
      signature['inputs'] = {}

      const pairs = signedUrl.substring(signedUrl.indexOf('?') + 1).split('&')
      for (let i = 0; i < pairs.length; i++) {
        if (!pairs[i])
          continue
        const pair = pairs[i].split('=')
        signature['inputs'][decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
      }

      return signature
    }).catch(function (error) {
      console.error(error)
    })
  }
}
