import { http } from './http'

const resource = 'api/admin'

export default {

  getStandards () {
    return http.get(`${resource}/standards`).then(response => {
      return response
    })
  }

}
