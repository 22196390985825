<template>
  <div class="container-detail-submission">
    <div class="profile">
      <div class="profile-left">
        <b-avatar :src="studentThumbnail"/>
      </div>
      <div class="profile-right">
        <div class="full-name">{{studentFullName}}</div>
        <div class="user-name">{{studentUserName}}</div>
      </div>
    </div>
    <hr>
    <div class="attribute">
      <div class="attribute-left">
        <video-custom class="img-fluid" :video="submission"/>
      </div>
      <div class="attribute-right">
        <div class="attribute-right-course">
          <h3 class="title">{{submission.class_task.task_name}}</h3>
          <div class="posted">Posted On: {{setDate(submission.class_task.created_at)}}</div>
        </div>
        <div class="attribute-right-status">
          <h3 :class="submission.status">STATUS: {{submission.status}}</h3>
          <div class="actions">
            <select-dropdown v-if="actions.length"
                             v-model="action"
                             value-field="value"
                             placeholder="Action"
                             @input="applyAction"
                             :options="actions"/>
          </div>
        </div>
      </div>
    </div>
    <b-overlay no-wrap :show="loading"/>
  </div>
</template>

<script>
import { SelectDropdown } from '@/core/components'
import VideoCustom from "@/core/components/Video/VideoCustom"
import { SubmissionsService } from '@/core/services'
import moment from 'moment'

export default {
  name: "ClassroomDetailSubmissionView",
  components: {
    SelectDropdown,
    VideoCustom
  },
  data() {
    return {
      action: '',
      actions: [
        {name: "Approve", value: "approved"},
        {name: "Unapprove", value: "pending"},
        {name: "Block", value: "blocked"},
        {name: "Delete", value: "deleted"},
      ],
      submissionId: this.$route.query.submission,
      classroomId: this.$route.params.id,
      submission: {
        status: '',
        class_task: '',
        url: ''
      },
      loading: false
    }
  },
  mounted() {
    this.getSubmission()
  },
  methods: {
    applyAction() {
      this.loading = true
      const data = {status: this.action}
      SubmissionsService.updateSubmissionStatus(this.classroomId, this.submissionId, data).then((response) => {
        const error = response.message
        if (!response.success) {
          const errorMessage = error ? error : 'Error updating status.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }).finally(async () => {
          await this.getSubmission()
          this.action = ''
      })
    },
    getSubmission() {
      this.loading = true
      SubmissionsService.getSubmissionById(this.classroomId, this.submissionId).then(({data}) => {
        this.submission = data
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error loading video.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      }).finally(() => {
          this.loading = false
      })
    },
    setDate(date) {
      return moment(date).format('MM/DD/YYYY h:mm a')
    }
  },
  computed: {
    studentUserName() {
      return this.submission.student && this.submission.student.username
    },
    studentFullName() {
      return this.submission.student && this.submission.student.first_name + ' ' + this.submission.student.last_initial
    },
    studentThumbnail() {
      return this.submission.student && this.submission.student.thumbnail
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

color-43 = #ffc5ca
color-39 = #FCE9C5
color-35 = #E2EFCD

.video-not-ready-status
  font font-opensans-bold
  font-size 14px
  text-transform uppercase
  letter-spacing 0
  color #262626
  padding 10px 14px
  background color-43 0% 0% no-repeat padding-box
  text-align center

.container-detail-submission
  padding-top 30px
  padding-bottom 100px

  .profile
    display flex
    align-items center

    &-left
      width 80px

      ::v-deep
        .b-avatar
          width 80px
          height 80px

    &-right
      text-align left
      padding-left 25px
      font font-opensans-bold
      font-size 15px
      line-height 25px

      .full-name
        text-transform capitalize

  .attribute
    display flex
    flex-direction column

    &-left
      padding-top 15px

      ::v-deep
        .video-player
          max-width 525px
          width 100%
          padding-top 120.95%
          height 0
          position relative
          margin 0 auto

          video
            width 100%
            height 100%
            position absolute
            top 0
            left 0

    &-right
      text-align left
      margin-top 35px

      &-course
        height auto
        overflow-y auto
        margin-bottom 50px

        .title
          font font-opensans-bold
          font-size 20px
          text-transform uppercase

        .subtitle
          font-size 14px
          font font-opensans-semibold
          margin-bottom 0

        .posted
        .tags
        .description
          font font-opensans-regular
          font-size 12px


        .description
          margin-top 8px

        .description
        .tags
          margin-bottom 15px


      &-status
        h3
          font font-opensans-bold
          font-size 14px
          text-transform uppercase
          text-align left
          letter-spacing 0px
          color #262626
          padding 10px 14px

          &.pending
          &.deleted
          &.blocked
            background color-39 0% 0% no-repeat padding-box

          &.approved
            background color-35 0% 0% no-repeat padding-box

        .actions
          display flex
          align-items center
          justify-content flex-end

          .custom-dropdown
            flex-basis 197px
            width auto
            margin-right 10px

            ::v-deep
              .dropdown-toggle
                border none

          .btn-common
            padding 8px 30px

          .bi-arrow-right
            font-size 1.8rem
            margin-left 10px

@media (min-width: 992px)
  .container-detail-submission
    padding-bottom 117px

    .profile
      &-left
        width 104px

        ::v-deep
          .b-avatar
            width 104px
            height 104px

      &-right
        font-size 16px
        line-height 30px

    .attribute
      flex-direction row

      &-left
        padding-top 0
        flex-basis 60%
        max-width 500px
        margin auto
        margin-right 38px

      &-right
        flex 1
        margin-top 30px
</style>
