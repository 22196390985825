import { http } from './http'

const resource = 'api/admin'

export default {
  getSubjects (filters = null, sort = null) {
    const params = {
      sort_by: sort
    }

    if (filters) {
      if (filters.search_string) {
        params.filter_by = filters.filterBy
        params.search_string = filters.search_string
      }
    }

    return http.get(`${resource}/subjects`, { params }).then(response => {
      return response
    })
  },
  createSubject (data) {
    return http.post(`${resource}/subjects`, data).then(response => {
      return response
    })
  },
  getSubjectById (id) {
    return http.get(`${resource}/subjects/${id}`).then(response => {
      return response
    })
  },
  updateSubject (id, data) {
    return http.put(`${resource}/subjects/${id}`, data).then(response => {
      return response
    })
  },
  deleteSubject (id) {
    return http.delete(`${resource}/subjects/${id}`).then(response => {
      return response
    })
  },
}
