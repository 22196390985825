<template>
    <main-layout >
      <router-view></router-view>
    </main-layout>
</template>

<script>
import MainLayout from '../../../core/components/PageBase/MainLayout.vue'
export default {
  name: 'ViewLoginPage',
  components: { MainLayout }
}
</script>

<style lang="stylus" scoped>

</style>
