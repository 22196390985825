<template>
  <modal ref="modalLibrarySubscription" id="modal-library-subscription" modal-class="primary_modal" @hide=" $emit('modal-teacher-detail-hide')" :hideFooter=true>
    <template #header>
    </template>
    <template #body>
      <b-container>
        <b-row>
            <b-col cols="12">
              <h2 class="subtitle">Library Subscription</h2>
              <div class="container-form">
                <b-form-group label-cols="4" label="Amount Paid" label-for="input-sm">
                  <b-form-input v-model="$v.form.amoundPaid.$model"
                                autocomplete="off"
                                maxlength="6"
                                :class="{'input-error': amoundPaidValid}"
                                type="text"/>
                  <div class="error" v-if="amoundPaidValid">
                    Amount paid must be a positive integer
                  </div>
                </b-form-group>

                <b-form-group label-cols="4" label="Purchased Date" label-for="input-sm">
                  <b-input-group>
                    <b-form-input id="date-input" v-model="purchaseDateFormatted" type="text" placeholder="Due Date"
                                  autocomplete="off" readonly></b-form-input>
                    <b-input-group-append class="btn-picker">
                      <b-form-datepicker v-model="$v.form.purchaseDate.$model" button-only right
                                         :min="minPurchaseDate"
                                         :max="maxPurchaseDate"
                                         locale="en-US"/>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-form-group label-cols="4" label="Expiration Date" label-for="input-sm" class="mt-4">
                  <b-input-group>
                    <b-form-input id="date-input" v-model="expirationDateFormatted" type="text" placeholder="Due Date"
                                  autocomplete="off" readonly></b-form-input>
                    <b-input-group-append class="btn-picker">
                      <b-form-datepicker v-model="$v.form.expirationDate.$model" button-only right
                                         :min="minExpireDate"
                                         :max="maxExpireDate"
                                         locale="en-US"/>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-form-group label-cols="4" label="Subject" label-for="input-sm" class="mt-4">
                  <b-form-input value="Library"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>

                <b-form-group class="flex-column text-center">
                  <b-button class="button button--email mt-2" @click="close">Cancel</b-button>
                  <b-button class="button button--email mt-2 ml-3" @click="saveSubscription">Save</b-button>
                </b-form-group>
              </div>
            </b-col>

          <b-overlay :show="loading" no-wrap spinner-medium rounded="sm">
          </b-overlay>
        </b-row>
      </b-container>
    </template>
  </modal>
</template>

<script>
import {Modal} from '@/core/components'
import {TeacherMixins, UtilsMixins} from '../Mixins'
import {required, integer, minValue} from "vuelidate/lib/validators"
import {LibrarianSubscriptionService} from "@/core/services"
import moment from 'moment'

export default {
  name: 'AddLibrarySubscription',
  mixins: [TeacherMixins, UtilsMixins],
  props: {
    schoolId: {
      default: null
    }
  },
  data() {
    const minPurchaseDate = moment().subtract(1, 'years').format('YYYY-MM-DD')
    const maxPurchaseDate = moment().add(1, 'M').format('YYYY-MM-DD')

    const maxExpireDate = moment().add(5, 'y').format('YYYY-MM-DD')

    return {
      minPurchaseDate: minPurchaseDate,
      maxPurchaseDate: maxPurchaseDate,
      maxExpireDate: maxExpireDate,
      token_data: {},
      form: {
        amoundPaid: 0,
        purchaseDate: '',
        expirationDate: ''
      },
      loading: false
    }
  },
  validations: {
    form: {
      amoundPaid: {
        integer: integer,
        minValue: minValue(1),
        required: required
      },
      purchaseDate: {
        required: required
      },
      expirationDate: {
        required: required
      }
    }
  },
  components: {
    Modal
  },
  methods: {
    open() {
      return new Promise((resolve) => {
        this.$v.$reset()
        this.$refs.modalLibrarySubscription.showModal()
        const currentDate = moment().format('YYYY-MM-DD')
        const defaultExpiration = moment().add(1, 'y').add(1, 'd').format('YYYY-MM-DD')
        this.form = {
          amoundPaid: 0,
          purchaseDate: currentDate,
          expirationDate: defaultExpiration
        }
        resolve(this)
      })
    },
    close() {
      return new Promise((resolve) => {
        this.$refs.modalLibrarySubscription.hideModal()
        resolve(this)
      })
    },
    async saveSubscription() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }

      if (!this.loading) {
        this.loading = true
        try {
          const data = {
            school_id: this.schoolId,
            total_price: this.form.amoundPaid,
            end_date: this.form.expirationDate,
            start_date: this.form.purchaseDate
          }
          await LibrarianSubscriptionService.saveSubscription({...data}).then((resp) => {
            if (resp.success) {
              this.$bvModal.msgBoxOk('Successful!', {
                size: 'sm',
                dialogClass: "modal-message-box",
                noCloseOnBackdrop: true,
                centered: true
              }).then(() => {
                this.$bvModal.hide('modal-library-subscription')
              })
              this.$emit('save-subscription')
            } else {
              const errorMessage = resp.message ? resp.message : 'Error fetching teachers list.'
              this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
                size: 'sm',
                dialogClass: "modal-message-box",
                noCloseOnBackdrop: true,
                centered: true
              })
            }
          })
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error save subscription.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        } finally {
          this.loading = false
        }
      }
    }
  },
  mounted() {
  },
  computed: {
    purchaseDateFormatted () {
      return moment(this.form.purchaseDate).format('MM/DD/YYYY')
    },
    expirationDateFormatted () {
      return moment(this.form.expirationDate).format('MM/DD/YYYY')
    },
    amoundPaidValid () {
      return (!this.$v.form.amoundPaid.integer || !this.$v.form.amoundPaid.minValue || !this.$v.form.amoundPaid.required) && this.$v.form.amoundPaid.$dirty
    },
    minExpireDate() {
      return moment(this.form.purchaseDate).add(1, 'y').add(1, 'd').format('YYYY-MM-DD')
    }
  },
  watch: {
    'form.purchaseDate'(date) {
      //validate if the end date is greater than the start date in one year
      if (!moment(this.form.expirationDate).isSameOrAfter(moment(date).add(1, 'years'))) {
        this.form.expirationDate = moment(this.form.purchaseDate).add(1, 'y').add(1, 'd').format('YYYY-MM-DD')
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

#date-input
  margin-bottom 0 !important

.btn-picker
  .b-form-btn-label-control
    ::v-deep
      .btn
        margin 0

.teacher-about
  margin-bottom 40px !important

.container-top {
  margin: 0rem -1.2rem 1rem -1.2rem
  padding-bottom: .5rem
  padding-left: 1rem
  border-bottom: 2px solid red
  border-color: color-gray-400
  color: color-blue-300

  &-back {
    width: calc(100% + 1rem);
    margin: 0;
    margin-left: -0.5rem;
  }

  a {
    font-size: 1rem
    font-weight: bolder
    margin-top: 0
    margin-bottom: 1rem

    &.icon-caret-left:before {
      font-size: 1rem
    }
  }
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin 10px 0
}

.container-button {
  padding: 0.5rem;
  color: #2f3e98;

  display: flex
  flex-direction: row
  width 100%
  max-width 420px
  justify-content: center

  &-masquerade {
    border-bottom: 2px solid color-gray-400;
    margin-bottom 4rem
  }

  &-bottom {
    margin-bottom 2rem
  }
}

.container-text {
  display: flex

  .b-avatar {
    margin-top: 2rem
    width 120px
    height 120px
  }
}

.list-item {
  display: flex
  margin: .4rem 0
  justify-content: left

  p {
    text-align: left
    min-width 112px
    max-width: 230px
    margin .1rem .3rem
    overflow-wrap: break-word
  }
}

.title {
  text-transform: uppercase
  font-size: 24px
  font-weight: bold
  overflow-wrap: break-word
}

.teacher-report-container
  display block
  position relative

.subtitle {
  text-transform: uppercase
  font-size: 14px
  font-weight: bold
  margin-top 1rem

  &.reported {
    font: font-opensans-regular
    font-size: 14px
    text-transform: capitalize
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

p {
  font-size: 13px
  overflow-wrap: break-word
}

.container-form {
  margin 2rem 0

  .custom-checkbox {
    margin-top 1.5rem
  }
}

.text-date {
  font-size 14px !important
}

.input-email
  margin-top 8px

.button--email
  height auto
  padding-top 7.5px
  padding-bottom 7.5px

#modal-library-subscription {
  .modal.primary_modal .modal-dialog {
    min-width: unset;
  }
}

@media (min-width: 1200px) {
  .col-xl-7,
  .col-xl-5 {
    flex(0 0 100%);
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 1360px) {
  .col-xl-5 {
    flex(0 0 41.666667%);
    max-width: 41.666667%;
  }

  .col-xl-7 {
    flex(0 0 58.333333%);
    max-width: 58.333333%;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1500px;
  }
}

@media (min-width: 768px) {
  .container-left {
    border-right: 1px solid #e8ecee;
  }
}

.input-error, .input-error:focus
  border-color red

</style>

<style lang="stylus">
#modal-library-subscription {
  .modal-dialog {
    min-width: unset;
  }
}
</style>
