export const state = {
  exchanges: [],
  loadingExchanges: true,
  loadingExchangesData: true,
  updatingExchangesData: false,
  exchangeDetail: {
    id: null,
    country_id: null,
    name: '',
    number_of_tokens: 1,
    price: 0,
    subject: {
      id: null
    },
    country: {}
  },
  exchangeDetailDefault: {
    id: null,
    country_id: null,
    name: '',
    number_of_tokens: 1,
    price: 0,
    subject: {
      id: null
    },
    country: {}
  },
}
