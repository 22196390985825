import * as labels from './mutation-types'

export const
  mutations = {
    [labels.FETCH_PROFILE_START] (state) {
      state.loadingProfile = true
    },
    [labels.FETCH_PROFILE_DATA] (state, user) {
      state.user = user
      state.loadingProfile = false
    }
  }
