import { http } from './http'

const resource = 'api/admin'

export default {
  getSchools(filters, sortBy, page) {
    return http.get(`${resource}/schools-module`, {params: {filters, sort_by: sortBy, page}}).then(response => {
      return response
    })
  },
  getSchoolById (id) {
    return http.get(`${resource}/schools-module/${id}`).then(response => {
      return response
    })
  },
  checkDpaSchool (id, form) {
    return http.put(`${resource}/schools-module/${id}`, form).then(response => {
      return response
    })
  },
}
