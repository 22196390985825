import {
  FETCH_PARTNERSHIP_START,
  FETCH_PARTNERSHIP_DATA,
  FETCH_ERROR_DATA,
  FETCH_ERROR_START,
  UPDATE_PARTNERSHIP_START,
  UPDATE_PARTNERSHIP_DATA,
} from "./mutation-types";
import { PartnershipService } from "../../services/";

export const actions = {
  fetchPartnership({ commit }, classroomCode) {
    const dataReset = {
      show_end_date: false,
      end_date: null,
      classrooms: {
        id: "",
        name: "",
        code: "",
        teacher: "",
        teacher_id: "",
        loginId: "",
        country: "",
        age: "",
        subject: "",
        nativeLanguage: "",
        task: "",
        dates: "",
        teacherUserType: ""
      },
      partnership: {
        name: "",
        code: "",
        teacher: "",
        loginId: "",
        country: "",
        age: "",
        subject: "",
        nativeLanguage: "",
        task: "",
        dates: "",
        teacherUserType: ""
      },
    };
    commit(FETCH_ERROR_START);
    commit(FETCH_PARTNERSHIP_START);
    if (!classroomCode) {
      commit(FETCH_PARTNERSHIP_DATA, dataReset);
      return;
    }
    return PartnershipService.getPartnership(classroomCode)
      .then((res) => {
        const endDate = res.data.end_date
          ? `${res.data.end_date.slice(0, 10)}`
          : null;

        const data = {
          show_end_date: !!endDate,
          end_date: endDate,
          classrooms: {
            id: res.data.classroom.id,
            name: res.data.classroom.name,
            code: res.data.classroom.code,
            teacher: res.data.classroom.teacher_full_name,
            teacher_id: res.data.classroom.teacher_id,
            loginId: res.data.classroom.teacher_username,
            country: res.data.classroom.country,
            age: res.data.classroom.age_range,
            subject: res.data.classroom.subject,
            nativeLanguage: res.data.classroom.lang_native,
            task: res.data.classroom.lang_instruction,
            level: res.data.classroom.level,
            course: res.data.classroom.course ?? {},
            courseStudentGroupType:
              res.data.classroom?.course_student_group_type,
            dates: `${res.data.classroom.start_date.slice(
              0,
              10
            )} / ${res.data.classroom.end_date.slice(0, 10)}`,
            student_payment_required:
              !!res.data.classroom.student_payment_required,
            student_payment_price: res.data.classroom.student_payment_price,
            teacherUserType: res.data?.classroom?.teacher_user_type,
            teacherAssistants: res.data?.classroom?.teacher_assistants[0]?.full_name,
            teacherAssistantsId: res.data?.classroom?.teacher_assistants[0]?.id,
            teacherAssistantsEmail: res.data?.classroom?.teacher_assistants[0]?.user?.email,
          },
          partnerships: {
            name: res.data.partner ? res.data.partner.name : "",
            code: res.data.partner ? res.data.partner.code : "",
            teacher: res.data.partner ? res.data.partner.teacher_full_name : "",
            loginId: res.data.partner ? res.data.partner.teacher_username : "",
            country: res.data.partner ? res.data.partner.country : "",
            age: res.data.partner ? res.data.partner.age_range : "",
            subject: res.data.partner ? res.data.partner.subject : "",
            nativeLanguage: res.data.partner
              ? res.data.partner.lang_native
              : "",
            task: res.data.partner ? res.data.partner.lang_instruction : "",
            teacherId: res.data.partner ? res.data.partner.teacher_id : "",
            level: res.data.partner ? res.data.partner.level : "",
            course: res.data.partner ? res.data.partner.course ?? {} : {},
            courseStudentGroupType: res.data.partner?.course_student_group_type,
            dates: res.data.partner
              ? `${res.data.partner.start_date.slice(
                  0,
                  10
                )} / ${res.data.partner.end_date.slice(0, 10)}`
              : "",
            student_payment_required: res.data.partner
              ? !!res.data.partner.student_payment_required
              : false,
            student_payment_price: res.data.partner
              ? res.data.partner.student_payment_price
              : "",
            teacherUserType: res.data?.partner?.teacher_user_type,
            teacherAssistants: res.data?.partner?.teacher_assistants[0]?.full_name,
            teacherAssistantsId: res.data?.partner?.teacher_assistants[0]?.id,
            teacherAssistantsEmail: res.data?.partner?.teacher_assistants[0]?.user?.email,
          },
        };

        Object.assign(data.classrooms, { enableStudentPayment: false });

        if (data.classrooms.courseStudentGroupType) {
          Object.assign(data.classrooms, {
            enableStudentPayment:
              data.classrooms.courseStudentGroupType.id === 1,
          });
        }

        if (data.partnerships.courseStudentGroupType) {
          Object.assign(data.partnerships, {
            enableStudentPayment:
              data.partnerships.courseStudentGroupType.id === 1,
          });
        }

        commit(FETCH_PARTNERSHIP_DATA, data);
      })
      .catch((e) => {
        commit(FETCH_PARTNERSHIP_DATA, dataReset);
        commit(FETCH_ERROR_DATA, e.message);
      });
  },
  updatePartnershipEndDate({ commit }, { classroom_code, end_date }) {
    commit(UPDATE_PARTNERSHIP_START);
    return PartnershipService.updatePartnerShipEndDate(
      classroom_code,
      end_date
    ).then((res) => {
      commit(UPDATE_PARTNERSHIP_DATA);
      return res;
    });
  },
  resetPartnership({ commit }) {
    const data = {
      show_end_date: false,
      end_date: null,
      classrooms: {
        id: "",
        name: "",
        code: "",
        teacher: "",
        teacher_id: "",
        loginId: "",
        country: "",
        age: "",
        subject: "",
        nativeLanguage: "",
        task: "",
        dates: "",
      },
      partnership: {
        name: "",
        code: "",
        teacher: "",
        loginId: "",
        country: "",
        age: "",
        subject: "",
        nativeLanguage: "",
        task: "",
        dates: "",
      },
    };
    commit(FETCH_PARTNERSHIP_DATA, data);
  },
};
