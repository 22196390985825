const adminTypes = Object.freeze({
  adminCourseEditor: 'admin-course-editor',
  customerServiceAgent: 'customer-service-agent',
  adminTest: 'admin-test',
  superAdmin: 'super-admin',
})

const groupTypes = Object.freeze({
  individual: 'individual',
  smallGroup: 'small-group',
  wholeClass: 'whole-class',
})

export {
  adminTypes,
  groupTypes
}
