<template>
  <div class="wrapper-security-page">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col>
          <div class="wrapper-security-form">
            <h2 class="security-form-subtitle mb-1">RECOVER PASSWORD</h2>
            <div class="security-form-page-info mb-4">
              <div class="required-fields">all fields required *</div>
            </div>
            <form @submit.prevent="" class="form-container mt-2">
              <b-form-input @keyup.enter.native="requestLink"
                            @input="validateForm"
                            @blur="validateForm"
                            v-model="form.password"
                            placeholder="New Password *"
                            :class="{ 'form-group--error': errors.password.length !== 0 }"
                            type="password"/>
              <ul class="error-container">
                <li class="error"
                    v-for="(error, idx) in errors.password"
                    :key="error + idx">{{error}}</li>
              </ul>
              <b-form-input @keyup.enter.native="requestLink"
                            @input="validateForm"
                            @blur="validateForm"
                            v-model="form.passwordConfirm"
                            placeholder="Confirm New Password *"
                            :class="{ 'form-group--error': errors.passwordConfirm.length !== 0 }"
                            type="password"/>
              <ul class="error-container">
                <li class="error"
                    v-for="(error, idx) in errors.passwordConfirm"
                    :key="error + idx">{{error}}</li>
              </ul>
              <div class="security-form-container-btns c-btns-secondary">
                <b-button @click="$router.push({name: 'LoginPages'})" class="button">Cancel</b-button>
                <b-button @click="requestLink"
                          class="button"
                          :disabled="errorPassword || errorPasswordConfirm || requiredFormFields">APPLY</b-button>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal ref="account_recovery_password_success">
      <template #body>
        <h2 class="modal-title"></h2>
        <p>Password update successful, now you can log in with your new password.</p>
      </template>
      <template #footer>
        <b-button @click="closeModal" class="button">OK</b-button>
      </template>
    </modal>
    <modal ref="account_recovery_password_error">
      <template #body>
        <h2 class="modal-title">Error.</h2>
        <p>An error occurred while trying to recover the password, please contact an administrator.</p>
      </template>
      <template #footer>
        <b-button @click="closeErrorModal">OK</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/core/components/Modal/Modal.vue';
import { validatePassword, validatePasswordMatch } from '@/core/utils';
import { AuthService } from '@/core/services'

export default {
  name: "ForgotPasswordPage",
  components: {
    Modal,
  },
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
      },
      errors: {
        password: [],
        passwordConfirm: [],
      },
    }
  },
  methods: {
    async validateForm () {
      this.errors.password = await validatePassword(this.form.password)
      this.errors.passwordConfirm = await validatePasswordMatch(this.form.password, this.form.passwordConfirm)
    },
    async requestLink () {
      const {password} = this.form
      const email = this.$route.query.email
      const token = this.$route.params.token

      if (this.errorPassword || this.errorPasswordConfirm) {
        return
      }
      AuthService.passwordRecovery(password, email, token).then((response) => {
        if (response.success === true) {
          this.showSuccessModal()
        }
      }).catch(() => {
        this.showErrorModal()
        this.form = {
          password: '',
          passwordConfirm: '',
        }
      })
    },
    showSuccessModal: function () {
      return this.$refs["account_recovery_password_success"].showModal()
    },
    closeModal: function () {
      return this.$refs["account_recovery_password_success"].hideModal().then(() => {
        this.$router.push({name: 'LoginPages'})
      })
    },
    showErrorModal: function () {
      return this.$refs["account_recovery_password_error"].showModal()
    },
    closeErrorModal: function () {
      return this.$refs["account_recovery_password_error"].hideModal()
    },
  },
  computed: {
    errorPassword () {
      return this.errors.password.length !== 0
    },
    errorPasswordConfirm () {
      return this.errors.passwordConfirm.length !== 0
    },
    requiredFormFields () {
      return this.form.password.length === 0 || this.form.passwordConfirm.length === 0
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.wrapper-security-page
  .wrapper-security-form
    width 100%
    padding-top 60px
    flexbox(flex)
    align-items(center)
    flex-direction(column)
    margin-bottom 100px
    @media (max-width 520px)
      padding-top 10px
      margin-bottom 0

    .form-container
      ::v-deep
        .form-control, .error-container
          width 300px

  .security-form
    &-subtitle
      font font-opensans-bold
      font-size 24px
      color color-black-200
      text-transform uppercase
      text-align center
      margin-bottom 26px

    &-page-info
      margin-bottom 21px
      text-align center

      .required-fields
        font font-opensans-regular
        font-size 14px
        color color-black-200
        opacity 0.6
        font-style italic

    &-container-btns
      margin-top 50px
      flexbox(flex)
      align-items(center)
      justify-content(space-between)
      flex-direction(column)

      .button
        margin 5px

      &.c-btns-secondary
        margin-top 40px
        flex-direction(row)
        justify-content(center)

  .form-group--error
    border-color red

  .error
    width 100%
    font font-opensans-bold
    font-size 11px
    color red

</style>
