import {
  FETCH_ALERT_LIST_START,
  FETCH_ALERT_LIST_DATA,
  FETCH_ALERT_DETAIL_START,
  FETCH_ALERT_DETAIL_DATA,
  UPDATE_ALERT_DETAIL_START,
  UPDATE_ALERT_DETAIL_DATA
} from './mutation-types'
import { AlertsService } from '../../services/'

export const actions = {
  fetchAlertsList ({ commit }, filterBy) {
    commit(FETCH_ALERT_LIST_START)
    return AlertsService.getAlerts( filterBy ).then((res) => {
      const data = res.data
        const alerts = [
          ...data,
        ]
      commit(FETCH_ALERT_LIST_DATA, { alerts })
      return res.data
    })
  },
  fetchAlertsDetail ({ commit }, id) {
    if (id) {
      commit(FETCH_ALERT_DETAIL_START)

      return AlertsService.getAlertById(id).then((res) => {
        const data = res.data
        const alert = {
          ...data,
        }
        commit(FETCH_ALERT_DETAIL_DATA, alert)
      })
    } else {
      commit(FETCH_ALERT_DETAIL_DATA, {})
    }
  },
  updateAlertsDetail ({ commit }, {id,data}) {
    commit(UPDATE_ALERT_DETAIL_START)
    if (id) {
      return AlertsService.updateAlert(id, data).then((res) => {
        const data = res.data
        const alert = {
          ...data,
        }
        commit(UPDATE_ALERT_DETAIL_DATA, alert)
      })
    } else {
      commit(UPDATE_ALERT_DETAIL_DATA, {})
    }
  },
  resetAlertDetail ({ commit }) {
    commit('resetAlertDetail')
  }
}
