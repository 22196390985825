export const exportFiles = (res, name)  => {
  const url = window.URL.createObjectURL(new Blob([res]));
  const link = document.createElement('a')
  link.href = url;
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const validatePassword = (password) => {
  let errors = []

  const minLenth = (password.length >= 8)
  const maxLenth = (password.length <= 16)
  const hasNumber = (/\d/).test(password)
  const hasLowercase = (/[a-z]/).test(password)
  const hasUppercase = (/[A-Z]/).test(password)
  const hasSpecial = (/[!@#$%^&*)(+=._-]/).test(password)

  if (!minLenth) {
    errors.push('Require a minimum of 8 characters.')
  }

  if (!maxLenth) {
    errors.push('Require a maximum of 16 characters.')
  }

  if (!hasNumber) {
    errors.push('Require Numeric characters.')
  }

  if (!hasLowercase) {
    errors.push('Require Lowercase characters.')
  }

  if (!hasUppercase) {
    errors.push('Require Uppercase characters.')
  }

  if (!hasSpecial) {
    errors.push('Requires at least one special character !@#$%^&*)(+=._-.')
  }
  return errors
}
export const validatePasswordMatch = (password, passwordConfirm) => {
  let errors = []

  if (passwordConfirm.length === 0) {
    errors.push('Field required.')
  }

  if (password !== passwordConfirm) {
    errors.push('Passwords must match.')
  }
  return errors
}
