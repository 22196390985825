import {
  FETCH_TEACHER_LIST_START,
  FETCH_TEACHER_LIST_DATA,
  FETCH_TEACHER_DETAIL_DATA,
  FETCH_TEACHER_CLASSROOMS_DATA,
  FETCH_TEACHER_CLASSROOMS_START,
  ADD_TEACHER_TOKENS_START,
  ADD_TEACHER_TOKENS_DATA
} from './mutation-types'

export const
  mutations = {
    [FETCH_TEACHER_LIST_START](state) {
      state.loadingTeachers = true
    },
    [FETCH_TEACHER_CLASSROOMS_START](state) {
      state.loadingClassrooms = true
    },
    [FETCH_TEACHER_LIST_DATA](state, data) {
      state.teachers = data
      state.loadingTeachers = false
    },
    [FETCH_TEACHER_DETAIL_DATA](state, teacherDetail) {
      state.teacherDetail = teacherDetail
      state.loadingTeachers = false
    },
    [FETCH_TEACHER_CLASSROOMS_DATA](state, classrooms) {
      state.teacherClassrooms = classrooms
      state.loadingClassrooms = false
    },
    [ADD_TEACHER_TOKENS_START](state) {
      state.addingTokens = true
    },
    [ADD_TEACHER_TOKENS_DATA](state) {
      state.addingTokens = false
    },
  }
