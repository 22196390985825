<template>
  <div>
    <modal ref="modalDetail"
           id="modal-organization-detail"
           modal-class="modal-task-detail"
           @hide="closeModalParentOrg()"
           :hide-close-button="true">
      <template #body>
        <a class="back">
          <span @click="closeModalParentOrg()"><i class="icon-caret-left"></i> Back</span>
        </a>
        <section class="container-body">
            <h2 class="modal-title text-center">{{ organizationTitle }}</h2>

            <custom-input v-model="$v.parentOrgName.$model"
                          label="Parent Organization Name*"
                          invalid-feedback="Parent organization name is required"
                          :maxlength="254"
                          :error="!$v.parentOrgName.required && $v.parentOrgName.$dirty"/>

            <b-col class="container-actions">
              <select-dropdown :options="states"
                               v-model="stateSelected"
                               @input="handleActions"
                               class="--secundary"
                               placeholder="States"
                               value-field="id"/>
              <search-input class="search-action"
                            v-model="search"
                            placeholder="SEARCH"
                            @on-search="handleActions"/>
            </b-col>

            <b-form-checkbox v-model="isDpaSigned"
                             class="custom-checkbox checkbox-lg">
              DPA completed
            </b-form-checkbox>

            <div class="dual-list-box">
              <div class="first-list">
                <h3 class="labels">List of schools without parent organization</h3>
                <b-list-group>
                  <b-list-group-item v-for="schools in paginatedSchools"
                                     :key="schools.id"
                                     href="#"
                                     :active="schools.selected"
                                     @click="selectSchools(schools.id)">{{schools.name}}</b-list-group-item>
                </b-list-group>

                <b-pagination v-show="showPagination"
                              v-model="currentPage"
                              :total-rows="schoolsList.length"
                              :per-page="perPage"
                              aria-controls="teachers-table"
                              first-number
                              last-number
                              align="fill"
                              size="sm"
                              class="my-2">
                  <template #prev-text><i class="icon-chevron-left"></i></template>
                  <template #next-text><i class="icon-chevron-right"></i></template>
                </b-pagination>

                <b-overlay :show="loadingSchools"
                           no-wrap
                           spinner-medium
                           rounded="sm"/>
              </div>
              <div class="actions">
                <b-button @click="addAssociatedSchool" class="button button--add">Add</b-button>
                <b-button @click="$refs.deleteSchools.showModal()" :disabled="!schoolsByRemover.length" class="button button--remove">Remove</b-button>
              </div>
              <div class="second-list">
                <h3 class="labels">List of associated schools</h3>
                <b-list-group>
                  <b-list-group-item v-for="schools in associatedSchoolsList"
                                     :key="schools.id"
                                     :active="schools.selected"
                                     href="#"
                                     @click="selectAssociatedSchoolsList(schools.id)">{{schools.name}}</b-list-group-item>
                </b-list-group>
              </div>
            </div>
        </section>
      </template>
      <template #footer>
        <b-button @click="openModalDelete" :disabled="isCreateOrganization" v-if="!isCreateOrganization" class="button b-ligth">Delete Parent Org</b-button>
        <b-button @click="closeModalParentOrg()" class="button b-ligth">Cancel</b-button>
        <b-button @click="createParentOrganization" class="button">Ok</b-button>
        <b-overlay :show="loadingOrganization" no-wrap spinner-medium rounded="sm"/>
      </template>
    </modal>
    <modal ref="deleteParent" id="delete-parent-modal" modal-class="delete">
      <template #header>
      </template>
      <template #body>
        <p class="text-center">
          This parent organization and school links to this parent organization will be deleted. Are you sure?
        </p>
      </template>
      <template #footer>
        <b-button @click="closeModalDelete" class="button">Cancel</b-button>
        <b-button class="button b-ligth" @click="deleteParent()">Ok</b-button>
      </template>
    </modal>
    <modal ref="deleteSchools" id="delete-school-organization-modal" modal-class="delete">
      <template #header>
      </template>
      <template #body>
        <p class="text-center">
          Do you want to remove this schools from the parent organization?
        </p>
        <ul class="schools-list-container">
          <li v-for="school in schoolsByRemover" :key="school.id">{{school.name}}</li>
        </ul>
      </template>
      <template #footer>
        <b-button @click="$refs.deleteSchools.hideModal()" class="button">Cancel</b-button>
        <b-button class="button b-ligth" @click="removeAssociatedSchool">Ok</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal, CustomInput, SelectDropdown, SearchInput} from '@/core/components'
import {CourseMixins, UtilsMixins} from '../Mixins'
import {required} from 'vuelidate/lib/validators'
import {TeacherService, ParentOrganizationService} from '@/core/services'

export default {
  name: 'ModalParentOrgDetail',
  components: {
    Modal,
    CustomInput,
    SelectDropdown,
    SearchInput
  },
  mixins: [CourseMixins, UtilsMixins],
  props: {
    organizationId: {
      default: null
    }
  },
  data () {
    return {
      perPage: 30,
      currentPage: 1,
      loadingOrganization: true,
      parentOrgName: '',
      schoolsList: [],
      associatedSchoolsList: [],
      states: [],
      stateSelected: 'international',
      search: '',
      loadingSchools: false,
      isDpaSigned: false
    }
  },
  validations: {
    parentOrgName: {
      required
    }
  },
  methods: {
    async handleActions() {
      this.loadingSchools = true
      await this.getSchools()
      this.loadingSchools = false
    },
    async getSchools() {
      const dataSchool = {
        "international": 1,
        "country_id": null,
        "state_id": null,
        "city_id": null,
        "not_in_parent_organization_id": this.organizationId,
        "name": ""
      }
      if (this.stateSelected !== 'international') {
        dataSchool.state_id = this.stateSelected
        dataSchool.international = 0
      } else {
        dataSchool.international = 1
      }
      if (this.search) {
        dataSchool.name = this.search
      }
      await ParentOrganizationService.getSchools(dataSchool).then(({data}) => {
        this.schoolsList = data
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error loading schools'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      })
    },
    getAssociatedSchools() {
      ParentOrganizationService.getParentOrganizationsAssociated(this.organizationId).then(({data}) => {
        this.associatedSchoolsList = data.schools
      })
    },
    closeModalParentOrg() {
      this.$v.$reset()
      this.parentOrgName = ''
      this.isDpaSigned = false
      this.stateSelected = 'international'
      this.search = ''
      this.$refs.modalDetail.hideModal()
    },
    async openModalParentOrg() {
      this.$refs.modalDetail.showModal()
      this.loadingOrganization = true
      this.currentPage = 1
      await this.getSchools()
      if (this.organizationId) {
        await this.getAssociatedSchools()
        await this.getParentOrganizationById()
        return
      }
      this.associatedSchoolsList = []
      this.loadingOrganization = false
    },
    openModalDelete() {
      this.$refs.deleteParent.showModal()
    },
    closeModalDelete() {
      this.$refs.deleteParent.hideModal()
    },
    closeModalDeleteSchools() {
      this.$refs.deleteSchools.hideModal()
    },
    getParentOrganizationById() {
      this.loadingOrganization = true
      ParentOrganizationService.getParentOrganizationById(this.organizationId).then((res) => {
        this.parentOrgName = res.data.name
        this.isDpaSigned = !!res.data.is_dpa_signed
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error Create Parent Organization'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }).finally(() => this.loadingOrganization = false)
    },
    deleteParent() {
      this.loadingOrganization = true
      ParentOrganizationService.deleteParentOrganization(this.organizationId).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error Create Parent Organization'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }).finally(() => {
        this.loadingOrganization = false
        this.closeModalParentOrg()
        this.closeModalDelete()
        this.$emit('organization-saved')
      })
    },
    async createParentOrganization() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.loadingOrganization = true
      let newOrganizationId
      if (this.organizationId) {
        await ParentOrganizationService.updateParentOrganization(this.organizationId, {name: this.parentOrgName, is_dpa_signed: this.isDpaSigned}).then(() => {
          this.$bvModal.msgBoxOk('Parent organization updated successful!.').then(() => {
            this.$emit('organization-saved')
            this.closeModalParentOrg()
          })
        }).catch((e) => {
          const errorMessage = e.message ? e.message : 'Error Update Parent Organization'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        })
      } else {
        await ParentOrganizationService.createParentOrganization({name: this.parentOrgName, is_dpa_signed: this.isDpaSigned}).then(({data}) => {
          newOrganizationId = data.id
          this.$bvModal.msgBoxOk('Successful!').then(() => this.closeModalParentOrg())
        }).catch((e) => {
          const errorMessage = e.message ? e.message : 'Error Create Parent Organization'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        })
      }

      const isSchoolToAdd = this.associatedSchoolsList.filter((school) => school.add)
      if (isSchoolToAdd.length) {
        const schoolsId = await isSchoolToAdd.map(school => {
          return {id: school.id}
        })
        await ParentOrganizationService.addSchools(this.organizationId || newOrganizationId, {schools: schoolsId})
      }

      const isSchoolToRemove = await this.schoolsList.filter((school) => school.remove)
      if (isSchoolToRemove.length) {
        const schoolsId = await isSchoolToRemove.map(school => {
          return {id: school.id}
        })
        await ParentOrganizationService.deleteSchools(this.organizationId || newOrganizationId, {schools: schoolsId})
      }

      this.$emit('organization-saved')
      this.loadingOrganization = false
    },
    addAssociatedSchool () {
      this.schoolsList.map(({...school}) => {
        if (school.selected) {
          school.selected = false
          school.add = true
          this.associatedSchoolsList.push(school)
        }
      })
      this.schoolsList = this.schoolsList.filter((school) => !school.selected)
    },
    removeAssociatedSchool () {
      this.associatedSchoolsList.map(({...school}) => {
        if (school.selected) {
          school.selected = false
          school.remove = true
          this.schoolsList.push(school)
        }
      })
      this.associatedSchoolsList = this.associatedSchoolsList.filter((school) => !school.selected)
      this.$refs.deleteSchools.hideModal()
    },
    selectSchools(id) {
      this.schoolsList = this.schoolsList.map((school) => {
        if (school.id === id) {
          school.selected = !school.selected
        }
        return school
      })
    },
    selectAssociatedSchoolsList(id) {
      this.associatedSchoolsList = this.associatedSchoolsList.map((school) => {
        if (school.id === id) {
          school.selected = !school.selected
        }
        return school
      })
    }
  },
  async mounted () {
    const usaId = 237
    await TeacherService.states(usaId).then(({data}) => {
      this.states = [
        {
          name: 'International',
          id: 'international'
        },
        ...data
      ]
    })
  },
  computed: {
    organizationTitle () {
      return this.organizationId ? 'EDIT PARENT ORGANIZATION' : 'CREATE NEW PARENT ORGANIZATION'
    },
    isCreateOrganization() {
      return !this.organizationId
    },
    schoolsByRemover() {
      return this.associatedSchoolsList.filter((school) => school.selected)
    },
    showPagination() {
      return this.schoolsList.length > this.perPage
    },
    paginatedSchools() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.schoolsList.slice(start, end);
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

a.back
  position absolute
  left -5px
  top 1.8rem
  color color-blue-100
  font font-opensans-bold
  font-size 17px
  text-decoration none
  margin-top -2rem !important
  padding 5px
  margin-bottom 1rem
  width 100%
  border-bottom 2px solid color-gray-400

  .icon-caret-left
    color color-blue-100

  span
    cursor pointer

.container-actions
  display flex
  justify-content space-between
  padding 0

  .--secundary
    max-width 400px
    margin-right 10px
    margin-top 10px

  .search-action
    margin-top 10px

.container-body
  width 95%
  margin 5.25rem auto

  .custom-checkbox
    margin-top 20px

.modal-title
  margin-bottom 62px

.modal-title.text-center
  overflow-wrap break-word

.dual-list-box
  display flex
  margin-top 18px
  justify-content space-between
  @media (max-width 550px)
    flex-direction column

  .first-list, .second-list
    width 100%
    position relative
    @media (max-width 700px)
      max-width 180px
    @media (max-width 550px)
      max-width none

    .list-group
      border 1px solid color-black-200
      border-radius 5px
      max-height 225px
      height 225px
      overflow auto

      .list-group-item
        word-break break-word
        margin 1px 0

  .actions
    display flex
    justify-content center
    align-items center
    flex-direction column
    padding 0 10px
    @media (max-width 550px)
      padding 10px

    .button
      margin 5px

    .button--add, .button--remove
      min-width auto
      width 94px

.error
  width 100%
  text-align center
  margin-bottom 20px
  margin-top 5px
  font font-opensans-bold
  font-size 11px
  color red

.labels
  margin 2px 0
  font font-opensans-regular
  font-size 12px
  color color-black-200
  height 30px
  margin-bottom 10px

</style>
<style lang="stylus">
#modal-organization-detail
  .modal-dialog
    @media (min-width 576px)
      max-width 95%
    @media (min-width 900px)
      max-width 70%

#delete-parent-modal
  .modal-dialog
    min-width unset !important

    .modal-body
      width auto
      min-height 0
      max-height 80px

      .close
        padding 0

#delete-school-organization-modal
  .modal-dialog
    min-width unset !important

    .modal-body
      width auto
      min-height 0
      max-height fit-content
      margin-bottom 0

      .close
        padding 0

      .body
        padding 15px

    .schools-list-container
      margin-top 15px

      li
        font font-opensans-regular
        font-size 14px
        color color-black-200
        word-break break-all

.modal-backdrop
  width 100% !important
  height 100% !important
</style>
