<template>
  <div>
    <modal ref="modalDetail"
           modal-class="modal-task-detail"
           @hide="closeModalDistributeExchange()"
           :hide-close-button="true">
      <template #body>
        <a class="back">
          <span @click="closeModalDistributeExchange()"><i class="icon-caret-left"></i> Back</span>
        </a>
        <section class="container-body">
          <b-overlay :show="loadingParentOrg"
                     spinner-medium
                     rounded="sm">

            <h2 class="modal-title text-center">Distribute Available Exchanges</h2>

            <b-form>
              <b-form-group label-cols="4"
                            label="Languages & Cultures Exchange(s):"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.languagesAndCulturesExchange"
                              autocomplete="off"
                              readonly
                              disabled
                              type="number"/>
              </b-form-group>
              <b-form-group label-cols="4"
                            label="Arts & Sciences Exchange(s):"
                            class="subtitle"
                            label-for="input-sm">
                <b-form-input v-model="form.artsAndSciencesExchange"
                              autocomplete="off"
                              readonly
                              disabled
                              type="number"/>
              </b-form-group>
            </b-form>

            <b-table :responsive="true"
                     hover
                     sort-icon-left
                     :fields="fields"
                     :items="teachers"
                     table-variant="ligth"
                     head-variant="ligth"
                     no-local-sorting
                     ref="table">
              <template #cell(name)="data">
                <span class="a-title title--label">{{ data.item.first_name + ' ' + data.item.last_name }}</span>
              </template>
              <template #cell(school)="data">
                <span class="a-title title--label">{{ data.item.school }}</span>
              </template>
              <template #cell(world_languages)="data">
                <b-form-spinbutton class="sb-input-exchanges"
                                   v-model="data.item.languagesAndCulturesExchanges"
                                   :max="form.languagesAndCulturesExchange + data.item.languagesAndCulturesExchanges"
                                   :min="0"
                                   @input="handleInput"/>
                <p class="label-used">Used: {{data.item.languagesAndCulturesExchangesUsed}}</p>
              </template>
              <template #cell(non_world_languages)="data">
                <b-form-spinbutton class="sb-input-exchanges"
                                   v-model="data.item.artsAndSciencesExchanges"
                                   :max="form.artsAndSciencesExchange + data.item.artsAndSciencesExchanges"
                                   :min="0"
                                   @input="handleInput"/>
                <p class="label-used">Used: {{data.item.artsAndSciencesExchangesUsed}}</p>
              </template>
            </b-table>
          </b-overlay>
        </section>
      </template>
      <template #footer>
        <div v-if="false" class="error">Invalid exchange amount(s)</div>
        <div class="container-buttons">
          <b-button @click="closeModalDistributeExchange" class="button  b-ligth">Cancel</b-button>
          <b-button @click="saveDistribute" :disabled="!exchangesIncrement.length && !exchangesDecrement.length" class="button">Ok</b-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from '@/core/components'
import {CourseMixins, UtilsMixins} from '../Mixins'
import {ParentOrganizationService} from '@/core/services'

export default {
  name: 'ModalDistributeExchanges',
  components: {
    Modal,
  },
  mixins: [CourseMixins, UtilsMixins],
  props: {
    tokensAvailable: {
      default: () => {
        return {
          languagesAndCulturesExchange: null,
          artsAndSciencesExchange: null
        }
      }
    },
    organizationId: {
      type: [Number, String],
    }
  },
  data () {
    return {
      form: {
        languagesAndCulturesExchange: 0,
        artsAndSciencesExchange: 0,
      },
      fields: [
        {
          key: 'name',
          label: 'Teacher Name'
        },
        {
          key: 'school',
          label: 'School Name'
        },
        {
          key: 'world_languages',
          label: 'Number of Languages & Cultures Exchanges'
        },
        {
          key: 'non_world_languages',
          label: 'Number of Arts & Sciences Exchanges'
        }
      ],
      teachers: [],
      teachersData: [
        {
          id: 1,
          name: 'Pedro Tant',
          school: 'school 1',
          languagesAndCulturesExchanges: 2,
          artsAndSciencesExchanges: 0
        },
        {
          id: 2,
          name: 'Lord D',
          school: 'school 2',
          languagesAndCulturesExchanges: 8,
          artsAndSciencesExchanges: 0
        },
        {
          id: 3,
          name: 'Tiny First',
          school: 'school 3',
          languagesAndCulturesExchanges: 0,
          artsAndSciencesExchanges: 0
        },
      ],
      loadingParentOrg: false,
      exchangesIncrement: [],
      exchangesDecrement: [],
      languagesAndCulturesDistribution: [],
      artsAndSciencesExchangesDistribution: [],
    }
  },
  methods: {
    async handleInput() {
      this.form.languagesAndCulturesExchange = this.tokensAvailable.languagesAndCulturesExchange
      this.form.artsAndSciencesExchange = this.tokensAvailable.artsAndSciencesExchange
      await this.updateTeacherList()
      this.languagesAndCulturesDistribution.map((tokens) => {
        this.form.languagesAndCulturesExchange = this.form.languagesAndCulturesExchange - tokens
      })
      this.artsAndSciencesExchangesDistribution.map((tokens) => {
        this.form.artsAndSciencesExchange = this.form.artsAndSciencesExchange - tokens
      })
    },
    isInteger(number) {
      return Number.isInteger(number) && number >= 0
    },
    closeModalDistributeExchange () {
      this.form = {
        languagesAndCulturesExchange: '',
        artsAndSciencesExchange: '',
      }
      this.$refs.modalDetail.hideModal()
    },
    openModalDistributeExchanges () {
      this.$refs.modalDetail.showModal()
      this.form = {
        languagesAndCulturesExchange: this.tokensAvailable.languagesAndCulturesExchange,
        artsAndSciencesExchange: this.tokensAvailable.artsAndSciencesExchange,
      }
      this.exchangesIncrement = []
      this.exchangesDecrement = []
      this.getTeachersByParentOrganization()
    },
    getTeachersByParentOrganization() {
      this.loadingParentOrg = true
      ParentOrganizationService.getTeachersByParentOrganization(this.organizationId).then((res) => {
        const teachersList = []
        const subjectsName = {
          languagesAndCultures: 'Languages & Cultures',
          artsAndSciences: 'Arts & Sciences',
        }
        res.data.map((item) => {
          if (item.teachers.length) {
            const newTeachers = item.teachers.map(({...teacherItem}) => {
              return {
                ...teacherItem,
                school: item.name,
                languagesAndCulturesExchanges: teacherItem.exchange_tokens_purchase.find((exchange) => exchange.name === subjectsName.languagesAndCultures)?.available_tokens_count ?? 0,
                artsAndSciencesExchanges: teacherItem.exchange_tokens_purchase.find((exchange) => exchange.name === subjectsName.artsAndSciences)?.available_tokens_count ?? 0,
                languagesAndCulturesExchangesUsed: teacherItem.exchange_tokens_purchase.find((exchange) => exchange.name === subjectsName.languagesAndCultures)?.used_tokens_count ?? 0,
                artsAndSciencesExchangesUsed: teacherItem.exchange_tokens_purchase.find((exchange) => exchange.name === subjectsName.artsAndSciences)?.used_tokens_count ?? 0
              }
            })
            teachersList.push(...newTeachers)
          }
        })
        this.teachersData = teachersList
        this.teachers = JSON.parse(JSON.stringify(this.teachersData))
      }).finally(() => {
        this.loadingParentOrg = false
      })
    },
    updateTeacherList() {
      this.exchangesIncrement = []
      this.exchangesDecrement = []
      this.languagesAndCulturesDistribution = []
      this.artsAndSciencesExchangesDistribution = []

      for (let i = 0; i < this.teachersData.length; i++) {
        const teacherList = this.teachersData[i]
        const newTeacherList = this.teachers[i]

        if (teacherList.languagesAndCulturesExchanges !== newTeacherList.languagesAndCulturesExchanges) {
          if ((teacherList.languagesAndCulturesExchanges - newTeacherList.languagesAndCulturesExchanges) > 0) {
            this.exchangesDecrement.push({
              teacher_id: teacherList.id,
              amount_tokens: -(teacherList.languagesAndCulturesExchanges - newTeacherList.languagesAndCulturesExchanges),
              subject_id: 1,
              organization_id: this.organizationId
            })
            this.languagesAndCulturesDistribution.push(-(teacherList.languagesAndCulturesExchanges - newTeacherList.languagesAndCulturesExchanges))
          } else {
            this.exchangesIncrement.push({
              teacher_id: teacherList.id,
              amount_tokens: Math.abs(teacherList.languagesAndCulturesExchanges - newTeacherList.languagesAndCulturesExchanges),
              subject_id: 1,
              organization_id: this.organizationId
            })
            this.languagesAndCulturesDistribution.push(Math.abs(teacherList.languagesAndCulturesExchanges - newTeacherList.languagesAndCulturesExchanges))
          }
        }

        if (teacherList.artsAndSciencesExchanges !== newTeacherList.artsAndSciencesExchanges) {
          if ((teacherList.artsAndSciencesExchanges - newTeacherList.artsAndSciencesExchanges) > 0) {
            this.exchangesDecrement.push({
              teacher_id: teacherList.id,
              amount_tokens: -(teacherList.artsAndSciencesExchanges - newTeacherList.artsAndSciencesExchanges),
              subject_id: 2,
              organization_id: this.organizationId
            })
            this.artsAndSciencesExchangesDistribution.push(-(teacherList.artsAndSciencesExchanges - newTeacherList.artsAndSciencesExchanges))
          } else {
            this.exchangesIncrement.push({
              teacher_id: teacherList.id,
              amount_tokens: Math.abs(teacherList.artsAndSciencesExchanges - newTeacherList.artsAndSciencesExchanges),
              subject_id: 2,
              organization_id: this.organizationId
            })
            this.artsAndSciencesExchangesDistribution.push(Math.abs(teacherList.artsAndSciencesExchanges - newTeacherList.artsAndSciencesExchanges))
          }
        }
      }
    },
    async saveDistribute() {
      this.loadingParentOrg = true
      const errors = []
      const newDistributionDecrement = await Promise.all(
        this.exchangesDecrement.map(async (teacher) => {
          return await this.assignationExchanges(teacher)
        })
      ).catch(e => {
        errors.push(e.message)
        const errorMessage = e.message ? e.message : 'Error creating alert.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      })
      let newDistributionIncrement
      if (!errors.length) {
         newDistributionIncrement = await Promise.all(
          this.exchangesIncrement.map(async (teacher) => {
            return await this.assignationExchanges(teacher)
          })
        ).catch(e => {
          errors.push(e.message)
          const errorMessage = e.message ? e.message : 'Error creating alert.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        })
      }
      console.log(newDistributionDecrement, newDistributionIncrement);
      errors.length || this.$bvModal.msgBoxOk('Exchanges assigned successfully.')
      this.$emit('success-assignation-exchanges')
      this.getTeachersByParentOrganization()
      this.closeModalDistributeExchange()
      this.loadingParentOrg = false
    },
    assignationExchanges(teacher) {
      return new Promise((resolve, reject) => {
        ParentOrganizationService.assignationExchanges(teacher).then((response) => {
          resolve(response)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

a.back
  position absolute
  left -5px
  top 1.8rem
  color color-blue-100
  font font-opensans-bold
  font-size 17px
  text-decoration none
  margin-top -2rem !important
  padding 5px
  margin-bottom 1rem
  width 100%
  border-bottom 2px solid color-gray-400

  .icon-caret-left
    color color-blue-100

  span
    cursor pointer

.container-body
  width 100%
  margin 5.25rem auto 0

.actions-item-dropdown
  margin-top 15px
  max-width 50%
  display block
  @media(max-width 600px)
    max-width 100%

.actions-item-dropdown--error
  ::v-deep
    .dropdown-toggle
      border-color color-red-error !important

.labels
  margin-top 10px
  font font-opensans-semibold
  color color-black-400

.label-used
  text-align center
  font-size 13px !important
  margin-top 4px !important
  font-weight 400

.custom-checkbox
  margin-bottom 12px
  margin-left 10px

.modal-title
  margin-bottom 62px

.modal-title.text-center
  overflow-wrap break-word

.error
  width 100%
  text-align center
  margin-bottom 20px
  margin-top 5px
  font font-opensans-bold
  font-size 11px
  color red

.container-buttons
  .button
    margin 5px

.title--label
  text-overflow ellipsis
  overflow hidden
  max-width 120px
  display block

.sb-input-exchanges
  margin-bottom 0 !important

</style>
<style lang="stylus">
#delete-task-modal
  .modal-dialog
    min-width unset !important

    .modal-body
      width auto

      .close
        padding 0

</style>
