<template>
  <b-col md="12">
    <b-row class="my-2">
      <b-table responsive
               id="report-table"
               :fields="fields"
               sort-icon-left
               :items="monthlyStatements"
               table-variant="ligth"
               head-variant="ligth">
        <template #cell(accounting_year)="data">{{ data.item.accounting_year }}</template>
        <template #cell(accounting_month)="data">{{ data.item.accounting_month }}</template>
        <template #cell(file_name)="data">{{ data.item.file_name }}</template>
        <template #cell(file_zip_url)="data">
          <span class="link"
            @click="exportMonthlyStatements(data.item.file_zip_url)">{{ data.item.file_zip_url }}</span>
        </template>
      </b-table>
    </b-row>
    <b-overlay :show="isLoading" spinner-medium rounded="sm" no-wrap/>

  </b-col>
</template>

<script>
import {MonthlyStatementsService} from '@/core/services'
import moment from "moment"
export default {
  name: 'MonthlyStatements',
  components: {

  },
  data() {
    return {
      monthlyStatements: [],
      isLoading: false,
      fields: [
        {key: 'accounting_year', label: 'ACCOUNTING YEAR', sortable: true},
        {key: 'accounting_month', label: 'ACCOUNTING MONTH', sortable: true},
        {key: 'file_name', label: 'FILE NAME', sortable: true},
        {key: 'file_zip_url', label: 'DOWNLOAD LINK', sortable: true},
      ],
    }
  },
  computed: {
  },
  methods: {
    async exportMonthlyStatements (urlStatement) {
      let urlSignature
      const key = await urlStatement.slice(40)
      const bucket = process.env.VUE_APP_S3_BUCKET_STUDENTS
      await MonthlyStatementsService.getSignature(key, bucket, 'get_object').then((res) => {
        urlSignature = res.url
      })

      const link = document.createElement('a')
      link.href = urlSignature
      link.setAttribute('download', 'Purchase.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    setDate (date) {
      return moment(date).format('M/D/YYYY')
    },
  },
  async mounted() {
    this.isLoading = true,
    await MonthlyStatementsService.getMonthlyStatements().then(({data}) => {
      this.monthlyStatements = data
    }).finally(() => this.isLoading = false)
  }
}
</script>

<style lang="stylus" scoped>

.link
  cursor pointer
  text-decoration underline

</style>
