import ViewAdminPanel from '../views/adminPanel.vue'
import {
  CourseContent,
  CourseDetail,
  Partnerships,
  TeacherContent,
  SubjectsList,
  SubjectsDetail,
  ExchangesList,
  ExchangeDetail,
  PurchaseReport,
  MonthlyStatements,
  AlertDetail,
  AlertsList,
  VideoReport,
  AdministerAdmins,
  ActionItems,
  ParentOrgList,
  SchoolsList,
  LibraryConnectionList
} from '../components'

const routes = [
  {
    path: '/',
    component: ViewAdminPanel,
    redirect: {name: 'CoursesList'},
    children: [
      {
        path: 'PanelAdmin',
        name: 'CoursesList',
        component: CourseContent,
        meta: {
          isAuthenticate: true,
          adminCourseEditor: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/Course/:id',
        name: 'CoursesDetail',
        component: CourseDetail,
        meta: {
          isAuthenticate: true,
          adminCourseEditor: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/Course',
        name: 'DetailCourse',
        component: CourseDetail,
        meta: {
          isAuthenticate: true,
          adminCourseEditor: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/Teacher/:id?',
        name: 'TeacherContent',
        component: TeacherContent,
        meta: {
          isAuthenticate: true,
          customerServiceAgent: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/Partnership',
        name: 'Partnership',
        component: Partnerships,
        meta: {
          isAuthenticate: true,
          customerServiceAgent: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/subjects',
        name: 'Subjects',
        component: SubjectsList,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'PanelAdmin/subjects/:id',
        name: 'SubjectsDetail',
        component: SubjectsDetail,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'PanelAdmin/pricing',
        name: 'Pricing',
        component: ExchangesList,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'PanelAdmin/pricing/:id?',
        name: 'PricingDetail',
        component: ExchangeDetail,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'PanelAdmin/purchaseReport',
        name: 'PurchaseReport',
        component: PurchaseReport,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'PanelAdmin/monthlyStatements',
        name: 'MonthlyStatements',
        component: MonthlyStatements,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'PanelAdmin/alerts',
        name: 'Alerts',
        component: AlertsList,
        meta: {
          isAuthenticate: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/alerts/:id?',
        name: 'AlertDetail',
        component: AlertDetail,
        meta: {
          isAuthenticate: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/administerAdmins',
        name: 'AdministerAdmins',
        component: AdministerAdmins,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'VideoReport/:id',
        name: 'VideoReport',
        component: VideoReport,
        meta: {
          isAuthenticate: true,
        }
      },
      {
        path: 'PanelAdmin/actions',
        name: 'ActionItems',
        component: ActionItems,
        meta: {
          isAuthenticate: true,
          adminCourseEditor: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/parentOrganization',
        name: 'ParentOrgList',
        component: ParentOrgList,
        meta: {
          isAuthenticate: true
        }
      },
      {
        path: 'PanelAdmin/schools',
        name: 'SchoolsList',
        component: SchoolsList,
        meta: {
          isAuthenticate: true,
          customerServiceAgent: true,
          isTestAdmin: true
        }
      },
      {
        path: 'PanelAdmin/library',
        name: 'LibraryConnectionList',
        component: LibraryConnectionList,
        meta: {
          isAuthenticate: true,
          customerServiceAgent: true,
        }
      },
    ]
  }
]
export default routes
