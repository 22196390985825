import {
  FETCH_SUBJECT_LIST_START,
  FETCH_SUBJECT_LIST_DATA,
  FETCH_SUBJECT_DETAIL_START,
  FETCH_SUBJECT_DETAIL_DATA
} from './mutation-types'
export const
  mutations = {
    [FETCH_SUBJECT_LIST_START] (state) {
      state.loadingSubjects = true
    },
    [FETCH_SUBJECT_LIST_DATA] (state, { subjects }) {
      state.subjects = subjects
      state.loadingSubjects = false
    },
    [FETCH_SUBJECT_DETAIL_START] (state) {
      state.loadingSubjectsData  = true
    },
    [FETCH_SUBJECT_DETAIL_DATA] (state, data) {
      state.subjectDetail = Object.assign(state.subjectDetailDefault, data)
      state.loadingSubjectsData  = false
    },
    resetSubjectDetail(state) {
      state.subjectDetail = Object.assign({}, state.subjectDetailDefault)
    }
  }
