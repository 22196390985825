import { http } from './http'

const resource = 'api/admin'

export default {

  getAgeRanges () {
    return http.get(`${resource}/age-ranges`).then(response => {
      return response
    })
  }

}
