export const getters = {
  getAlerts: (state) => {
    return state.alerts
  },
  isLoadingAlerts: (state) => {
    return state.loadingAlerts
  },
  loadingAlertsData: (state) => {
    return state.loadingAlertsData
  },
  updatingAlertsData: (state) => {
    return state.updatingAlertsData
  },
  getAlertDetail: (state) => {
    return state.alertDetail
  },
}
