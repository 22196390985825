const FETCH_EXCHANGE_LIST_START = 'FETCH_EXCHANGE_LIST_START'
const FETCH_EXCHANGE_LIST_DATA = 'FETCH_EXCHANGE_LIST_DATA'
const FETCH_TASK_LIST_DATA = 'FETCH_TASK_LIST_DATA'
const FETCH_EXCHANGE_DETAIL_START = 'FETCH_EXCHANGE_DETAIL_START'
const FETCH_EXCHANGE_DETAIL_DATA = 'FETCH_EXCHANGE_DETAIL_DATA'
const UPDATE_EXCHANGE_DETAIL_START = 'UPDATE_EXCHANGE_DETAIL_START'
const UPDATE_EXCHANGE_DETAIL_DATA = 'UPDATE_EXCHANGE_DETAIL_DATA'

export {
  FETCH_EXCHANGE_LIST_START,
  FETCH_EXCHANGE_LIST_DATA,
  FETCH_EXCHANGE_DETAIL_DATA,
  FETCH_EXCHANGE_DETAIL_START,
  FETCH_TASK_LIST_DATA,
  UPDATE_EXCHANGE_DETAIL_START,
  UPDATE_EXCHANGE_DETAIL_DATA
}
