<template>
<section>
  <b-row class="align-items-center justify-content-between">
    <div class="col-auto">
      <div class="logo">
        <img :src="require('@/assets/images/luv-logo-wide.png')" class="wide-logo" alt="LUV">
      </div>
    </div>
    <div class="col-auto d-flex">
      <b-navbar-nav>
        <b-nav-item-dropdown menu-class="dropdown-menu-user">
          <template #button-content>
            <b-avatar :src="getUser.avatar"></b-avatar>
          </template>
          <b-dropdown-item @click="logout" >LOG OUT</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
  </b-row>
</section>
</template>

<script>
import { AppStorage } from '@/core/utils'
import { UserMixins } from '@/modules/panel/Mixins'
export default {
  name: 'HeaderLayout',
  mixins: [UserMixins],
  methods: {
    logout () {
      AppStorage.clear()
      this.$router.push('/')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
section {
  width 100%
  height 80px
  padding .5rem 7rem 0.5rem 2rem
  margin 0
  border-bottom 2px solid color-gray-400
  @media (max-width 600px) {
    padding .5rem 1rem 0.5rem 2rem
  }
}
.logo
  .wide-logo
    width 240px
    @media (max-width 400px) {
      width 180px
    }

.dropdown-menu-user {
  border 1px solid color-gray-400
  border-radius 7px
  width 2rem
  padding 2px
  margin 0
}
span, .icon-bell:before {
  color color-blue-100
}
</style>
