<template>
  <b-dropdown :class="classSelectDropdown" style="padding: 0;" :disabled="disabled" no-caret>
    <template #button-content>
      <span v-if="selected.length">{{ getNamesSelected }}</span>
      <span v-else>{{ placeholder }}</span>
      <div class="custom-dropdown-button-icon icon-dropdown"></div>
    </template>
    <template>
      <b-form-checkbox-group v-model="selected"
                             @input="optionSelect">
        <b-dropdown-item v-for="(option, index) in options"
                         :key="`${_uid}-${index}`"
                         :value="option[valueField]"
                         @click.native.capture.stop>
          <b-form-checkbox
            :value="option[valueField]"
            class="custom-checkbox">
            {{ option.name }}
          </b-form-checkbox>
        </b-dropdown-item>
      </b-form-checkbox-group>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  name: "SelectMultipleDropdown",
  props: {
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    value: {
      type: [Array, Number, String],
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    classSelect: {
      type: String,
    },
    state: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    valueField:
      {
        type: String,
        default: 'id'
      }
  },
  methods: {
    optionSelect() {
      this.$emit('selected')
    },
  },
  computed: {
    classSelectDropdown() {
      const className = {
        'custom-dropdown': true
      }

      className[this.classSelect] = !!this.classSelect
      className[this.state] = !!this.state
      return {
        ...className
      }
    },
    selected: {
      get() {
        return Array.isArray(this.value) ? this.value : this.value !== null ? [this.value] : []
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    getNamesSelected() {
      const selectedNames = this.selected.map(s => {
        const currentOption = this.options.find(o => o[this.valueField] === s)
        return currentOption ? currentOption.name : ""
      })
      return selectedNames.join(', ')
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/mixins.styl"
.custom-dropdown {
  width 100%
  border none
  height 50px
  margin 0

  ::v-deep {
    .dropdown-toggle {
      background-color color-gray-300
      border 2px solid color-blue-100
      border-radius(4px)
      padding 6px 38px 6px 20px
      width 100%
      position relative
      font-weight 700
      font-size 14px
      outline none
      text-align left
      flexbox(flex)
      align-items(center)
      box-shadow(transparent-shadow !important)

      &:focus {
        box-shadow(transparent-shadow !important)
      }

      span {
        font font-opensans-bold
        font-size 14px
        color color-blue-200
      }

      .custom-dropdown-button-icon {
        position absolute
        right 0.391rem
        top 0.313rem
        bottom 5px
        height auto
        font-size 2.2rem

        &:before {
          color color-blue-100
        }
      }

      &:active {
        background-color color-gray-300
      }
    }

    .dropdown-menu {
      background-color color-gray-300 !important
      text-align left
      font font-opensans-bold
      font-size 10px
      letter-spacing 0
      color color-black-300 !important
      text-transform uppercase
      padding 0
      border 2px solid color-blue-100
      border-radius(0 0 4px 4px)
      margin-top -1px
      width 100%
      min-width 100%
      max-height: 280px
      overflow-x: hidden
      overflow-y: auto

      .dropdown-item {
        padding 6px 38px 6px 20px
        font font-opensans-bold
        font-size 14px
        color color-blue-200
        line-height 2em

        &:hover
        &:active {
          color color-black-300 !important
          text-decoration none
          background-color #e9ecef
        }
      }

      li {
        padding 0 2px

        &:not(:first-of-type) {
          .dropdown-item {
            border-top 1px solid color-blue-100
          }
        }
      }
    }
  }

  &.show {
    ::v-deep {
      .dropdown-toggle {
        border-radius(4px 4px 0 0)
        border-bottom 2px solid transparent
        background-color: white
        border-color: color-blue-100
      }
    }
  }

  &.--md {
    height 40px
  }

  ::v-deep {
    .dropdown-toggle {
      &:after {
        width 29px
        height 29px
        top 3px
      }
    }
  }

  &.--secundary {
    height: 38px

    ::v-deep {
      .dropdown-toggle {
        background-color color-gray-500 !important
        border 2px solid color-gray-400
        color color-gray-700 !important
        font: font-opensans-bolder

        span {
          color color-black-200
          font: font-opensans-bolder
          font-size: 14px
        }

        .custom-dropdown-button-icon {
          font-size 1.7rem
        }

        &:active {
          background-color white
        }
      }

      .dropdown-menu {
        background-color color-gray-500 !important
        border 2px solid color-gray-400

        .dropdown-item {
          padding 6px 38px 6px 20px
          color color-black-200
          font: font-opensans-bolder
          font-size: 14px

          &:hover
          &:active {
            color color-black-300 !important
            text-decoration none
          }
        }

        li {
          padding 0 2px

          &:not(:first-of-type) {
            .dropdown-item {
              border-top 1px solid color-gray-400
            }
          }
        }
      }
    }
  }

  &.--secundary-ligth {
    font font-opensans-regular
    height: 38px

    ::v-deep {
      .dropdown-toggle {
        background-color color-gray-500 !important
        border 2px solid color-gray-400
        color color-gray-700 !important

        span {
          color color-black-200
          font font-opensans-regular
          font-size: 14px
        }

        .custom-dropdown-button-icon {
          font-size 1.65rem
        }

        &:active {
          background-color white
        }
      }

      .dropdown-menu {
        background-color color-gray-500 !important
        font font-opensans-regular
        border 2px solid color-gray-400

        .dropdown-item {
          padding 6px 38px 6px 20px
          font font-opensans-regular
          color color-black-200

          &:hover
          &:active {
            color color-black-300 !important
            text-decoration none
          }
        }

        li {
          padding 0 2px

          &:not(:first-of-type) {
            .dropdown-item {
              border-top 1px solid color-gray-400
            }
          }
        }
      }
    }
  }
}
</style>
