<template>
  <div>
    <EtagBanner />
    <header-layout v-show="isAuthenticate"/>
    <main :class="isHeader">
      <slot></slot>
    </main>
    <footer-layout/>
  </div>
</template>

<script>
import FooterLayout from './FooterLayout'
import HeaderLayout from './HeaderLayout'
import EtagBanner from "@/core/components/PageBase/EtagBanner.vue";

export default {
  name: 'MainLayout',
  components: {
    EtagBanner,
    FooterLayout,
    HeaderLayout
  },
  props: {
    isAuthenticate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isHeader: function () {
      return this.isAuthenticate ? 'container-principal md' : 'container-principal'
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.container-principal {
  background-color: color-gray-900
  margin: 0
  padding 1rem 0
  min-height: 94vh
  display: flex
  justify-content: center
  align-items: center

  &.md {
    min-height: 86vh
    display: block
  }
}

header-layout {
  height: 80px
  width 100%
  margin: 0
}

footer-layout {
  background-color color-blue-200
  width 100%
  height: 30px
  margin: 0
  position relative
}
</style>
