import * as labels from './mutation-types'
import { AuthService } from '@/core/services'

export const actions = {
  setUser ({ commit }) {
    commit(labels.FETCH_PROFILE_START)
    const user = AuthService.get()
    commit(labels.FETCH_PROFILE_DATA, user)
    return user
  }
}
