<template>
  <div class="container-attach-link">
    <div class="form-group">
      <div class="attach-name">
        <b-input type="text" v-model="formLink.label" trim class="form-control-attach-link"
                 placeholder="Link Title"/>
      </div>
      <div class="attach-url">
        <b-input type="url" v-model="formLink.url" class="form-control-attach-link"
                 placeholder="Link"/>
      </div>
    </div>
    <div class="form-group-action">
      <button @click="onAddLink" class="button attach">
        add
        <span class="icon-attach"></span>
      </button>
    </div>
    <div class="form-group-links">
      <p v-if="error" class="error"> {{ error }} </p>
      <ul>
        <li :key="'attach-link-'+index" v-for="(link, index) in value">
          <a target="_blank" class="text-normal" :href="link.url">{{ link.label }}
          </a>
          <button type="button" @click="onDeleteLink(index)"><span class="icon-delete"></span></button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AttachLink',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formLink: {
        label: '',
        url: ''
      },
      error: ''
    }
  },
  methods: {
    onAddLink() {
      this.error = ''

      if (!this.formLink.label) {
        this.error = 'Link Title is required'
        return
      }

      if (!this.formLink.url) {
        this.error = 'Link is required'
        return
      }

      this.validateUlr()

      if (!this.error) {
        this.error = ''
        this.value.push(this.formLink)
        this.formLink = {label: '', url: ''}
      }
    },
    validateUlr() {
      this.error = ''
      //eslint-disable-next-line
      const regex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/

      let newUrl
      try {
        newUrl = new URL(this.formLink.url)
      } catch {
        newUrl = false
      }

      if (!['http:','https:'].includes(newUrl.protocol) || !regex.test(this.formLink.url)) {
        this.error = 'A valid url is required'
      }
    },
    onDeleteLink(index) {
      this.value.splice(index, 1)
    }
  },
  mounted() {
    this.error = ''
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/common.styl"

.container-attach-link
  ::v-deep
    .form-control-attach-link
      border-color color-gray-400
      padding 7px 20px
      max-height unset
      height unset
      background-color color-gray-500

      placeholder-styles(@block{
        font font-opensans-regular
        font-size 14px
        color color-black-200
      })

.container-attach-link
  .form-group
    display flex
    flex-direction column

    &-action
      display flex
      justify-content(end)

      .btn-common
        font-size 12px

        span
          display inline-block
          transform rotate(45deg)
          padding-left 5px
          vertical-align middle

      button.attach
        font font-opensans-bold
        padding 2px 5px
        font-size 12px
        text-transform capitalize
        min-height unset
        min-width unset
        height unset
        width unset
        border-width 1px

        span
          display inline-block
          transform rotate(45deg)
          padding-left 0
          vertical-align middle

          &:before
            padding-left unset

    &-links
      ul
        padding 0
        margin 0
        list-style-type none
        display block

        & > li
          border 0
          padding 0
          display block
          color color-blue-100
          width: 90%
          word-break break-all

          a
            font font-opensans-bold
            font-size 14px
            color color-blue-400
            text-transform uppercase

          button
            border 0
            background-color transparent

            span
              font-size 0.7rem

              &:before
                color color-blue-100


  @media (min-width: 768px)
    .form-group
      flex-direction(row)

      .attach-name
        flex-basis(193px)

      .attach-url
        padding-left 10px
        flex 1

        .form-group
          flex-direction column

.error
  width 100%
  padding-top: 4rem
  text-align center
  font font-opensans-bold
  color red

</style>
