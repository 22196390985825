import { http } from './http'

const resource = 'api/admin'

export default {

  getLevels () {
    return http.get(`${resource}/levels`).then(response => {
      return response
    })
  }

}
