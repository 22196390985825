import { render, staticRenderFns } from "./StudentPayment.vue?vue&type=template&id=a2a2673e&scoped=true&"
import script from "./StudentPayment.vue?vue&type=script&lang=js&"
export * from "./StudentPayment.vue?vue&type=script&lang=js&"
import style0 from "./StudentPayment.vue?vue&type=style&index=0&id=a2a2673e&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2a2673e",
  null
  
)

export default component.exports