import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import '@/core/stylus/main.styl'
import '@/core/plugins'
import '@/core/directives'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
