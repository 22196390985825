import {
  FETCH_SUBJECT_LIST_START,
  FETCH_SUBJECT_LIST_DATA,
  FETCH_SUBJECT_DETAIL_START,
  FETCH_SUBJECT_DETAIL_DATA
} from './mutation-types'
import { SubjectService } from '../../services/'

export const actions = {
  fetchSubjectsList ({ commit }, { filters, pagination, sortBy }) {
    commit(FETCH_SUBJECT_LIST_START)
    return SubjectService.getSubjects(filters, pagination, sortBy).then((res) => {
      const subjects = res.data.map((elem) => ({
        ...elem,
        name: elem.name.length >= 15 ? `${elem.name.slice(0, 12)}...`: elem.name,
      }))
      commit(FETCH_SUBJECT_LIST_DATA, { subjects })
      return subjects
    })
  },
  fetchSubjectsDetail ({ commit }, id) {
    if (id) {
      commit(FETCH_SUBJECT_DETAIL_START)

      return SubjectService.getSubjectById(id).then((res) => {
        const data = res.data
        const subject = {
          ...data,
        }
        commit(FETCH_SUBJECT_DETAIL_DATA, subject)
      })
    } else {
      commit(FETCH_SUBJECT_DETAIL_DATA, {})
    }
  },
  updateSubjectsDetail ({ commit }, {id,data}) {
    if (id) {
      return SubjectService.updateSubject(id, data).then((res) => {
        const data = res.data
        const subject = {
          ...data,
        }
        commit(FETCH_SUBJECT_DETAIL_DATA, subject)
      })
    } else {
      commit(FETCH_SUBJECT_DETAIL_DATA, {})
    }
  },
}
