import Vue from 'vue'
import Vuex from 'vuex'
import UserStore from '@/core/store/User'
import CourseStore from '@/core/store/Course'
import TeacherStore from '@/core/store/Teacher'
import PartnershipsStore from '@/core/store/Partnership'
import UtilsStore from '@/core/store/Utils'
import SubjectStore from '@/core/store/Subject'
import BundleStore from '@/core/store/Bundle'
import AlertStore from '@/core/store/Alert'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    UserStore,
    CourseStore,
    TeacherStore,
    PartnershipsStore,
    UtilsStore,
    SubjectStore,
    BundleStore,
    AlertStore
  }
})
