<template>
  <div>
    <b-overlay :show="loading"
               spinner-medium
               rounded="sm">
      <b-col class="container-actions">
        <select-dropdown :options="options"
                         v-model="filterSelected"
                         @input="handleDropdown"
                         class="role-dropdown --secundary"
                         value-field="slug"/>
        <search-input class="--secundary"
                      v-model="search"
                      placeholder="PARENT ORG NAME SEARCH"
                      @on-search="handlerSearch"/>
        <b-button @click="openParentOrgDetail(null)" class="button button--resize">Create Parent Org</b-button>
      </b-col>
      <b-table :responsive="true"
               hover
               sort-icon-left
               :fields="fields"
               :items="organizationList"
               table-variant="ligth"
               head-variant="ligth"
               no-local-sorting
               id="admin-table"
               ref="table">
        <template #cell(name)="data">
          <a class="a-title a-title--name"
             @click="openParentOrgDetail(data.item.id)">
             {{ data.item.name }}</a>
        </template>
        <template #cell(schools)="data">
          <span class="a-title a-title--schools">{{ data.item.schools_count }}</span>
        </template>
        <template #cell(exchanges)="data">
          <div class="a-title a-title--exchanges">
            <span @click="openParentOrgExchange(data.item)">{{ data.item.exchange_tokens_count }}</span>
          </div>
        </template>
      </b-table>
    </b-overlay>
    <modal-parent-org-detail ref="modalParentOrgDetail"
                             :organization-id="organizationSelectedId"
                             @organization-saved="updateOrganizationList"/>
    <modal-parent-org-exchange-detail :parent-organization-id="organizationSelected.id"
                                      :parent-organization-name="organizationSelected.name"
                                      @on-success-account-tokens="getParentOrganization"
                                      @success-assignation-exchanges="getParentOrganization"
                                      ref="modalParentOrgExchangeDetail"/>
  </div>
</template>

<script>
import { AlertMixins } from '../Mixins'
import { SearchInput, SelectDropdown } from '@/core/components'
import { ParentOrganizationService } from '@/core/services'
import ModalParentOrgDetail from './ModalParentOrgDetail.vue'
import ModalParentOrgExchangeDetail from './ModalParentOrgExchangeDetail'

export default {
  name: 'ParentOrgList',
  mixins: [AlertMixins],
  components: {
    SearchInput,
    ModalParentOrgDetail,
    SelectDropdown,
    ModalParentOrgExchangeDetail
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'schools',
          label: '# Schools'
        },
        {
          key: 'exchanges',
          label: '# Exchanges'
        }
      ],
      options: [
        { name: 'All', slug: 0},
        { name: 'Associated', slug: 1}
      ],
      organizationList: [],
      filterSelected: 0,
      search: '',
      loading: false,
      organizationSelectedId: null,
      organizationSelected: {
        id: null,
        name: ''
      }
    }
  },
  async mounted() {
    this.loading = true
    this.getParentOrganization()
  },
  methods: {
    getParentOrganization() {
      this.loading = true
      let data = {
        associated: this.filterSelected
      }
      if (this.search) {
        data.name = this.search
      }
      ParentOrganizationService.getParentOrganizations(data).then((response) => {
        if (response.success) {
          this.organizationList = response.data
          if (!this.organizationList.length && this.search) {
            this.$bvModal.msgBoxOk('No matches found.').then(() => {
              this.search = ''
              this.filterSelected = 0
              this.getParentOrganization()
            })
          }
        } else {
          const errorMessage = response.message ? response.message : 'Error.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }).finally(() => this.loading = false)
    },
    updateOrganizationList() {
      this.getParentOrganization()
    },
    handlerSearch() {
      this.getParentOrganization()
    },
    handleDropdown() {
      this.search = ''
      this.getParentOrganization()
    },
    addAdmin() {
      this.$refs.modalAdminDetail.open()
    },
    openParentOrgDetail(organizationId) {
      if (organizationId) {
        this.organizationSelectedId = organizationId
      } else {
        this.organizationSelectedId = null
      }
      this.$nextTick(() => this.$refs.modalParentOrgDetail.openModalParentOrg())
    },
    openParentOrgExchange(organization) {
      this.organizationSelected = organization
      this.$nextTick(() => this.$refs.modalParentOrgExchangeDetail.open())
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.container-actions
  display flex
  justify-content space-between
  margin-bottom 10px

  .--secundary
    max-width 400px
    margin-right 10px
    margin-top 10px

  .role-dropdown
    max-width 350px

  @media(max-width 900px)
    display block

    .role-dropdown
      max-width 400px

.button--resize
  padding 5px 40px
  margin-top 10px
  height auto !important
  min-width 250px
  @media(max-width 550px)
    min-width auto

.a-title--name, .a-title--schools
  text-overflow ellipsis
  overflow hidden
  max-width 250px
  white-space nowrap
  display block
  cursor pointer
  @media(max-width 1000px)
    max-width 200px
  @media(max-width 800px)
    max-width 100px

.a-title--schools
  cursor auto

.a-title--exchanges
  span
    cursor pointer
    text-decoration underline
    font font-opensans-semibold
</style>
