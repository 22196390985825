<template>
  <div>
    <modal id="modal-teacher-create"
           modal-class="modal-task-detail"
           @hide="closeModalTeacherCreate()"
           :hide-footer="true">
      <template #body>
        <div class="form-wrapper">
          <h2 class="mt-5 title">CREATE TEACHER</h2>
          <upload-image-avatar v-model="$v.form.avatar.$model"
                               :validator="$v.form.avatar"
                               place-holder="Select Avatar *"
                               label="Select Avatar"
                               :custom-class="`--secundary ${form.avatar && 'form-upload-avatar'}`"
                               ref="upload-image-avatar"
                               @click-on-avatar="showAvatarsModal"
                               @File:loaded="onFileLoaded"/>

          <b-form-imput v-model="$v.form.firstName.$model"
                        :validator="$v.form.firstName"
                        label="First Name *"
                        custom-class="common-input common-input--primary"/>

          <b-form-imput v-model="$v.form.lastName.$model"
                        :validator="$v.form.lastName"
                        label="Last Name *"
                        custom-class="common-input common-input--primary"/>

          <b-form-imput v-model="$v.form.email.$model"
                        :validator="$v.form.email"
                        label="Email *"
                        name="email"
                        type="email"
                        custom-class="common-input common-input--primary"/>

          <b-form-textarea v-model.trim="$v.form.aboutInfo.$model"
                          :validator="$v.form.aboutInfo"
                          label="About *"
                          custom-class="common-input common-input--primary"/>

          <b-form-input-data-list id="dropdown-contries-list"
                                  v-model="$v.form.countryId.$model"
                                  :validator="$v.form.countryId"
                                  value-field="id"
                                  label="Country *"
                                  ref="countriesListInput"
                                  :options="countries"
                                  custom-class="common-input common-input--primary"/>

          <b-form-select v-if="showStateSelect"
                        v-model="$v.form.stateId.$model"
                        :validator="$v.form.stateId"
                        label="State *"
                        :options="states"
                        custom-class="--secundary actions-item-dropdown"/>

          <b-form-imput v-model="$v.form.cityName.$model"
                        :validator="$v.form.cityName"
                        label="City *"
                        custom-class="common-input common-input--primary"/>

          <b-form-input-data-list v-if="showSchoolSelect"
                                  id="dropdown-school-list"
                                  v-model="$v.form.schoolName.$model"
                                  :validator="$v.form.schoolName"
                                  label="Select Your School *"
                                  :options="schools"
                                  custom-class="common-input common-input--primary"
                                  @on-option-selected="onSchoolSelected"/>

          <span v-if="showSchoolDataInputs">
            <b-form-imput v-model="$v.form.schoolName.$model"
                          :validator="$v.form.schoolName"
                          label="School Name *"
                          custom-class="common-input common-input--primary"/>

            <b-form-textarea v-model.trim="$v.form.schoolAddress.$model"
                            :validator="$v.form.schoolAddress"
                            label="School Address *"
                            custom-class="common-input common-input--primary"/>

            <b-form-imput v-model="$v.form.schoolWebsite.$model"
                          :validator="$v.form.schoolWebsite"
                          label="School Website *"
                          custom-class="common-input common-input--primary"/>

            <b-form-imput v-model="$v.form.schoolPhoneNumber.$model"
                          :validator="$v.form.schoolPhoneNumber"
                          label="School Phone Number (Not Used For Marketing) *"
                          custom-class="common-input common-input--primary"/>
          </span>

          <div v-if="false">
            <h2 class="subtitle">Preferred communication method *</h2>
            <b-form-radio v-model="$v.form.preferredComunicationMethod.$model"
                          :options="communicationMethods"
                          name="comunication-methods"/>
            <div class="container-mobile-number">
              <b-form-imput v-model="$v.form.phoneCountryCode.$model"
                            :validator="$v.form.phoneCountryCode"
                            label="Country Code *"
                            :disabled="!isMobileNumber"
                            custom-class="common-input common-input--primary common-input--country-Code"/>

              <b-form-imput v-model="$v.form.phoneNumber.$model"
                            :validator="$v.form.phoneNumber"
                            label="Mobile Number *"
                            :disabled="!isMobileNumber"
                            custom-class="common-input common-input--primary"/>
            </div>
          </div>

          <b-form-checkbox-group v-model="$v.form.subjects.$model"
                                :validator="$v.form.subjects"
                                label="Subjects *"
                                :is-object-array="true"
                                value-field="id"
                                text-field="name"
                                class="custom-form-my-instruction"
                                :options="subjectsList"
                                name="subjects"
                                custom-class="custom-checkbox-primary custom-checkbox--one-column"/>

          <h2 class="subtitle">Student's Primary Language *</h2>
          <b-form-radio v-model="$v.form.nativeLanguageId.$model"
                        class="radio-buttons-container"
                        :options="getLanguages"/>

          <b-form-checkbox-group v-model="$v.form.instructionLanguages.$model"
                                :validator="$v.form.instructionLanguages"
                                label="Students Are Learning *"
                                v-if="isSubjectWorldLanguages"
                                :is-object-array="true"
                                value-field="id"
                                text-field="name"
                                class="custom-form-my-instruction"
                                :options="getLanguages"
                                name="subjects"
                                custom-class="custom-checkbox-primary custom-checkbox--two-columns"/>

          <b-form-checkbox-group v-model="$v.form.ageRanges.$model"
                                :validator="$v.form.ageRanges"
                                label="Student Age Range *"
                                :is-object-array="true"
                                value-field="id"
                                text-field="name"
                                class="custom-form-my-instruction"
                                :options="getAgeRanges"
                                name="subjects"
                                custom-class="custom-checkbox-primary custom-checkbox--two-columns"/>

          <b-form-checkbox-group v-model="$v.form.monthsAvailable.$model"
                                label="School is in Session *"
                                class="custom-form-my-instruction"
                                :validator="$v.form.monthsAvailable"
                                :options="months"
                                name="months_available"
                                custom-class="custom-checkbox-primary custom-checkbox--two-columns"/>

          <h2 class="subtitle">Teacher Type</h2>
          <select-dropdown :options="getTeacherTypes"
                            v-model="form.teacherTypeSelected"
                            class="--secundary"
                            @inputObject="handleTeacherType"
                            value-field="id"/>

          <h2 class="subtitle subtitle--password">Password *</h2>
          <b-form-input @input="validateForm"
                        @blur="validateForm"
                        v-model="form.password"
                        :class="{'form-group--error': !!errorsPassword.length}"
                        class="common-input common-input--primary"
                        type="password"/>

          <ul class="error-container">
            <li class="error"
                v-for="(error, idx) in errorsPassword"
                :key="error + idx">{{error}}</li>
          </ul>
        </div>
        <div class="container-buttons">
          <b-button @click="closeModalTeacherCreate" class="button">Cancel</b-button>
          <b-button @click="saveTeacher" class="button">SAVE</b-button>
        </div>
        <b-overlay no-wrap :show="isLoading"/>
      </template>
    </modal>
    <avatar-list @on-avatar-select="(avatarSelected) => form.avatar = avatarSelected"
                 @upload-avatar="openUploadFile"/>
  </div>
</template>

<script>
import Modal from '@/core/components/Modal/Modal'
import UploadImageAvatar from '@/core/components/UploadFileAttachLink/UploadImageAvatar'
import { required, requiredIf, minLength, maxLength, email } from 'vuelidate/lib/validators'
import AvatarList from '@/core/components/AvatarList/AvatarList'
import { UploadService } from '@/core/services'
import { Input, TextArea, InputDataList, Select, CheckboxGroup} from '@/core/components/Form'
import { UtilsMixins } from '../Mixins'
import CustomRadio from '@/core/components/CustomRadio'
import {TeacherService, CountriesService} from '@/core/services'
import { validatePassword } from '@/core/utils'
import { SelectDropdown } from '@/core/components'

export default {
  name: 'ModalTeacherCreate',
  mixins: [UtilsMixins],
  components: {
    Modal,
    UploadImageAvatar,
    AvatarList,
    'b-form-imput': Input,
    'b-form-textarea': TextArea,
    'b-form-input-data-list': InputDataList,
    'b-form-select': Select,
    'b-form-checkbox-group': CheckboxGroup,
    'b-form-radio': CustomRadio,
    SelectDropdown
  },
  data () {
    return {
      form: {
        avatar: '',
        firstName: '',
        lastName: '',
        email: '',
        aboutInfo: '',
        countryId: '',
        stateId: '',
        cityName: '',
        schoolId: '',
        schoolName: '',
        schoolAddress: '',
        schoolWebsite: '',
        schoolPhoneNumber: '',
        subjects: [],
        nativeLanguageId: 1,
        instructionLanguages: [],
        ageRanges: [],
        monthsAvailable: [],
        password: null,
        preferredComunicationMethod: 'email',
        phoneCountryCode: null,
        phoneNumber: null,
        teacherTypeSelected: 3
      },
      avatarFile: null,
      countries: [],
      states: [],
      schools: [],
      months: [
        {text: 'January', value: '1'},
        {text: 'February', value: '2'},
        {text: 'March', value: '3'},
        {text: 'April', value: '4'},
        {text: 'May', value: '5'},
        {text: 'June', value: '6'},
        {text: 'July', value: '7'},
        {text: 'August', value: '8'},
        {text: 'September', value: '9'},
        {text: 'October', value: '10'},
        {text: 'November', value: '11'},
        {text: 'December', value: '12'},
      ],
      errorsPassword: {},
      communicationMethods: [
        {name: 'Email', id: 'email', value: 'email'},
        {name: 'Text Message', id: 'text_message', value: 'text_message'},
        {name: 'WhatsApp', id: 'whatsapp', value: 'whatsapp'}
      ],
      isLoading: false,
      teacherTypeSlug: "teacher"
    }
  },
  validations: {
    form: {
      avatar: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      aboutInfo: {
        required
      },
      countryId: {
        required
      },
      stateId: {
        required: requiredIf(function() {
          return this.form.countryId && this.isUSA
        })
      },
      cityName: {
        required
      },
      schoolName: {
        required,
        maxLength: maxLength(250),
      },
      schoolAddress: {
        required: requiredIf(function() {
          return this.showSchoolDataInputs
        }),
        minLength: minLength(2),
        maxLength: maxLength(250),
      },
      schoolWebsite: {
        required: requiredIf(function() {
          return this.showSchoolDataInputs
        }),
        maxLength: maxLength(250)
      },
      schoolPhoneNumber: {
        required: requiredIf(function() {
          return this.showSchoolDataInputs
        }),
        minLength: minLength(2),
        maxLength: maxLength(250),
      },
      subjects: {
        required
      },
      nativeLanguageId: {
        required
      },
      instructionLanguages: {
        required: requiredIf(function() {
          return this.isSubjectWorldLanguages
        }),
      },
      ageRanges: {
        required
      },
      monthsAvailable: {
        required
      },
      preferredComunicationMethod: {
        required
      },
      phoneCountryCode: {
        required: requiredIf(function() {
          return this.isMobileNumber
        })
      },
      phoneNumber: {
        required: requiredIf(function() {
          return this.isMobileNumber
        })
      }
    }
  },
  props: {

  },
  async mounted () {
    await this.fetchSubjects()
    await this.fetchLanguages()
    await this.fetchAgeRange()
    await CountriesService.getCountries().then(({data}) => {
      this.countries = data
    })
  },
  computed: {
    isUSA() {
      const country = this.countries.find(country => country.id === this.form.countryId)
      return country && (country.iso.toLowerCase() === 'us')
    },
    showStateSelect() {
      return this.form.countryId && this.isUSA
    },
    isSchoolNotFind() {
      return this.form.schoolId === 'no_figure'
    },
    showSchoolSelect() {
      return this.showStateSelect && this.form.stateId && this.schools.length > 0 && !this.isSchoolNotFind
    },
    showSchoolDataInputs() {
      return (this.form.countryId && !this.isUSA)
        || (this.form.countryId && this.isUSA && this.form.stateId && this.isSchoolNotFind)
        || (this.form.countryId && this.isUSA && this.form.stateId && this.schools.length === 0)
    },
    isSubjectWorldLanguages() {
      let isWorldLanguages = null
      this.form.subjects.map(subject => {
        if (subject.type === 'world_languages') {
          return isWorldLanguages = subject.type
        }
      })
      return isWorldLanguages
    },
    isMobileNumber() {
      return this.form.preferredComunicationMethod !== 'email'
    },
    isLibrarian() {
      return this.teacherTypeSlug === 'teacher-librarian'
    },
    subjectsList() {
      return this.isLibrarian ? this.getSubjects : this.getSubjects.filter((subject) => subject.name !== 'Library')
    }
  },
  methods: {
    handleTeacherType (type) {
      this.teacherTypeSlug = type.slug
    },
    async validateForm () {
      this.errorsPassword = await validatePassword(this.form.password ? this.form.password : '')
    },
    closeModalTeacherCreate() {
      this.form = {
        avatar: '',
        firstName: '',
        lastName: '',
        email: '',
        aboutInfo: '',
        countryId: '',
        stateId: '',
        cityName: '',
        schoolId: '',
        schoolAddress: '',
        schoolName: '',
        schoolWebsite: '',
        schoolPhoneNumber: '',
        subjects: [],
        nativeLanguageId: 1,
        instructionLanguages: [],
        ageRanges: [],
        monthsAvailable: [],
        password: null,
        preferredComunicationMethod: 'email',
        phoneCountryCode: null,
        phoneNumber: null,
        teacherTypeSelected: 3
      }

      this.$refs.countriesListInput.clearPreviousInputValue()
      this.errorsPassword = {}
      this.$bvModal.hide('modal-teacher-create')
      this.$v.$reset()
    },
    openModalTeacherCreate() {
      this.$v.$reset()
      this.$bvModal.show('modal-teacher-create')
    },
    showAvatarsModal() {
      this.$bvModal.show('avatar-list-modal')
    },
    openUploadFile () {
      this.$refs["upload-image-avatar"].openUploadFile()
    },
    onFileLoaded(files) {
      if (files.length > 0) {
        const file = files[0]

        this.avatarFile = {
          file: file,
          index_file: 0,
          type_file: 'avatar'
        }

        this.form.avatar = URL.createObjectURL(file)
        this.$bvModal.hide('avatar-list-modal')
      }
    },
    async saveTeacher() {
      this.$v.form.$touch()
      await this.validateForm()
      if (this.$v.form.$invalid || this.errorsPassword?.length) {
        return
      }
      this.isLoading = true
      if (this.avatarFile) {
        let {file, index_file, type_file} = this.avatarFile

        const result = await UploadService.uploadFile(file, index_file, type_file)
        this.form.avatar = result.name
      }

      const profile = {...this.form}

      const data = {
        avatar: profile.avatar,
        first_name: profile.firstName,
        last_name: profile.lastName,
        email: profile.email,
        about_info: profile.aboutInfo,
        country_id: profile.countryId,
        state_id: profile.stateId,
        city_name: profile.cityName,
        school_id: profile.schoolId === 'no_figure' ? null : profile.schoolId,
        school_address: profile.schoolAddress,
        school_name: profile.schoolName,
        school_website: profile.schoolWebsite,
        school_phone_number: profile.schoolPhoneNumber,
        educational_affiliation: '',
        subjects: profile.subjects,
        native_language_id: profile.nativeLanguageId,
        instruction_languages: profile.instructionLanguages,
        age_ranges: profile.ageRanges,
        months_available: profile.monthsAvailable.join(),
        password: profile.password,
        terms_of_use_agree: "terms_of_use_agree",
        privacy_policy_agree: "privacy_policy_agree",
        preferred_communication: profile.preferredComunicationMethod,
        phone_number: this.filterNumbers(profile.phoneNumber),
        phone_country_code: this.filterNumbers(profile.phoneCountryCode),
        user_type_id: profile.teacherTypeSelected
      }

      TeacherService.createTeacher(data).then((res) => {
        if (res.success) {
          this.$bvModal.msgBoxOk('Teacher created successfully!').then(() => this.closeModalTeacherCreate())
        } else {
          const errorMessage = res.message ? res.message : 'Failed to create teacher.'
          this.$bvModal.msgBoxOk(`${res?.errors?.email ? res.errors.email[0] : errorMessage} Please try again.`)
        }
      }).catch((e) => {
      const errorMessage = e.message ? e.message : 'Failed to create teacher.'
      this.$bvModal.msgBoxOk(`${e?.errors?.email ? e.errors.email[0] : errorMessage} Please try again.`)
      }).finally(() => this.isLoading = false)
    },
    onSchoolSelected(school) {
      this.form.schoolId = school['id']
      this.form.schoolAddress = school['address'] ?? ''
      this.form.schoolWebsite = school['website'] ?? ''
      this.form.schoolPhoneNumber = school['phone_number'] ?? ''
    },
    filterNumbers(string) {
      return string?.split(/ /)[0].replace(/[^\d]/g, '')
    },
  },
  watch: {
    'form.countryId'(countryId) {
      if (typeof countryId === 'string') return
      TeacherService.states(countryId).then((response) => {
        this.states = response.data
      })
    },
    'form.stateId'(stateId) {
      if (!this.form.countryId || !stateId) return
      TeacherService.schools(this.form.countryId, stateId).then((response) => {
        this.schools = [...response.data]
      })
    },
    'form.schoolName'(newSchoolName) {
      !newSchoolName && (this.form.schoolId = null)
    },
    'teacherTypeSlug'() {
      if (!this.isLibrarian && this.form.subjects.some(subject => subject.name === 'Library')) {
        this.form.subjects = []
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl'
@import '~@/core/stylus/common.styl'

.form-wrapper
  padding 0 60px
  @media (max-width 1000px)
    padding 0

.title
  font font-opensans-bold
  font-size 24px
  margin-bottom 30px
  color color-black-200
  text-align center

.subtitle
  font font-opensans-semibold
  color #3c3c3c
  margin-bottom 0
  margin-top 30px

.subtitle--password
  margin-bottom 5px

.form-upload-avatar
  width 160px
  height 160px

  .form-upload-file
    padding 20px 0
    display flex
    flex-direction column
    height 100%
    justify-content end

    .form-file-text
      padding-bottom 25px

    .btn-input-file
      border-radius(4px)
      letter-spacing 0
      line-height 17px

.custom-form-my-instruction
  margin-top 20px

.container-mobile-number
  display flex
  ::v-deep
    .form-group:first-child
      max-width 70px
      margin-right 10px

      .error-content
        padding-left 20px

    .form-group:nth-child(2)
      width 100%
      margin-top 22px

::v-deep
  .custom-checkbox-primary
    display grid
    grid-template-columns 1fr 1fr 1fr
    gap 10px 10px
    grid-gap 10px 10px

  .custom-checkbox--two-columns
    grid-template-columns 1fr 1fr

  .custom-checkbox--one-column
    grid-template-columns 1fr

  .custom-checkbox
    .custom-control-label
      &::before
        width 1.5rem
        height 1.5rem
        top 0

      span
        margin-left 7px

    .custom-control-input
      width 24px
      height 24px

    .custom-control-input:checked ~ .custom-control-label::after
      font-size 1.5rem
      top 0

.form-group--error
    border-color color-red-error

  .error
    width 100%
    font font-opensans-regular
    font-size 14px
    color color-red-error

.container-buttons
  width 100%
  display flex
  justify-content center
  padding 40px 0

  .button
    margin 0 10px
</style>
