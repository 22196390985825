import { http } from './http'
import { AppStorage } from '@/core/utils'

const resource = '/api/admin'

export default {
  login (email, password) {
    return http.post(`${resource}/login`, {
      email: email,
      password: password
    }).then(response => {
      if (response.success === true) {
        AppStorage.setUser(response.data)
      }
      return response
    })
  },
  //  loginWithToken (token) {
  //    return http.post(`${resource}/token/refresh`, {
  //      refreshToken: token
  //    }).then(response => {
  //      AppStorage.setUserToken(response.data.token)
  //    })
  //  },
  logout () {
    // remove all data from local storage (including user) to log out
    AppStorage.clear()
  },
  get () {
    return AppStorage.getUser()
  },
  passwordRecoveryEmail(email) {
    return http.post(`${resource}/password/forgot`, {
      email: email
    }).then(response => {
        return response
      }
    )
  },
  passwordRecovery(password, email, token) {
    return http.post(`${resource}/password/recover`, {
      password: password,
      email: email,
      token: token
    }).then(response => {
        return response
      }
    )
  }
}
