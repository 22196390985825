const FETCH_TEACHER_LIST_START = 'FETCH_TEACHER_LIST_START'
const FETCH_TEACHER_CLASSROOMS_START = 'FETCH_TEACHER_CLASSROOMS_START'
const FETCH_TEACHER_LIST_DATA = 'FETCH_TEACHER_LIST_DATA'
const FETCH_TEACHER_DETAIL_DATA = 'FETCH_TEACHER_DETAIL_DATA'
const FETCH_TEACHER_CLASSROOMS_DATA = 'FETCH_TEACHER_CLASSROOMS_DATA'
const ADD_TEACHER_TOKENS_START = 'ADD_TEACHER_TOKENS_START'
const ADD_TEACHER_TOKENS_DATA = 'ADD_TEACHER_TOKENS_DATA'

export {
  FETCH_TEACHER_LIST_START,
  FETCH_TEACHER_LIST_DATA,
  FETCH_TEACHER_DETAIL_DATA,
  FETCH_TEACHER_CLASSROOMS_DATA,
  FETCH_TEACHER_CLASSROOMS_START,
  ADD_TEACHER_TOKENS_START,
  ADD_TEACHER_TOKENS_DATA
}
