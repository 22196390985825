<template>
  <modal ref="modalDetail" id="modal-token-expiration" modal-class="primary_modal" @hide=" $emit('modal-teacher-detail-hide')" :hideFooter=true>
    <template #header>
    </template>
    <template #body>
      <b-container>
        <b-row>
            <b-col cols="12">
              <h2 class="subtitle" v-if="isLibrary">Update Expiration Date</h2>
              <h2 class="subtitle" v-else>{{(isParentOrganization || isStudentReports) ? 'Details:' : 'Update Exchange Expiration Date'}}</h2>
              <div class="container-form">

                <b-form-group label-cols="4" label="Quantity" label-for="input-sm" v-if="isParentOrganization && !isStudentReports">
                  <b-form-input :value="quantity"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>
                <b-form-group label-cols="4" label="Amount Paid" label-for="input-sm" v-else>
                  <b-form-input :value="amoundPaid"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>
                <b-form-group label-cols="4" label="Purchased date" label-for="input-sm">
                  <b-form-input :value="dateFormat(purchaseDate)"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>
                <b-form-group label-cols="4" label="Expiration date" label-for="input-sm" v-if="!isStudentReports">
                  <b-form-input :value="dateFormat(expirationDate)"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>
                <b-form-group label-cols="4" label="Subject" label-for="input-sm">
                  <b-form-input :value="isLibrary ? 'Library' : subject"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>
                <b-form-group label-cols="4" label="Teacher" label-for="input-sm" v-if="!isStudentReports && !isLibrary">
                  <b-form-input :value="isLibrary ? 'N/A' : teacher"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>
                <b-form-group label-cols="4" label="Student" label-for="input-sm" v-if="isStudentReports">
                  <b-form-input :value="student"
                                autocomplete="off"
                                type="text" readonly disabled/>
                </b-form-group>

                <b-form-group label="New expiration date *" v-if="!isParentOrganization && !isStudentReports">
                  <b-input-group>
                    <b-form-input id="date-input" v-model="expire_at_formatted" type="text" placeholder="Due Date"
                                  autocomplete="off" readonly></b-form-input>
                    <b-input-group-append class="btn-picker">
                      <b-form-datepicker v-model="$v.new_expire_at.$model" button-only right
                                         :min="min_expire_at"
                                         :max="max_expire_at"
                                         locale="en-US"></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-form-group class="flex-column text-center">
                  <b-button class="button button--email mt-2" @click="close">Cancel</b-button>
                  <b-button class="button button--email mt-2 ml-3" v-if="!isParentOrganization && !isStudentReports" @click="saveTokens">Save</b-button>
                </b-form-group>
              </div>
            </b-col>

          <b-overlay :show="addingTokens || loading" no-wrap spinner-medium rounded="sm">
          </b-overlay>
        </b-row>
      </b-container>
    </template>
  </modal>
</template>

<script>
import {Modal} from '@/core/components'
import {TeacherMixins, UtilsMixins} from '../Mixins'
import {required} from "vuelidate/lib/validators"
import {LibrarianSubscriptionService} from "@/core/services"
import moment from 'moment'

export default {
  name: 'ModalTokenExpiration',
  mixins: [TeacherMixins, UtilsMixins],
  props: {
    isParentOrganization: {
      type: Boolean,
      default: false
    },
    isLibrary: {
      type: Boolean,
      default: false
    },
    isStudentReports: {
      type: Boolean,
      default: false
    },
    quantity: [Number, String],
    amoundPaid: [Number, String],
    purchaseDate: [Number, String],
    expirationDate: [Number, String],
    subject: [Number, String],
    teacher: [Number, String],
    student: [Number, String],
    subscriptionId: {
      type: Number
    },
  },
  data() {
    const expire_at = moment().add(1, 'd').format('YYYY-MM-DD')
    const max_expire_at = moment().add(2, 'y').add(6, 'M').format('YYYY-MM-DD')
    const maxExpireSubscription = moment().add(5, 'y').format('YYYY-MM-DD')

    return {
      min_expire_at: expire_at,
      max_expire_at: this.isLibrary ? maxExpireSubscription : max_expire_at,
      new_expire_at: expire_at,
      token_data: {},
      loading: false
    }
  },
  validations: {
    new_expire_at: {
      required: required
    },
  },
  components: {
    Modal,
  },
  methods: {
    open() {
      return new Promise((resolve) => {
        this.$refs.modalDetail.showModal()
        if (this.isLibrary) {
          const newExpireDate = moment(this.purchaseDate).add(1, 'y').add(1, 'd').format('YYYY-MM-DD')
          this.new_expire_at = this.expirationDate
          this.min_expire_at = newExpireDate
        }
        resolve(this)
      })
    },
    close() {
      return new Promise((resolve) => {
        this.$refs.modalDetail.hideModal()
        resolve(this)
      })
    },
    dateFormat(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    async saveTokens() {
      this.$v.new_expire_at.$touch()
      if (this.$v.new_expire_at.$anyError) {
        return
      }

      if (!this.addingTokens) {
        if (this.isLibrary) {
          this.loading = true
          LibrarianSubscriptionService.updateSubscriptionExpiration(this.subscriptionId, this.new_expire_at).then((resp) => {
            if (resp.success) {
              this.$bvModal.msgBoxOk('Successful!', {
                size: 'sm',
                dialogClass: "modal-message-box",
                noCloseOnBackdrop: true,
                centered: true
              }).then(() => {
                this.$bvModal.hide('modal-token-expiration')
              })
              this.$emit('expire-date-update')
            } else {
              const errorMessage = resp.message ? resp.message : 'Error fetching teachers list.'
              this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
                size: 'sm',
                dialogClass: "modal-message-box",
                noCloseOnBackdrop: true,
                centered: true
              })
            }
          }).finally(() => this.loading = false)
          return
        }
        try {
          const exchangePurchasesReportId = this.token_data.id
          const new_expire_at = this.new_expire_at

          await this.updateExpirationDate({exchangePurchasesReportId, new_expire_at})

          this.$emit('on-token-updated')
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error fetching teachers list.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        }
      }
    },
  },
  mounted() {
  },
  computed: {
    expire_at_formatted: function () {
      return moment(this.new_expire_at).format('MM/DD/YYYY')
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

#date-input
  margin-bottom 0 !important

.btn-picker
  .b-form-btn-label-control
    ::v-deep
      .btn
        margin 0

.teacher-about
  margin-bottom 40px !important

.container-top {
  margin: 0rem -1.2rem 1rem -1.2rem
  padding-bottom: .5rem
  padding-left: 1rem
  border-bottom: 2px solid red
  border-color: color-gray-400
  color: color-blue-300

  &-back {
    width: calc(100% + 1rem);
    margin: 0;
    margin-left: -0.5rem;
  }

  a {
    font-size: 1rem
    font-weight: bolder
    margin-top: 0
    margin-bottom: 1rem

    &.icon-caret-left:before {
      font-size: 1rem
    }
  }
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin 10px 0
}

.container-button {
  padding: 0.5rem;
  color: #2f3e98;

  display: flex
  flex-direction: row
  width 100%
  max-width 420px
  justify-content: center

  &-masquerade {
    border-bottom: 2px solid color-gray-400;
    margin-bottom 4rem
  }

  &-bottom {
    margin-bottom 2rem
  }
}

.container-text {
  display: flex

  .b-avatar {
    margin-top: 2rem
    width 120px
    height 120px
  }
}

.list-item {
  display: flex
  margin: .4rem 0
  justify-content: left

  p {
    text-align: left
    min-width 112px
    max-width: 230px
    margin .1rem .3rem
    overflow-wrap: break-word
  }
}

.title {
  text-transform: uppercase
  font-size: 24px
  font-weight: bold
  overflow-wrap: break-word
}

.teacher-report-container
  display block
  position relative

.subtitle {
  text-transform: uppercase
  font-size: 14px
  font-weight: bold
  margin-top 1rem

  &.reported {
    font: font-opensans-regular
    font-size: 14px
    text-transform: capitalize
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

p {
  font-size: 13px
  overflow-wrap: break-word
}

.container-form {
  margin 2rem 0

  .custom-checkbox {
    margin-top 1.5rem
  }
}

.text-date {
  font-size 14px !important
}

.input-email
  margin-top 8px

.button--email
  height auto
  padding-top 7.5px
  padding-bottom 7.5px

#modal-token-expiration {
  .modal.primary_modal .modal-dialog {
    min-width: unset;
  }
}

@media (min-width: 1200px) {
  .col-xl-7,
  .col-xl-5 {
    flex(0 0 100%);
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 1360px) {
  .col-xl-5 {
    flex(0 0 41.666667%);
    max-width: 41.666667%;
  }

  .col-xl-7 {
    flex(0 0 58.333333%);
    max-width: 58.333333%;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1500px;
  }
}

@media (min-width: 768px) {
  .container-left {
    border-right: 1px solid #e8ecee;
  }
}

</style>

<style lang="stylus">
#modal-token-expiration {
  .modal-dialog {
    min-width: unset;
  }
}
</style>
