export const getters = {
  getTeachers: (state) => {
    return state.teachers
  },
  isLoadingTeachers: (state) => {
    return state.loadingTeachers
  },
  isLoadingClassroom: (state)=> {
    return state.loadingClassrooms
  },
  getTeacherDetail: (state) => {
    return state.teacherDetail
  },
  getTeacherClassrooms: (state) => {
    return state.teacherClassrooms
  },
  addingTokens: (state) => {
    return state.addingTokens
  }
}
