import { http } from './http'

const resource = 'api/admin'

export default {

  getCountries () {
    return http.get(`${resource}/countries`).then(response => {
      return response
    })
  }

}
