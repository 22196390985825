<template>
  <div v-if="buildHashChanged" class="buildHash-changed-banner">
    A new version of the application is available, please refresh the browser.
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EtagBanner',
  data () {
    return {
      buildHash: null,
      buildHashChanged: false,
      buildHashChecking: false
    }
  },
  mounted () {
    this.buildHashInit()
  },
  methods: {
    buildHashInit () {
      setInterval(() => {
        this.buildHashCheck()
        // }, 60000)
      }, 10000)
    },
    async buildHashCheck () {
      if (!this.buildHashChecking) {
        this.buildHashChecking = true

        let buildHash;


        if (process.env.NODE_ENV === 'dev') {
          let res = await axios.get('/js/app.js')
          buildHash = res.headers.etag;
        } else {
          let res = await axios.get('/index.html')
          const appFile = res.data.toString().match(/\/js\/app\.[\w\d]+\.js/);
          if (appFile) {
            buildHash = appFile[0].split('.')[1];
          }
        }

        if (!this.buildHash) {
          this.buildHash = buildHash
        } else {
          if (buildHash !== this.buildHash) {
            this.buildHashChanged = true
          }
        }

        this.buildHashChecking = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.buildHash-changed-banner
  text-align center
  background-color color-red-error
  color color-39
  font-size .9rem
  font-weight 800
</style>
