import { mapActions, mapGetters } from 'vuex'

export const PartnershipsMixins = {
  computed: {
    ...mapGetters({
      getClassroomPartnership: 'PartnershipsStore/getClassroomPartnership',
      isLoadingPartnership: 'PartnershipsStore/isLoadingPartnership',
      updatingPartnership: 'PartnershipsStore/updatingPartnership',
      getError: 'PartnershipsStore/getError',
    })
  },
  methods: {
    ...mapActions({
      fetchPartnership: 'PartnershipsStore/fetchPartnership',
      resetPartnership: 'PartnershipsStore/resetPartnership',
      updatePartnershipEndDate: 'PartnershipsStore/updatePartnershipEndDate'
    })
  }
}
