<template>
  <div class="footer-social">
    <div class="copyright">Copyright Level Up Village {{getCurrentYear()}} Student Privacy Notice</div>
    <div class="social">
      <span class="icon-facebook"></span>
      <span class="icon-linkedin"></span>
      <span class="icon-instagram"></span>
      <span class="icon-youtube"></span>
      <span class="icon-twitter"></span>
    </div>
  </div>
</template>

<script>
import moment from "moment"
export default {
  name: 'Social',
  methods: {
    getCurrentYear () {
      return moment().year()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.footer-social {
  display flex
  height 100%
  align-items center
  justify-content space-between
  flex-direction row
  background-color: color-blue-100

  .copyright {
    font-size .8rem
    font-family "Open Sans", sans-serif
    color white
    padding: 1rem
  }

  .social {
    padding 1rem 3.5rem
    span {
      vertical-align middle
      padding  .5rem
      font-size 1rem

      &:before {
        color white
      }
    }
  }
}
</style>
