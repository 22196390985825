<template>
  <section class="container-task">
    <h2 class="title">{{titleTable}}</h2>
    <b-table responsive
             hover
             :fields="fields"
             :items="courseTasks"
             ref="taskList"
             id="taskList"
             :current-page="currentPage"
             :per-page="perPage"
             table-variant="ligth"
             head-variant="ligth">
      <template #cell(task_name)="data">
        <a class="a-title" @click="$emit('on-click-name', data.value.id)">
          {{ cutString(data.value.name) }}
        </a>
      </template>
      <template #cell(visible)="visible">
        <p @click="updateVisible(visible)"
           :class="visible.value ? 'fa fa-eye' : 'fa fa-eye-slash'"></p>
      </template>
      <template #cell(topic)="data">
        {{ cutString(data.item.topic) }}
      </template>
      <template #cell(order)="data">
        <input v-model="data.item.order"
               type="number"
               min="1"
               :max="courseTasks.length"
               required
               @change="updateOrder(data)"
               class="order-input">
      </template>
    </b-table>
    <b-col class="col-5 mx-auto">
      <b-pagination ref="pagination"
                    v-show="showPagination"
                    v-model="currentPage"
                    :total-rows="courseTasks.length"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="fill"
                    size="sm"
                    class="my-3 mx-5">
        <template #prev-text><i class="icon-chevron-left"></i></template>
        <template #next-text><i class="icon-chevron-right"></i></template>
      </b-pagination>
      <p v-show="errorOrder" class="error">{{ errorOrder }}</p>
    </b-col>
    <b-overlay no-wrap :show="loadingTask"/>
  </section>
</template>

<script>
import {CourseService} from '@/core/services'
import {CourseMixins, UtilsMixins} from '../Mixins'

export default {
  name: 'TaskListTable',
  mixins: [CourseMixins, UtilsMixins],
  components: {

  },
  props: {
    courseTasks: {
      type: Array,
      default: function() {
        return []
      }
    },
    disableTask: {
      type: Boolean
    },
    titleTable: {
      type: String
    }
  },
  data() {
    return {
      loadingTask: false,
      errorOrder: '',
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: 'task_name',
          label: 'TASK NAME'
        },
        {
          key: 'topic',
          label: 'TOPIC'

        },
        {
          key: 'order',
          label: 'ORDER',
        },
        {
          key: 'visible',
          label: 'VISIBLE'
        }
      ],
    }
  },
  methods: {
    cutString(string) {
      return string ? string.length > 9 ? `${string.slice(0, 9)}...` : string : ''
    },
    updateOrder(data) {
      if (data.item.order <= 0) {
        this.errorOrder = 'Invalid Value must be greater than or equal to 1'
      } else if (data.item.order > this.courseTasks.length) {
        this.errorOrder = `Invalid Value must be less than or equal to ${this.courseTasks.length}`
      } else {
        this.errorOrder = ''
        this.loadingTask = true
        const order = this.courseTasks.map((elem) => ({id: elem.task_name.id, order: elem.order}))
        CourseService.orderTask(this.$route.params.id, {tasks: order})
          .catch((e) => {
            const errorMessage = e.message ? e.message : 'Error while change order.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          })
          .finally(() => {
            this.fetchTaskList(this.$route.params.id).then(() => {
              this.$refs.taskList.refresh()
              this.loadingTask = false
            })
          })

      }
    },
    updateVisible(data) {
      this.loadingTask = true
      CourseService.visibleTask(this.$route.params.id, data.item.task_name.id, !data.item.visible)
        .catch((e) => {
          const errorMessage = e.message ? e.message : 'Error while updating Task.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }).finally(() => {
        this.fetchTaskList(this.$route.params.id).then(() => {
          this.$refs.taskList.refresh()
          this.loadingTask = false
        })
      })
    },
  },
  computed: {
    showPagination() {
      return this.courseTasks.length > 10
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

a {
  color: color-blue-100
  font font-opensans-bold
  font-size: 17px
  text-decoration none
  margin-top: 0
  margin-bottom: 1rem

  .icon-caret-left {
    color: color-blue-100
  }
}

.container-form {
  width: 80%
  margin: 1px auto
}

.container-task {
  width: 100%
  margin: 1px auto
}

.container-button {
  padding: 1rem 0
  padding-top: 0
  width 90%
  margin 0

  .button {
    margin 0 2% 0 3%
    margin-top: 1rem
  }
}

.title {
  font font-opensans-bold
  font-size: 24px
  color: color-black-200
}

.subtitle {
  font-size 14px
  color color-black-300
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin-bottom: 10px
}

.order-input {
  height 3rem
  width 2.6rem
  text-decoration: none
  text-align: center
  cursor: pointer
  padding-left: 11px
  border: 2px solid color-gray-200
  border-radius: 4px
}

.top {
  width: 105%
  margin: 0rem 0rem 1rem -1.5rem
  padding-bottom: .5rem
  border-bottom: 2px solid red
  border-color: color-gray-400
}

.create-task-button {
  width: 100% !important;
  max-width: 300px !important;
}

</style>
