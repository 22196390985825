import { mapActions, mapGetters } from 'vuex'
export const CourseMixins = {
  computed: {
    ...mapGetters({
      getCourses: 'CourseStore/getCourses',
      isLoadingCourses: 'CourseStore/isLoadingCourses',
      getCourseDetail: 'CourseStore/getCourseDetail',
      getCourseTask: 'CourseStore/getCourseTask',
      getDetailTask: 'CourseStore/getDetailTask',
      getPagination: 'CourseStore/getPagination',
      getCourseTeacherTasks: 'CourseStore/getCourseTeacherTasks'
    })
  },
  methods: {
    ...mapActions({
      fetchCoursesList: 'CourseStore/fetchCoursesList',
      fetchCoursesDetail: 'CourseStore/fetchCoursesDetail',
      fetchTaskList: 'CourseStore/fetchTaskList',
      fetchTaskDetails: 'CourseStore/fetchTaskDetails',
      resetTaskDetail: 'CourseStore/resetTaskDetail',
      resetCourseDetail: 'CourseStore/resetCourseDetail',
      updateVideoTimeLimit: 'CourseStore/updateVideoTimeLimit'
    })
  }
}
