import { http } from "./http";

const resource = "api/admin";

export class PartnershipsError extends Error {
  constructor(message) {
    super(message);
    this.name = "PartnershipsError";
  }
}

export class PartnershipSmallGroupOrWholeClassroomError extends Error {
  constructor(message) {
    super(message);
    this.name = "PartnershipSmallGroupOrWholeClassroomError";
  }
}

export default {
  getPartnership(classroom_code) {
    return http
      .get(`${resource}/partnerships/${classroom_code}`)
      .then((response) => {
        if (!response.success)
          throw new PartnershipsError(
            response.message ?? "Error while loading classroom."
          );
        return response;
      });
  },
  createPartnership(classroom_code, data) {
    return http
      .post(`${resource}/partnerships/${classroom_code}`, data)
      .then((response) => {
        return response;
      });
  },
  deletePartnership(classroom_code, keep_student_data) {
    return http
      .delete(`${resource}/partnerships/${classroom_code}`, {
        data: keep_student_data,
      })
      .then((response) => {
        return response;
      });
  },
  updatePartnerShipEndDate(classroom_code, end_date) {
    return http
      .put(`${resource}/partnerships/${classroom_code}`, { end_date: end_date })
      .then((response) => {
        return response;
      });
  },
  updatePartnershipCourse(classroom_code, data) {
    return http
      .post(`${resource}/partnership-course/${classroom_code}`, data)
      .then((response) => {
        return response;
      });
  },
  saveStudentPayment(classroomCode, { paymentRequired, paymentPrice }) {
    return http
      .put(`${resource}/classrooms/${classroomCode}`, {
        student_payment_required: paymentRequired,
        student_payment_price: paymentPrice,
      })
      .then((response) => {
        return response;
      });
  },
  getClassroom(classroomCode) {
    return http
      .get(`${resource}/classrooms/${classroomCode}`)
      .then((response) => {
        return response.data;
      });
  },
};
